import { createModuleEpics } from "@app/modules/app.reducers";
import { AuditTrail } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { moduleActions } from "./auditTrail.actions";
import { auditTrailDataSelectors } from "./auditTrail.selectors";
import { AUDIT_TRAIL_COLUMNS_CONFIG } from "./auditTrail.table";

const auditTrailModuleEpics = createModuleEpics<AuditTrail>(moduleActions, {
  normalizeItem: (its) => its,
  getRequestParams: (state, tableId) => {
    const tableParams = auditTrailDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, AUDIT_TRAIL_COLUMNS_CONFIG, {
      defaultOrder: ["id DESC"],
    });

    // params.groupBy = "transactionId";
    return params;
  },
  getSingleRequestParams: (params) => {
    return params;
  },
  api: {
    createItem: (() => {}) as any,
    deleteItem: (() => {}) as any,
    editItem: (() => {}) as any,
    getItems: (params) => {
      const mode = (params.filter?.where as any)?.mode;
      if (mode) {
        delete (params.filter?.where as any)?.mode;
      }

      switch (mode) {
        case "script":
          return API.getAuditTrailScript(params);
        case "project":
          return API.getAuditTrailProject(params);
        case "settings":
          return API.getAuditTrailSettings(params);
        default:
          return API.getAuditTrail(params);
      }
    },
    getItemsCount: (params) => {
      const mode = (params?.where as any)?.mode;
      if (mode) {
        delete (params?.where as any)?.mode;
      }
      switch (mode) {
        case "script":
          return API.getAuditTrailScriptCount(params);
        case "project":
          return API.getAuditTrailProjectCount(params);
        case "settings":
          return API.getAuditTrailSettingsCount(params);
        default:
          return API.getAuditTrailCount(params);
      }
    },
  },
});

export default auditTrailModuleEpics;
