import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import { ApplicationState } from "@app/modules/app.reducers";
import CreateEditScriptForm from "@app/modules/scripts/components/CreateEditScriptForm";
import { scriptsActions } from "@app/modules/scripts/scripts.actions";
import {
  getScriptWithBasicVariablesSelector,
  isNotEditableScriptSelector,
} from "@app/modules/scripts/scripts.selectors";
import { API } from "@app/services/api/api";
import { validateEnvironmentFormValues } from "@app/utils/systemDictionary";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import { EntityType, URL_SELECT_MODE, ROLES } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import DataSourceContainer from "./DataSource.container";
import WithAuthorization from "../common/WithAuthorization";
import { currentUserRolesSelector } from "@ea/shared_components/auth/auth.selectors";
import { stepsTableActions } from "../steps/steps.actions";
import { STEPS_TABLE_ID } from "../steps/steps.table";
import { bindActionCreators, Dispatch } from "redux";
import {
  getProjectsTreeSelector,
  projectSelectors,
  getProjectPath,
} from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import { isSame } from "@ea/shared_components/utils/array";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { generateTemplateName } from "@app/utils/transformations";
import { Button } from "antd";
import { toast } from "react-toastify";
import { DataTestIds } from "@app/utils/dataTestIds";

interface IScriptDetailsProps {
  scriptId: number;
  testPlansFormChunk?: (() => React.ReactNode)[];
  loadingIntegrationData?: boolean;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
});

const ContainerElement = styled.div({
  display: "flex",
  flexDirection: "column",
  margin: "10px",
});

const AuthorizedButton = WithAuthorization([], [ROLES.freedocs])(Button as any) as any;
class ScriptDetails extends React.Component<IScriptDetailsProps & IConnectProps, any> {
  shouldComponentUpdate(nextProps: IScriptDetailsProps & IConnectProps, nextState) {
    return (
      JSON.stringify(this.props.script) !== JSON.stringify(nextProps.script) ||
      this.props.scriptId !== nextProps.scriptId ||
      this.props.project !== nextProps.project ||
      !isSame(this.props.projects, nextProps.projects) ||
      !isSame(this.props.userRoles, nextProps.userRoles) ||
      this.props.isNotEditable !== nextProps.isNotEditable ||
      !!this.props.testPlansFormChunk !== !!nextProps.testPlansFormChunk ||
      this.props.loadingIntegrationData !== nextProps.loadingIntegrationData
    );
  }

  editScript = async (values) => {
    validateEnvironmentFormValues(values);

    const { urlSwitch, ...rest } = values;
    await API.patchScript({
      id: this.props.scriptId,
      ...rest,
      isManualUrl: urlSwitch === URL_SELECT_MODE.MANUAL,
    });

    this.props.loadSingle({ id: this.props.scriptId });
    this.props.loadSteps({
      query: {
        taskScriptId: this.props.scriptId,
      },
    });
  };

  onGenerateTemplateClick = async () => {
    const { script } = this.props;
    if (script) {
      const { id, name } = script;
      try {
        const blob = await API.generateTemplate({ scriptId: id });
        downloadFileBlob(blob, generateTemplateName({ id, name }));
      } catch (error) {
        toast.error(
          <FormattedMessage id={getTranslationKey("scripts", "error", "errorGenerateTemplate")} />,
        );
      }
    }
  };

  getUrlOptions = () => {
    const { userRoles } = this.props;

    const options = [
      {
        text: getTranslationKey("common", "label", "manualUrl"),
        value: URL_SELECT_MODE.MANUAL,
      },
    ];
    if (!userRoles.includes(ROLES.freedocs)) {
      options.push({
        text: getTranslationKey("common", "label", "environment"),
        value: URL_SELECT_MODE.ENVIRONMENT,
      });
    }
    return options;
  };

  render() {
    const { script, scriptId, projects, project, isNotEditable, testPlansFormChunk, projectPath } =
      this.props;
    if (!script) {
      return (
        <Container>
          <h3>
            <FormattedMessage id={getTranslationKey("scripts", "header", "noScriptSelect")} />
          </h3>
        </Container>
      );
    }

    const DataSourceContainerElement = WithAuthorization(
      [],
      [ROLES.freedocs],
    )(() => (
      <ContainerElement style={{ maxWidth: "450px" }}>
        <h1>
          <FormattedMessage id={getTranslationKey("common", "header", "dataSource")} />
        </h1>
        <DataSourceContainer
          parentId={scriptId}
          parentType={EntityType.SCRIPT}
          viewOnly={isNotEditable}
        />
        <AuthorizedButton
          type="primary"
          key="generateTemplate"
          onClick={() => this.onGenerateTemplateClick()}
          data-testid={DataTestIds.BUTTON_GENERATE_TEMPLATE}
        >
          <FormattedMessage
            id={getTranslationKey("step", "detailsForm", "label", "generateTemplate")}
          />
        </AuthorizedButton>
      </ContainerElement>
    ));

    const {
      environmentId,
      startUrl,
      description,
      virtualUserId,
      takeScreenshots,
      showActionHint,
      hideComments,
      projectId,
      timeout,
      isManualUrl,
      name,
      clickOnDisabledButtons,
      tags,
      integrationMetadata,
      documentationTemplate,
      reportTemplate,
      autoRefreshDatasource,
      useVirtualUser,
      screenshotsOnlyOnInterruption,
      resolvers,
      skipLinkedScriptsInDocumentation,
      disableStartStep,
    } = script;

    return (
      <Container>
        <ContainerElement style={{ width: "50%" }}>
          <h1>{<FormattedMessage id={getTranslationKey("scripts", "header", "generalInfo")} />}</h1>

          <EditeableAreaFinal
            disableEditing={isNotEditable}
            onSave={this.editScript}
            initialValues={{
              name,
              description,
              defaultSystemId: project.defaultSystemId,
              defaultVirtualUserId: project.defaultVirtualUserId,
              defaultSystem: isManualUrl || !script.environmentId,
              defaultVirtualUser: isManualUrl || !script.virtualUserId,
              startUrl,
              environmentId,
              urlSwitch: !isManualUrl ? URL_SELECT_MODE.ENVIRONMENT : URL_SELECT_MODE.MANUAL,
              virtualUserId,
              takeScreenshots,
              screenshotsOnlyOnInterruption,
              showActionHint,
              resolvers,
              hideComments,
              projectId,
              timeout,
              clickOnDisabledButtons,
              tags: tags ? tags.map((t) => t.id) : [],
              integrationMetadata,
              documentationTemplate,
              reportTemplate,
              autoRefreshDatasource,
              useVirtualUser,
              skipLinkedScriptsInDocumentation,
              disableStartStep,
            }}
            onCancel={() => {}}
            render={({ values, readOnly, ...form }) => (
              <CreateEditScriptForm
                projects={projects}
                values={values}
                form={form}
                urlOptions={this.getUrlOptions()}
                additionalFormChunks={testPlansFormChunk}
                readOnly={readOnly}
                projectPath={projectPath}
              />
            )}
          />
        </ContainerElement>
        <DataSourceContainerElement />
      </Container>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: IScriptDetailsProps) => {
    const script = getScriptWithBasicVariablesSelector(state, props.scriptId);
    return {
      script,
      scriptId: props.scriptId,
      isLoading: state.scripts.isLoading,
      userRoles: currentUserRolesSelector(state),
      projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
      project: projectSelectors.getItemSelector(state, script!.projectId) || {},
      projectPath: getProjectPath(state, script!.projectId),
      isNotEditable: isNotEditableScriptSelector(state, props.scriptId),
    };
  },
  (dispatch: Dispatch) => ({
    ...bindActionCreators(scriptsActions, dispatch),
    loadSteps: bindActionCreators(stepsTableActions(STEPS_TABLE_ID), dispatch).setPersistentQuery,
  }),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ScriptDetails);
