import * as React from "react";
import styled from "@emotion/styled";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import {
  AUDIT_TRAIL_TABLES_CONFIG,
  AUDIT_TRAIL_COLUMNS,
  AUDIT_TRAIL_PROJECT_COLUMNS,
  AUDIT_TRAIL_SCRIPT_COLUMNS,
  AUDIT_TRAIL_SETTINGS_COLUMNS,
} from "./auditTrail.table";
import { getAuditTrailTableActions } from "./auditTrail.actions";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { printFormValues } from "@ea/shared_components/Form/FormDebugElements";
import { FormattedMessage } from "react-intl";
import { DataTestIds } from "@app/utils/dataTestIds";
import { AuditTrail, URL_SELECT_MODE } from "@ea/shared_types";
import StepDetailsForm from "../steps/components/Forms/StepDetailsForm";
import AuditTrailAutoValidateForm from "./AuditTrailAutoValidateForm";
import { diff } from "deep-object-diff";
import CreateEditScriptForm from "../scripts/components/CreateEditScriptForm";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { getProjectsTreeSelector } from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import ConditionalForm from "@app/modules/guards/components/ConditionalForm";
import { kpisDataSelectors } from "../kpis/kpis.selectors";
import { KPIS_TABLES_CONFIG } from "../kpis/kpis.table";
import { CODE_TEMPLATES_TABLES_CONFIG } from "../codeTemplates/codeTemplates.table";
import { getGlobalMutable, getGlobalConstant } from "../globalVariables/globalVariables.selectors";
import { GLOBAL_VARIABLES_TABLES_CONFIG } from "../globalVariables/globalVariables.table";
import { getGlobalVariablesTableActions } from "@app/modules/globalVariables/globalVariables.actions";
import { bindActionCreators } from "redux";
import { InputField } from "@ea/shared_components/Form/Fields/InputField";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { systemDictionaryDataSelectors } from "../systemDictionary/systemDictionary.selectors";
import { SYSTEM_DICTIONARY_TABLES_CONFIG } from "../systemDictionary/systemDictionary.table";
import { virtualUsersDataSelectors } from "../virtualUsers/virtualUsers.selectors";
import { VIRTUAL_USERS_TABLES_CONFIG } from "../virtualUsers/virtualUsers.table";
import { getSystemTableActions } from "../systemDictionary/systemDictionary.actions";
import { getVirtualUsersTableActions } from "../virtualUsers/virtualUsers.actions";
import { getKpisTableActions } from "../kpis/kpis.actions";
import CreateSystemForm from "@app/modules/systemDictionary/components/CreateSystemForm";
import CreateEditVirtualUser from "../virtualUsers/components/CreateEditVirtualUser";
import ProjectForm from "../projects/components/ProjectForm";
import i18next from "i18next";
import GlobalVariablesForm from "../globalVariables/components/GlobalVariablesForm";
import { getCodeTemplatesTableActions } from "../codeTemplates/codeTemplates.actions";
import { codeTemplatesDataSelectors } from "../codeTemplates/codeTemplates.selectors";
import { usersDataSelectors } from "../users/users.selectors";
import { USERS_TABLES_CONFIG } from "../users/users.table";
import { getUsersTableActions } from "../users";

const { Panel: CollapsePanel } = Collapse;

const Wrapper = styled.div({
  width: "100%",
  height: "100%",
});

export const AuditResultWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
});

export const AuditData = styled.pre({
  border: "1px solid #ccc",
  background: "rgba(0, 0, 0, 0.1)",
  boxShadow: "inset 1px 1px 3px rgba(0, 0, 0, 0.2)",
  padding: "20px",
  maxWidth: "48%",
  width: "48%",
  wordWrap: "break-word",
  whiteSpace: "pre-wrap",
});

interface IAuditTrailProps {
  scriptMode?: boolean;
}

interface IAuditTrailState {
  persistentQuery: { mode: AuditTrailMode };
}

enum AuditTrailMode {
  SCRIPT = "script",
  PROJECT = "project",
  NORMAL = "normal",
  SETTINGS = "settings",
}

const connectedTableSettingsMap = {
  [AuditTrailMode.NORMAL]: {
    columnConfig: AUDIT_TRAIL_COLUMNS,
    tableId: AUDIT_TRAIL_TABLES_CONFIG.AUDIT.main,
    preferencesId: AUDIT_TRAIL_TABLES_CONFIG.AUDIT.preferences,
    key: AuditTrailMode.NORMAL,
  },
  [AuditTrailMode.SCRIPT]: {
    columnConfig: AUDIT_TRAIL_SCRIPT_COLUMNS,
    tableId: AUDIT_TRAIL_TABLES_CONFIG.SCRIPT.main,
    preferencesId: AUDIT_TRAIL_TABLES_CONFIG.SCRIPT.preferences,
    key: AuditTrailMode.SCRIPT,
  },
  [AuditTrailMode.PROJECT]: {
    columnConfig: AUDIT_TRAIL_PROJECT_COLUMNS,
    tableId: AUDIT_TRAIL_TABLES_CONFIG.SCRIPT.main,
    preferencesId: AUDIT_TRAIL_TABLES_CONFIG.SCRIPT.preferences,
    key: AuditTrailMode.PROJECT,
  },
  [AuditTrailMode.SETTINGS]: {
    columnConfig: AUDIT_TRAIL_SETTINGS_COLUMNS,
    tableId: AUDIT_TRAIL_TABLES_CONFIG.SETTINGS.main,
    preferencesId: AUDIT_TRAIL_TABLES_CONFIG.SETTINGS.preferences,
    key: AuditTrailMode.SETTINGS,
  },
};

class AuditTrailContainer extends React.Component<
  IAuditTrailProps & IConnectProps,
  IAuditTrailState
> {
  connectedTable: any;
  myRef: any;
  valid: any;

  constructor(props: IAuditTrailProps & IConnectProps) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      persistentQuery: { mode: AuditTrailMode.SCRIPT },
    };
  }

  async componentDidMount() {
    const {
      globalVariablesActions,
      systemActions,
      virtualUsersActions,
      kpisActions,
      codeTemplateActions,
      usersActions,
    } = this.props;

    await Promise.all([
      globalVariablesActions.load({}),
      systemActions.load({}),
      virtualUsersActions.load({}),
      kpisActions.load({}),
      codeTemplateActions.load({}),
      usersActions.load({}),
    ]);

    // this.myRef.current.submit();
  }

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  filterModifiedFields = (record) => {
    const keys = Object.keys(record.changedFields);
    const filtered = Object.keys(record.rowData)
      .filter((key) => keys.includes(key))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: record.rowData[key],
        }),
        {},
      );
    return filtered;
  };

  getBeforeData = (record) => {
    switch (record.action) {
      case "I":
        return;
      case "U":
        return printFormValues(this.filterModifiedFields(record));
      case "D":
        return printFormValues(record.rowData);
      default:
        return record.text;
    }
  };

  getAfterData = (record) => {
    switch (record.action) {
      case "I":
        return printFormValues(record.rowData);
      case "U":
        return printFormValues(record.changedFields);
      case "D":
        return;
      default:
        return record.text;
    }
  };

  getScriptViewChunks = ({ data, tableName, recordDiff, action, diffKeys }) => {
    const { globalConstant, globalMutable, systems, virtualUsers, codeTemplates } = this.props;

    if (tableName === "taskstep") {
      //only when linenum is changed
      if (
        (diffKeys.length === 1 || diffKeys.length === 2) &&
        diffKeys.some((x) => x === "lineNum")
      ) {
        return (
          <>
            <InputField
              name="label"
              label={"Label"}
              readOnly
              value={i18next.t(data.labelKey, data.labelParams)}
              data-testid={DataTestIds.FORM_INPUT_COMMAND_ID}
            />
            <InputField
              name="lineNum"
              label={"LineNum"}
              readOnly
              value={data.lineNum}
              data-testid={DataTestIds.FORM_INPUT_COMMAND_ID}
            />
          </>
        );
      }
      //  only for start and end steps
      if (
        tableName === "taskstep" &&
        (action === "I" || action === "D") &&
        (data.commandId === CoreCommandsIds.end || data.commandId === CoreCommandsIds.start)
      ) {
        return `${data.commandId} step ${action === "I" ? "inserted" : "deleted"}`;
      }

      return (
        <>
          <InputField
            name="lineNum"
            label={"LineNum"}
            readOnly
            value={data.lineNum}
            data-testid={DataTestIds.FORM_INPUT_COMMAND_ID}
          />

          <StepDetailsForm
            values={{
              ...data,
              urlSwitch: !data.isManualUrl ? URL_SELECT_MODE.ENVIRONMENT : URL_SELECT_MODE.MANUAL,
              defaultSystem: data.isManualUrl || !data.environmentId,
              defaultVirtualUser: data.isManualUrl || !data.virtualUserId,
            }}
            steps={[]}
            variables={[]}
            virtualUsers={virtualUsers}
            systems={systems}
            variablesGroups={[]}
            script={{} as any}
            change={() => {}}
            codeTemplates={codeTemplates}
            globalConstants={globalConstant}
            globalMutables={globalMutable}
            openLinked={() => {}}
            readOnly
          />
        </>
      );
    }

    if (tableName === "taskscript") {
      // only when script status has changed
      if (["version", "status", "updatedAt"].every((elem) => diffKeys.includes(elem))) {
        return (
          <>
            <InputField name="version" label={"Version"} readOnly value={data.version} />
            <InputField name="status" label={"Status"} readOnly value={data.status} />
          </>
        );
      }

      return (
        <CreateEditScriptForm
          values={{
            ...data,
            urlSwitch: !data.isManualUrl ? URL_SELECT_MODE.ENVIRONMENT : URL_SELECT_MODE.MANUAL,
            defaultSystem: data.isManualUrl || !data.environmentId,
            defaultVirtualUser: data.isManualUrl || !data.virtualUserId,
          }}
          isCreating
          readOnly
          projects={this.props.projects}
          projectPath=""
          urlOptions={[
            {
              text: getTranslationKey("common", "label", "manualUrl"),
              value: URL_SELECT_MODE.MANUAL,
            },
            {
              text: getTranslationKey("common", "label", "environment"),
              value: URL_SELECT_MODE.ENVIRONMENT,
            },
          ]}
          form={{ change: () => {} }}
        />
      );
    }

    if (tableName === "guard") {
      return (
        <ConditionalForm
          values={data}
          steps={[]}
          change={() => {}}
          kpis={this.props.kpis}
          variables={[]}
          script={data}
          globalMutable={globalMutable}
          globalConstant={globalConstant}
          readOnly
          expandAllCollapse
        />
      );
    }

    if (tableName === "variable") {
      return (
        <>
          <InputField name="name" label={"Name"} readOnly />
          <InputField name="valueType" label={"Value type"} readOnly />
          <InputField name="defaultValue" label={"Default value"} readOnly />
        </>
      );
    }

    if (tableName === "guardssteps") {
      return (
        <>
          <InputField name="guardId" label={"Guard id"} readOnly />
          <InputField name="taskStepId" label={"Assigned to step"} readOnly />
        </>
      );
    }

    if (tableName === "taskscripttag") {
      return (
        <>
          <InputField name="tagId" label={"Tag id"} readOnly />
          <InputField name="taskScriptId" label={"Assigned to script"} readOnly />
        </>
      );
    }
    return "Not supported";
  };

  getProjectViewChunks = ({ data, tableName, recordDiff, action, diffKeys }) => {
    const { appUsers } = this.props;

    if (tableName === "project") {
      return <ProjectForm initialOwners={[]} values={data} change={() => {}} isAuditTrailView />;
    }
    if (tableName === "projectowners") {
      return (
        <>
          <InputField
            name="appuserName"
            label={"User name"}
            readOnly
            defaultValue={
              appUsers?.find((user) => user.id === data.appuserId)?.username || data.appuserId
            }
          />
        </>
      );
    }
  };

  getSettingsViewChunks = ({ data, tableName, recordDiff, action, diffKeys }) => {
    if (tableName === "systemdictionary") {
      return <CreateSystemForm values={data} readOnly />;
    }
    if (tableName === "virtualuser") {
      return <CreateEditVirtualUser values={data} readOnly form={{ change: () => {} } as any} />;
    }
    if (tableName === "globalvariable") {
      return <GlobalVariablesForm values={data} form={{} as any} change={() => {}} readOnly />;
    }
    return "Not supported";
  };

  getChangeFormChunk = ({ data, tableName, recordDiff, action }) => {
    if (!data) {
      return "No data";
    }

    const diffKeys = Object.keys(recordDiff || {});

    if (diffKeys.length === 1 && diffKeys[0] === "updatedAt") {
      return "Saved by system without changes";
    }

    switch (this.state.persistentQuery.mode) {
      case AuditTrailMode.SCRIPT:
        return this.getScriptViewChunks({ data, tableName, recordDiff, action, diffKeys });
      case AuditTrailMode.PROJECT:
        return this.getProjectViewChunks({ data, tableName, recordDiff, action, diffKeys });
      case AuditTrailMode.SETTINGS:
        return this.getSettingsViewChunks({ data, tableName, recordDiff, action, diffKeys });
      default:
        return "Not supported";
    }
  };

  getExperimentData = (record: AuditTrail) => {
    const { oldData, tableName, newData, action } = record;

    const recordDiff =
      action === "U"
        ? diff(
            {
              ...oldData,
              urlSwitch: !oldData?.isManualUrl
                ? URL_SELECT_MODE.ENVIRONMENT
                : URL_SELECT_MODE.MANUAL,
              defaultSystem: oldData?.isManualUrl || !oldData?.environmentId,
              defaultVirtualUser: oldData?.isManualUrl || !oldData?.virtualUserId,
            },
            {
              ...newData,
              urlSwitch: !newData?.isManualUrl
                ? URL_SELECT_MODE.ENVIRONMENT
                : URL_SELECT_MODE.MANUAL,
              defaultSystem: newData?.isManualUrl || !newData?.environmentId,
              defaultVirtualUser: newData?.isManualUrl || !newData?.virtualUserId,
            },
          )
        : undefined;
    return (
      <>
        <AuditData>
          {action === "I" ? (
            "No data"
          ) : (
            <AuditTrailAutoValidateForm initialValues={oldData}>
              {this.getChangeFormChunk({ data: oldData, tableName, recordDiff, action })}
            </AuditTrailAutoValidateForm>
          )}
        </AuditData>
        <AuditData>
          {action === "D" ? (
            "No data"
          ) : (
            <AuditTrailAutoValidateForm recordDiff={recordDiff} initialValues={newData}>
              {this.getChangeFormChunk({ data: newData, tableName, recordDiff, action })}
            </AuditTrailAutoValidateForm>
          )}
        </AuditData>
      </>
    );
  };

  isSelectedMode = (mode: AuditTrailMode) => this.state.persistentQuery.mode === mode;

  expandedRowRender = (record) => (
    <div>
      <AuditResultWrapper>
        <h4>
          <FormattedMessage id={getTranslationKey("common", "label", "before")} />
        </h4>
        <h4>
          <FormattedMessage id={getTranslationKey("common", "label", "after")} />
        </h4>
      </AuditResultWrapper>

      <AuditResultWrapper>
        {this.state.persistentQuery.mode !== AuditTrailMode.NORMAL ? (
          this.getExperimentData(record)
        ) : (
          <>
            <AuditData>{this.getBeforeData(record)}</AuditData>
            <AuditData>{this.getAfterData(record)}</AuditData>
          </>
        )}
      </AuditResultWrapper>

      {this.state.persistentQuery.mode !== AuditTrailMode.NORMAL && (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              rotate={isActive ? 90 : 0}
              data-testid={DataTestIds.ICON_COLLAPSE_DOC_SETTINGS}
            />
          )}
        >
          <CollapsePanel header={"Raw data"} key="rawData">
            <AuditResultWrapper>
              <AuditData>{this.getBeforeData(record)}</AuditData>
              <AuditData>{this.getAfterData(record)}</AuditData>
            </AuditResultWrapper>
          </CollapsePanel>
        </Collapse>
      )}
    </div>
  );

  getColumnConfig = () => {
    switch (this.state.persistentQuery.mode) {
      case AuditTrailMode.SCRIPT:
        return AUDIT_TRAIL_COLUMNS;
      case AuditTrailMode.PROJECT:
        return AUDIT_TRAIL_PROJECT_COLUMNS;
      case AuditTrailMode.SETTINGS:
        return AUDIT_TRAIL_PROJECT_COLUMNS;
      default:
        return AUDIT_TRAIL_COLUMNS;
    }
  };

  setMode = (mode) => {
    this.setState({
      persistentQuery: {
        mode,
      },
    });
  };

  render() {
    const { persistentQuery } = this.state;
    const { mode } = persistentQuery;
    return (
      <Wrapper>
        <CommandBar data-testid={DataTestIds.COMMANDBAR_AUDIT_TRAIL}>
          <CommandBarButton
            name="refresh"
            onClick={this.reload}
            text={getTranslationKey("commandBar", "refresh")}
            icon="ReloadOutlined"
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
          />
          <CommandBarButton
            name="switch"
            onClick={() => this.setMode(AuditTrailMode.NORMAL)}
            text={"Normal view"}
            icon="TableOutlined"
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
            disabled={this.isSelectedMode(AuditTrailMode.NORMAL)}
          />
          <CommandBarButton
            name="switch"
            onClick={() => this.setMode(AuditTrailMode.SCRIPT)}
            text={"Script view"}
            icon="TableOutlined"
            disabled={this.isSelectedMode(AuditTrailMode.SCRIPT)}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
          />
          <CommandBarButton
            name="switch"
            onClick={() => this.setMode(AuditTrailMode.PROJECT)}
            text={"Project View"}
            icon="TableOutlined"
            disabled={this.isSelectedMode(AuditTrailMode.PROJECT)}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
          />
          <CommandBarButton
            name="switch"
            onClick={() => this.setMode(AuditTrailMode.SETTINGS)}
            text={"Settings View"}
            icon="TableOutlined"
            disabled={this.isSelectedMode(AuditTrailMode.SETTINGS)}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
          />
        </CommandBar>
        <ConnectedTable
          columnsConfig={connectedTableSettingsMap[mode].columnConfig}
          pageable
          selectable={false}
          tableId={connectedTableSettingsMap[mode].tableId}
          preferencesId={connectedTableSettingsMap[mode].preferencesId}
          stateKey="auditTrail"
          tableActions={getAuditTrailTableActions}
          persistentQuery={persistentQuery}
          multiselect={false}
          setRef={(component) => (this.connectedTable = component)}
          expandedRowRender={this.expandedRowRender}
          key={connectedTableSettingsMap[mode].key}
        />
      </Wrapper>
    );
  }
}

const connectCreator = connect(
  (state: ApplicationState) => {
    const projects = getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id());
    return {
      projects,
      kpis: kpisDataSelectors.getOrderedDataSelector(state, KPIS_TABLES_CONFIG.MAIN.id()),
      globalMutable: getGlobalMutable(state, GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.id()),
      globalConstant: getGlobalConstant(state, GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.id()),
      codeTemplates: codeTemplatesDataSelectors.getOrderedDataSelector(
        state,
        CODE_TEMPLATES_TABLES_CONFIG.MAIN.id(),
      ),
      systems: systemDictionaryDataSelectors.getOrderedDataSelector(
        state,
        SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id(),
      ),
      virtualUsers: virtualUsersDataSelectors.getOrderedDataSelector(
        state,
        VIRTUAL_USERS_TABLES_CONFIG.MAIN.id(),
      ),
      appUsers: usersDataSelectors.getOrderedDataSelector(state, USERS_TABLES_CONFIG.MAIN.id()),
    };
  },
  (dispatch, props) => ({
    globalVariablesActions: {
      ...bindActionCreators(
        getGlobalVariablesTableActions(GLOBAL_VARIABLES_TABLES_CONFIG.MAIN.id()),
        dispatch,
      ),
    },
    systemActions: {
      ...bindActionCreators(
        getSystemTableActions(SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()),
        dispatch,
      ),
    },
    virtualUsersActions: {
      ...bindActionCreators(
        getVirtualUsersTableActions(VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()),
        dispatch,
      ),
    },
    kpisActions: {
      ...bindActionCreators(getKpisTableActions(KPIS_TABLES_CONFIG.MAIN.id()), dispatch),
    },
    codeTemplateActions: {
      ...bindActionCreators(
        getCodeTemplatesTableActions(CODE_TEMPLATES_TABLES_CONFIG.MAIN.id()),
        dispatch,
      ),
    },
    usersActions: {
      ...bindActionCreators(getUsersTableActions(USERS_TABLES_CONFIG.MAIN.id()), dispatch),
    },
  }),
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(AuditTrailContainer);
