import { createModuleEpics, makeAsyncEpic } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { IssueTrackingToolConfiguration } from "@ea/shared_types/types";
import { Action } from "redux";
import { ActionsObservable } from "redux-observable";
import { itsConfigurationsActionCreators, moduleActions } from "./itsConfigurations.actions";
import { itsConfigurationsDataSelectors } from "./itsConfigurations.selectors";
import { ITS_CONFIGURATIONS_COLUMNS_CONFIG } from "./itsConfigurations.table";

const itsConfigurationsModuleEpics = {
  ...createModuleEpics<IssueTrackingToolConfiguration>(moduleActions, {
    normalizeItem: (configuration) => configuration,
    getRequestParams: (state, tableId) => {
      const tableParams = itsConfigurationsDataSelectors.getParamsSelector(state, tableId!);
      const params = createRequestParams(tableParams, ITS_CONFIGURATIONS_COLUMNS_CONFIG, {
        defaultOrder: ["createdAt DESC"],
      });
      return params;
    },
    getSingleRequestParams: (params) => params,
    api: {
      createItem: API.createItsConfiguration,
      deleteItem: API.deleteItsConfiguration,
      editItem: API.editItsConfiguration,
      getItems: API.getItsConfigurations,
      getItemsCount: API.getItsConfigurationCount,
    },
  }),
  fetch: makeAsyncEpic(itsConfigurationsActionCreators.fetch, async (payload, state) => {
    await API.fetchItsConfigurations({});
  }),
  reloadAfterFetch: (action$: ActionsObservable<Action>) =>
    action$
      .ofType(itsConfigurationsActionCreators.fetch.done)
      .map((itsConfigurationsAction$: any) => {
        return itsConfigurationsActionCreators.table.load.started({
          tableId: itsConfigurationsAction$.payload.params.tableId,
        });
      }),
};

export default itsConfigurationsModuleEpics;
