import * as React from "react";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { VIRTUAL_USER_AUTH_TYPE } from "@ea/shared_types/types";
import CheckboxField from "@ea/shared_components/Form/Fields/CheckboxField";
import { getIn, FormApi } from "final-form";
import RadioField, { RadioType } from "@ea/shared_components/Form/Fields/RadioField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { getWebPlatforms } from "@ea/runner_loader/runner.loader";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { TableForm, EmptyField } from "@ea/shared_components";
import { FormattedMessage } from "react-intl";
import { Divider } from "antd";
import { InputNumberField } from "@ea/shared_components/Form/Fields/InputNumberField";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ICreateEditVirtualFormProps {
  readOnly?: boolean;
  isEditMode?: boolean;
  values: any;
  form: FormApi<object>;
}

type Props = ICreateEditVirtualFormProps;

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 12 },
};

const authOptions = [
  {
    value: VIRTUAL_USER_AUTH_TYPE.NONE,
    text: getTranslationKey("virtualUsers", "authType", "none"),
  },
  {
    value: VIRTUAL_USER_AUTH_TYPE.BASIC_AUTH,
    text: getTranslationKey("virtualUsers", "authType", "basic"),
  },
  {
    value: VIRTUAL_USER_AUTH_TYPE.NTLM,
    text: getTranslationKey("virtualUsers", "authType", "ntlm"),
  },
];

export const defaultSystemData = {
  login: { value: "", isSensitive: false },
  customUrl: { value: "", isSensitive: false },
  password: { value: "", isSensitive: true },
};

export const getCustomScriptColumns = (isSensitiveRequired, values) => [
  {
    title: <FormattedMessage id={getTranslationKey("common", "label", "name")} />,
    dataIndex: "key",
    key: "key",
    required: true,
    width: "35%",
    render: (text, record) => {
      // add validators
      return (
        <InputField
          required
          name={`${record}.key`}
          placeholder={getTranslationKey("common", "placeholder", "name")}
        />
      );
    },
  },
  {
    title: <FormattedMessage id={getTranslationKey("variable", "label", "value")} />,
    dataIndex: "value",
    key: "value",
    width: "35%",
    required: true,
    render: (text, record) => {
      const isSensitive = getIn(values, `${record}.isSensitive`);
      return (
        <InputField
          required={isSensitiveRequired}
          name={`${record}.value`}
          type={isSensitive ? "password" : undefined}
          placeholder={getTranslationKey("variable", "placeholder", "enterValue")}
        />
      );
    },
  },
  {
    title: <FormattedMessage id={getTranslationKey("virtualUsers", "isSensitive")} />,
    dataIndex: "isSensitive",
    key: "isSensitive",
    width: "15%",
    required: true,
    render: (text, record) => {
      return <CheckboxField name={`${record}.isSensitive`} />;
    },
  },
  {
    title: <FormattedMessage id={getTranslationKey("virtualUsers", "isSystemLogin")} />,
    dataIndex: "isSystemLogin",
    key: "isSystemLogin",
    width: "15%",
    required: false,
    render: (text, record) => {
      return (
        <CheckboxField
          name={`${record}.isSystemLogin`}
          disabled={
            !getIn(values, `${record}.isSystemLogin`) &&
            getIn(values, "systemData.values").some((v) => !!v.isSystemLogin)
          }
        />
      );
    },
  },
];

class CreateEditVirtualUser extends React.Component<Props> {
  envTypes;

  renderCommonServerOptions() {
    const { isEditMode, readOnly } = this.props;
    return (
      <>
        <InputField
          name="serverData.login"
          required={!isEditMode}
          placeholder={getTranslationKey("common", "placeholder", "login")}
          label={getTranslationKey("common", "label", "login")}
          data-testid={DataTestIds.FORM_INPUT_LOGIN}
          readOnly={readOnly}
        />
        <InputField
          name="serverData.password"
          required={!isEditMode}
          type="password"
          placeholder={getTranslationKey("common", "label", "password")}
          label={getTranslationKey("common", "label", "password")}
          data-testid={DataTestIds.FORM_INPUT_PASSWORD}
          readOnly={readOnly}
        />
      </>
    );
  }

  renderBasicOptions() {
    return this.renderCommonServerOptions();
  }

  renderNTLMOptions() {
    return (
      <>
        {this.renderCommonServerOptions()}
        <InputField
          name="serverData.domain"
          placeholder={getTranslationKey("virtualUsers", "domain")}
          label={getTranslationKey("virtualUsers", "domain")}
          data-testid={DataTestIds.FORM_INPUT_DOMAIN}
          readOnly={this.props.readOnly}
        />
        <InputField
          name="serverData.workstation"
          placeholder={getTranslationKey("virtualUsers", "workstation")}
          label={getTranslationKey("virtualUsers", "workstation")}
          data-testid={DataTestIds.FORM_INPUT_WORKSTATION}
          readOnly={this.props.readOnly}
        />
      </>
    );
  }

  renderSystemOptions() {
    const { values, isEditMode, form, readOnly } = this.props;
    const useCustomScript = getIn(values, `systemData.useCustomScript`);
    const rememberSensitiveData = getIn(values, "systemData.rememberSensitiveData");
    const reauthenticate = getIn(values, "systemData.reauthenticate");
    const manualMode = getIn(values, "systemData.isManual");

    const CommonFields = (
      <>
        <EmptyField name="systemData.values" defaultValue={defaultSystemData} />
        <CheckboxField
          name="systemData.isManual"
          label={getTranslationKey("common", "label", "isManual")}
          data-testid={DataTestIds.FORM_CHECKBOX_IS_MANUAL}
          readOnly={readOnly}
        />
        <CheckboxField
          name="systemData.rememberSensitiveData"
          label={getTranslationKey("virtualUsers", "rememberSensitiveData")}
          data-testid={DataTestIds.FORM_CHECKBOX_REMEMBER_SENSITIVE_DATA}
          readOnly={readOnly}
        />
        <CheckboxField
          name="systemData.useCustomScript"
          label={getTranslationKey("virtualUsers", "customScript")}
          data-testid={DataTestIds.FORM_CHECKBOX_USE_CUSTOM_SCRIPT}
          readOnly={readOnly}
          defaultChecked
          onChange={(v) => {
            const systemDataValues = getIn(values, `systemData`);

            if (v.target.checked) {
              form.change("systemData", {
                ...systemDataValues,
                platform: undefined,
                values: [],
              });
            } else {
              form.change("systemData", {
                ...systemDataValues,
                values: {},
              });
            }
          }}
        />
      </>
    );

    const ReauthFields = (
      <>
        <CheckboxField
          name="systemData.reauthenticate"
          readOnly={!rememberSensitiveData === true || !manualMode === false ? true : false}
          label={getTranslationKey("virtualUsers", "reauthenticate")}
          data-testid={DataTestIds.FORM_CHECKBOX_REAUTHENTICATE}
        />
        {reauthenticate && (
          <InputField
            name="systemData.reauthenticateInterval"
            type="number"
            required
            readOnly={readOnly}
            min={2}
            label={getTranslationKey("virtualUsers", "reauthenticateInterval")}
            addonAfter="hours"
            data-testid={DataTestIds.FORM_INPUT_REAUTHENTICATION_INTERVAL}
          />
        )}
      </>
    );

    if (useCustomScript) {
      return (
        <>
          {CommonFields}
          <InputNumberField
            name="systemData.customScriptId"
            placeholder={getTranslationKey("virtualUsers", "customScriptId")}
            label={getTranslationKey("virtualUsers", "customScriptId")}
            min={1}
            required={useCustomScript}
            readOnly={readOnly}
            data-testid={DataTestIds.FORM_INPUT_CUSTOM_SCRIPT_ID}
          />
          <TableForm<{ name: string }>
            name="systemData.values"
            columns={getCustomScriptColumns(!isEditMode && rememberSensitiveData, values)}
            allowEmptyForm
            initialRecord={{ name: "", value: "", isSensitive: false, isSystemLogin: false }}
          />
          {rememberSensitiveData && ReauthFields}
        </>
      );
    }

    const platforms = getWebPlatforms();

    const platformOptions: OptionType[] = platforms
      .filter((p) => p.virtualUserExtension)
      .map((p) => ({
        value: p.id,
        text: p.getFriendlyName(),
      }));

    const platformOption = getIn(values, "systemData.platform");
    const platform = platforms.find((p) => p.id === platformOption);

    const defaultFormChunk = {
      login: (
        <InputField
          name="systemData.values.login.value"
          required
          placeholder={getTranslationKey("common", "placeholder", "login")}
          label={getTranslationKey("common", "label", "login")}
          data-testid={DataTestIds.FORM_INPUT_LOGIN_VALUE}
          readOnly={readOnly}
        />
      ),
      password: (
        <>
          {rememberSensitiveData && (
            <InputField
              name="systemData.values.password.value"
              required={rememberSensitiveData}
              readOnly={readOnly || !rememberSensitiveData}
              type="password"
              placeholder={getTranslationKey("common", "label", "password")}
              label={getTranslationKey("common", "label", "password")}
              data-testid={DataTestIds.FORM_INPUT_PASSWORD_VALUE}
            />
          )}
        </>
      ),
    };

    return (
      <>
        {CommonFields}
        <SelectField
          name="systemData.platform"
          data-testid={DataTestIds.FORM_SELECT_PLATFORM}
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("virtualUsers", "platform")}
          label={getTranslationKey("virtualUsers", "platform")}
          onChange={(v) => {
            const newPlatform = platforms.find((p) => p.id === v && p.virtualUserExtension);

            if (newPlatform) {
              form.change(
                "systemData.values",
                newPlatform.virtualUserExtension!.getVirtualUserInitData(defaultSystemData),
              );
            } else {
              form.change("systemData.values", defaultSystemData);
            }
          }}
          options={platformOptions}
        />

        {platform &&
          platform.virtualUserExtension &&
          platform.virtualUserExtension.getVirtualUserFormChunk({
            prefix: "systemData.values",
            values,
            isEditMode,
            defaultFormChunk,
            rememberSensitiveData,
          })}
        {rememberSensitiveData && ReauthFields}
      </>
    );
  }

  render() {
    const { isEditMode, values, readOnly } = this.props;

    const isSystemAuth = getIn(values, "isSystemAuthEnabled");
    const authType = getIn(values, "serverAuthType");

    return (
      <FormLayout {...formItemLayout} readOnly={readOnly}>
        <InputField
          name="name"
          required={!isEditMode}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <TextAreaField
          label={getTranslationKey("common", "label", "description")}
          name="description"
          placeholder={getTranslationKey("common", "placeholder", "description")}
          autoSize={{ minRows: 2, maxRows: 6 }}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
        <Divider orientation="left">
          <FormattedMessage id={getTranslationKey("virtualUsers", "systemAuthentication")} />
        </Divider>
        <CheckboxField
          name="isSystemAuthEnabled"
          label={getTranslationKey("virtualUsers", "systemAuthEnabled")}
          defaultValue={true}
          data-testid={DataTestIds.FORM_CHECKBOX_IS_SYSTEM_AUTH_ENABLED}
        />
        {isSystemAuth && this.renderSystemOptions()}
        <Divider orientation="left">
          <FormattedMessage id={getTranslationKey("virtualUsers", "authType", "label")} />
        </Divider>
        <RadioField
          name="serverAuthType"
          required
          type={RadioType.Button}
          defaultValue={VIRTUAL_USER_AUTH_TYPE.NONE}
          label={getTranslationKey("virtualUsers", "authType", "label")}
          options={authOptions}
          data-testid={DataTestIds.FORM_RADIO_AUTH_TYPE}
        />
        {authType === VIRTUAL_USER_AUTH_TYPE.BASIC_AUTH && this.renderBasicOptions()}
        {authType === VIRTUAL_USER_AUTH_TYPE.NTLM && this.renderNTLMOptions()}
      </FormLayout>
    );
  }
}

export default CreateEditVirtualUser;
