import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import CommandBar from "./components/ApiKeyCommandBar";
import { apiKeyDataSelectors } from "@app/modules/apiKey/apiKey.selectors";
import { API_KEY_COLUMNS, API_KEY_TABLES_CONFIG } from "@app/modules/apiKey/apiKey.table";
import CreateEditApiKeyContainer from "@app/modules/apiKey/CreateEditApiKey.container";
import { API } from "@app/services/api/api";
import { apiKeyActions, getTokensTableActions } from "./apiKey.actions";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface IApiKeyTableProps {}

interface IApiKeyTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
}

class ApiKeyTableContainer extends React.Component<
  IApiKeyTableProps & IConnectProps,
  IApiKeyTableState
> {
  connectedTable: any;

  state: IApiKeyTableState = {
    createWindowVisibility: false,
    editWindowVisibility: false,
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedApiKey) {
      this.props.actions.loadSingle({ id: this.props.selectedApiKey.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  refreshToken = async () => {
    if (this.props.selectedApiKey) {
      await this.props.actions.edit({
        ...this.props.selectedApiKey,
        created: new Date(),
      });
      await this.connectedTable.reload();
    }
  };
  render() {
    return (
      <Container>
        <CommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
          onRefreshClick={this.refreshToken}
        />
        <CreateEditApiKeyContainer
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={this.props.selectedApiKey}
          isEdit={this.state.editWindowVisibility}
          user={this.props.user}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={API_KEY_COLUMNS}
          tableId={API_KEY_TABLES_CONFIG.MAIN.id()}
          preferencesId={API_KEY_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"apiKey"}
          tableActions={getTokensTableActions}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(apiKeyActions, dispatch),
    ...bindActionCreators(getTokensTableActions(API_KEY_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: apiKeyDataSelectors.getSelectedSelector(state, API_KEY_TABLES_CONFIG.MAIN.id()),
    selectedApiKey: apiKeyDataSelectors.getSelectedItemSelector(
      state,
      API_KEY_TABLES_CONFIG.MAIN.id(),
    ),
    user: state.auth.user,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ApiKeyTableContainer);
