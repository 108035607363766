import { getIn } from "final-form";
import debounce from "lodash.debounce";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { API } from "@app/services/api/api";
import { AutoCompleteField } from "@ea/shared_components/Form/Fields/AutoCompleteField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { RadioField, RadioType } from "@ea/shared_components/Form/Fields/RadioField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import {
  System,
  URL_SELECT_MODE,
  VirtualUser,
  ROLES,
  ProjectTreeNode,
  FEATURES,
  ReportTemplate,
  GlobalSettingsResolversModes,
  GLOBAL_SETTINGS_KEYS,
} from "@ea/shared_types/types";
import CheckboxField from "@ea/shared_components/Form/Fields/CheckboxField";
import WithAuthorization from "@app/modules/common/WithAuthorization";
import { inputPositiveNumbersProps } from "@app/modules/steps/components/Forms/CreateStepCommonForm";
import ScriptSystemAndVUFormChunk from "./ScriptSystemAndVUFormChunk";
import { getTranslationKey } from "@app/translations/translations.helpers";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import HideableFeature from "@app/modules/common/HideableFeature";
import { Collapse, Tooltip } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { withRouter, RouteComponentProps } from "react-router";
import { ROUTES } from "@app/routes";
import { ProjectTreeWithGoTo } from "./ProjectTreeWithGoTo";
import { TreeSelectField } from "@ea/shared_components/Form/Fields/TreeSelectField";
import { DataTestIds } from "@app/utils/dataTestIds";

const { Panel: CollapsePanel } = Collapse;
interface ICreateScriptFormProps {
  isCreating?: boolean;
  readOnly?: boolean;
  projects: ProjectTreeNode[];
  values: any;
  form: any;
  urlOptions: { text: string; value: URL_SELECT_MODE }[];
  additionalFormChunks?: ((props) => React.ReactNode)[];
  projectPath?: string;
}

interface ICreateEditScriptFormState {
  hints: string[];
  virtualUsers: VirtualUser[];
  systems: System[];
  tagOptions: OptionType[];
  templatesOptions: OptionType[];
}
type Props = ICreateScriptFormProps & RouteComponentProps<any>;

const AuthorizedRadioField = WithAuthorization([], [ROLES.freedocs])(RadioField);
class CreateEditScriptForm extends React.Component<Props, ICreateEditScriptFormState> {
  state: ICreateEditScriptFormState = {
    hints: [],
    virtualUsers: [],
    systems: [],
    tagOptions: [],
    templatesOptions: [],
  };

  constructor(props: Props) {
    super(props);

    this.loadCompletions = debounce(this.loadCompletions, 300);
  }

  componentDidMount() {
    const projectId = getIn(this.props.values, "projectId");
    this.loadTags();
    this.loadTemplates();
    this.reloadProjectData(projectId);
  }

  loadTags = async () => {
    const tags = await API.getTag({});
    const createTagOptions = () =>
      tags.map((tag) => ({
        key: tag.id,
        text: tag.name,
        value: tag.id,
      }));

    this.setState({
      tagOptions: createTagOptions(),
    });
  };

  loadCompletions = async (url: string) => {
    if (url.length < 2) {
      this.setState({
        hints: [],
      });
      return;
    }
    const results = await API.scriptAutocomplete({ url });
    const hints = results.map((element) => (element as any).starturl);

    this.setState({
      hints,
    });
  };

  loadTemplates = async () => {
    const templates: ReportTemplate[] = await API.getReportTemplates({});
    const createTemplatesOptions = () =>
      templates.map((l) => ({
        key: l.id,
        text: l.name,
        value: l.id,
      }));

    this.setState({
      templatesOptions: createTemplatesOptions(),
    });
  };

  onProjectChange = (projectId) => {
    const isManual = getIn(this.props.values, "urlSwitch") === URL_SELECT_MODE.MANUAL;
    if (!isManual) {
      this.props.form.change("environmentId", undefined);
    }
    this.props.form.change("virtualUserId", undefined);
    this.reloadProjectData(projectId);
  };

  reloadProjectData = async (projectId) => {
    const { change } = this.props.form;

    if (!projectId) {
      return;
    }

    const project = (
      await API.getProjects({
        filter: {
          where: {
            id: {
              inq: [projectId],
            },
          } as any,
          include: ["virtualUsers", "systems"],
        },
      })
    )[0];

    if (!project) {
      return;
    }

    this.setState({
      virtualUsers: project.virtualUsers!,
      systems: project.systems!,
    });

    change("defaultSystemId", project.defaultSystemId);
    change("defaultVirtualUserId", project.defaultVirtualUserId);
  };

  onUrlSwitchChange = () => {
    const { change } = this.props.form;
    change("startUrl", undefined);
    change("environmentId", undefined);
    change("virtualUserId", undefined);
    change("useVirtualUser", false);
  };

  goToProject = (selectedProjectId) => {
    this.props.history.push({
      pathname: `${ROUTES.projects}/${selectedProjectId}`,
    });
  };

  clearScreenshotsOnInterruption = () => {
    const { change } = this.props.form;
    change("screenshotsOnlyOnInterruption", false);
  };

  onResolverEnabledChange = () => {
    const { change } = this.props.form;
    change("resolvers.mode", undefined);
  };

  render() {
    const {
      values,
      urlOptions,
      projects,
      additionalFormChunks,
      readOnly,
      isCreating,
      projectPath,
    } = this.props;
    const { hints, virtualUsers, systems, tagOptions, templatesOptions } = this.state;
    const isManual = getIn(values, "urlSwitch") === URL_SELECT_MODE.MANUAL;
    const selectedProjectId = getIn(values, "projectId");
    const takeScreenshots = getIn(values, "takeScreenshots");
    const customResolversMode = getIn(values, "resolvers.enabled");

    return (
      <>
        {isCreating ? (
          <TreeSelectField
            label={
              (<FormattedMessage id={getTranslationKey("common", "label", "project")} />) as any
            }
            readOnly={readOnly}
            name="projectId"
            required
            treeDefaultExpandAll
            onChange={this.onProjectChange}
            nodes={projects}
            placeholder={getTranslationKey("common", "placeholder", "project")}
            data-testid={DataTestIds.FORM_SELECT_PROJECT}
          />
        ) : (
          <ProjectTreeWithGoTo
            onGoToClick={() => this.goToProject(selectedProjectId)}
            onProjectChange={this.onProjectChange}
            projects={projects}
            readOnly={readOnly}
            projectPath={projectPath}
            data-testid={DataTestIds.FORM_SELECT_PROJECT}
          />
        )}
        <InputField
          label={getTranslationKey("common", "label", "name")}
          name="name"
          required
          placeholder={getTranslationKey("common", "placeholder", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <AuthorizedRadioField
          name="urlSwitch"
          type={RadioType.Button}
          options={urlOptions}
          onChange={this.onUrlSwitchChange}
          data-testid={DataTestIds.FORM_RADIO_URL_SWITCH}
        />
        {isManual ? (
          <AutoCompleteField
            name="startUrl"
            required
            onChange={this.loadCompletions}
            placeholder={getTranslationKey("common", "placeholder", "enterUrl")}
            dataSource={hints || []}
            label={getTranslationKey("common", "label", "url")}
            data-testid={DataTestIds.FORM_INPUT_START_URL}
          />
        ) : (
          <ScriptSystemAndVUFormChunk
            {...this.props}
            virtualUsers={virtualUsers}
            systems={systems}
          />
        )}
        <TextAreaField
          label={getTranslationKey("common", "label", "description")}
          name="description"
          placeholder={getTranslationKey("common", "placeholder", "description")}
          autoSize={{ minRows: 2, maxRows: 6 }}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
        <InputField
          name="timeout"
          label={getTranslationKey("common", "label", "timeout")}
          addonAfter="seconds"
          type="number"
          data-testid={DataTestIds.FORM_INPUT_TIMEOUT}
          {...inputPositiveNumbersProps}
        />
        <CheckboxField
          label={getTranslationKey("common", "label", "takeScreenshots")}
          name="takeScreenshots"
          onChange={this.clearScreenshotsOnInterruption}
          data-testid={DataTestIds.FORM_CHECKBOX_TAKE_SCREENSHOTS}
        />
        {takeScreenshots && (
          <CheckboxField
            label={getTranslationKey("common", "label", "screenshotsOnlyOnInterruption")}
            name="screenshotsOnlyOnInterruption"
            data-testid={DataTestIds.FORM_CHECKBOX_SCREENSHOTS_ON_INTERRUPTION}
          />
        )}
        <CheckboxField
          label={getTranslationKey("common", "label", "showActionHint")}
          name="showActionHint"
          data-testid={DataTestIds.FORM_CHECKBOX_SHOW_ACTION_HINT}
        />
        <CheckboxField
          label={getTranslationKey("common", "label", "hideComments")}
          name="hideComments"
          data-testid={DataTestIds.FORM_CHECKBOX_HIDE_COMMENTS}
        />
        <CheckboxField
          label={getTranslationKey("step", "detailsForm", "label", "clickOnDisabledButtons")}
          name="clickOnDisabledButtons"
          data-testid={DataTestIds.FORM_CHECKBOX_CLICK_ON_DISABLED_BUTTONS}
        />
        <CheckboxField
          label={getTranslationKey("step", "detailsForm", "label", "disableStartStep")}
          name="disableStartStep"
          data-testid={DataTestIds.FORM_CHECKBOX_DISABLE_START_STEP}
        />
        <CheckboxField
          label={getTranslationKey("step", "detailsForm", "label", "autoRefreshDatasource")}
          name="autoRefreshDatasource"
          data-testid={DataTestIds.FORM_CHECKBOX_AUTO_REFRESH_DATASOURCE}
        />
        <CheckboxField
          label={getTranslationKey("step", "detailsForm", "label", "customResolversMode")}
          name="resolvers.enabled"
          data-testid={DataTestIds.FORM_CHECKBOX_CUSTOM_RESOLVERS_MODE}
          onChange={this.onResolverEnabledChange}
        />
        {customResolversMode && (
          <Tooltip
            title={
              <div>
                <div>
                  <FormattedMessage
                    id={getTranslationKey("globalSettings", "resolvers", "info", "intro")}
                  />
                </div>
                <br></br>
                <br></br>
                <div>
                  <FormattedMessage
                    id={getTranslationKey(
                      "globalSettings",
                      "resolvers",
                      "info",
                      GlobalSettingsResolversModes.SMART,
                    )}
                  />
                </div>
                <br></br>
                <div>
                  <FormattedMessage
                    id={getTranslationKey(
                      "globalSettings",
                      "resolvers",
                      "info",
                      GlobalSettingsResolversModes.FALLBACK,
                    )}
                  />
                </div>
                <br></br>
                <div>
                  <FormattedMessage
                    id={getTranslationKey(
                      "globalSettings",
                      "resolvers",
                      "info",
                      GlobalSettingsResolversModes.LEGACY,
                    )}
                  />
                </div>
              </div>
            }
          >
            <RadioField
              name="resolvers.mode"
              required
              data-testid={DataTestIds.FORM_INPUT_RESOLVERS_MODE}
              label={getTranslationKey("globalSettings", "resolvers", "mode")}
              type={RadioType.Button}
              options={[
                {
                  text: getTranslationKey(
                    "globalSettings",
                    "resolvers",
                    "modes",
                    GlobalSettingsResolversModes.SMART,
                  ),
                  value: GlobalSettingsResolversModes.SMART,
                },
                {
                  text: getTranslationKey(
                    "globalSettings",
                    "resolvers",
                    "modes",
                    GlobalSettingsResolversModes.FALLBACK,
                  ),
                  value: GlobalSettingsResolversModes.FALLBACK,
                },
                {
                  text: getTranslationKey(
                    "globalSettings",
                    "resolvers",
                    "modes",
                    GlobalSettingsResolversModes.LEGACY,
                  ),
                  value: GlobalSettingsResolversModes.LEGACY,
                },
              ]}
            />
          </Tooltip>
        )}
        <HideableFeature feature={FEATURES.GXP_TAGS}>
          {() => (
            <SelectField
              name="tags"
              mode="multiple"
              label={getTranslationKey("common", "label", "tags")}
              placeholder={getTranslationKey("common", "placeholder", "tags")}
              options={tagOptions}
              data-testid={DataTestIds.FORM_SELECT_TAGS}
            />
          )}
        </HideableFeature>
        {additionalFormChunks?.map((chunkRender) => chunkRender(this.props))}
        <HideableFeature feature={FEATURES.DOCUMENTATION}>
          {() => (
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 0}
                  data-testid={DataTestIds.ICON_COLLAPSE_DOC_SETTINGS}
                />
              )}
            >
              <CollapsePanel
                header={
                  <FormattedMessage
                    id={getTranslationKey("globalSettings", "documentationSettings")}
                  />
                }
                key="debugFields"
              >
                <SelectField
                  name="documentationTemplate"
                  label={getTranslationKey("globalSettings", "documentationTemplate")}
                  placeholder={getTranslationKey("globalSettings", "selectDocumentationTemplate")}
                  options={templatesOptions}
                  data-testid={DataTestIds.FORM_SELECT_DOCUMENTATION_TEMPLATE}
                  allowClear
                />
                <SelectField
                  name="reportTemplate"
                  label={getTranslationKey("globalSettings", "reportTemplate")}
                  placeholder={getTranslationKey("globalSettings", "selectReportTemplate")}
                  options={templatesOptions}
                  data-testid={DataTestIds.FORM_SELECT_REPORT_TEMPLATE}
                  allowClear
                />
                <CheckboxField
                  label={getTranslationKey("step", "detailsForm", "label", "skipLinkedScriptsInDocumentation")}
                  name="skipLinkedScriptsInDocumentation"
                  data-testid={DataTestIds.FORM_CHECKBOX_SKIP_LINKED_SCRIPTS_IN_DOCUMENTATION}
                />
              </CollapsePanel>
            </Collapse>
          )}
        </HideableFeature>
      </>
    );
  }
}

export default withRouter(CreateEditScriptForm);
