/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { Spin, Button, Tooltip } from "antd";
import * as React from "react";
import Dropzone from "react-dropzone";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { API } from "@app/services/api/api";
import { COLORS } from "@app/styles/consts";
import { EntityType, DataSource, MESSAGE_TYPE } from "@ea/shared_types/types";
import { MessageBar } from "@ea/shared_components/MessageBar";
import { variableActions } from "../variables/variables.actions";
import { parseLoopbackError } from "@app/utils/error";
import { scriptsActions } from "./scripts.actions";
import {
  UploadOutlined,
  FileExcelOutlined,
  DownloadOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { DataTestIds } from "@app/utils/dataTestIds";
interface IDataSourceContainerProps {
  parentId: number;
  parentType: EntityType;
  viewOnly?: boolean;
  onUpload?: () => void;
}

interface IDataSourceContainerState {
  files: DataSource[];
  loading: boolean;
  error: string | undefined;
}

const Container = styled.div({
  display: "flex",
  width: "100%",
  flexDirection: "column",
});

const LoadedFilesContainer = styled.div({
  display: "flex",
  flexDirecion: "column",
});

const LoadedFile = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px",
});

const DropZoneDiv = styled.div(
  {
    borderRadius: "4px",
    background: "#f4f4f4",
    border: "2px dashed #d9d9d9",
    transition: "border-color .3s",
    width: "400px",
    height: "150px",
    textAlign: "center",
    padding: "16px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#dbdbdb",
      border: "2px dashed #2b2b2b",
    },
  },
  ({ isDragActive }: { isDragActive: boolean }) =>
    isDragActive ? { border: "2px dashed green" } : {},
);

const spinner = css({
  width: "400px",
  height: "150px",
});

const documentIcon = css({
  fontSize: 30,
  margin: "10px",
  color: COLORS.DARK_GREEN,
  cursor: "pointer",
  ":hover": {
    textShadow: "0px 0px 5px lightgreen",
  },
});

class DataSourceContainer extends React.Component<IConnectProps, IDataSourceContainerState> {
  formRef: any;
  dropzoneRef: any;

  state: IDataSourceContainerState = {
    files: [],
    loading: false,
    error: undefined,
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    const files = await this.getFiles();
    this.setState({
      files,
      loading: false,
    });
  }

  async getFiles() {
    const { parentId, parentType } = this.props;
    let files: DataSource[] = [];
    try {
      files = await API.getDataSources({
        filter: { where: { parentId: parentId, parentType: parentType, temporary: false } },
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: getTranslationKey("messages", "error", "fileGet"),
      });
      console.error(parseLoopbackError(err));
      return [];
    }
    return files;
  }

  onDrop = async (files: File[]) => {
    const { parentId, parentType, onUpload, loadVariables, loadScript } = this.props;
    let createdFiles: DataSource[] = [];
    this.setState({
      loading: true,
    });
    try {
      createdFiles = await API.uploadFile({ parentId, parentType, files });
    } catch (err) {
      this.setState({
        loading: false,
        error: getTranslationKey("messages", "error", "fileUpload"),
      });
      console.error(parseLoopbackError(err));
      toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "fileUpload")} />);
      return;
    }
    toast.success(<FormattedMessage id={getTranslationKey("messages", "success", "fileUpload")} />);
    loadVariables(parentId);
    loadScript({ id: parentId });

    this.setState({
      files: createdFiles,
      loading: false,
    });
    onUpload?.();
  };

  onReject = () => {
    toast.warn(<FormattedMessage id={getTranslationKey("messages", "error", "fileUpload")} />);
  };

  onDelete = async (datasourceId: number) => {
    this.setState({
      loading: true,
    });
    const { parentId, loadVariables } = this.props;
    let files: DataSource[] = [];
    try {
      files = await API.deleteDataSource({ datasourceId: datasourceId }).then(() =>
        this.getFiles(),
      );
    } catch (err) {
      this.setState({
        loading: false,
        error: parseLoopbackError(err),
      });
      console.error(parseLoopbackError(err));
      toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "fileDownload")} />);
      return;
    }
    toast.success(
      <FormattedMessage id={getTranslationKey("messages", "success", "fileRemoved")} />,
    );
    loadVariables(parentId);
    this.setState({
      files: files,
      loading: false,
    });
  };

  onDownload = async (fileName: string, originalFilename: string) => {
    try {
      API.downloadFile(fileName, originalFilename);
    } catch (err) {
      console.error(parseLoopbackError(err));
      this.setState({
        error: getTranslationKey("messages", "error", "fileDownload"),
      });
      return;
    }
  };

  renderDropzone = () => (
    <Dropzone
      ref={(element) => {
        this.dropzoneRef = element;
      }}
      multiple={false}
      onDropAccepted={this.onDrop}
      onDropRejected={this.onReject}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <DropZoneDiv {...(getRootProps() as any)} isDragActive={isDragActive}>
          <input {...(getInputProps() as any)} data-testid={DataTestIds.DROPZONE} />
          <UploadOutlined style={{ fontSize: 50 }} />
          <p>
            <FormattedMessage id={getTranslationKey("messages", "info", "dropZone")} />
          </p>
        </DropZoneDiv>
      )}
    </Dropzone>
  );

  renderDataSourceList = () =>
    this.state.files.map((file, index) => (
      <LoadedFile key={index}>
        <Tooltip title={<FormattedMessage id={getTranslationKey("button", "download")} />}>
          <FileExcelOutlined
            css={documentIcon}
            onClick={() => this.onDownload(file.name, file.originalFilename)}
          />
        </Tooltip>
        <h3 style={{ margin: 0 }}>{file.originalFilename}</h3>
        <Tooltip title={<FormattedMessage id={getTranslationKey("button", "download")} />}>
          <Button
            icon={<DownloadOutlined />}
            style={{ marginLeft: "20px" }}
            onClick={() => this.onDownload(file.name, file.originalFilename)}
          />
        </Tooltip>
        <Tooltip title={<FormattedMessage id={getTranslationKey("button", "reload")} />}>
          <Button
            icon={<ReloadOutlined />}
            title="Reload"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              this.dropzoneRef.open();
            }}
          />
        </Tooltip>
        {!this.props.viewOnly && (
          <Tooltip title={<FormattedMessage id={getTranslationKey("button", "delete")} />}>
            <Button
              icon={<DeleteOutlined />}
              danger
              style={{ marginLeft: "10px" }}
              onClick={() => this.onDelete(file.id)}
            />
          </Tooltip>
        )}
      </LoadedFile>
    ));

  render() {
    const { loading, error, files } = this.state;
    const { viewOnly } = this.props;
    if (loading) {
      return (
        <Container>
          <Spin css={spinner} style={{ marginTop: "50px", marginRight: "200px" }} />
        </Container>
      );
    }
    return (
      <Container>
        {error && <MessageBar messages={[{ text: error, type: MESSAGE_TYPE.ERROR }]} />}

        <div
          style={{
            display: files.length === 0 && !viewOnly ? "initial" : "none",
          }}
        >
          {this.renderDropzone()}
        </div>

        {(files.length !== 0 || viewOnly) && (
          <LoadedFilesContainer>
            {files.length ? (
              this.renderDataSourceList()
            ) : (
              <p>
                <FormattedMessage id={getTranslationKey("messages", "info", "noDataSource")} />
              </p>
            )}
          </LoadedFilesContainer>
        )}
      </Container>
    );
  }
}

const connectCreator = connect((state, props: IDataSourceContainerProps) => props, {
  loadVariables: variableActions.loadDataSource,
  loadScript: scriptsActions.loadSingle,
});

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(DataSourceContainer);
