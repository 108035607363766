import { TranslationDefinition } from "./en.translations";

const translationsConsts = {
  error: {
    checkConsole: "Sprawdź konsolę po więcej informacji",
  },
};

const translations: TranslationDefinition = {
  globalSettings: {
    elementDetection: "Rozpoznawanie elementów",
    publishingCredentialsConfirmation: "Potwierdź poświadczeniami zmianę statusu",
    timeouts: {
      inactivityTimeout: "Limit czasu bezczynności",
      step: {
        findElementTimeout: "Limit czasu odnalezienia elementu w kroku",
      },
      optionalStep: {
        findElementTimeout: "Limit czasu odnalezienia elementu w opcjonalnym kroku",
      },
      redirectTimeout: "Limit czas przekierowania na inne środowisko"
    },
    resolvers: {
      info: {
        intro:
          "Mechanizm odpowiedzialny ze wyszukiwanie i znajdowanie elementów w trakcie nagrywania lub odtwarzania. Przepisany w celu poprawienia stabilnośći",
        SMART: "Smart - nowy mechanizm który powinien poprawić stabilność i długość życia skryptów",
        FALLBACK:
          "Mix - ten tryb używa mechanizmu 'Inteligentny' a w przypadku błedów próbuje się wspomóc mechanizmem 'Prosty'",
        LEGACY: "Prosty - stary mechanizm",
      },
      mode: "Metoda wykrywania elementów",
      modes: {
        SMART: "Inteligentny",
        FALLBACK: "Mix",
        LEGACY: "Prosty",
      },
      generateOnPlaybackInfo:
        "Uwaga! Ta opcja włącza przegenerowanie danych dla trybu inteligentnego rozpoznawiania kroków. Odbywa się to w trakcie odtwarzania skryptów. Używaj tej opcji tylko w sytuacjach kiedy kroki nie działają poprawnie",
      generateOnPlayback: "Przegeneruj dane trybu inteligetnego rozpoznawiania kroków",
      positionResolverThreshold: "Próg resolvera pozycji",
      positionResolverThresholdInfo:
        "Mechanizm szukania elementu po pozycji sprawdza metadane znalezionego elementu. Ten próg określa ile metadanych musi się zgodzić z metadanymi nagranego elementu.",
    },
    documentationTemplate: "Szablon dokumentacji",
    docTemplate: "Szablon dokum.",
    selectDocumentationTemplate: "Wybierz szablon dokumentacji",
    reportTemplate: "Szablon raportów",
    selectReportTemplate: "Wybierz szablon raportów",
    documentationSettings: "Ustawienia dokumentacji",
    powerBiSettings: "Ustawienia platformy Power BI",
    placeholder: {
      powerBiPlaceholder: "Wpisz adres URL platformy Power BI",
    },
  },
  navigation: {
    unknownRoute: "Nie można odnaleźć strony.",
    loading: "Ładuję...",
  },
  button: {
    cancel: "Anuluj",
    create: "Utwórz",
    change: "Zmień",
    clone: "Klonuj",
    publish: "Publikuj",
    edit: "Edytuj",
    close: "Zamknij",
    download: "Pobierz",
    delete: "Usuń",
    save: "Zapisz",
    yes: "Tak",
    no: "Nie",
    openWorkItem: "Otwórz zgłoszenie",
    createWorkItem: "Utwórz zgłoszenie",
    run: "Odtwórz",
    record: "Nagraj",
    changeStatus: "Zmień status",
    export: "Wyeksportuj",
    import: "Importuj",
    add: "Dodaj",
    reload: "Przeładuj",
    goToCloned: "Idź do sklonowanego skryptu",
    default: "Domyślny",
    custom: "Niestandardowy",
    generatePdf: "Wygeneruj PDF",
  },
  commandBar: {
    addToGroup: "Dodaj do grupy",
    createGroup: "Utwórz grupę",
    moveGroup: "Przesuń do grupy",
    compare: "Porównaj",
    downloadDoc: "Pobierz dokumentację",
    download: "Pobierz",
    tokens: "Uwierzytelnianie",
    add: "Dodaj",
    actions: "Akcje",
    create: "Utwórz",
    clone: "Klonuj",
    close: "Zamknij",
    refresh: "Odśwież",
    code: "Kod",
    copy: "Kopiuj",
    view: "Widok",
    fetch: "Pobierz",
    showClosed: "Pokaż zamknięte",
    hideClosed: "Ukryj zamknięte",
    import: "Import",
    showInternal: "Pokaż wewnątrzne",
    script: "Skrypt",
    shortcut: "Skrót klawiszowy",
    hideInternal: "Ukryj wewnętrzne",
    open: "Otwórz",
    start: "Uruchom",
    record: "Nagraj",
    play: "Odtwórz",
    flat: "Płaski",
    grouped: "Zgrupowany",
    statistics: "Statystki",
    generate: "Generuj",
    terminate: "Przerwij",
    removeLinks: "Usuń linki",
    removeClose: "Usuń/Zamknij",
    password: "Zmień hasło",
    remove: "Usuń",
    removeSelected: "Usuń zaznaczone",
    edit: "Edytuj",
    editMode: "Tryb edycji",
    authenticate: "Uwierzytelnij",
    nonSupervisedRecord: "Nagrywanie nienadzorowane",
    supervisedRecord: "Nagrywanie nadzorowane",
    logout: "Wyloguj",
    save: "Zapisz",
    refreshApiKey: "Odśwież klucz API",
    assignValue: "Przypisz wartość do zmiennej",
    newStep: "Nowy krok",
    rework: "Nagraj nowe kroki",
    setRework: "Ustaw punkt nagrywania",
    resetRework: "Resetuj punkt nagrywania",
    assert: "Warunek",
    login: "Loguj",
    paste: "Wklej",
    reorder: "Zmień kolejność",
    stopReorder: "Zakończ zmianę kolejności",
    quickAction: "Szybka akcja",
    enableSteps: "Włącz krok(i)",
    disbaleSteps: "Wyłącz krok(i)",
    setStepsOptional: "Ustaw krok jako opcjonalny",
    unsetStepsOptional: "Ustaw krok jako obowiązkowy",
    aggregated: "Agregowane logi",
    nonAggregated: "Niezagregowane logi",
    screenshotGallery: "Galeria zrzutów ekranu",
    showErrors: "Pokaż błędy/ostrzeżenia",
    goTo: "Idź do",
    export: "Eksportuj do pliku",
    exportToCsv: "Eksportuj do csv",
    exportSelectedToCsv: "Eksportuj zaznaczone do csv",
    override: "Nadpisywanie",
    markDefault: "Ustaw jako domyślne",
    showWithWorkItems: "Pokaż skrypty ze zgłoszeniami",
    hideWithWorkItems: "Pokaż wszystkie skrypty",
    addons: "Dodatki",
    capturedFiles: "Przechwycone pliki",
    editTranslations: "Edytuj tłumaczenia",
    showTranslations: "Pokaż tłumaczenia",
    excludeJobs: "Wyklucz wyniki",
    ganttStartTime: "Zmień czas rozpoczęcia gantta",
    scriptsStartTime: "Zmień czas rozpoczecia skryptów",
    functions: "Funkcje",
    azureDevOps: "Azure DevOps",
    openTestCase: "Otwórz test case",
    openTestResult: "Otwórz test result",
    openTestSuite: "Otwórz test suite",
    openTestSuiteResults: "Otwórz wyniki test suite",
    showDevopsExecutions: "Pokaż wykonania test run",
    details: "Detale",
    createAndAssign: "Utwórz i przypisz",
    unassign: "Odepnij",
    assign: "Przypisz",
    data: "Dane",
    enableDrawOverlay: "Aktywuj rysowanie obramówek kroku",
    disableDrawOverlay: "Dezaktywuj rysowanie obramówek kroku",
    showActiveSessions: "Pokaż aktywne sesje",
    showLogs: "Pokaż logi",
    performanceCounters: "Liczniki wydajności",
    addScripts: "Dodaj skrypty",
    true: "Tak",
    false: "Nie",
    usedIn: "Użyty w",
  },
  topbar: {
    scripts: "Skrypty",
    history: "Historia",
    scheduler: "Harmonogram",
    shortcuts: "Skróty",
    journeys: "Podróże",
    users: "Użytkownik",
    settings: "Ustawienia",
    projects: "Projekty",
    documentation: "Dokumentacja",
    reports: "Raporty",
  },
  projects: {
    name: "Nazwa projektu",
    noProjects: "Brak projektów",
    notFound: "Nie odnaleziono projektu",
    loading: "Ładowanie projektów...",
    scheduleExecution: "Zaplanuj wykonanie",
    schedulerCreated: "Zaplanowano wykonanie dla projektu",
    schedulerCreatedTitle: "Zaplanowano wykonanie",
    terminateExecution: "Przerwij",
    dispatching: "Uruchamianie",
    exportToDevOps: "Wyeksportuj do Test Plans",
    removeProject: "Czy na pewno chcesz usunąć wybrany projekt?",
    removeTestPlanSuite: "Usuń połączony Test Plan / Suite?",
    tabs: {
      details: "Szczegóły",
      scripts: "Skrypty",
      userGroups: "Grupy użytkowników",
      history: "Historia",
      virtualUsers: "Wirtualni użytkownicy",
      systems: "Systemy",
      testResults: "Wyniki testów",
      issueTracking: "Śledzenie zdarzeń",
      documentationScripts: "Skrypty z dokumentacją",
      documentation: "Dokumentacje",
    },
    details: {
      label: {
        name: "Nazwa",
        category: "Kategoria",
        version: "Wersja",
        dueDate: "Data zakończenia",
        startDate: "Data rozpoczęcia",
        owners: "Właściciele projektu",
        statusToRun: "Status do uruchomienia",
        tagsToRun: "Tagi do uruchomienia",
        attachedTestPlan: "Powiązany test plan",
        attachedTestSuite: "Powiązana test suite'a",
        openAttachedTestPlan: "Otwórz powiązany test plan",
        openAttachedTestSuite: "Otwórz powiązaną test suite",
        documentationLocale: "Język dokumentacji",
        configuration: "Konfiguracja",
      },
      placeholder: {
        name: "Wpisz nazwę",
        category: "Wybierz kategorię",
        search: "Szukaj projektu",
        version: "Wpisz wersję",
        dueDate: "Wybierz datę zakończenia",
        startDate: "Wybierz datę rozpoczęcia",
        owners: "Wybierz właścicieli projektu",
        statusToRun: "Wybierz status do uruchomienia",
        tagsToRun: "Wyberz tagi do uruchomienia",
        attachedTestPlan: "Wybierz test plan",
        attachedTestSuite: "Wybierz test suite",
        documentationLocale: "Wybierz język dokumentacji",
        configuration: "Wybierz konfigurację",
      },
    },
  },
  platform: {
    default: "Platform domyślna",
    microsoftLogin: "Microsoft Login",
    d365fo: "Dynamics 365 For Finance and Operations",
    other: "Inny",
    cpos: "Cloud POS",
  },
  validators: {
    required: "Pole wymagane",
    whitespaces: "Spacje są zabronione",
    startLetter: " Nazwa nie może zaczynać się od liczby",
    mustBeNumber: "Wartość musi być liczbą",
    minValue: "Wartość musi być większa niż",
    whitespacesAndSpecialChars: "Spacje i znaki specjalne są zabronione",
    numSequenceFormat: "Zły format, dodaj przynajmniej jeden znak # dla sekwencji",
    numSequenceBreak: "Zły format, nie można rozdzielać #",
    emailList: "Wprowadź poprawną listę adresów rozdzieloną przecinkami",
    email: "Wprowadź prawidłowy adres e-mail",
    null: "Pole nie może mieć wartości null",
    userName: "Wprowadź login aktualnie zalogowanego użytkownika",
  },
  messages: {
    help: {
      pick: "Zmienna zostanie utworzona",
    },
    info: {
      noUserIts: "Użytkownik nie skonfigurował domyślnego systemu śledzenia zdarzeń",
      stepsSyncing: "Kroki są synchronizowane, proszę czekać",
      connectionEstablished: "Połączono z serwerem.",
      reconnectAttempt: "Próba odnowienia połączenia",
      scriptNoProjectSystem: "Projekt nie posiada domyślnego środowiska",
      scriptNoProjectDefaultVu: "Projekt nie posiada domyślnego wirtualnego użytkownia.",
      dropZone: "Spróbuj upuścić arkusz kalkulacyjny lub wybierz plik do załadowania.",
      dropZipZone: "Spróbuj upuścić plik ze skryptami lub wybierz plik do załadowania.",
      noDataSource: "Brak źródła danych.",
      nothingCopied: "Nic nie zostało skopiowane.",
      moreThanOneStep: "Zaznaczono więcej niż jeden krok.",
      noStepSelected: "Nie zaznaczono kroków.",
      error: "Coś poszło nie tak. Odśwież stronę lub skontaktuj się z administratorem systemu.",
      browserOutdated: "Przeglądarka nie jest obsługiwana. Użyj Google Chrome.",
      dataSource: "Źródło danych zawiera błędne wiersze.",
      noScript: "Nie można znaleźć systemu dla danego skryptu.",
      openDraft: "Czy chcesz otworzyć wersję roboczą",
      overrideDraft: "Czy chcesz nadpisać wersję roboczą?",
      closeScript: "Czy na pewno chcesz zamknąć zaznaczony skrypt?",
      closeScripts: "Czy na pewno chcesz zamknąć zaznaczone skrypty?",
      exporting: "Eksportowanie danych do pliku",
      selectProject: "Wybierz projekt z drzewa projektów",
      commandNotInstalled: "Brak wsparcia dla komend",
      commandQuickEditNotAvailable: "Komenda nie wspiera szybkiej edycji",
      uploadingFiles: "Niektóre pliki są dalej wysyłane",
    },
    success: {
      reconnected: "Połączenie przywrócone.",
      fileUpload: "Plik został przesłany",
      fileRemoved: "Plik został usunięty",
      cloneScripts: "Skrypty zostały sklonowane.",
      stepsCopied: "Kroki zostały skopiowane",
      termination: "Zakończenie pomyślne",
      stepsDeleted: "Kroki zostały usunięte",
      workItem: "Zgłoszenie zostało utworzone",
      userSettingsEdited: "Ustawienia użytkownika zostały zmienione",
      excludeJobs: "Zmieniono status wykluczenia",
      scriptStatusChange: "Status skryptu został zmieniony",
    },
    error: {
      noReportLink: "Użytkownik nie ustawił adresu Power BI",
      failedLogin: "Nieudana próba logowania",
      loading: "Problem z załadowaniem",
      connectionError: "Błąd połączenia z serwerem.",
      connectionLost: "Połączenie z serwerem przerwane.",
      noStartEndStepDelete: "Nie można usunąć kroku: Start / End.",
      fileGet: `Błąd podczas otrzymywania pliku. ${translationsConsts.error.checkConsole}`,
      fileUpload: `Błąd podczas ładowania pliku. ${translationsConsts.error.checkConsole}`,
      fileDownload: "Błąd podczas pobierania pliku.",
      fileDelete: `Error while deleting file. ${translationsConsts.error.checkConsole}`,
      noScriptSelect: "Nie zaznaczono żadnych skryptów",
      passwordNotSame: "Hasła nie są takie same",
      failedFetchStatistics: "Błąd podczas pobierania statystyk",
      termination: "Zakończenie błędne",
      fetchError: "Problem z ładowaniem systemu",
      notLogin: "Niezalogowany",
      noDraftId: "Brak wersji roboczej",
      cloneScript: " Nie można sklonować skryptu",
      publshScript: "Nie można opublikować skryptu",
      export: "Błąd podczas eksportu do pliku",
      sessionStart: "Błąd podczas uruchamiania odtwarzania",
      sessionLimitReached: "Osiągnieto limit sesji na pierwszym planie",
      checkConsole: `Błąd podczas uruchamiania odtwarzania. ${translationsConsts.error.checkConsole}`,
      publishScriptConsole: `Błąd podczas publikowania skryptu. ${translationsConsts.error.checkConsole}`,
      workItem: "Błąd podczas rejestracji zgłoszenia",
      workItemNoIts:
        "Błąd podczas rejestracji zgłoszenia. Wybierz aktywny system zgłoszeń w ustawieniach użytkownika.",
      preferences: "Błąd podczas zapisywania preferencji.",
      cannotFetchFiles: "Nie można pobrać listy przechwyconych plików",
      userSettingsEdited: "Błąd podczas zapisywania ustawień użytkownika",
      excludeJobs: "Błąd podczas zmiany statusu wykluczenia",
      fileRequired: "Plik jest wymagany",
      captchaUnavailable: "Serwis ReCaptcha jest niedostępny. Sprawdź połączenie z internetem.",
      captchaExpired: "Wyzwanie ReCaptcha wygasło.",
      dispatchingFailed: "Uruchamianie nie powiodło się",
      reportGenerationFailed: "Generowanie raportu nie powiodło się",
      licenseFileError: "Wygląda na to, że plik licencyjny jest nie poprawny.",
      notEnoughWorkerCapacity: "Brak wolnych workerów",
      stepWrongRecord: "Krok został nagrany niepoprawnie. Skontaktuj się ze wsparciem technicznym.",
    },
    warn: {
      singleFile: "Obługiwany jest tylko pojedynczy arkusz kalkulacyjny",
    },
    confirm: {
      delStep: "Czy na pewno chcesz usunąć krok?",
      delSteps: "Czy na pewno chcesz usunąć wybrane kroki?",
      delGlobalVar: "Czy na pewno chcesz usunąć zmienną globalną?",
      delGlobalVars: "Czy na pewno chcesz usunąć zmienne globalne?",
      delKpi: "Czy na pewno chcesz usunąć kpi?",
      delKpis: "Czy na pewno chcesz usunąć wybrane kpi?",
      delCodeTemplateGroup: "Czy na pewno chcesz usunąć wybraną grupę?",
      delCodeTemplateGroups: "Czy na pewno chcesz usunąć wybrane grupy?",
      reactivateScheduler:
        "Czy na pewno chcesz reaktywować scheduler'a? Pamiętaj, że może on zawierać zamknięte/usunięte skrypty co moze powodowac problemy z odtwarzaniem.",
      consentDocumentationInfo:
        "Potwierdzam, że dokument {prettyName} jest zweryfikowany i niniejszym potwierdzam zmianę statusu na: {status}",
      consentScriptInfo:
        "Potwierdzam, że skrypt: {name} jest zweryfikowany i niniejszym potwierdzam zmianę statusu na: {status}",
      consentCheckbox: "Potwierdzam",
      confirmation: "Potwierdzenie",
    },
    tooltip: {
      reorder: "Przeciągnij i upuść aby zmienić kolejność",
      pickColumns: "Kliknij aby wybrać kolumny",
      goToProject: "Idź do projektu",
      saveBeforeCreateGroup: "Zapisz przed tworzeniem grupy",
    },
  },
  scripts: {
    error: {
      errorGenerateTemplate: "Błąd podczas generowania szablonu źródła danych",
    },
    label: {
      newScript: "Utwórz nowy skrypt",
      shortcut: "Skrót klawiszowy",
      status: "Status",
      attachedTestCase: "Powiązany test case",
      openAttachedTestCase: "Otwórz powiązany test case",
      exportToTestCase: "Wyeksportuj jako test case",
      updateTestCaseStatus: "Zaktualizuj status test case'a",
      takeScreenshots: "Utwórz zrzut ekranu",
      screenshotsOnlyOnInterruption: "Zrzut ekranu tylko na zdarzeniu",
      clickOnDisabledButtons: "Klikaj w zablokowane przyciski",
      useDefaultEnvironment: "Użyj domyślnego środowiska",
      topProjectName: "Nazwa głównego projekt",
      virtualUserName: "Nazwa wirtualnego użytkownika",
    },
    placeholder: {
      shortcut: "Wybierz skrót klawiszowy",
      status: "Wybierz status",
      attachedTestCase: "Wybierz test case",
    },
    uploadingFiles: "Załaduj plik",
    emptyField: "Wprowadź nazwę pliku",
    header: {
      noScriptSelect: "Nie zaznaczono skryptów",
      import: "Import skryptów",
      generalInfo: "Informacje ogólne",
      edit: "Edytuj skrypt",
      create: "Utwórz nowy skrypt",
      shortcut: "Stwórz skrót",
      clone: "Klonuj",
      changeStatus: "Zmień status",
    },
    state: {
      notFound: "Skrypt nieodnaleziony",
    },
    actions: {
      openDraft: "Otwórz wersję roboczą",
      saveDraft: "Zapisz wersję roboczą",
    },
    status: {
      PUBLISHED: "OPUBLIKOWANE",
      PENDING: "W TRAKCIE",
      DRAFT: "SZKIC",
      CLOSED: "ZAMKNIĘTE",
      IN_PROGRESS: "W TRAKCIE",
      READY_TO_TEST: "GOTOWE DO TESTU",
      READY_FOR_REVIEW: "GOTOWE DO PRZEGLĄDU",
    },
  },
  messageBuilder: {
    clone: "Klonowanie",
    publish: "Publikowanie",
    succesfully: "zakończone powodzeniem",
    goTo: "Idź do",
    hasBeen: "został",
    created: "utworzony",
    published: "opublikowany",
    cloned: "sklonowany",
    publishNew: "Opublikuj jako nowy",
    dearUser: "Drogi użytkowniku,",
    pleaseContact: "Proszę o kontakt na adres:",
  },
  guards: {
    type: {
      label: "Reaguj dalej",
      elementNotFound: "Element nie został znaleziony",
      systemError: "Błąd systemu",
      systemWarning: "Ostrzeżenie systemowe",
      error: "Błąd",
    },
    steps: "Kroki strażnika",
    action: {
      label: "Wykonaj akcję",
      value: "Wartość akcji",
      continue: "Kontynuuj",
      repeat: "Powtórz",
      goTo: "Skocz do kroku",
    },
    header: "Stwórz strażnika",
  },
  userGroups: {
    noGroup: "Brak grupy",
    header: {
      edit: "Zmodyfikuj grupę użytkowników",
      create: "Stwórz grupę użytkowników",
    },
  },
  virtualUsers: {
    customScriptId: "ID niestandardowego skryptu",
    customScriptLogin: "Niestandardowy skrypt - nie można pobrać loginu",
    isSensitive: "Wrażliwe dane",
    isSystemLogin: "Login systemowy",
    rememberSensitiveData: "Zapamiętaj wrażliwe dane",
    noSensitiveData: "Brak wrażliwych danych",
    systemAuthEnabled: "Włącz uwierzytelnianie systemowe",
    systemAuthentication: "Uwierzytelnianie systemowe",
    reauthenticate: "Automatycznie uwierzytelnianie",
    authenticate: "Uwierzytelnienie",
    reauthenticateInterval: "Powtarzaj co",
    authErrorBox: "Błąd autoryzacji",
    customUrl: "Niestandardowy adres URL",
    customScript: "Niestandardowy skrypt",
    retailServerUrl: "URL serwera sprzedażowego",
    cposUrl: "Cloud POS Url",
    hoursPlural: "Godziny",
    platform: "Platforma",
    systemType: "Typ systemu",
    header: {
      edit: "Edytuj witualnego użytkownia",
      create: "Stwórz wirtualnego użytkownika",
    },
    domain: "Domena",
    workstation: "Stacja robocza",
    user: "Użytkownik",
    deviceId: "Id urządzenia",
    operatorId: "ID Operatora",
    serverUrl: "URL Serwera",
    registerNumber: "Numer rejestracyjny",
    operatorPassword: "Hasło operatora",
    pairHardwareStation: "Sparuj urządzenie robocze",
    hardwareStationName: "Nazwa stacji roboczej",
    pin: "Pin",
    authType: {
      label: "Dodatkowe uwierzytelnianie",
      basic: "Basic",
      ntlm: "NTLM",
      system: "Systemowy",
      none: "Brak",
    },
    systemOptions: {
      microsoftLogin: "Microsoft login",
      cpos: "Cloud point of service",
      microsoftLoginOkta: "Microsoft login & Okta",
      transporeon: "Transporeon",
    },
    extraNtlm: "Dodatkowe NTLM",
    sensitiveData: "Dane wrażliwe",
  },
  systemDictionary: {
    label: {
      expandAll: "Rozwiń automatycznie wszystkie zakładki",
      forceOpenPopup: "Wymuszaj otwieranie popupow",
      forceOpenContextMenu: "Wymuszaj otwieranie menu contextowego",
    },
    type: {
      development: "Development",
      test: "Testy",
      production: "Produkcja",
      uat: "Testy akceptacyjne",
      other: "Inne",
    },
    placeholder: {
      environmentType: "Wybierz typ środowiska",
      virtualUsers: "Wpisz nazwę wirtualnego użytkownika",
    },
    header: {
      edit: "Edytuj definicję systemu",
      create: "Stwórz definicję systemu",
    },
  },
  user: {
    header: {
      editUser: "Edytuj ustawienia użytkownika",
      createUser: "Stwórz użytkownika",
      setting: "Zmień ustawienia",
    },
    label: {
      currentPassword: "Obecne hasło",
      newPassword: "Nowe hasło",
    },
    placeholder: {
      currentPassword: "Wprowadź obecne hasło",
      newPassword: "Wprowadź nowe hasło",
      repeatedNewPassword: "Powtórz nowe hasło",
    },
  },
  sequences: {
    placeholder: {
      name: "Wprowadź nazwę sekwencji",
      format: "Wprowadź format sekwencji",
      nextValue: "Wprowadź następną wartość sekwencji",
    },
    label: {
      format: "Format",
      nextValue: "Następna wartość",
    },
    message: {
      noDecrease: "Nie można zmniejszyć wartości",
    },
    header: {
      edit: "Edytuj sekwencję",
      create: "Stwórz sekwencję",
    },
  },
  storage: {
    placeholder: {
      name: "Wprowadź nazwę magazynu",
      apiKey: "Wprowadź klucz API",
      type: "Wybierz typ magazynu",
    },
    label: {
      apiKey: "Wprowadź klucz API",
      type: "Typ magazynu",
    },
    header: {
      edit: "Edytuj magazyn",
      create: "Stwórz nowy magazyn",
    },
  },
  playMode: {
    playMode: "Tryb odtwarzania",
    foreground: "Pierwszy plan",
    background: "Tło",
    autoStart: "Autostart",
    stepByStep: "Krok po kroku",
    slowMode: "Tryb spowolniony",
    selectPlayMode: "Wybierz tryb uruchamiania",
    documentation: "Dokumentacja",
    document: "Dokument",
    video: "Video",
    docAndVideo: "Dokument oraz video",
    mapWithDevops: "Wynik do DevOps",
    visible: "Widoczny",
    hidden: "Ukryty",
    onlyCurrentStep: "Tylko aktualny krok",
    onError: "Podczas błędu",
    onSuccess: "Podczas sukcesu",
    always: "Zawsze",
  },
  recordMode: {
    selectRecordMode: "Wybierz tryb nagrywania",
    recorderType: "Typ rekordera",
    basic: "Podstawowy",
    advanced: "Zaawansowany",
    newWindow: "Nowe okno",
    currentWindow: "Aktualne okno",
    openInNewWindow: "Otwórz w nowym oknie",
  },
  recorderPosition: {
    left: "Lewa strona",
    right: "Prawa strona",
  },
  advancedRecorder: {
    tooltip: {
      hideStepsPanel: "Schowaj panel z krokami",
      showStepsPanel: "Pokaż panel z krokami",
      back: "Cofnij",
      refreshPage: "Odśwież stronę",
      close: "Zamknij",
    },
  },
  field: {
    emptyField: "Pole nie może być puste",
  },
  password: {
    changePassword: "Zmień hasło",
    diffPassword: "Hasła nie są takie same",
    currentPassword: "Aktualne hasło",
    newPassword: "Nowe hasło",
    repeatPassword: "Powtórz hasło",
    passwordChanged: "Hasło zmienione poprawnie",
    passwordChangeError: "Nie można zmienić hasła",
    length: "Hasło musi mieć przynajmniej 12 znaków",
    lowerCase: "Hasło musi zawierać przynajmniej jedną małą literę",
    capitalLetter: "Hasło musi zawierać przynajmniej jedną dużą literę",
    digit: "Hasło musi zawierać przynajmniej jedną cyfrę",
    specialCharacter: "Hasło musi zawierać przynajmniej jeden znak specjalny",
    notAllowedCharacter: "Hasło zawiera niedozwolone znaki lub spacje",
  },
  variable: {
    label: {
      variableName: "Nazwa zmiennej",
      noScript: "Brak zaznaczonych zmiennych",
      select: "Zaznacz zmienne",
      value: "Wartość",
      removeVariable: "Usuń zmienną",
      local: "Lokalne",
      global: "Globalne",
    },
    placeholder: {
      selectType: "Zaznacz typ",
      enterValue: "Wprowadź wartość",
      defaultValue: "Wartość domyślna",
      name: "Nazwa zmiennej",
      typeValue: "Typ wartości",
      initialValue: "Wartość początkowa",
      noValue: "No value",
    },
    input: {
      name: "Nazwa:",
      type: "Typ:",
      defaultValue: "Wartość domyślna:",
    },
    type: {
      text: "Tekst",
      number: "Numer",
      date: "Data",
    },
  },
  logs: {
    header: {
      message: "Wiadomości",
      inputHeader: "Zmienne wejściowe",
      executedCode: "Kod wykonania",
      outputValue: "Wartość wyjściowa",
    },
    lastErrorMessage: "Ostatnie problemy",
    scriptLogUrl: "Url logu skryptu",
  },
  scheduler: {
    mode: {
      basic: "Basic",
      gantt: "Gantt",
    },
    runMode: {
      sequential: "Sekwencyjny",
      parallel: "Równoległy",
      gantt: "Gantt",
    },
    activate: "Aktywuj",
    deactivate: "Dezaktywuj",
    label: {
      existingGroup: "Istniejące grupy",
      jobName: "Wprowadź nazwę zadania",
      triggerType: "Typ wyzwalacza",
      maximumParallelSessions: "Maksymalna ilość sesji równoległych",
      createAsGroup: "Dodaj jako grupę",
      groupName: "Nazwa grupy",
    },
    placeholder: {
      existingGroup: "Wybierz grupę",
      triggerType: "Wybierz typ wyzwalacza",
      startDate: "Wybierz datę uruchomienia",
      notificationEmails: "Wybierz adres email",
    },
    settings: {
      recurring: "Powtarzaj",
      repeatsEvery: "Powtarzaj co",
      endType: "Typ zakończenia",
      never: "Nigdy",
      afterOccurrences: "Po wystąpieniu",
      onDate: "Z datą",
      storage: "Przestrzeń",
      startDateTime: "Czas rozpoczęcia",
      occurrences: "Wystąpienie",
      endDateTime: "Czas zakończenia",
      minutes: "Minutę",
      hours: "Godzinę",
      days: "Dzień",
      weeks: "Tydzień",
      months: "Miesiąc",
      years: "Rok",
      minutesPlural: "Minuty",
      hoursPlural: "Godziny",
      daysPlural: "Dni",
      weeksPlural: "Tygodnie",
      monthsPlural: "Miesiące",
      yearsPlural: "Lata",
      time: "Czas",
      notifyByEmail: "Powiadomienia email",
      notificationEmails: "Adres email",
      schedulerJobFlow: "Harmonogram zadań",
    },
    message: {
      notFound: "Harmonogram nie został znaleziony",
    },
    header: {
      createGroup: "Stwórz grupę",
      moveGroup: "Przesuń do grupy",
      edit: "Edytuj harmonogram",
      create: "Stwórz nowy harmonogram",
      setGanttStart: "Zmień czas rozpoczęcia",
      setScriptsStart: "Zmień czas rozpoczęcia skryptów",
    },
    statuses: {
      ACTIVE: "AKTYWNY",
      COMPLETED: "UKOŃCZONY",
      INACTIVE: "NIEAKTYWNY",
      CLOSED: "ZAMKNIĘTY",
      RUNNING: "W TRAKCIE",
    },
    flow: {
      addPanel: {
        addScripts: "Dodaj skrypty",
        selectScripts: "Wybierz skrypty",
        scriptsDetails: "Detale skryptu",
        scriptName: "Nazwa skryptu",
        scriptLabel: "Etykieta skryptu",
        repeatCount: "Ilość powtórzeń",
      },
    },
  },
  table: {
    action: "Akcja",
    rowData: "Dane rekordu",
    rowId: "Id wiersza",
    serverLogin: "Login serwera",
    systemLogin: "Login systemowy",
    changedFields: "Zmodyfikowane pola",
    tableName: "Nazwa tabeli",
    version: "Wersja",
    selectAll: "Zaznacz wszystkie dane",
    dataSource: "Źródło danych",
    selectAllColumns: "Zaznacz wszystkie",
    pickColumns: "Wybierz kolumny",
    noColumnsSelected: "Nie zaznaczono kolumn",
    noValue: "Brak wartości",
    noTitle: "Brak tytułu",
    clear: "Odznacz wszystkie",
    valueType: "Typ wartości",
    defaultValue: "Wartość domyślna",
    groupId: "Id",
    scriptId: "Id Skryptu",
    id: "Id",
    parentId: "Id rodzica",
    repeatCount: "Ilość powtórzeń",
    default: "Domyślny",
    groupName: "Nazwa grupy",
    projectName: "Nazwa projektu",
    envName: "Nazwa środowiska",
    envNameShort: "Nazwa środ.",
    envType: "Typ środowiska",
    envTypeShort: "Typ środ.",
    publishName: "Nazwa publikacji",
    startUrl: "Adres startowy",
    status: "Status",
    createdAt: "Data utworzenia",
    creator: "Autor",
    parentType: "Typ",
    sessionId: "Id sesji",
    startTime: "Start",
    scriptStatus: "Status skryptu",
    signedBy: "Podpisane przez",
    startTimeShort: "Czas uruchomienia",
    endTime: "Czas zakończenia",
    endTimeShort: "Koniec",
    duration: "Czas trwania",
    durationShort: "Czas trwania",
    parent: "Nazwa",
    schedulerJobName: "Nazwa zadania",
    schedulerJobNameShort: "Zadanie",
    screenshotPath: "Zrzut ekranu",
    lineNum: "Nr lini",
    label: "Etykieta",
    username: "Nazwa użytkownika",
    email: "Email",
    apiKey: "Klucz API",
    name: "Nazwa",
    filename: "Nazwa pliku",
    envAddress: "Adres",
    applicationName: "Nazwa aplikacji",
    applicationNameShort: "Nazwa apl.",
    description: "Opis",
    virtualUsers: "Wirtualny użytkownik",
    jobId: "Id zadania",
    startDateTime: "Pierwsze wystąpienie",
    endDateTime: "Czas zakończenia",
    triggerType: "Typ wyzwalacza",
    reccurrence: "Powtórzenie",
    ttl: "Czas życia",
    type: "Typ",
    types: {
      bug: "Błąd",
      task: "Zadanie",
      issue: "Problem",
    },
    account: "Konto",
    storageType: "Typ zmiennej",
    format: "Format",
    nextval: "Następna wartość",
    updatedAt: "Data edycji",
    login: "Login",
    line: "Linia",
    optional: "Opcjonalny",
    avgTime: "Średni czas",
    state: "Stan",
    states: {
      none: "ŻADEN",
      running: "WYKONYWANY",
      paused: "ZATRZYMANY",
      finished: "ZAKOŃCZONY",
      terminated: "PRZERWANY",
      success: "SUKCES",
      warning: "OSTRZEŻENIE",
      error: "BŁĄD",
    },
    statesCapitalLetter: {
      none: "Żaden",
      running: "Wykonywany",
      paused: "Zatrzymany",
      finished: "Zakończony",
      terminated: "Przerwany",
      success: "Sukces",
      warning: "Ostrzeżenie",
      error: "Błąd",
    },
    all: "Wszystkie",
    projectId: "Id Projektu",
    schedulerJobId: "Id zadania",
    scriptName: "Nazwa",
    columns: "Kolumny",
    noFilters: "Brak filtrów",
    host: "Adres systemu",
    project: "Projekt",
    token: "Token",
    title: "Tytuł",
    its: "System śledzenia zdarzeń",
    stepLabel: "Krok",
    link: "Link",
    hasConditions: "Warunkowy",
    previousStatus: "Poprzedni status",
    newStatus: "Nowy status",
    comment: "Komentarz",
    manualUrl: "Manualny URL",
    projectDefaultEnv: "Domyślne środowisko projektu",
    projectUnknown: "Projekt nieznany",
    tags: "Tagi",
    scriptVersion: "Wersja skryptu",
    language: "Język",
    isExcluded: "Wykluczony",
    virtualUser: "Wirtualny użytkownik",
    virtualUserShort: "W.U.",
    isSensitive: "Wrażliwe",
    value: "Wartość",
    active: "Aktywny",
    scriptEnvironment: "Środowisko skryptu",
    overridenEnv: "Przeciążone środowisko",
    overridenVu: "Przeciążony wirtualny użytkownik",
    activeVersion: "Aktywna wersja",
    lastExecutionStatus: "Ostatni status wykonania",
    lastExecutionDate: "Data ostatniego wykonania",
    lastExecutorName: "Ostatnio uruchamiane przez",
  },
  settings: {
    auditTrail: "Log audytowy",
    globalSettings: "Globalne ustawienia",
    globalActions: "Globalne akcje",
    application: "Aplikacja",
    systemDefinitions: "Definicje systemów",
    storages: "Magazyn",
    sequences: "Sekwencje",
    globalVariables: "Globalne zmienne",
    virtualUser: "Wirtualni użytkownicy",
    apiKey: "Klucz API",
    profil: "Profil",
    users: "Użytkownicy",
    basicSettings: "Ustawienia podstawowe",
    executionSettings: "Ustawienia wykonania",
    userGroups: "Grupy użytkowników",
    projectCategory: "Kategorie projektu",
    issueTrackingTools: "Systemy",
    itsRules: "Reguły",
    itsConfigurations: "Konfiguracje",
    reportExtension: "Typ pliku raportu wykonania",
    reportExtensionPlaceholder: "Wybierz typ pliku raportu wykonania",
    tags: "Tagi",
    displayOnlyPublishedDocuments: "Wyświetlaj tylko opublikowaną dokumentacje",
    localizations: "Lokalizacje językowe",
    reportTemplates: "Wzory raportów",
    translate: "Tłumaczenie",
    scriptsReportsLocale: "Język skryptów i raportów",
    selectScriptsReportsLocale: "Wybierz język skryptów i raportów",
    recorderType: "Typ rekordera",
    recorderOpenMode: "Sposób otwarcia rekordera",
    recorderPosition: "Pozycja rekordera",
    playerType: "Typ odtwarzacza",
    playerOpenMode: "Sposób otwarcia odtwarzacza",
    playerPosition: "Pozycja odtwarzacza",
    license: "Licencja",
    codeTemplateGroups: "Grupy szablonów kodu",
    kpi: "KPI",
    codeTemplates: "Szablony kodu",
    code: "Kod",
  },
  localization: {
    import: "Wczytaj plik lokalizacyjny",
    overwriteExisting: "Nadpisz istniejące tłumaczenia",
    needsTranslation: "Wymaga tłumaczenia",
  },
  apiKey: {
    label: {
      ttl: "Czas życia",
    },
    placeholder: {
      ttl: "Wybierz czas życia",
    },
    header: {
      edit: "Edytuj klucz API",
      create: "Stwórz klucz API",
    },
  },
  loadingLabel: "Ładuję...",
  none: "Żaden",
  step: {
    newPanel: {
      create: "Utwórz",
      step: "krok",
    },
    hasConditions: "Krok jest sprawdzany pod kątem warunków podczas wykonania",
    hasCodeTemplate: "Krok ma przypisany szablon kodu",
    diagram: {
      noSteps: "Brak kroków do wyświetlenia",
    },
    tokens: {
      header: {
        create: "Create tokens step",
      },
    },
    detailsForm: {
      column: "Kolumna",
      rows: "Wiersze",
      advancedFields: "Pola zaawansowane",
      emailPreview: "Podglad emaila",
      overrideVariables: "Nadpisz zmienne",
      termination: {
        type: {
          timeout: "Maksymalny czas wykonania",
          executions: "Liczba wykonań kroku",
        },
        action: {
          error: "Błąd",
          ignore: "Zignoruj krok",
        },
        label: {
          type: "Rodzaj przerwania",
          value: "Wartość",
          action: "Akcja",
        },
        placeholder: {
          type: "Wybierz rodzaj",
          value: "Wpisz wartość",
          action: "Wybierz akcje",
        },
        version: "Wersja platformy",
      },
      label: {
        linkName: "Nazwa połączenia",
        disableLinkedStartStep: "Deaktywuj krok początkowy połączonego skryptu",
        skipLinkedScriptsInDocumentation:
          "Ignoruj połączone skrypty w trakcie generowania dokumentacji",
        timeout: "Niestandardowy limit czasu",
        isTimeoutEnabled: "Włącz niestandardowy limit czasu",
        urlRedirect: "Adres URL do przekierowania",
        variable: "Zmienne",
        label: "Etykieta",
        manualLabel: "Ręczna etykieta",
        delay: "Opóźnienie",
        isOptional: "Opcjonalny",
        menuIsNotSimulated: "Nie symuluj odtwarzania",
        scriptSettings: "Z ustawień skryptu",
        comment: "Komentarz",
        path: "Ścieżka",
        rule: "ID Reguły",
        ruleVersion: "Wersja reguły",
        rulePlatform: "Platforma reguły",
        pathType: "Typ ścieżki",
        eventType: "Typ zdarzenia",
        commandId: "Typ rejestracji",
        isDisabled: "Wyłączony",
        isOneTime: "Jednorazowy",
        version: "Wersja",
        originalValue: "Oryginalna wartość",
        disableStartStep: "Nie odtwarzaj kroku startowego",
        clickOnDisabledButtons: "Klikaj w zablokowane przyciski",
        autoRefreshDatasource: "Auto-odświeżanie źródła danych",
        customResolversMode: "Kontroluj metode wykrywania",
        generateTemplate: "Wygeneruj szablon źródła danych",
        platformVersion: "Wersja platformy",
      },
      placeholder: {
        selectKey: "Wybierz klucz",
      },
    },
    createStep: {
      conditional: {
        inspectCondition: "WARTOŚĆ INSPECTA",
        checkbox: "Stwórz wraz z warunkiem",
        defaultLabel: "Warunek dla kroku inspecta",
        title: "Krok warunkowy",
      },
      label: {
        delay: "Opóźnienie",
        lineNum: "Utwórz po kroku",
        label: "Etykieta",
      },
      placeholder: {
        selectStep: "Wybierz krok",
        enterLabel: "Wprowadź etykietę",
      },
      scriptStep: {
        header: "Stwórz Krok z Skryptem",
        select: "Wybierz Skrypt",
        stepDetails: "Wprowadź szczegóły skryptu",
        label: "Etykieta",
        linkName: "Nazwa linku",
      },
    },
    placeholder: {
      selectOperator: "Wybierz operator",
      selectType: "Wybierz typ",
      selectStep: "Wybierz krok",
      enterMessage: "Wpisz wiadomość",
      selectVariable: "Wybierz zmienną",
      pick: "Wybierz lub utwórz zmienną",
      selectSheet: "Wybierz kartę",
    },
    label: {
      selectAction: "Wybierz akcję",
      selectStep: "Wybierz krok",
      assertType: "Typ warunku",
      message: "Wiadomość",
      condition: "Warunek",
      conditional: "Warunkowy",
      conditionalReadOnly: "Warunek tylko do odczytu",
      noVariables: "Brak zmiennych",
    },
    assign: {
      header: {
        create: "Utwórz krok przypisania",
      },
    },
    login: {
      header: {
        create: "Utwórz krok logowania",
      },
    },
    assert: {
      resetOnLastRow: "Zresetuj po ostatnim wierszu",
      header: {
        if: "JEŻELI",
        then: "NASTĘPNIE",
        else: "W PRZECIWNYM RAZIE",
        createStep: "Utwórz krok warunkowy",
      },
      assertionType: {
        checkValue: "Sprawdź wartość",
        dataSource: "Źródło danych",
      },
      conditionType: {
        const: "Stała",
        stepValue: "Z wartości kroku",
        variable: "Ze zmiennej",
        globalConst: "Ze stałej globalnej",
        globalMutable: "Ze zmiennej globalnej",
        hasNext: "Źródło danych posiada następny wiersz",
      },
      option: {
        continue: "Dalej",
        warning: "Ostrzeżenie",
        log: "Zapisz w logach i kontynuuj",
        error: "Zatrzymaj i pokaż błędy",
        dataSourceAndJump: "Wybierz następny krok ze źródła danych i przejdź do kroku",
        jump: "Przejdź do kroku",
        repeat: "Powtórz",
        increaseKpi: "Zwiększ KPI",
      },
      comparisonOption: {
        equal: "Równe",
        notEqual: "Nie równe",
        greater: "Większe",
        greaterOrEqual: "Większe bądź równe",
        less: "Mniejsze",
        lessOrEqual: "Mniejsze bądź równe",
      },
    },
  },
  common: {
    min: "Min",
    max: "Max",
    selected: "Zaznaczone",
    total: "Wszystkie",
    loadingStatistics: "Ładowanie statystyk...",
    loadingLicense: "Ładowanie licencji...",
    running: "Wykonywanie",
    noData: "Brak danych",
    notEnoughData: "Niewystarczająca ilość danych. Wymagane są przynajmniej dwa wykonania",
    header: {
      compare: "Porównaj",
      dataSource: "Źródło danych",
      generalInfo: "Informacje ogólne",
      exportWithAdditional: "Eksportuj z dodatkowymi polami",
    },
    login: {
      microsoft: "Zaloguj się za pomocą konta Microsoft",
      pleaseLogIn: "Zaloguj się",
      loggingIn: "Logowanie...",
      loginButton: "Zaloguj",
      activeSession:
        "Ten użytkownik posiada aktywną sesje, czy chcesz zamknąć sesje i zalogować się na tym urządzeniu?",
    },
    label: {
      state: "Stan",
      sso: "SSO",
      mode: "Tryb",
      documentation: "Dokumentacja",
      specialMode: "Tryb",
      playerView: "Widok odtwarzacza",
      slowExecution: "Zwolnij wykonanie (s)",
      saveVideoCondition: "Warunek zapisu video",
      meta: "Meta",
      isManual: "Tryb manualny",
      runMode: "Tryb działania",
      defaultSystem: "Domyślne środowisko projektu",
      defaultVirtualUser: "Domyślny wirtualny użytkownik projektu",
      timeout: "Maksymalny czas wykonania",
      name: "Nazwa",
      script: "Skrypt",
      takeScreenshots: "Utwórz zrzut ekranu",
      screenshotsOnlyOnInterruption: "Zrzut ekranu tylko na zdarzeniu",
      showActionHint: "Pokaż podpowiedzi",
      hideComments: "Ukryj komentarze",
      url: "Adres URL",
      type: "Typ",
      expirationTime: "Data wygaśnięcia",
      showPasswordField: "Pokaż pole hasło",
      lineNumber: "Nr lini",
      description: "Opis",
      manualUrl: "Ręczny adres URL",
      general: "Ogólne",
      steps: "Kroki",
      history: "Historia",
      account: "Konto",
      variables: "Zmienne",
      environment: "Środowisko",
      published: "Opublikowane",
      diagram: "Diagram",
      guards: "Strażnicy",
      overrode: "Nadpisane zmienne",
      login: "Login",
      password: "Hasło",
      username: "Nazwa użytkownika",
      email: "Email",
      virtualUser: "Wirtualny użytkownik",
      label: "Etykieta",
      statistics: "Statystyki",
      flow: "Przepływ",
      data: "Dane",
      gantt: "Gantt",
      executionStatus: "Status wykonania",
      progression5: "Ostatnie 5 wykonań",
      progressionChart: "Wykres postępu",
      environmentAddress: "Adres środowiska",
      applicationName: "Nazwa aplikacji",
      environmentType: "Typ środowiska",
      language: "Język",
      settings: "Ustawienia",
      logout: "Wyloguj",
      project: "Projekt",
      roles: "Role",
      linked: "Powiązania",
      workItems: "Zgłoszenia",
      comment: "Komentarz",
      changelog: "Dziennik zmian",
      systemPlatform: "Platforma",
      enableMultiWindowsSupport: "Wsparcie dla wielu okien",
      disableResourceSharing: "Wyłącz współdzielenie zasobów",
      tags: "Tagi",
      projectsToExport: "Projekty to eksportu",
      before: "Przed",
      after: "Po",
      useVirtualUser: "Użyj wirtualnego użytkownika",
      isActive: "Aktywny",
      confluence: "Baza wiedzy",
      jira: "Zgłoś błąd przez Jire",
      contactUs: "Skontaktuj się przez email",
    },
    placeholder: {
      state: "Wybierz stan",
      sso: "SSO",
      language: "Wybierz język",
      username: "Nazwa użytkownika",
      systemPlatform: "Platforma",
      selectType: "Wybierz typ",
      selectView: "Wybierz widok",
      slowExecution: "Czas w milisekundach",
      account: "Wprowadź nazwę konta",
      applicationName: "Wprowadź nazwę aplikacji",
      name: "Wprowadź nazwę",
      login: "Wprowadź login",
      enterUrl: "Wprowadź adres URL",
      environment: "Wybierz środowisko",
      enterEnvironment: "Wprowadź środowisko",
      description: "Wprowadź opis",
      selectVariable: "Wybierz zmienną",
      virtualUser: "Wybierz wirtualnego użytkownika",
      enterPassword: "Wprowadź hasło",
      rememberPassword: "Zapamiętaj hasło",
      password: "Hasło",
      email: "Wprowadź adres email",
      environmentAddress: "Wprowadź adres środowiska",
      environmentType: "Wybierz typ środowiska",
      project: "Wybierz projekt",
      roles: "Przypisz role",
      comment: "Wpisz komentarz",
      tags: "Wybierz tagi",
    },
  },
  errorList: {
    title: "Błędy i ostrzeżenia dla wybranych wykonań",
    noErrors: "Brak błędów i ostrzeżeń dla wybranych wykonań",
    errorWhileLoading: "Błąd podczas ładowania danych wykonania",
  },
  screenshotGallery: {
    noScreenshots: "Brak dostępnych zrzutów ekranu",
    loading: "Ładuję...",
    errorLoadingScreenshots: "Błąd podczas ładowania zrzutów ekranu",
  },
  manyToMany: {
    source: {
      users: "Wszyscy użytkownicy",
      userGroups: "Wszystkie grupy użytkowników",
      virtualUsers: "Wszyscy wirtualni uzytkownicy",
      systemDictionary: "Wszystkie systemy",
      itsRules: "Wszystkie reguły obsługi zdarzeń",
    },
    destination: {
      users: "Wybrani uzytkownicy",
      userGroups: "Wybrane grupy użytkowników",
      virtualUsers: "Wybrane wirtualni uzytkownicy",
      systemDictionary: "Wybrane systemy",
      itsRules: "Wybrane reguły obsługi zdarzeń",
    },
  },
  projectCategory: {
    header: {
      create: "Utwórz kategorię projektu",
      edit: "Zmodyfikuj kategorię projektu",
    },
  },
  globalVariable: {
    type: {
      constant: "Stała",
      mutable: "Zmienna",
    },
    label: {
      isSensitive: "Wrażliwe dane",
      variableType: "Typ zmiennej",
    },
    header: {
      create: "Utwórz zmienną globalną",
      edit: "Edytuj zmienną globalną",
      assign: "Przypisz zmienną globalną do skryptu",
    },
    errors: {
      create: "Błąd podczas tworzenia zmiennej globalnej",
      edit: "Błąd podczas edycji zmiennej globalnej",
      assign: "Błąd podczas przypisywania zmiennej do skryptu",
      unassign: "Błąd podczas odpisywania zmiennej od skryptu",
    },
  },
  kpi: {
    header: {
      create: "Utwórz kpi",
      edit: "Edytuj kpi",
      performanceCountersStats: "Statystyki liczników wydajności",
    },
    errors: {
      create: "Błąd podczas tworzenia kpi",
      edit: "Błąd podczas edycji kpi",
      loadingStats: "Błąd podczas ładowania statystyk",
      noStatsAvailable: "Brak statystyk",
    },
    label: {
      selectKpi: "Wybierz KPI",
      kpiOccurrenceInTime: "Występowanie KPI w czasie",
    },
    table: {
      kpiName: "Nazwa KPI",
      totalOccurrences: "Wystąpienia łącznie",
      occurrencesPerMinute: "Wystąpień na minutę",
      occurrencesPerHour: "Wystąpień na godzinę",
    },
  },
  codeTemplateGroup: {
    header: {
      create: "Utwórz grupę szablonów kodu",
      edit: "Edytuj grupę szablonów kodu",
    },
    errors: {
      create: "Błąd podczas tworzenia grupy",
      edit: "Błąd podczas edycji grupy",
    },
  },
  its: {
    cannotFetchProjects: "Nie można pobrać projektów. Sprawdź parametry połączenia",
    cannotFetchIterations: "Nie można pobrać iteracji. Sprawdź parametry połączenia",
    cannotFetchWorkItemTypes: "Nie można pobrać typów zgłoszeń. Sprawdź parametry połączenia",
    cannotFetchAreas: "Nie można pobrać obszarów. Sprawdź parametry połączenia",
    fetchProjects: "Pobierz projekty",
    addCustomField: "Dodaj pole niestandardowe",
    removeCustomField: "Usuń pole niestandardowe",
    loadingCustomFields: "Ładowanie pól niestandardowych",
    cannotFetchWorkItemFields: "Nie można pobrać pól zgłoszeń. Sprawdź parametry połączenia",
    cannotFetchTestPlans: "Nie można pobrać test plan'ów. Sprawdź parametry połączenia",
    cannotFetchTestSuites: "Nie można pobrać test suite. Sprawdź parametry połączenia",
    cannotFetchConfigurations: "Nie można pobrać konfiguracji. Sprawdź parametry połączenia",
    cannotFetchTestCases: "Nie można pobrać przypadkó testowych. Sprawdź parametry połączenia",
    header: {
      create: "Utwórz definicję systemu obsługi zdarzeń",
      edit: "Zmodyfikuj definicję systemu obsługi zdarzeń",
      createRule: "Utwórz regułę obsługi zdarzeń",
      editRule: "Zmodyfikuj regułę obsługi zdarzeń",
    },
    label: {
      active: "Aktywny",
      inactive: "Niekatywny",
      removed: "Usunięty",
      its: "System obsługi zdarzeń",
      host: "Adres",
      token: "Token dostępowy",
      defaultPriority: "Domyślny priorytet",
      defaultSeverity: "Domyślna uporczywość",
      defaultIteration: "Domyślna iteracja",
      priority: "Priorytet",
      severity: "Uporczywość",
      iteration: "Iteracja",
      triggers: "Wyzwalacze",
      workItemType: "Typ zgłoszenia",
      defaultWorkItemType: "Domyślny typ zgłoszenia",
      issueTracking: "Obsługa zdarzeń",
      testPlans: "Test plan'y",
      area: "Obszar",
      defaultArea: "Domyślny obszar",
    },
    placeholder: {
      its: "Wybierz system obsługi zdarzeń",
      host: "Wpisz adres systemu",
      token: "Wpisz token dostępowy",
      defaultPriority: "Wybierz domyślny priorytet",
      defaultSeverity: "Wybierz domyślną uporczywość",
      defaultIteration: "Wpisz domyślną iterację",
      priority: "Wybierz priorytet",
      severity: "Wybierz uporczywość",
      iteration: "Wybierz iteracje",
      triggers: "Wybierz wyzwalacze",
      workItemType: "Wybierz typ zgłoszenia",
      defaultWorkItemType: "Wybierz domyślny typ zgłoszenia",
      area: "Wybierz obszar",
      defaultArea: "Wybierz domyślny obszar",
    },
  },
  conditionals: {
    createHeader: "Utwórz zaawansowany warunek",
    type: "Typ",
    SUCCESS: "Sukces",
    ERROR: "Błąd",
    WARNING: "Ostrzeżenie",
    or: "lub",
    overrideStatusTo: "Ustaw status na",
    INTERRUPTION: "Zdarzenie",
    reaction: "Reakcja",
    addReaction: "Dodaj reakcję",
    simple: "Prosty",
    advanced: "Zaawansowany",
    label: {
      reactOn: "Reaguj na",
      activityType: "Typ aktywności",
      activityValue: "Wartość aktywności",
    },
    placeholder: {
      reactOn: "Wybierz zdarzenie",
      activityType: "Wybierz typ aktywności",
      activityValue: "Wybierz wartość aktywności",
    },
    advancedConditionNo: "Zaawansowany warunek nr",
    reactionOptions: {
      SYSTEM_ERROR: "Błąd systemowy",
      SYSTEM_WARNING: "Ostrzeżenie systemowe",
      ELEMENT_NOT_FOUND: "Element nie został znaleziony",
      ERROR: "Błąd",
      WARNING: "Ostrzeżenie",
    },
    addCondition: "Dodaj warunek",
  },
  documentation: {
    type: {
      VIDEO: "FILM",
      DOCUMENT: "DOKUMENT",
    },
    header: {
      edit: "Edytuj dokumentacje",
    },
    noDocs: "Brak dokumentów",
  },
  reportTemplate: {
    header: {
      edit: "Edytuj wzór raportu",
      create: "Utwórz wzór raportu",
    },
  },
  tags: {
    header: {
      edit: "Zmodyfikuj tag",
      create: "Stwórz tag",
    },
    noTags: "Brak tagów",
  },
  tablesFriendlyNames: {
    project: "Projekty",
    projectitsrules: "Reguły projektów",
    projectowners: "Właściciele projektu",
    projectsystemdictionary: "Definicje systemów projektu",
    projectscheduler: "Harmonogram",
    projectusergroup: "Grupy użytkowników projektu",
    projectvirtualuser: "Wirtualni użytkownicy projektu",
    systemdictionary: "Definicje systemów",
    guardssteps: "Strażnicy kroku",
    guard: "Strażnicy",
    taskstep: "Kroki",
    taskscript: "Skrypty",
    variable: "Zmienne",
    taskscripttag: "Skrypt Tag",
    virtualuser: "Wirtualnu użytkownik",
    globalvariable: "Zmienne globalne",
  },
  charts: {
    jobId: "Id zadania",
    scriptCount: "Liczba skryptów",
  },
  runner: {
    inspect: {
      header: "Utwórz krok typu inspect",
      stepLabel: "Nazwa kroku",
      stepLabelPlaceholder: "Wpisz nazwę kroku",
      variableName: "Nazwa zmiennej",
    },
    sessionLoadError: "Id sesji jest nieprawidłowe lub sesja wygasła.",
    mode: {
      player: "Odtwarzacz",
      recorder: "Rekorder",
    },
    playMode: {
      normal: "Normalny",
      stepByStep: "Pojedynczy step",
      until: "Do pozycji nagrywania",
    },
    data: {
      datasource: "Źródło danych",
      sequences: "Sekwencje",
      localVariables: "Zmiennne lokalne",
      liveVariables: "Zmienne 'na żywo'",
      global: "Globalne",
    },
    syncError: "Błąd synchronizacji",
    liveVariablesSaveError: "Błąd zapisu zmiennych",
    stepDetails: "Detale kroku",
  },
  license: {
    license: "Licencja",
    licenseNumber: "Numer licencji",
    licenseType: "Typ licencji",
    licenseExpirationDate: "Data wygaśnięcia licencji",
    supportRenewalDate: "Data odnowy planu wsparcia",
    maximumConcurrentUsers: "Maksymalna liczba użytkowników",
    maximumParallelSessions: "Maksymalna liczba sesji równoległych",
    releaseNotes: "Informacje o wydaniu",
    errorReleaseNotes: "Niemożna załadować informacji o wydaniu.",
    payPerUse: "Statystyki wykonań skryptów",
    total: "Suma",
    thisMonth: "Ten miesiąc",
    lastMonth: "Poprzedni miesiąc",
    executedScriptCount: "Liczba wykonanych skryptów",
    scriptsCount: "Liczba skryptów",
    statistics: "Statystyki",
    licenseOverview: "Przegląd licencji",
    from: "od",
    to: "do",
    generationDate: "Data wygenerowania",
    installNewLicense: "Instaluj nową licencję",
    errorWhileUpdating: "Błąd podczas instalowania licencji",
    licenseUpdated: "Licencja została zaktualizowana. Nastąpi restart środowiska.",
    modal: {
      title: "Potwierdzenie",
      content:
        "Czy chcesz kontynuować? Środowisko zostanie zrestartowane i będzie niedostępne przez kilka minut.",
      okText: "Tak",
      cancelText: "Nie",
    },
  },
  globalActions: {
    screenshots: {
      info: {
        title: "Usuń zrzuty ekranów logów",
        older: "starsze niż",
        days: "dni",
        hour: "o",
        complete: "Procedura czyszczenia została zakończona",
      },
      mode: {
        once: "raz",
        every: "co 24 godziny",
      },
      action: {
        deleteOnce1: "Rozpoczęcie usuwania [",
        deleteOnce2: "] zrzutów ekranów",
      },
      progress1: "Usuwanie",
      progress2: "zrzutów ekranów z",
      progress3: "dni",
      button: {
        enable: "Aktywuj",
        disable: "Dezaktywuj",
        remove: "Usuń",
        loading: "Pobieranie danych",
      },
      modal: {
        title: "Potwierdzenie",
        content:
          "Ta akcja nie może zostać cofnięta ani przerwana. Czy jesteś pewny że chcesz kontynuować?",
        okText: "Tak",
        cancelText: "Nie",
      },
    },
  },
  codeTemplates: {
    header: {
      edit: "Edytuj szablon kodu",
      create: "Stwórz szablon kodu",
      import: "Importuj wzór kodu",
      setAsActive: "Ustaw nową wersję jako aktywną?",
      usedIn: "Szablon kodu użyty w",
    },
    label: {
      group: "Grupa",
      functionName: "Nazwa funkcji",
      version: "Wersja",
      activeVersion: "Aktywna wersja",
      currentlyModifedVersion: "Wersja obecnie edytowana",
      code: "Kod",
      addFunctionParam: "Dodaj parametry funkcji",
      currentModified: "Aktualnie modyfikowana",
      asNewVersion: "Jako nowa wersja",
      asNewActiveVersion: "Jako nowa aktywna wersja",
      saveMode: "Metoda zapisu",
    },
    placeholder: {
      group: "Wybierz grupę szablonu",
      functionName: "Wpisz nazwę funkcji",
    },
    codeSection: "Sekcja kodu",
    error: {
      failedToImport: `Błąd podczas importowania szablonów kodu: {codeTemplateNames}. Sprawdź konsole po więcej informacji.`,
    },
  },
  scriptChangelog: {
    signedBy: "Podpisane przez",
  },
};

export default translations;
