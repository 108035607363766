import { createModuleEpics, makeAsyncEpic } from "@app/modules/app.reducers";
import { moduleActions, projectsActionCreators } from "./projects.actions";
import { Project } from "@ea/shared_types/types";
import { projectSelectors } from "./projects.selectors";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { getProjectsParents, getProjectsChildren } from "./projects.query";
import { Query } from "@ea/shared_components/services/api.common.models";

const includeFields = ["groups", "systems", "owners", "defaultSystem", "itsRules"];

const includeFilter = [
  ...includeFields,
  {
    relation: "virtualUsers",
  },
  {
    relation: "defaultVirtualUser",
  },
];

const projectModuleEpics = {
  ...createModuleEpics<Project>(moduleActions, {
    getRequestParams: (state, tableId) => {
      const tableParams = projectSelectors.getParamsSelector(state, tableId!);
      const params = createRequestParams(tableParams, {}, { defaultOrder: ["name ASC"] });

      params.filter = {
        ...params.filter,
        include: "defaultSystem",
      };

      return params;
    },
    normalizeItem: (item, state, mode) => {
      if (mode === "SINGLE") {
        return item;
      }

      const currentProject = state.projects.items[item.id];
      const { virtualUsers, groups, systems, owners, itsRules } = currentProject || {};
      return {
        virtualUsers: virtualUsers || [],
        groups: groups || [],
        systems: systems || [],
        owners: owners || [],
        itsRules: itsRules || [],
        ...item,
      };
    },
    getSingleRequestParams: (query, state) => {
      query.filter = {
        ...query.filter,
        include: includeFilter,
      };

      return query;
    },
    api: {
      createItem: API.createProject,
      deleteItem: API.deleteProject,
      editItem: API.editProject,
      getItems: API.getProjects,
    },
  }),
  refreshChildBranch: makeAsyncEpic(
    projectsActionCreators.refreshChildBranch,
    async (payload, state) => {
      const projectWithChildren = getProjectsChildren(payload, state);

      const params: Query<Project> = {
        filter: {
          where: {
            id: { inq: projectWithChildren },
          },
          include: includeFilter,
        },
      };

      const result = await API.getProjects(params);

      return result;
    },
  ),
  refreshParentBranch: makeAsyncEpic(
    projectsActionCreators.refreshParentBranch,
    async (payload, state) => {
      const projectWithParents = getProjectsParents(payload, state);
      if (projectWithParents.filter((p) => p).length === 0) {
        return [];
      }

      const params: Query<Project> = {
        filter: {
          where: {
            id: { inq: projectWithParents },
          },
          include: includeFilter,
        },
      };

      const result = await API.getProjects(params);

      return result;
    },
  ),
};

export default projectModuleEpics;
