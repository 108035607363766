import { Button, Dropdown, Menu } from "antd";
import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";
import { ApplicationState } from "@app/modules/app.reducers";
import { authActions } from "../auth/auth.actions";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { PanelType } from "@ea/shared_components/Panel";
import UserBasicSettingsForm from "./components/UserBasicSettingsForm";
import { API } from "@app/services/api/api";
import UserPasswordForm from "./components/UserPasswordForm";
import { bindActionCreators, Dispatch } from "redux";
import { getIstTableActions } from "../issueTrackingTool/its.actions";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { toast } from "react-toastify";
import { UserOutlined } from "@ant-design/icons";
import { isFeatureDisabledSelector } from "../globalSettings/globalSettings.selectors";
import { FEATURES } from "@ea/shared_types/types";

interface IUserOptionsContainerProps {}
interface IUserOptionsContainerState {
  dropdownVisibility: boolean;
  changePasswordVisibility: boolean;
  settingsVisibility: boolean;
}

const Container = styled.div(
  {
    display: "flex",
    height: "100%",
    minWidth: "140px",
    alignItems: "center",
    marginRight: "15px",
    cursor: "pointer",
    justifyContent: "center",
    ":hover": {
      background: "rgba(0, 0, 0, .2)",
    },
  },
  ({ visibility }: { visibility?: boolean | 1 | 0 | "true" | "false" }) => ({
    background: visibility ? "rgba(0, 0, 0, .2)" : "transparent",
  }),
);

class UserOptionsContainer extends React.Component<
  IUserOptionsContainerProps & IConnectProps & InjectedIntlProps,
  IUserOptionsContainerState
> {
  state = {
    dropdownVisibility: false,
    changePasswordVisibility: false,
    settingsVisibility: false,
  };

  componentDidMount() {
    this.props.actions.its.load({});
  }

  onMenuClick = ({ key }: { key: string | number }) => {
    switch (key) {
      case "logout":
        this.logout();
        break;
      case "password":
        this.onChangePasswordVisibilityChange(true);
        break;
      case "settings":
        this.onChangeSettingsVisibilityChange(true);
        break;
      default:
        return;
    }
  };

  onDropdownVisibilityChange = (visible) => this.setState({ dropdownVisibility: visible });

  onChangePasswordVisibilityChange = (visibility) =>
    this.setState({ changePasswordVisibility: visibility, dropdownVisibility: false });

  onChangeSettingsVisibilityChange = (visibility) =>
    this.setState({ settingsVisibility: visibility, dropdownVisibility: false });

  logout = () => {
    this.props.actions.auth.logout();
  };

  editSettings = async (values) => {
    const { projects, settings, ...rest } = values;
    const newSettings = { ...this.props.user!.settings, ...settings };
    try {
      const editedUser = await API.editUser({
        ...rest,
        settings: newSettings,
      });

      this.props.actions.auth.initDone({
        params: {
          ...this.props.user,
          ...rest,
          settings: newSettings,
        },
        result: { roles: this.props.user!.roles, ...editedUser },
      });
      toast.success(
        <FormattedMessage id={getTranslationKey("messages", "success", "userSettingsEdited")} />,
      );
    } catch (error) {
      toast.error(
        <FormattedMessage id={getTranslationKey("messages", "error", "userSettingsEdited")} />,
      );
    }

    this.onChangeSettingsVisibilityChange(false);
  };

  changePassword = async (values) => {
    const { newPassword, currentPassword, repeatedNewPassword } = values;

    if (newPassword !== repeatedNewPassword) {
      throw new Error(this.props.intl.formatMessage({ id: "messages.error.passwordNotSame" }));
    }

    await API.changePassword({
      oldPassword: currentPassword,
      newPassword,
    });

    this.onChangePasswordVisibilityChange(false);
  };

  render() {
    if (!this.props.user) {
      return null;
    }

    const { dropdownVisibility, changePasswordVisibility, settingsVisibility } = this.state;

    const menu = (
      <Menu onClick={this.onMenuClick}>
        <Menu.Item key="settings">
          <FormattedMessage id={"common.label.settings"} />
        </Menu.Item>
        <Menu.Item key="password">
          <FormattedMessage id={"password.changePassword"} />
        </Menu.Item>
        <Menu.Item key="logout">
          <FormattedMessage id={"common.label.logout"} />
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <Dropdown overlay={menu} onVisibleChange={this.onDropdownVisibilityChange}>
          <Container visibility={dropdownVisibility ? 1 : 0}>
            <Button style={{ backgroundColor: "white" }} shape="circle" icon={<UserOutlined />} />
            <span style={{ marginLeft: "5px" }}> {this.props.user.username} </span>
          </Container>
        </Dropdown>
        <PanelFormFinal
          visibility={changePasswordVisibility}
          panelType={PanelType.MEDIUM}
          headerText={getTranslationKey("password", "changePassword")}
          onCancelClick={() => this.onChangePasswordVisibilityChange(false)}
          onOkClick={this.changePassword}
          cancelButtonText={getTranslationKey("button", "close")}
          key="EditPassword"
          okButtonText={getTranslationKey("button", "save")}
          initialValues={this.props.user}
          render={({ change, values }) => <UserPasswordForm values={values} change={change} />}
        />
        <PanelFormFinal
          visibility={settingsVisibility}
          panelType={PanelType.MEDIUM}
          headerText={getTranslationKey("user", "header", "editUser")}
          onCancelClick={() => this.onChangeSettingsVisibilityChange(false)}
          onOkClick={this.editSettings}
          cancelButtonText={getTranslationKey("button", "close")}
          key="Edit"
          okButtonText={getTranslationKey("button", "save")}
          initialValues={this.props.user}
          render={({ change, values }) => (
            <UserBasicSettingsForm
              values={values}
              change={change}
              isEdit
              its={this.props.its}
              mySettings
            />
          )}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IUserOptionsContainerProps) => {
  return {
    actions: {
      auth: {
        ...bindActionCreators(authActions, dispatch),
      },
      its: {
        ...bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch),
      },
    },
  };
};

const connectCreator = connect(
  (state: ApplicationState) => ({
    user: state.auth.user,
    its: itsDataSelectors.getOrderedDataSelector(state, ITS_TABLES_CONFIG.MAIN.id()),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default injectIntl(connectCreator(UserOptionsContainer)) as any;
