import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";

import {
  Log,
  ReportExtension,
  ExportToCsvModels,
  EXECUTION_STATE,
  CsvExportAdditionalFields,
} from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { LogsState } from "./";
import CommandBar from "@app/modules/logs/components/LogsCommandBar";
import StatisticsModal from "./components/StatisticsModal";
import { logsDataSelectors } from "./logs.selectors";
import { LOGS_COLUMNS, LOGS_COLUMNS_CONFIG, LOGS_TABLES_CONFIG } from "./logs.table";
import ExecutionStatusChartContainer from "./statistics/ExecutionStatusChart.container";
import { getLogsTableActions } from "./logs.actions";
import { toast } from "react-toastify";
import ShowGalleryContainer from "./components/ShowGalleryContainer";
import ErrorWarningList from "./components/ErrorWarningList";
import { ROUTES } from "@app/routes";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import ExportingModal from "../common/components/ExportingModal";
import { ColumnConfig } from "@ea/shared_components/Table/common.tables";
import { getProjectsTreeSelector } from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import { itsDataSelectors } from "../issueTrackingTool/its.selectors";
import { ITS_TABLES_CONFIG } from "../issueTrackingTool/its.table";
import { getIstTableActions } from "../issueTrackingTool/its.actions";
import {
  currentUserSettingsSelector,
  currentUserIdSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { generateReportName } from "@app/utils/reports";
import CapturedFilesModal from "./components/CapturedFilesModal";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { disabledFeaturesSelector } from "../globalSettings/globalSettings.selectors";
import { exportToCsv } from "../../utils/exportCsv";
import KpiStatisticsModal from "../kpis/components/KpiStatisticsModal";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { PanelType } from "@ea/shared_components/Panel";
import { LogsCsvAdditionalFieldForm } from "./components/LogsCsvAdditionalFieldForm";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  height: "100%",
});

interface ILogsProps {
  tableId?: string;
  preferencesId?: string;
  columns?: ColumnConfig<any>[];
  persistentQuery?: any;
  className?: string;
  renderStatistics?: any;
  autoReload?: boolean | number;
  changeHideAggregatedLogsView?: () => void;
  isLogsViewChanging?: boolean;
  disableViewChangeButton?: boolean;
}

interface ILogsState extends LogsState {
  screenshotGalleryVisible: boolean;
  errorWarningListVisible: boolean;
  capturedFilesVisible: boolean;
  kpiVisible: boolean;
  clickedStepLogId?: number;
  isExporting?: boolean;
  showActiveSessions: boolean;
  exportWithAdditionalFieldsVisibile: boolean;
}

class Logs extends React.Component<IConnectProps & RouteComponentProps<any>, ILogsState> {
  connectedTable: any;

  constructor(props: IConnectProps & RouteComponentProps<any>) {
    super(props);

    this.state = {
      persistentQuery: props.persistentQuery || {
        and: [{ scriptId: props.match.params.id }, { sessionId: props.match.params.sessionId }],
      },
      statisticsVisible: false,
      screenshotGalleryVisible: false,
      errorWarningListVisible: false,
      isExporting: false,
      capturedFilesVisible: false,
      showActiveSessions: false,
      kpiVisible: false,
      exportWithAdditionalFieldsVisibile: false,
    };
  }

  componentDidMount() {
    this.props.actions.its.load({});
  }

  componentDidUpdate(prevProps: IConnectProps & RouteComponentProps<any>) {
    const { match, persistentQuery } = this.props;

    if (
      match.params.id !== prevProps.match.params.id ||
      match.params.sessionId !== prevProps.match.params.sessionId ||
      persistentQuery !== prevProps.persistentQuery
    ) {
      this.setState({
        persistentQuery: this.props.persistentQuery || {
          and: [
            {
              scriptId: match.params.id,
            },
            {
              sessionId: match.params.sessionId,
            },
          ],
        },
      });
    }
  }

  compareQuery = (currentQuery, prevQuery) =>
    currentQuery.and[0].scriptId !== prevQuery.and[0].scriptId ||
    currentQuery.and[1].sessionId !== prevQuery.and[1].sessionId ||
    currentQuery?.mappedWithDevops !== prevQuery?.mappedWithDevops ||
    currentQuery?.isSessionActive !== prevQuery?.isSessionActive;

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  openDetails = (item?: Log) => {
    const { selectedLog, match } = this.props;
    const selectedItem = (item && item.sessionId ? item : null) || selectedLog;
    if (selectedItem) {
      const id = match.params.id || selectedItem.scriptId;
      this.props.history.push({
        pathname: `${ROUTES.history}/${id}/${selectedItem.sessionId}`,
        search: `?scriptLogId=${selectedItem.id}`,
      });
    }
  };

  goToSelected = (item?: Log) => {
    const { selectedLog, match } = this.props;
    const selectedItem = (item && item.sessionId ? item : null) || selectedLog;
    if (selectedItem) {
      const id = match.params.id || selectedItem.scriptId;
      this.props.history.push({
        pathname: `${ROUTES.scripts}/${id}`,
      });
    }
  };

  openStatistics = () => {
    this.setState({
      statisticsVisible: true,
    });
  };

  closeStatistics = () => {
    this.setState({
      statisticsVisible: false,
    });
  };

  onTerminate = async () => {
    const { selectedLogs, selectedItems } = this.props;

    const toCloseStatuses = [
      EXECUTION_STATE.NONE,
      EXECUTION_STATE.PAUSED,
      EXECUTION_STATE.FINISHED,
      EXECUTION_STATE.TERMINATED,
    ];

    const toClose: string[] = selectedItems
      .filter((i) => toCloseStatuses.includes(i.state))
      .map((i) => i.sessionId);

    const toTerminate: number[] = selectedItems
      .filter((i) => i.state === EXECUTION_STATE.RUNNING)
      .map((i) => i.id);

    if (selectedLogs) {
      try {
        if (toTerminate.length > 0) {
          await API.killInBackgroundExecution({
            selectedLogs: toTerminate,
          });
        }
        if (toClose.length > 0) {
          await API.closeSessions({ sessionIds: toClose });
        }
      } catch (error) {
        toast.error(
          <FormattedMessage id={getTranslationKey("messages", "error", "termination")} />,
        );
        console.error(error);
        return;
      }
      toast.success(
        <FormattedMessage id={getTranslationKey("messages", "success", "termination")} />,
      );
      this.reload();
    }
  };

  openGallery = () => {
    this.setState({
      screenshotGalleryVisible: true,
    });
  };

  closeScreenshotGallery = () => {
    this.setState({
      screenshotGalleryVisible: false,
    });
  };

  openErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: true,
    });
  };

  closeErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: false,
    });
  };

  toggleFilterDevopsExecutions = () => {
    const { persistentQuery } = this.state;
    const { mappedWithDevops, ...rest } = persistentQuery;
    if (!mappedWithDevops) {
      this.setState({
        persistentQuery: {
          ...persistentQuery,
          mappedWithDevops: true,
        },
      });
    } else {
      this.setState({
        persistentQuery: rest,
      });
    }
  };

  generateReport = async () => {
    const { selectedLog, userSettings, tableId, actions } = this.props;
    if (selectedLog) {
      actions.setIsGeneratingReport({
        isGeneratingReport: true,
      });

      try {
        const extension = userSettings.reportExtension || ReportExtension.docx;
        await API.generateReport({
          logId: selectedLog.id,
          extension,
          tableId,
          reportName: generateReportName(selectedLog!, extension),
        });
      } catch (error) {
        toast.error(error.message);
        actions.setIsGeneratingReport({
          isGeneratingReport: false,
        });
      }
    }
  };

  exportToExcel = async (additionalFields?: CsvExportAdditionalFields) => {
    const { tableParams, selectedLogs, preferencesId, userId } = this.props;
    const params = createRequestParams(tableParams, LOGS_COLUMNS_CONFIG, {
      defaultOrder: ["startTime DESC"],
    });

    const fields = LOGS_COLUMNS.map((log) => log.props.dataIndex).filter(
      (field) => field !== LOGS_COLUMNS_CONFIG.datasource.dataIndex,
    );

    params.filter = {
      ...params.filter,
    };

    await exportToCsv({
      params,
      fields,
      modelName: ExportToCsvModels.LOGS,
      selected: selectedLogs,
      onExportStart: () => this.setState({ isExporting: true }),
      onExportFinish: () => this.setState({ isExporting: false }),
      userId: userId!,
      preferencesId,
      additionalFields,
    });
  };

  toggleShowActiveSessions = () => {
    this.setState(({ showActiveSessions, persistentQuery }) => ({
      persistentQuery: { ...persistentQuery, isSessionActive: !showActiveSessions || undefined },
      showActiveSessions: !showActiveSessions,
    }));
  };

  onPerformanceCountersClick = () => {
    this.setState(({ kpiVisible }) => ({
      kpiVisible: !kpiVisible,
    }));
  };

  onExportWithAdditionalFieldsClick = async () => {
    this.setState({ exportWithAdditionalFieldsVisibile: true });
  };
  closeExportWithAdditionalFields = () => {
    this.setState({ exportWithAdditionalFieldsVisibile: false });
  };

  exportWithAdditionalFields = async (additionalFields: CsvExportAdditionalFields) => {
    this.closeExportWithAdditionalFields();
    this.exportToExcel(additionalFields);
  };

  render() {
    const {
      selectedLog,
      selectedItems,
      tableId,
      preferencesId,
      columns,
      projects,
      className,
      renderStatistics,
      autoReload,
      its,
      isLogsViewChanging,
      disableViewChangeButton,
      disabledFeatures,
      tableParams,
    } = this.props;

    const {
      persistentQuery,
      statisticsVisible,
      isExporting,
      showActiveSessions,
      kpiVisible,
      exportWithAdditionalFieldsVisibile,
    } = this.state;
    return (
      <Container>
        <ExportingModal visible={isExporting} />
        <StatisticsModal
          visible={!!statisticsVisible}
          title={<FormattedMessage id={getTranslationKey("common", "label", "statistics")} />}
          onClose={this.closeStatistics}
        >
          {statisticsVisible && renderStatistics ? (
            renderStatistics()
          ) : (
            <ExecutionStatusChartContainer
              columnsConfig={LOGS_COLUMNS_CONFIG}
              tableId={tableId}
              title={
                <FormattedMessage id={getTranslationKey("common", "label", "executionStatus")} />
              }
            />
          )}
        </StatisticsModal>
        <CapturedFilesModal
          visible={!!this.state.capturedFilesVisible}
          title={<FormattedMessage id={getTranslationKey("commandBar", "capturedFiles")} />}
          onClose={() => {
            this.setState({ capturedFilesVisible: false });
          }}
          selectedLog={selectedLog}
        />
        {kpiVisible && (
          <KpiStatisticsModal
            visible={kpiVisible}
            onClose={this.onPerformanceCountersClick}
            executionLogId={this.props.selectedLog?.id!}
          />
        )}
        <CommandBar
          selectedLogs={this.props.selectedLogs}
          selectedLog={this.props.selectedLog}
          onTerminate={this.onTerminate}
          onOpen={this.openDetails}
          onReload={this.reload}
          onShowStatistics={this.openStatistics}
          onReportGenerate={this.props.selectedLog ? this.generateReport : undefined}
          selectedItems={this.props.selectedItems}
          onGoTo={this.goToSelected}
          onOpenScreenshotGallery={this.openGallery}
          screenshotSelectedMode
          onOpenErrorWarningList={this.openErrorWarningList}
          onExport={this.exportToExcel}
          onExportWithAdditionalFieldsClick={this.onExportWithAdditionalFieldsClick}
          isExporting={this.state.isExporting}
          isGeneratingReport={tableParams.isGeneratingReport}
          onOpenCapturedFilesModal={() => {
            this.setState({ capturedFilesVisible: true });
          }}
          changeHideAggregatedLogsView={this.props.changeHideAggregatedLogsView}
          isLogsViewChanging={isLogsViewChanging}
          disableViewChangeButton={disableViewChangeButton}
          onFilterDevopsExecutions={this.toggleFilterDevopsExecutions}
          showOnlyDevopsExecutions={persistentQuery.mappedWithDevops}
          showActiveSessions={showActiveSessions}
          toggleShowActiveSessions={this.toggleShowActiveSessions}
          onPerformanceCountersClick={() => this.onPerformanceCountersClick()}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          comparePersistentQuery={this.props.persistentQuery ? undefined : this.compareQuery}
          dynamicFilterValues={{
            projectId: projects,
          }}
          className={className}
          columnsConfig={columns}
          tableId={tableId}
          preferencesId={preferencesId}
          stateKey={"logs"}
          tableActions={getLogsTableActions}
          onRowDoubleClick={this.openDetails}
          persistentQuery={this.state.persistentQuery}
          autoReload={autoReload ? autoReload : true}
          disabledFeatures={disabledFeatures}
        />
        <ShowGalleryContainer
          onClose={this.closeScreenshotGallery}
          selectedLog={selectedLog}
          visible={this.state.screenshotGalleryVisible}
        />
        <ErrorWarningList
          onClose={this.closeErrorWarningList}
          visible={this.state.errorWarningListVisible}
          selectedLogs={selectedItems}
          its={its}
        />
        <PanelFormFinal
          visibility={exportWithAdditionalFieldsVisibile}
          panelType={PanelType.MODAL}
          customWidth={500}
          headerText={getTranslationKey("common", "header", "exportWithAdditional")}
          onCancelClick={this.closeExportWithAdditionalFields}
          onOkClick={this.exportWithAdditionalFields}
          cancelButtonText={getTranslationKey("button", "cancel")}
          okButtonText={getTranslationKey("button", "export")}
          initialValues={{}}
          allowPrisitineSubmit
          render={({ change, values }) => (
            <LogsCsvAdditionalFieldForm values={values} change={change} />
          )}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: ILogsProps) => {
  const tableId = props.tableId || LOGS_TABLES_CONFIG.MAIN.id();
  return {
    actions: {
      ...bindActionCreators(getLogsTableActions(tableId), dispatch),
      its: {
        ...bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch),
      },
    },
  };
};

const connectCreator = connect((state: ApplicationState, props: ILogsProps) => {
  const tableId = props.tableId || LOGS_TABLES_CONFIG.MAIN.id();
  const preferencesId = props.preferencesId || LOGS_TABLES_CONFIG.MAIN.preferencesId;
  const columns = props.columns || LOGS_COLUMNS;
  const userSettings = currentUserSettingsSelector(state);

  return {
    ...props,
    tableId,
    preferencesId,
    columns,
    selectedLogs: logsDataSelectors.getSelectedSelector(state, tableId),
    selectedItems: logsDataSelectors.getSelectedItemsSelector(state, tableId),
    selectedLog: logsDataSelectors.getSelectedItemSelector(state, tableId),
    tableParams: logsDataSelectors.getParamsSelector(state, tableId),
    projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    its: userSettings.its ? itsDataSelectors.getItemSelector(state, userSettings.its) : undefined,
    userSettings,
    userId: currentUserIdSelector(state),
    disabledFeatures: disabledFeaturesSelector(state),
  };
}, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(Logs));
