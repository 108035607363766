import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { bindActionCreators, Dispatch } from "redux";
import { FormattedMessage } from "react-intl";
import { ApplicationState } from "@app/modules/app.reducers";
import LogsCommandBar from "@app/modules/logs/components/LogsCommandBar";
import StatisticsModal from "@app/modules/logs/components/StatisticsModal";
import { getLogsTableActions } from "@app/modules/logs/logs.actions";
import { logsDataSelectors } from "@app/modules/logs/logs.selectors";
import {
  LOGS_COLUMNS_CONFIG,
  LOGS_TABLES_CONFIG,
  SCHEDULER_JOB_LOGS_COLUMNS,
} from "@app/modules/logs/logs.table";
import ExecutionStatusChartContainer from "@app/modules/logs/statistics/ExecutionStatusChart.container";
import { API } from "@app/services/api/api";
import {
  Log,
  ExportToCsvModels,
  ReportExtension,
  CsvExportAdditionalFields,
} from "@ea/shared_types/types";

import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { ROUTES } from "@app/routes";
import { getProjectsTreeSelector } from "../projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "../projects/projects.tables";
import CapturedFilesModal from "../logs/components/CapturedFilesModal";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { disabledFeaturesSelector } from "../globalSettings/globalSettings.selectors";
import ExportingModal from "../common/components/ExportingModal";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { exportToCsv } from "../../utils/exportCsv";
import {
  currentUserIdSelector,
  currentUserSettingsSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { generateReportName } from "@app/utils/reports";
import KpiStatisticsModal from "../kpis/components/KpiStatisticsModal";
import ErrorWarningList from "../logs/components/ErrorWarningList";
import { PanelType } from "@ea/shared_components/Panel";
import { LogsCsvAdditionalFieldForm } from "../logs/components/LogsCsvAdditionalFieldForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  height: "100%",
});

interface SchedulerLogsContainerState {
  persistentQuery: any;
  statisticsVisible: boolean;
  capturedFilesVisible: boolean;
  errorWarningListVisible: boolean;
  isExporting: boolean;
  kpiVisible: boolean;
  exportWithAdditionalFieldsVisibile: boolean;
}
interface ISchedulerLogsProps {
  changeHideAggregatedLogsView?: () => void;
  isLogsViewChanging?: boolean;
  disableViewChangeButton?: boolean;
  persistentQuery?: any;
}
class SchedulerLogsContainer extends React.Component<
  IConnectProps & ISchedulerLogsProps & RouteComponentProps<any>,
  any
> {
  connectedTable: any;

  state: SchedulerLogsContainerState = {
    statisticsVisible: false,
    persistentQuery: undefined,
    capturedFilesVisible: false,
    errorWarningListVisible: false,
    isExporting: false,
    kpiVisible: false,
    exportWithAdditionalFieldsVisibile: false,
  };

  componentWillMount() {
    this.setState({
      persistentQuery: this.props.persistentQuery || {
        schedulerJobId: this.props.schedulerJobId,
      },
    });
  }

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  openSelectedItemDetails = () => {
    this.openDetails();
  };

  openDetails = (item?: Log) => {
    const { selectedLog } = this.props;
    const selectedItem = item || selectedLog;
    if (selectedItem) {
      const id = selectedItem.scriptId;
      this.props.history.push(
        `${ROUTES.history}/${id}/${selectedItem.sessionId}?scriptLogId=${selectedItem.id}`,
      );
    }
  };

  openStatistics = () => {
    this.setState({
      statisticsVisible: true,
    });
  };

  closeStatistics = () => {
    this.setState({
      statisticsVisible: false,
    });
  };

  onTerminate = async () => {
    const { selectedLogs } = this.props;
    if (selectedLogs) {
      try {
        await API.killInBackgroundExecution({
          selectedLogs,
        });
      } catch (error) {
        toast.error("Termination failed");
        console.error(error);
        return;
      }
      toast.success("Terminated succesfully");
    }
  };

  toggleFilterDevopsExecutions = () => {
    const { persistentQuery } = this.state;
    const { mappedWithDevops, ...rest } = persistentQuery;
    if (!mappedWithDevops) {
      this.setState({
        persistentQuery: {
          ...persistentQuery,
          mappedWithDevops: true,
        },
      });
    } else {
      this.setState({
        persistentQuery: rest,
      });
    }
  };
  exportToExcel = async (additionalFields?: CsvExportAdditionalFields) => {
    const { tableParams, selectedLogs, userId, preferencesId } = this.props;
    const params = createRequestParams(tableParams, LOGS_COLUMNS_CONFIG, {
      defaultOrder: ["startTime DESC"],
    });
    if (!params.filter) {
      params.filter = {};
    }
    if (!params.filter.where) {
      params.filter.where = {};
    }
    params.filter.where.internal = false;

    const fields = SCHEDULER_JOB_LOGS_COLUMNS.map((log) => log.props.dataIndex);

    params.filter = {
      ...params.filter,
      include: {
        relation: "executionStepLogs",
        scope: {
          fields: [
            "messages",
            "parentStepLogId",
            "lineNum",
            "label",
            "labelKey",
            "labelParams",
            "id",
          ],
          order: "id desc",
        },
      },
    };

    await exportToCsv({
      fields,
      params,
      modelName: ExportToCsvModels.LOGS,
      selected: selectedLogs,
      onExportStart: () => this.setState({ isExporting: true }),
      onExportFinish: () => this.setState({ isExporting: false }),
      userId,
      preferencesId,
      overrideFileName: `NonAggregatedSchedulerLogs.csv`,
      additionalFields,
    });
  };

  goToSelected = () => {
    const { selectedLog, history } = this.props;
    if (selectedLog) {
      history.push({
        pathname: `${ROUTES.scripts}/${selectedLog.scriptId}`,
      });
    }
  };

  generateReport = async () => {
    const { selectedLog, userSettings, tableId, actions } = this.props;
    if (selectedLog) {
      actions.setIsGeneratingReport({
        isGeneratingReport: true,
      });

      try {
        const extension = userSettings.reportExtension || ReportExtension.docx;
        await API.generateReport({
          logId: selectedLog.id,
          extension,
          tableId,
          reportName: generateReportName(selectedLog!, extension),
        });
      } catch (error) {
        toast.error(error.message);
        actions.setIsGeneratingReport({
          isGeneratingReport: false,
        });
      }
    }
  };

  onPerformanceCountersClick = () => {
    this.setState(({ kpiVisible }) => ({
      kpiVisible: !kpiVisible,
    }));
  };

  openErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: true,
    });
  };

  closeErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: false,
    });
  };
  onExportWithAdditionalFieldsClick = async () => {
    this.setState({ exportWithAdditionalFieldsVisibile: true });
  };
  closeExportWithAdditionalFields = () => {
    this.setState({ exportWithAdditionalFieldsVisibile: false });
  };

  exportWithAdditionalFields = async (additionalFields: CsvExportAdditionalFields) => {
    this.closeExportWithAdditionalFields();
    this.exportToExcel(additionalFields);
  };

  render() {
    const {
      schedulerJobId,
      projects,
      selectedLog,
      changeHideAggregatedLogsView,
      isLogsViewChanging,
      disableViewChangeButton,
      disabledFeatures,
      preferencesId,
      selectedItems,
      selectedLogs,
    } = this.props;

    const { persistentQuery, isExporting, statisticsVisible, kpiVisible, errorWarningListVisible } =
      this.state;
    return (
      <Container>
        <StatisticsModal
          visible={statisticsVisible}
          title={<FormattedMessage id={getTranslationKey("common", "label", "statistics")} />}
          onClose={this.closeStatistics}
        >
          {statisticsVisible && (
            <ExecutionStatusChartContainer
              columnsConfig={LOGS_COLUMNS_CONFIG}
              tableId={LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId)}
              title={
                <FormattedMessage id={getTranslationKey("common", "label", "executionStatus")} />
              }
            />
          )}
        </StatisticsModal>
        <CapturedFilesModal
          visible={!!this.state.capturedFilesVisible}
          title={<FormattedMessage id={getTranslationKey("commandBar", "capturedFiles")} />}
          onClose={() => {
            this.setState({ capturedFilesVisible: false });
          }}
          selectedLog={selectedLog}
        />
        <LogsCommandBar
          selectedLogs={selectedLogs}
          selectedLog={selectedLog}
          onTerminate={this.onTerminate}
          onOpen={this.openSelectedItemDetails}
          onReload={this.reload}
          onShowStatistics={this.openStatistics}
          selectedItems={selectedItems}
          onReportGenerate={selectedLog ? this.generateReport : undefined}
          onOpenCapturedFilesModal={() => {
            this.setState({ capturedFilesVisible: true });
          }}
          onOpenErrorWarningList={this.openErrorWarningList}
          changeHideAggregatedLogsView={changeHideAggregatedLogsView}
          isLogsViewChanging={isLogsViewChanging}
          disableViewChangeButton={disableViewChangeButton}
          onFilterDevopsExecutions={this.toggleFilterDevopsExecutions}
          showOnlyDevopsExecutions={persistentQuery.mappedWithDevops}
          onExport={this.exportToExcel}
          onExportWithAdditionalFieldsClick={this.onExportWithAdditionalFieldsClick}
          isExporting={isExporting}
          onGoTo={this.goToSelected}
          onPerformanceCountersClick={() => this.onPerformanceCountersClick()}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={SCHEDULER_JOB_LOGS_COLUMNS}
          disabledFeatures={disabledFeatures}
          tableId={LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId)}
          preferencesId={preferencesId}
          stateKey={"logs"}
          tableActions={getLogsTableActions}
          onRowDoubleClick={this.openDetails}
          autoReload
          persistentQuery={persistentQuery}
          dynamicFilterValues={{
            projectId: projects,
          }}
        />
        {kpiVisible && (
          <KpiStatisticsModal
            visible={kpiVisible}
            onClose={this.onPerformanceCountersClick}
            executionLogId={selectedItems[0].id}
          />
        )}
        <ErrorWarningList
          onClose={this.closeErrorWarningList}
          visible={errorWarningListVisible}
          selectedLogs={selectedItems}
        />
        <ExportingModal visible={this.state.isExporting} />
        <PanelFormFinal
          visibility={this.state.exportWithAdditionalFieldsVisibile}
          panelType={PanelType.MODAL}
          customWidth={500}
          headerText={getTranslationKey("common", "header", "exportWithAdditional")}
          onCancelClick={this.closeExportWithAdditionalFields}
          onOkClick={this.exportWithAdditionalFields}
          cancelButtonText={getTranslationKey("button", "cancel")}
          okButtonText={getTranslationKey("button", "export")}
          initialValues={{}}
          allowPrisitineSubmit
          render={({ change, values }) => (
            <LogsCsvAdditionalFieldForm values={values} change={change} />
          )}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, { schedulerJobId }) => ({
  actions: {
    ...bindActionCreators(
      getLogsTableActions(LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId)),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState, { schedulerJobId }) => ({
    schedulerJobId,
    selectedLogs: logsDataSelectors.getSelectedSelector(
      state,
      LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId),
    ),
    selectedItems: logsDataSelectors.getSelectedItemsSelector(
      state,
      LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId),
    ),
    selectedLog: logsDataSelectors.getSelectedItemSelector(
      state,
      LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId),
    ),
    projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    disabledFeatures: disabledFeaturesSelector(state),
    tableParams: logsDataSelectors.getParamsSelector(
      state,
      LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId),
    ),
    userId: currentUserIdSelector(state),
    preferencesId: LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.preferencesId,
    tableId: LOGS_TABLES_CONFIG.SCHEDULER_JOB_LOGS.id(schedulerJobId),
    userSettings: currentUserSettingsSelector(state),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(SchedulerLogsContainer));
