import { currentUserIdSelector } from "@ea/shared_components/auth/auth.selectors";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import {
  JOB_STATUS_TYPE,
  SchedulerJobMapping as SchedulerJobMappingType,
} from "@ea/shared_types/types";
import styled from "@emotion/styled";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSystemTableActions } from "../systemDictionary/systemDictionary.actions";
import { getSystemFormOptions } from "../systemDictionary/systemDictionary.selectors";
import { SYSTEM_DICTIONARY_TABLES_CONFIG } from "../systemDictionary/systemDictionary.table";
import { getVirtualUsersTableActions } from "../virtualUsers/virtualUsers.actions";
import { getVirtualUserFormOptions } from "../virtualUsers/virtualUsers.selectors";
import { VIRTUAL_USERS_TABLES_CONFIG } from "../virtualUsers/virtualUsers.table";
import { SchedulerFlowAddScriptsPanel } from "./components/SchedulerFlowAddScriptsPanel";
import SchedulerFlowCommandBar from "./components/SchedulerFlowTableCommandBar";
import { getSchedulerFlowTableActions } from "./schedulerFlow.actions";
import {
  schedulerFlowDataSelectors,
  schedulerFlowSelectorsModifier,
} from "./schedulerFlow.selectors";
import { SCHEDULER_FLOW_TABLES_CONFIG, SCHEDULER_FLOW_COLUMNS_CONFIG } from "./schedulerFlow.table";
import { getSchedulerFlowColumnsRenderers } from "./SchedulerFlowColumnsRenderers";
import { useAsyncFn } from "react-use";
import { getSchedulerRunParams, getSchedulerStatus } from "../scheduler/scheduler.selectors";
import { ApplicationState } from "../app.reducers";
import {
  TableDisplayMode,
  tableDisplayModes,
  exportToExcel,
  getColumnConfig,
  saveMapping,
} from "./schedulerFlow.helpers";
import { SCRIPTS_TABLES_CONFIG } from "../scripts/scripts.tables";
import { getScriptsTableActions } from "../scripts/scripts.actions";
import { schedulerActions } from "../scheduler";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "75vh",
});

interface SchedulerFlowTableProps {
  schedulerJobId: number;
}

interface SchedulerFlowTableState {
  isDragEnabled: boolean;
  displayMode: TableDisplayMode;
  addScriptFormVisible: boolean;
}

const comparePersistentQuery: ((currentQuery: any, prevQuery: any) => boolean) | undefined = (
  currentQuery,
  prevQuery,
) => currentQuery.schedulerJobId !== prevQuery.schedulerJobId;

const SchedulerFlowTableContainer = ({ schedulerJobId }: SchedulerFlowTableProps) => {
  const tableId = SCHEDULER_FLOW_TABLES_CONFIG.SINGLE_SCHEDULER_FLOW.id(schedulerJobId);

  const dispatch = useDispatch();
  const schedulerJobRunParams = useSelector((state: ApplicationState) =>
    getSchedulerRunParams(state, schedulerJobId),
  );
  const virtualUserOptions = useSelector((state: ApplicationState) =>
    getVirtualUserFormOptions(state, VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()),
  );
  const environmentOptions = useSelector((state: ApplicationState) =>
    getSystemFormOptions(state, SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()),
  );
  const schedulerStatus = useSelector((state: ApplicationState) =>
    getSchedulerStatus(state, schedulerJobId),
  );
  const selected = useSelector((state: ApplicationState) =>
    schedulerFlowDataSelectors.getSelectedSelector(
      state,
      SCHEDULER_FLOW_TABLES_CONFIG.SINGLE_SCHEDULER_FLOW.id(schedulerJobId),
    ),
  );
  const userId = useSelector((state: ApplicationState) => currentUserIdSelector(state));

  const schedulerJobMapping = useSelector((state: ApplicationState) =>
    schedulerFlowDataSelectors.getOrderedDataSelector(
      state,
      SCHEDULER_FLOW_TABLES_CONFIG.SINGLE_SCHEDULER_FLOW.id(schedulerJobId),
    ),
  );

  const [isDragEnabled, setIsDragEnabled] =
    useState<SchedulerFlowTableState["isDragEnabled"]>(false);
  const [addScriptFormVisible, setAddScriptFormVisible] =
    useState<SchedulerFlowTableState["addScriptFormVisible"]>(false);
  const [displayMode, setDisplayMode] = useState<SchedulerFlowTableState["displayMode"]>(
    tableDisplayModes.READ,
  );

  const readOnly = displayMode === tableDisplayModes.READ;

  const schedulerFlowColumnRenderers = useMemo(() => getSchedulerFlowColumnsRenderers(), []);

  const columnsConfig = useMemo(
    () =>
      getColumnConfig(
        schedulerFlowColumnRenderers,
        virtualUserOptions,
        environmentOptions,
        dispatch,
        readOnly,
        isDragEnabled,
        schedulerJobRunParams,
      ),
    [
      virtualUserOptions,
      environmentOptions,
      readOnly,
      schedulerJobRunParams,
      schedulerFlowColumnRenderers,
      isDragEnabled,
    ],
  );

  const [exportingStatus, doExportToCsvClick] = useAsyncFn(async () => {
    return exportToExcel({ schedulerJobId, userId });
  }, [schedulerJobId, userId]);

  const [savingStatus, doSave] = useAsyncFn(async () => {
    return saveMapping({ schedulerJobId, schedulerJobMapping });
  }, [schedulerJobMapping]);

  useEffect(() => {
    if (schedulerStatus === JOB_STATUS_TYPE.ACTIVE && displayMode === tableDisplayModes.EDIT) {
      setDisplayMode(tableDisplayModes.READ);
    }
  }, [schedulerStatus]);

  useEffect(() => {
    dispatch(getVirtualUsersTableActions(VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()).load({}));
    dispatch(getSystemTableActions(SYSTEM_DICTIONARY_TABLES_CONFIG.MAIN.id()).load({}));
    dispatch(
      getScriptsTableActions(SCRIPTS_TABLES_CONFIG.SCHEDULER_FLOW_DISPLAY.id(schedulerJobId)).load(
        {},
      ),
    );
  }, []);

  const onAddScript = useCallback(() => {
    dispatch(getSchedulerFlowTableActions(tableId).load({}));
    dispatch(schedulerActions.loadSingle({ id: schedulerJobId }));

    setAddScriptFormVisible(false);
  }, []);

  const onAddScriptCancel = useCallback(() => {
    setAddScriptFormVisible(false);
  }, []);

  const refresh = useCallback(() => dispatch(getSchedulerFlowTableActions(tableId).load({})), []);

  const onSave = () => {
    doSave();
    setIsDragEnabled(false);
    setDisplayMode(tableDisplayModes.READ);
    dispatch(schedulerActions.loadSingle({ id: schedulerJobId }));
  };

  const onEdit = useCallback(() => {
    clearAllFilters();
    setIsDragEnabled(false);
    setDisplayMode(tableDisplayModes.EDIT);
  }, []);

  const onCancel = useCallback(() => {
    setIsDragEnabled(false);
    setDisplayMode(tableDisplayModes.READ);
    dispatch(getSchedulerFlowTableActions(tableId).load({}));
  }, []);

  const onRemove = useCallback((ids: number[]): void => {
    dispatch(getSchedulerFlowTableActions(tableId).delete({ ids }));
    dispatch(schedulerActions.loadSingle({ id: schedulerJobId }));
  }, []);

  const toggleDragEnabled = useCallback(() => setIsDragEnabled((s) => !s), []);

  const clearAllFilters = () => {
    const schedulerJobMappingKeys = Object.keys(
      SCHEDULER_FLOW_COLUMNS_CONFIG,
    ) as (keyof SchedulerJobMappingType)[];
    dispatch(
      getSchedulerFlowTableActions(tableId).clearFilters({ fields: schedulerJobMappingKeys }),
    );
  };

  const onAddScriptsClick = useCallback(() => {
    clearAllFilters();
    setAddScriptFormVisible(true);
  }, []);

  return (
    <Container>
      <SchedulerFlowAddScriptsPanel
        visible={addScriptFormVisible}
        onOk={onAddScript}
        onCancel={onAddScriptCancel}
        schedulerJobId={schedulerJobId}
        schedulerJobMapping={schedulerJobMapping}
      />
      <SchedulerFlowCommandBar
        onAddScriptsClick={onAddScriptsClick}
        onExportToCsvClick={doExportToCsvClick}
        onRefresh={refresh}
        isExporting={exportingStatus.loading}
        toggleDragMode={toggleDragEnabled}
        isDragEnabled={isDragEnabled}
        isSaving={savingStatus.loading}
        onSave={onSave}
        onEdit={onEdit}
        onCancel={onCancel}
        onRemove={onRemove}
        selected={selected}
        displayMode={displayMode}
        readOnly={schedulerStatus === JOB_STATUS_TYPE.ACTIVE}
        isEditDisabled={(schedulerJobMapping || []).length === 0}
      />
      <ConnectedTable
        pageable={false}
        isDragEnabled={isDragEnabled}
        columnsConfig={columnsConfig}
        tableId={tableId}
        preferencesId={SCHEDULER_FLOW_TABLES_CONFIG.SINGLE_SCHEDULER_FLOW.preferencesId}
        stateKey={"schedulerFlow"}
        tableActions={getSchedulerFlowTableActions}
        persistentQuery={{
          schedulerJobId,
        }}
        comparePersistentQuery={comparePersistentQuery}
        modifySelectors={schedulerFlowSelectorsModifier}
      />
    </Container>
  );
};

export default SchedulerFlowTableContainer;
