import "./styles/styles.css";
import styled from "@emotion/styled";

import { Alert, Button, Spin } from "antd";

import * as React from "react";

import { ApplicationState } from "@app/modules/app.reducers";
import { authActions } from "@app/modules/auth/auth.actions";
import { Redirect, StaticContext, withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Modal } from "antd";
import { Form } from "react-final-form";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { FormattedMessage } from "react-intl";
import { PUBLIC_SETTINGS_KEYS, SPECIAL_KEY_CODES, EXECUTION_STATUS } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { LOGIN_BUTTON_COLOR } from "@app/styles/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { API } from "@app/services/api/api";
import { getIn } from "final-form";
import { toast } from "react-toastify";
import { connect, ConnectedProps } from "react-redux";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { COLORS } from "@ea/shared_components/styles/consts";
import { MicrosoftIcon } from "./MicrosoftIcon";
import Icon from "@ant-design/icons";
import { Link } from "react-router-dom";

interface ILoginProps {}
interface ILoginState {
  recaptchaPublicKey?: string;
  recaptchaEnabled?: boolean;
  ssoAzureEnabled?: boolean;
}

const Container = styled.div({
  width: "60%",
  "& > div": {
    marginBottom: "15px",
  },
  height: "250px",
});

const ErrorLabel = styled(Alert)({
  color: "#a80000",
  fontSize: "12px",
  marginTop: "15px",
});

const ButtonSpinner = styled(Spin)({
  display: "inline-block",
  height: "32px",
  paddingTop: "6px",
  marginLeft: "15px",
});

const ButtonContainer = styled.div({
  marginTop: "15px",
});

const LoginButton = styled(Button)({
  width: "126px",
});

const SSOButton = styled(Button)({
  height: 36,
});

const WindowsLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  height: "auto",
});

const OR = styled.h5({
  marginTop: 20,
  marginBottom: 20,
});

const formItemLayout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

class Login extends React.Component<
  ILoginProps &
    IConnectProps &
    RouteComponentProps<undefined, StaticContext, { from?: string; fromLocation?: Location }>,
  ILoginState
> {
  usernameInput: HTMLInputElement;
  _reCaptchaRef: React.RefObject<ReCAPTCHA>;
  _formRef: React.RefObject<HTMLFormElement>;

  state: ILoginState = {
    recaptchaPublicKey: undefined,
    recaptchaEnabled: false,
    ssoAzureEnabled: false,
  };

  constructor(
    props: ILoginProps &
      IConnectProps &
      RouteComponentProps<undefined, StaticContext, { from?: string; fromLocation?: Location }>,
  ) {
    super(props);

    this._reCaptchaRef = React.createRef<ReCAPTCHA>();
    this._formRef = React.createRef();
  }

  login = async (credentials, form) => {
    const { username, password } = credentials;
    const { recaptchaEnabled, recaptchaPublicKey } = this.state;
    if (recaptchaEnabled && recaptchaPublicKey) {
      this._reCaptchaRef?.current?.execute?.();
      return;
    }
    this.props.login({
      username,
      password,
      withLogout: this.props.hasActiveSession,
    });
  };

  handleEnterPress = (e, callback) => {
    if (e.which === SPECIAL_KEY_CODES.ENTER) {
      e.preventDefault();
      callback(e);
    }
  };

  getPublicInternalSettings = async () => {
    const publicSettings = await API.getPublicInternalSettings({});

    const getSettingValue = (key) => publicSettings.find((s) => s.id === key)?.value;

    return {
      recaptchaEnabled: getSettingValue(PUBLIC_SETTINGS_KEYS.RECAPTCHA_ENABLED),
      recaptchaPublicKey: getSettingValue(PUBLIC_SETTINGS_KEYS.RECAPTCHA_PUBLIC_KEY),
      ssoAzureEnabled: getSettingValue(PUBLIC_SETTINGS_KEYS.SSO_AZURE_ENABLED),
    };
  };

  async componentDidMount() {
    const recaptchaSettings = await this.getPublicInternalSettings();
    this.setState(recaptchaSettings);
    this.usernameInput?.focus();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isError && this.props.isError) {
      this._reCaptchaRef?.current?.reset?.();
    }
  }

  onReCaptchaChange = (token, loginFormValues) => {
    if (this.state.recaptchaPublicKey) {
      const username = getIn(loginFormValues, "username");
      const password = getIn(loginFormValues, "password");
      if (token !== null) {
        this.props.login({
          username,
          password,
          token,
          withLogout: this.props.hasActiveSession,
        });
      }
      this._reCaptchaRef?.current?.reset?.();

      return;
    }
  };

  onRecaptchaLoadError = () => {
    toast.error(
      <FormattedMessage id={getTranslationKey("messages", "error", "captchaUnavailable")} />,
    );
    this._reCaptchaRef?.current?.reset?.();
  };

  onRecaptchaExpired = () => {
    toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "captchaExpired")} />);
    this._reCaptchaRef?.current?.reset?.();
  };

  render() {
    const { isLoggedIn, isError, isLoggingIn, errorMessage, location, hasActiveSession } =
      this.props;
    const { recaptchaEnabled, recaptchaPublicKey, ssoAzureEnabled } = this.state;
    const displayReCaptcha = recaptchaEnabled && recaptchaPublicKey;

    const fromLocation = location.state?.fromLocation || location;

    const from =
      location.state?.from ||
      (["/login", "/login/"].includes(fromLocation.pathname) ? { pathname: "/" } : fromLocation);

    if (isLoggedIn) {
      return <Redirect to={from} />;
    }

    return (
      <Container>
        <h4>
          {" "}
          <FormattedMessage id={getTranslationKey("common", "login", "pleaseLogIn")} />{" "}
        </h4>
        <Modal
          visible={hasActiveSession}
          closable={false}
          onOk={() => {
            this._formRef?.current?.dispatchEvent(
              new Event("submit", { cancelable: true, bubbles: true }),
            );
          }}
          onCancel={() => this.props.clearLoginError()}
          bodyStyle={{ display: "flex", justifyContent: "center" }}
        >
          <span style={{ marginLeft: "15px" }}>
            <QuestionCircleOutlined
              style={{
                marginRight: 30,
                color: COLORS.STATUS[EXECUTION_STATUS.WARNING],
                fontSize: 25,
              }}
            />
            <FormattedMessage id={getTranslationKey("common", "login", "activeSession")} />
          </span>
        </Modal>
        <Form
          onSubmit={this.login}
          render={({ handleSubmit, invalid, values }) => {
            return (
              <form
                ref={this._formRef}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                onKeyDown={(e) => {
                  this.handleEnterPress(e, handleSubmit);
                }}
              >
                {displayReCaptcha && (
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    size="invisible"
                    ref={this._reCaptchaRef}
                    sitekey={recaptchaPublicKey}
                    onChange={(token) => this.onReCaptchaChange(token, values)}
                    onErrored={this.onRecaptchaLoadError}
                    onExpired={this.onRecaptchaExpired}
                  />
                )}
                <FormLayout {...formItemLayout}>
                  <InputField
                    name="username"
                    ref={(username) => (this.usernameInput = username)}
                    placeholder={getTranslationKey("common", "placeholder", "username")}
                    required
                  />
                  <InputField
                    name="password"
                    placeholder={getTranslationKey("common", "placeholder", "password")}
                    required
                    type="password"
                  />
                  <ButtonContainer>
                    <LoginButton
                      disabled={isLoggingIn || invalid}
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: LOGIN_BUTTON_COLOR,
                        borderColor: LOGIN_BUTTON_COLOR,
                      }}
                    >
                      <FormattedMessage
                        id={
                          isLoggingIn
                            ? getTranslationKey("common", "login", "loggingIn")
                            : getTranslationKey("common", "login", "loginButton")
                        }
                      />
                    </LoginButton>
                    {isLoggingIn ? <ButtonSpinner /> : undefined}
                  </ButtonContainer>
                </FormLayout>
              </form>
            );
          }}
        />

        {!hasActiveSession && !isLoggingIn && isError ? (
          <ErrorLabel type="error" message={errorMessage} />
        ) : undefined}
        {ssoAzureEnabled && (
          <>
            <FormattedMessage id={getTranslationKey("conditionals", "or")}>
              {(text) => <OR>{text}</OR>}
            </FormattedMessage>

            <SSOButton>
              <WindowsLink to="/sso/microsoft" target="_self">
                <Icon component={MicrosoftIcon} style={{ marginRight: "5px" }} />
                <FormattedMessage id={getTranslationKey("common", "login", "microsoft")} />
              </WindowsLink>
            </SSOButton>
          </>
        )}
      </Container>
    );
  }
}

const connectCreator = connect((state: ApplicationState) => {
  return { ...state.auth };
}, authActions);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(Login));
