import "./Table.css";
import { Table as AntdTable } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { OnSelectHandler, OnSortHandler, SelectMode } from "../../redux/common.models";
import { SortSection } from "../../redux/reducers/common.data.reducer";
import { isObjectEmpty } from "../../utils/object";
import { Item } from "@ea/shared_types/types";
import { MenuOutlined, LockOutlined } from "@ant-design/icons";
import { SorterResult, TableRowSelection, TablePaginationConfig } from "antd/lib/table/interface";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import EditableCell, { EditableRow } from "./TableBody";
const SortableTableContainer = SortableContainer<any>((props) => <tbody {...props} />);
const SortableTableRowRenderer = SortableElement<any>((props) => <tr {...props} />);

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "pointer" }} />);

const doubleClickHack = {
  timer: 0 as any,
  delay: 200,
  prevent: false,
};

export interface ITableProps<T extends Item> {
  columns: ColumnProps<T>[];
  selected: number[];
  dataSource: T[];
  isLoading?: boolean;
  onSelect: OnSelectHandler;
  disableSelectAll?: boolean;
  selectAllLabel?: string;
  expandedRowRender?: (item: T) => any;
  shouldRowBeExpandable?: (item: T) => boolean;
  onRowClick?: (record: T, index: number) => any;
  onRowDoubleClick?: (record: T, index: number) => any;
  defaultExpandAllRows?: boolean;
  defaultExpandedRowKeys?: string[] | number[];
  expandedRowKeys?: string[] | number[];
  multiselect?: boolean;
  size?: "default" | "middle" | "small";
  pagination?:
    | false
    | {
        pageSizes: string[];
        pageSize: number;
        total: number;
        current: number;
        onPageChange: (page: number) => void;
        onPageSizeChange: (pageSize: number) => void;
        position?: TablePaginationConfig["position"];
        itemRender?: (
          page: number,
          type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
          originalElement: React.ReactElement<HTMLElement>,
        ) => React.ReactNode;
      };
  sort?:
    | false
    | {
        config: SortSection<T> | undefined;
        onSortChange: OnSortHandler<T>;
        onClearSorting: ({}: any) => void;
      };
  selectable?: boolean;
  className?: string;
  showHeader?: boolean;
  footer?: () => string;
  isRowDisabled?: (record) => boolean;
  notSelectableIds?: (number | string)[];
  actions?: any;
  isDragEnabled?: boolean;
  markPositionClass?: (record: T) => string;
  disbaleExpandingOnDragEnabled?: boolean;
  nestedInnerShadow?: (record: T) => string;
  isExpandedRowEmpty?: (record: T) => boolean;
  autoScrollAnchor?: (record: T) => string;
  id?: string;
  disableRowSelection?: boolean;
}

type AntSorter = {
  order: "descend" | "ascend";
  columnKey: string;
};

export default class Table<T extends Item> extends React.Component<ITableProps<T>, any> {
  componentWillUnmount() {
    if (doubleClickHack.timer) {
      clearTimeout(doubleClickHack.timer);
    }
  }

  onSelect = (record: T, selected: boolean, selectedRows: Object[]) => {
    this.props.onSelect({
      mode: this.props.multiselect ? SelectMode.Ctrl : SelectMode.Replace,
      ids: [record.id],
    });
  };

  onSelectAll = (selected: boolean, selectedRows: T[], changeRows: Object[]) => {
    this.props.onSelect({
      mode: SelectMode.Replace,
      ids: selectedRows.filter((selectedRow) => !!selectedRow).map((selectedRow) => selectedRow.id),
    });
  };

  onRowClick = (record: T, index: number) => {
    doubleClickHack.timer = setTimeout(() => {
      if (!doubleClickHack.prevent) {
        this.props.onSelect({ mode: SelectMode.Replace, ids: [record.id] });
      }
      doubleClickHack.prevent = false;
    }, doubleClickHack.delay);
  };

  onDoubleClick = (record: T, index: number) => {
    const { onRowDoubleClick } = this.props;

    if (!onRowDoubleClick) {
      return;
    }

    doubleClickHack.prevent = true;
    onRowDoubleClick(record, index);
  };

  onPageChange = (page: number, pageSize?: number) => {
    if (this.props.pagination) {
      this.props.pagination.onPageChange(page);
    }
  };

  getRowClassName = (record: T, index: number) => {
    const {
      isRowDisabled,
      shouldRowBeExpandable,
      markPositionClass,
      notSelectableIds,
      nestedInnerShadow,
      isExpandedRowEmpty,
      autoScrollAnchor,
    } = this.props;
    const classes: string[] = [];
    if (isRowDisabled && isRowDisabled(record)) {
      classes.push("disabled-row");
    }
    if (shouldRowBeExpandable && !shouldRowBeExpandable(record)) {
      classes.push("table-hide-expand");
    }
    if (markPositionClass) {
      classes.push(markPositionClass(record));
    }
    if (notSelectableIds && notSelectableIds.includes(record.id)) {
      classes.push("disabled-record");
    }

    if (nestedInnerShadow) {
      classes.push(nestedInnerShadow?.(record));
    }

    if (isExpandedRowEmpty?.(record)) {
      classes.push("empty-expanded-row");
    }

    if (autoScrollAnchor) {
      classes.push(autoScrollAnchor?.(record));
    }

    return classes.join(" ");
  };

  onPageSizeChange = (current: number, size: number) => {
    if (this.props.pagination) {
      this.props.pagination.onPageSizeChange(size);
    }
  };

  onTableChange = (
    tablePagination: TablePaginationConfig,
    filters: Record<keyof T, string[]>,
    sorter: SorterResult<T>,
  ) => {
    const { sort, pagination } = this.props;
    if (sort) {
      if (
        pagination &&
        (pagination.current !== tablePagination.current ||
          pagination.pageSize !== tablePagination.pageSize)
      ) {
        // antd set default sort while changing page (or page size), we dont want that so we ignore it here
        return;
      }

      const isSorterEmpty = isObjectEmpty(sorter);
      if (sort.config === undefined && !isSorterEmpty) {
        const { columnKey, order } = sorter as AntSorter;
        const normalizeOrder = order === "ascend" ? "ASC" : "DESC";
        sort.onSortChange({ sortBy: columnKey as any, sortDirection: normalizeOrder });
        return;
      }

      if (sort.config) {
        if (isSorterEmpty) {
          sort.onClearSorting({});
          return;
        }
        const { columnKey, order } = sorter as AntSorter;
        const { sortBy, sortDirection } = sort.config;
        const normalizeOrder = order === "ascend" ? "ASC" : "DESC";

        const doesColumnChange = sortBy !== columnKey;
        const doesSortDirectionChange = sortDirection !== normalizeOrder;

        if (doesColumnChange || doesSortDirectionChange) {
          sort.onSortChange({ sortBy: columnKey as any, sortDirection: normalizeOrder });
        }
      }
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource, notSelectableIds } = this.props;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => (x as any).id === restProps["data-row-key"]);

    let isDisabled: boolean = false;

    if (!dataSource[index]) {
      isDisabled = true;
    } else if (notSelectableIds) {
      isDisabled = notSelectableIds.indexOf(dataSource[index].id) > -1;
    }
    return (
      <SortableTableRowRenderer
        {...restProps}
        index={index}
        key={index}
        className={className}
        isDisabled={isDisabled}
        collection={isDisabled ? 0 : 1}
      />
    );
  };

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const { moveTo } = this.props.actions;

    if (moveTo && oldIndex !== newIndex) {
      const { dataSource } = this.props;
      moveTo({ id: (dataSource[oldIndex] as any).id, from: oldIndex, to: newIndex });
    }
  };

  getColumns = () => {
    const { notSelectableIds, isDragEnabled } = this.props;
    const columns = [...this.props.columns];
    if (isDragEnabled) {
      columns.unshift({
        title: "Sort",
        dataIndex: "sort",
        render: (text, record, index) => {
          if (notSelectableIds) {
            return notSelectableIds?.indexOf(record.id) > -1 ? <LockOutlined /> : <DragHandle />;
          }
          return <DragHandle />;
        },
      });
    }
    return columns;
  };

  DraggableContainer = (props) => (
    <SortableTableContainer
      {...props}
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
    />
  );

  isAnyColumnEditable = () => this.props.columns.some((col) => (col as any).editable);

  getComponents = () => {
    const { isDragEnabled } = this.props;
    if (isDragEnabled) {
      return {
        body: {
          wrapper: this.DraggableContainer,
          row: this.DraggableBodyRow,
        },
      };
    }
    if (this.isAnyColumnEditable()) {
      return {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };
    }
    return undefined;
  };

  render() {
    const {
      selected,
      pagination,
      dataSource,
      columns,
      isLoading,
      expandedRowRender,
      size,
      onRowClick,
      selectable = true,
      onRowDoubleClick,
      multiselect,
      disableSelectAll,
      selectAllLabel,
      footer,
      notSelectableIds,
      isDragEnabled,
      expandedRowKeys,
      disbaleExpandingOnDragEnabled,
      id,
      disableRowSelection,
      ...restProps
    } = this.props;

    const selections = disableSelectAll
      ? undefined
      : [
          {
            key: "selectAll",
            text: selectAllLabel ? selectAllLabel : "Select All Data",
            onSelect: () => {
              this.props.onSelect({
                mode: SelectMode.SelectAll,
                ids: [],
              });
            },
          },
        ];

    const rowSelection: TableRowSelection<T> | undefined = selectable
      ? {
          onSelect: this.onSelect,
          onSelectAll: this.onSelectAll,
          selectedRowKeys: selected,
          columnTitle: multiselect ? undefined : " ",
          selections,
          getCheckboxProps: (record) => ({
            disabled: notSelectableIds?.includes(record.id),
          }),
        }
      : undefined;

    let paginationConfig: TablePaginationConfig | false = false;
    if (pagination) {
      const { pageSize, current, total, pageSizes, position, itemRender } = pagination;
      paginationConfig = {
        pageSize,
        total,
        current,
        pageSizeOptions: pageSizes,
        onChange: this.onPageChange,
        onShowSizeChange: this.onPageSizeChange,
        showSizeChanger: true,
        position,
        itemRender,
      };
    }

    return (
      <AntdTable
        rowSelection={isDragEnabled ? undefined : rowSelection}
        dataSource={dataSource}
        columns={this.getColumns()}
        rowKey={"id"}
        id={id}
        onRow={(record, index) => ({
          onClick: (event) => {
            if (disableRowSelection !== true) {
              this.onRowClick(record, index!);
            }
          },
          onDoubleClick: (event) => this.onDoubleClick(record, index!),
        })}
        pagination={paginationConfig}
        loading={isLoading}
        onChange={this.onTableChange}
        expandable={{
          expandedRowRender:
            disbaleExpandingOnDragEnabled && isDragEnabled ? undefined : expandedRowRender,
          expandedRowKeys,
        }}
        rowClassName={this.getRowClassName}
        size="small"
        footer={footer}
        components={this.getComponents()}
        {...restProps}
      />
    );
  }
}
