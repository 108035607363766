import * as React from "react";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { PanelType } from "@ea/shared_components/Panel";
import Dropzone from "react-dropzone";
import styled from "@emotion/styled";
import { getTranslationKey } from "./../../translations/translations.helpers";
import { toast } from "react-toastify";
import { Button, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { COLORS } from "@app/styles/consts";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { API } from "@app/services/api/api";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { ReportTemplate } from "@ea/shared_types/types";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { FileWordOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { DataTestIds } from "@app/utils/dataTestIds";
interface CreateEditReportTemplateContainerProps {
  visibility: boolean;
  onClose: () => void;
  onReload: () => void;
  isEdit: boolean;
  selected?: ReportTemplate;
}

interface CreateEditReportTemplateContainerState {
  files: any[];
  loading: boolean;
  dropping: boolean;
}
const DropZoneDiv = styled.div(
  {
    borderRadius: "4px",
    background: "#f4f4f4",
    border: "2px dashed #d9d9d9",
    transition: "border-color .3s",
    width: "400px",
    height: "150px",
    textAlign: "center",
    padding: "16px 0",
    cursor: "pointer",
    "&:hover": {
      background: "#dbdbdb",
      border: "2px dashed #2b2b2b",
    },
  },
  ({ isDragActive }: { isDragActive: boolean }) =>
    isDragActive ? { border: "2px dashed green" } : {},
);
const LoadedFilesContainer = styled.div({
  display: "flex",
  flexDirecion: "column",
  padding: "0 0 0 0px",
});

const LoadedFile = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  margin: "5px",
});

const formItemLayout = {
  labelCol: {
    sm: { span: 12 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
};

class CreateEditReportTemplateContainer extends React.Component<
  CreateEditReportTemplateContainerProps & InjectedIntlProps
> {
  formRef: any;
  dropzoneRef: any;
  state: CreateEditReportTemplateContainerState = {
    files: [],
    loading: false,
    dropping: false,
  };
  componentDidMount() {
    const { selected, isEdit } = this.props;
    if (selected && isEdit) {
      this.setState({ files: [{ name: selected.originalFilename }] });
    }
  }
  componentDidUpdate(prevProps: CreateEditReportTemplateContainerProps) {
    const { selected } = this.props;
    if (selected && prevProps.selected?.id !== selected?.id) {
      this.setState({ files: [{ name: selected.originalFilename }] });
    }
  }
  onDrop = async (files: any) => {
    this.setState({ dropping: true });
  };
  onDropAccepted = async (files: any) => {
    this.setState({ files, dropping: false });
  };
  onReject = () => {
    toast.warn(<FormattedMessage id={getTranslationKey("messages", "error", "fileUpload")} />);
  };
  onOk = async (values) => {
    const { isEdit, intl } = this.props;
    const { files } = this.state;
    const isNewFile = this.wasNewFileUploaded();

    if (files.length === 0) {
      throw new Error(
        intl.formatMessage({ id: getTranslationKey("messages", "error", "fileRequired") }),
      );
    }

    const trimmedName = values.name?.trim() || values.name;
    try {
      if (isNewFile) {
        await API.reportTemplateUpload({
          files,
          values: { ...values, name: trimmedName },
        });
      } else if (isEdit) {
        await API.editReportTemplate({ ...values, name: trimmedName });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      throw error;
    }
    this.setState({ files: [], loading: false });
    this.props.onClose();
    this.props.onReload();
  };
  onDelete = () => {
    this.setState({ files: [] });
  };
  onCancel = () => {
    this.setState({ files: [] });
    this.props.onClose();
  };
  renderFileList = () => (
    <LoadedFile>
      <FileWordOutlined />
      <h3 style={{ margin: 15 }}>{this.state.files[0].name}</h3>
      <Tooltip title={<FormattedMessage id={getTranslationKey("button", "delete")} />}>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => this.onDelete()}
          data-testid={DataTestIds.BUTTON_DELETE}
        />
      </Tooltip>
    </LoadedFile>
  );
  wasNewFileUploaded = () => this.state.files.length === 1 && !!this.state.files[0].size;
  render() {
    const { visibility, isEdit, selected } = this.props;
    const { files, dropping } = this.state;
    return (
      <PanelFormFinal
        initialValues={isEdit ? selected : undefined}
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("reportTemplate", "header", isEdit ? "edit" : "create")}
        cancelButtonText={getTranslationKey("button", "cancel")}
        okButtonText={getTranslationKey("button", isEdit ? "edit" : "create")}
        onCancelClick={this.onCancel}
        allowPrisitineSubmit={this.wasNewFileUploaded()}
        onOkClick={this.onOk}
        render={() => (
          <>
            <FormLayout {...formItemLayout}>
              <InputField
                name="name"
                required
                placeholder={getTranslationKey("common", "placeholder", "name")}
                label={getTranslationKey("common", "label", "name")}
                parse={(value) => (value.length > 0 && value[0] === " " ? value.trim() : value)}
                data-testid={DataTestIds.FORM_INPUT_NAME}
              />
              <TextAreaField
                name="description"
                placeholder={getTranslationKey("common", "placeholder", "description")}
                autoSize={{ minRows: 2, maxRows: 6 }}
                label={getTranslationKey("common", "label", "description")}
                data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
              />
            </FormLayout>
            <LoadedFilesContainer>
              {files.length > 0 ? (
                this.renderFileList()
              ) : (
                <Dropzone
                  ref={(element) => {
                    this.dropzoneRef = element;
                  }}
                  multiple={false}
                  onDrop={this.onDrop}
                  onDropAccepted={this.onDropAccepted}
                  onDropRejected={this.onReject}
                  accept=".docx"
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <DropZoneDiv {...(getRootProps() as any)} isDragActive={isDragActive}>
                      <input {...(getInputProps() as any)} data-testid={DataTestIds.DROPZONE} />
                      <UploadOutlined style={{ fontSize: 50 }} />
                      <p>
                        {!dropping ? (
                          <FormattedMessage
                            id={getTranslationKey("messages", "info", "dropZipZone")}
                          />
                        ) : (
                          <FormattedMessage id={getTranslationKey("loadingLabel")!} />
                        )}
                      </p>
                    </DropZoneDiv>
                  )}
                </Dropzone>
              )}
            </LoadedFilesContainer>
          </>
        )}
      />
    );
  }
}

export default injectIntl(CreateEditReportTemplateContainer);
