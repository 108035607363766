import { createModuleEpics } from "@app/modules/app.reducers";
import { Script } from "@ea/shared_types/types";

import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { SCRIPTS_COLUMNS_CONFIG } from "./scripts.tables";
import { scriptsDataSelectors } from "./scripts.selectors";
import { moduleActions, scriptsActionCreators } from "./scripts.actions";
import { makeAsyncEpic } from "@app/modules/app.reducers";

import { normalizeScript } from "../common/normalizeUtils";

const scriptInclude = [
  {
    relation: "environment",
    scope: {
      fields: ["envName", "envType", "envAddress"],
    },
  },
  {
    relation: "appUser",
    scope: {
      fields: ["username"],
    },
  },
  {
    relation: "datasource",
    scope: {
      fields: ["sheetsMeta"],
    },
  },
  {
    relation: "workItems",
  },
  {
    relation: "tags",
  },
  {
    relation: "globalVariables",
  },
];

const scriptEpics = {
  ...createModuleEpics<Script>(moduleActions, {
    normalizeItem: normalizeScript,
    getRequestParams: (state, tableId) => {
      const tableParams = scriptsDataSelectors.getParamsSelector(state, tableId!);
      const params = createRequestParams(tableParams, SCRIPTS_COLUMNS_CONFIG);

      params.filter = {
        ...params.filter,
        include: scriptInclude,
      };

      return params;
    },
    getSingleRequestParams: (params, state) => {
      params.filter = {
        ...params.filter,
        include: scriptInclude,
      };

      return params;
    },
    api: {
      createItem: API.createScript,
      deleteItem: API.deleteScript,
      editItem: API.editScript,
      getItems: API.getScripts,
      getItemsCount: API.getScriptsCount,
    },
  }),
  closeScriptEpic: makeAsyncEpic(scriptsActionCreators.close, (payload) =>
    API.closeScript(payload),
  ),
};

export default scriptEpics;
