import "rxjs/add/observable/fromPromise";
import "rxjs/add/observable/of";
import "rxjs/add/observable/empty";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/do";
import "rxjs/add/operator/distinctUntilChanged";
import "rxjs/add/operator/merge";
import "rxjs/add/operator/groupBy";
import "rxjs/add/operator/ignoreElements";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/scan";
import "rxjs/add/operator/combineLatest";
import "rxjs/add/operator/take";
import "rxjs/add/observable/of";

import { Action } from "redux";
import { Observable } from "rxjs/Observable";
import { AsyncActionCreators, ActionCreator, Failure } from "typescript-fsa";

import { capitalizeFirstLetter } from "../utils/formatters";
import { formatNewLineErrorToast } from "../utils/toast";
import { isDevelopment } from "../utils/env";
import { toast } from "react-toastify";
import { isUserError } from "../utils/error";
import UserError from "@ea/shared_types/userError";

/**
 *  @example const loadActionEpic = makeAsyncEpic(actions.loadAction, asyncAction);
 */
export const makeAsyncEpic =
  <State>() =>
  <T, P, S>(
    { started, done, failed }: AsyncActionCreators<T, P, S>,
    asyncMethod: (params: T, state: State) => Promise<P>,
    filter?: (action$: Observable<Action>, state: State) => boolean,
  ) => {
    return (action$: Observable<Action>, store: { getState: () => State }) =>
      action$
        .filter(started.match)
        .filter(() => (filter === undefined ? true : filter(action$, store.getState())))
        .mergeMap((action) =>
          Observable.fromPromise(asyncMethod(action.payload, store.getState()))
            .map((result) => {
              return done({
                params: action.payload,
                result,
              });
            })
            .catch((error: Error | UserError) => {
              if (isDevelopment() || isUserError(error)) {
                toast.error(formatNewLineErrorToast(capitalizeFirstLetter(error.message)));
                console.error(error);
              }
              return Observable.of(
                failed({
                  params: action.payload,
                  error: capitalizeFirstLetter(error.message) as any,
                }),
              );
            }),
        );
  };

type MultipleActionsCreator<S, K> = (result: S, state: K) => Action[];
export const createEpicRequest =
  <State>() =>
  <T, P, S>(
    asyncMethod: (params: T, state: State) => Promise<P>,
    {
      started,
      done,
      failed,
    }: {
      started: ActionCreator<T>;
      failed?: ActionCreator<Failure<T, any>>;
      done: MultipleActionsCreator<P, State>;
    },
    filter?: (action$: Observable<Action>, state: State) => boolean,
  ) => {
    return (action$: Observable<Action>, store: { getState: () => State }) =>
      action$
        .filter(started.match)
        .filter(() => (filter === undefined ? true : filter(action$, store.getState())))
        .mergeMap((action) =>
          Observable.fromPromise(asyncMethod(action.payload, store.getState()))
            .mergeMap((result) => {
              return Observable.of(...done(result, store.getState()));
            })
            .catch((error: Error | UserError) => {
              if (isDevelopment() || isUserError(error)) {
                toast.error(formatNewLineErrorToast(capitalizeFirstLetter(error.message)));
                console.error(error);
              }
              if (failed) {
                return Observable.of(
                  failed({
                    params: action.payload,
                    error: capitalizeFirstLetter(error.message) as any,
                  }),
                );
              }

              return Observable.empty();
            }),
        );
  };
