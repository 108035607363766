import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "@app/modules/guards/guards.actions";
import { Guard } from "@ea/shared_types/types";
import { guardDataSelectors } from "@app/modules/guards/guards.selectors";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";

const guardModuleEpics = createModuleEpics<Guard>(moduleActions, {
  normalizeItem: (guard) => ({ ...guard, steps: guard.steps.map((s: any) => s.id) }),
  getRequestParams: (state, tableId) => {
    const tableParams = guardDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, {});

    params.filter = {
      ...params.filter,
      include: [
        {
          relation: "steps",
        },
        { relation: "kpis" },
      ],
    };

    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createGuard,
    deleteItem: API.deleteGuard,
    editItem: API.editGuard,
    getItems: API.getGuards,
    getItemsCount: API.getGuardsCount,
  },
});

export default guardModuleEpics;
