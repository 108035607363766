import { ReportExtension, User, StartForegroundSessionParams } from "@ea/shared_types/types";
import moment from "moment";
import * as qs from "qs";

import {
  CloseSchedulerJobsRequest,
  CloseScriptRequest,
  CloseScriptResponse,
  CreateSchedulerJobMappingRequest,
  CreateSchedulerJobMappingResponse,
  CreateSchedulerJobRequest,
  CreateSchedulerJobResponse,
  CreateScriptRequest,
  CreateScriptResponse,
  CreateSequenceRequest,
  CreateSequenceResponse,
  CreateTokenRequest,
  CreateTokenResponse,
  CreateStepRequest,
  CreateStepResponse,
  CreateSystemRequest,
  CreateSystemResponse,
  CreateStorageRequest,
  CreateStorageResponse,
  CreateUserRequest,
  CreateUserResponse,
  DeleteDataSourceRequest,
  DeleteDataSourceResponse,
  DeleteSchedulerJobsRequest,
  DeleteScriptRequest,
  DeleteSequenceRequest,
  DeleteTokenRequest,
  ImportScriptRequest,
  ImportScriptResponse,
  ExportScriptRequest,
  ExportScriptResponse,
  DeleteStepRequest,
  DeleteSystemRequest,
  DeleteStorageRequest,
  DeleteUserRequest,
  EditSchedulerJobRequest,
  EditSchedulerJobResponse,
  EditScriptRequest,
  EditScriptResponse,
  EditSequenceRequest,
  EditSequenceResponse,
  EditTokenRequest,
  EditTokenResponse,
  EditStepRequest,
  EditStepResponse,
  EditSystemRequest,
  EditSystemResponse,
  EditStorageRequest,
  EditStorageResponse,
  EditUserRequest,
  EditUserResponse,
  GetDataSourcesRequest,
  GetDataSourcesResponse,
  GetGroupRequest,
  GetGroupResponse,
  GetLogsStatisticsRequest,
  GetLogsStatisticsResponse,
  GetSchedulerJobMappingRequest,
  GetSchedulerJobMappingResponse,
  GetSchedulerJobRequest,
  GetSchedulerJobResponse,
  GetSchedulerJobsCountRequest,
  GetSchedulerJobsCountResponse,
  GetSchedulerJobsRequest,
  GetSchedulerJobsResponse,
  GetScriptsCountRequest,
  GetScriptsCountResponse,
  GetScriptsRequest,
  GetScriptsResponse,
  GetSequenceCountRequest,
  GetSequenceCountResponse,
  GetSequencesRequest,
  GetSequencesResponse,
  GetTokenCountRequest,
  GetTokenCountResponse,
  GetTokensRequest,
  GetTokensResponse,
  GetStepRequest,
  GetStepsResponse,
  GetSystemCountRequest,
  GetSystemCountResponse,
  GetSystemsRequest,
  GetSystemsResponse,
  GetStorageCountRequest,
  GetStorageCountResponse,
  GetStoragesRequest,
  GetStoragesResponse,
  GetUsersCountRequest,
  GetUsersCountResponse,
  GetUsersRequest,
  GetUsersResponse,
  GetVariablesRequest,
  GetVariablesResponse,
  KillInBackgroundExecutionRequest,
  KillInBackgroundExecutionResponse,
  LoginRequest,
  LoginResponse,
  MoveToStepRequest,
  PasswordRequest,
  PasswordResponse,
  PasteStepsRequest,
  PatchGroupRequest,
  PatchGroupResponse,
  PatchScriptRequest,
  PatchScriptResponse,
  PatchVariableRequest,
  PatchVariableResponse,
  PatchVariablesRequest,
  PatchVariablesResponse,
  PlayInBackgroundRequest,
  PlayInBackgroundResponse,
  UploadFileRequest,
  CloneScriptRequest,
  CloneScriptResponse,
  UploadFileResponse,
  GetVirtualUsersRequest,
  GetVirtualUsersResponse,
  EditVirtualUserRequest,
  EditVirtualUserResponse,
  CreateVirtualUserRequest,
  CreateVirtualUserResponse,
  DeleteVirtualUserRequest,
  GetVitualUsersCountRequest,
  GuardAPITypes,
  RoleAPITypes,
  GetScreenshotRequest,
  LogsAPITypes,
  StepLogsAPITypes,
  AggregatedJobLogsAPITypes,
  GetStepsScreenshotsRequest,
  GetStepsScreenshotsResponse,
  GetStepsErrorDataResponse,
  GetStepsErrorDataRequest,
  ProjectAPITypes,
  DeleteExportCsvRequest,
  DeleteExportCsvResponse,
  UserGroupsAPITypes,
  ProjectCategoryAPITypes,
  RunSchedulerJobRequest,
  RunSchedulerJobResponse,
  KillBackgroundExecutionJobRequest,
  KillBackgroundExecutionJobResponse,
  CurrentExecutionRequest,
  CurrentExecutionResponse,
  CreateWorkItemRequest,
  CreateWorkItemResponse,
  ItsAPITypes,
  GetIterationsRequest,
  GetIterationsResponse,
  GetProjectsResponse,
  GetProjectsRequest,
  GetWorkItemTypesRequest,
  GetWorkItemTypesResponse,
  WorkItemAPITypes,
  GetVariableMapRequest,
  GetVariableMapResponse,
  ItsRuleAPITypes,
  GetGlobalSettingsRequest,
  GetGlobalSettingsResponse,
  GetScriptChangelogResponse,
  CreateScriptChangelogRequest,
  CreateScriptChangelogResponse,
  GetScriptChangelogRequest,
  GetScriptChangelogCountRequest,
  GetScriptChangelogCountResponse,
  TagAPITypes,
  ExportProjectsToTestPlansRequest,
  ExportProjectsToTestPlansResponse,
  GetTestPlansRequest,
  GetTestPlansResponse,
  GetTestSuitesRequest,
  GetTestSuitesResponse,
  GetTestCasesRequest,
  GetTestCasesResponse,
  ExportScriptToTestCaseRequest,
  ExportScriptToTestCaseResponse,
  AuditTrailAPITypes,
  EditGlobalSettingsRequest,
  EditGlobalSettingsResponse,
  DocumentationAPITypes,
  LocalizationAPITypes,
  ReportTemplateAPITypes,
  GetCapturedFilesRequest,
  GetCapturedFilesResponse,
  GetInternalSettingsRequest,
  GetInternalSettingsResponse,
  ToggleJobLogsExclusionRequest,
  ToggleJobLogsExclusionResponse,
  DeleteProjectRequest,
  ReportTemplateUploadFileRequest,
  ReportTemplateUploadFileResponse,
  GetWorkItemFieldsRequest,
  GetWorkItemFieldsResponse,
  UpsertSchedulerGroupRequest,
  UpsertSchedulerGroupResponse,
  AddSchedulerScriptsToGroupRequest,
  AddSchedulerScriptsToGroupResponse,
  RemoveSchedulerGroupResponse,
  RemoveSchedulerGroupRequest,
  RemoveSchedulerGroupsAndScriptsRequest,
  RemoveSchedulerGroupsAndScriptsResponse,
  ReplaceAllSchedulerGroupRequest,
  GetVariableCountResponse,
  GetVariableCountRequest,
  CreateGlobalVariableRequest,
  CreateGlobalVariableResponse,
  GetGlobalVariableCountRequest,
  GetGlobalVariableCountResponse,
  GetGlobalVariablesRequest,
  GetGlobalVariablesResponse,
  EditGlobalVariableRequest,
  EditGlobalVariableResponse,
  DeleteGlobalVariableRequest,
  ExportToCsvRequest,
  ExportToCsvResponse,
  LicenseResponse,
  GetAreasRequest,
  GetAreasResponse,
  ItsConfigurationAPITypes,
  GenerateTemplateRequest,
  GenerateTemplateResponse,
  PayPerUseCounterResponse,
  PayPerUseStatisticsRequest,
  PayPerUseStatisticsResponse,
  GetCodeTempleteGroupsResponse,
  DeleteCodeTempleteGroupRequest,
  EditCodeTempleteGroupsRequest,
  CreateCodeTempleteGroupResponse,
  GetCodeTempleteGroupsCountResponse,
  CreateKpiRequest,
  CreateKpiResponse,
  GetKpisRequest,
  GetKpisResponse,
  EditKpiRequest,
  EditKpiResponse,
  DeleteKpiRequest,
  GetKpiCountRequest,
  SchedulerFlowAPITypes,
  DeleteStepLogsScreenshotsRequest,
  DeleteStepLogsScreenshotsResponse,
  GetStepLogsScreenshotsStatsRequest,
  GetStepLogsScreenshotsStatsResponse,
  GetStepLogsScreenshotsAutoCleanupRequest,
  GetStepLogsScreenshotsAutoCleanupResponse,
  PostStepLogsScreenshotsAutoCleanupRequest,
  PostStepLogsScreenshotsAutoCleanupResponse,
  GetKpiExecutionStatisticsRequest,
  GetKpiExecutionStatisticsResponse,
  GetKpiJobExecutionStatisticsRequest,
  GetKpiJobExecutionStatisticsResponse,
  CodeTemplatesAPITypes,
  CreateCodeTempleteGroupRequest,
  GetCodeTempleteGroupsRequest,
  EditCodeTempleteGroupResponse,
  GetCodeTempleteGroupsCountRequest,
  ExportCodeTemplateRequest,
  ExportCodeTemplateResponse,
  ImportCodeTemplateRequest,
  ImportCodeTemplateResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from "./api.models";
import { endpointsCreator } from "@ea/shared_components/utils/endpointCreator";
import { CountResponse } from "@ea/shared_components/services/api.common.models";
import { RecorderExecutionCache, PlayerExecutionCache } from "@ea/shared_types/runner.common.types";
import {
  RecorderFinishRequest,
  RecorderInitRequest,
  RunnerInitRequest,
  RunnerInitResponse,
  AdvancedRecorderSyncResponse,
  AdvancedRecorderSyncRequest,
} from "@ea/shared_types/runner.api.types";
import { ws } from "@app/modules/common/websocket";

const apiCreator = endpointsCreator("api");

const BACKGROUND_ENDPOINT_ENDING = "background";
const SCRIPTS_ENDPOINT_ENDING = "TaskScript";
const USERS_ENDPOINT_ENDING = "Appusers";
const STEPS_ENDPOINT_ENDING = "TaskSteps";
const FILES_ENDPOINT_ENDING = "files";
const DATASOURCE_ENDPOINT_ENDING = "Datasources";
const SCHEDULER_ENDPOINT_ENDING = "Scheduler";
const SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING = "SchedulerJobMappings";
const SCHEDULER_GROUPS_ENDPOINT_ENDING = "SchedulerGroups";
const SYSTEM_DICTIONARY_ENDPOINT_ENDING = "SystemDictionary";
const STORAGE_ENDPOINT_ENDING = "Storage";
const VIRTUAL_USERS_ENDPOINT_ENDING = "VirtualUsers";
const SEQUENCES_ENDPOINT_ENDING = "NumberSequences";
const GUARDS_ENDPOINT_ENDING = "Guards";
const PROJECTS_ENDPOINT_ENDING = "Projects";
const PROJECT_CATEGORIES_ENDPOINT_ENDING = "ProjectCategories";
const TOKENS_ENDPOINT_ENDING = "Tokens";
const LOGS_ENDPOINT_ENDING = "ExecutionLogs";
const STEP_LOGS_ENDPOINT_ENDING = "ExecutionStepLogs";
const JOB_LOGS_ENDPOINT_ENDING = "ExecutionJobLogs";
const USER_GROUPS_ENDPOINT_ENDING = "UserGroups";
const ROLES_ENDPOINT_ENDING = "roles";
const AZURE_DEVOPS_ENDPOINT_ENDING = "azureDevops";
const ITS_ENDPOINT_ENDING = "IssueTrackingTools";
const ITS_RULES_ENDPOINT_ENDING = "ItsRules";
const ITS_CONFIGURATIONS_ENDPOINT_ENDING = "ItsConfigurations";
const WORK_ITEMS_ENDPOINT_ENDING = "WorkItems";
const VARIABLE_MAP_ENDPOINT_ENDING = "VariableMaps";
const GLOBAL_SETTINGS_ENDPOINT_ENDING = "GlobalSettings";
const SCRIPT_CHANGELOG_ENDPOINT_ENDING = "ScriptChangelog";
const TAGS_ENDPOINT_ENDING = "Tags";
const INTEGRATION_ENDPOINT_ENDING = "integration";
const AUDIT_TRAIL_ENDPOINT_ENDING = "AuditTrails";
const AUDIT_TRAIL_SCRIPT_ENDPOINT_ENDING = "auditTrailScriptView";
const AUDIT_TRAIL_PROJECT_ENDPOINT_ENDING = "auditTrailProjectView";
const AUDIT_TRAIL_SETTINGS_ENDPOINT_ENDING = "auditTrailSettingsView";
const DOCUMENTATION_ENDPOINT_ENDING = "Documentation";
const LOCALIZATION_ENDPOINT_ENDING = "Localizations";
const INTERNAL_SETTINGS_ENDPOINT_ENDING = "internalSettings";
const PUBLIC_INTERNAL_SETTINGS_ENDPOINT_ENDING = "publicInternalSettings";
const REPORT_TEMPLATES_ENDPOINT_ENDING = "ReportTemplates";
const GLOBAL_VARIABLES_ENDPOINT_ENDING = "GlobalVariables";
const KPI_ENDPOINT_ENDING = "Kpis";
const CODE_TEMPLATE_GROUPS_ENDPOINT_ENDING = "CodeTemplateGroups";
const VARIABLES_ENDPOINT_ENDING = "Variables";
const LICENSE_ENDPOINT_ENDING = "license";
const CODE_TEMPLATES_ENDPOINT_ENDING = "CodeTemplates";

export const API = {
  runner: {
    meta: apiCreator<RunnerInitRequest, RunnerInitResponse>("GET", "runner/meta"),
    init: apiCreator<RunnerInitRequest, RunnerInitResponse>("GET", "runner/init"),
    player: {
      init: apiCreator<RecorderInitRequest, PlayerExecutionCache>("GET", "player2/init"),
    },
    recorder: {
      init: apiCreator<RecorderInitRequest, RecorderExecutionCache>("GET", "recorder/init"),
      save: apiCreator<RecorderFinishRequest, RecorderExecutionCache>("POST", "recorder/finish"),
      syncSteps: apiCreator<AdvancedRecorderSyncRequest, AdvancedRecorderSyncResponse>(
        "POST",
        "recorder/syncSteps",
      ),
    },
  },
  startSession: apiCreator<
    StartForegroundSessionParams,
    { url: string; id: string; error?: { code?: string } }
  >("POST", "runner/start"),
  login: apiCreator<LoginRequest, LoginResponse>("POST", "Appusers/login", {
    isAuthenticated: false,
  }),
  changePassword: apiCreator<PasswordRequest, PasswordResponse>("POST", "Appusers/change-password"),
  refreshToken: apiCreator<RefreshTokenRequest, RefreshTokenResponse>("GET", "sso/refresh"),
  getGroups: apiCreator<GetGroupRequest, GetGroupResponse>("GET", "Groups"),
  patchGroup: apiCreator<PatchGroupRequest, PatchGroupResponse>("PATCH", "Groups"),
  getInternalSettings: apiCreator<GetInternalSettingsRequest, GetInternalSettingsResponse>(
    "GET",
    INTERNAL_SETTINGS_ENDPOINT_ENDING,
  ),
  getPublicInternalSettings: apiCreator<GetInternalSettingsRequest, GetInternalSettingsResponse>(
    "GET",
    PUBLIC_INTERNAL_SETTINGS_ENDPOINT_ENDING,
    {
      isAuthenticated: false,
    },
  ),
  getGlobalSettings: apiCreator<GetGlobalSettingsRequest, GetGlobalSettingsResponse>(
    "GET",
    GLOBAL_SETTINGS_ENDPOINT_ENDING,
  ),
  editGlobalSettings: apiCreator<EditGlobalSettingsRequest<any>, EditGlobalSettingsResponse>(
    "PATCH",
    GLOBAL_SETTINGS_ENDPOINT_ENDING,
  ),
  cloneScript: apiCreator<CloneScriptRequest, CloneScriptResponse>(
    "POST",
    `${SCRIPTS_ENDPOINT_ENDING}/clone`,
  ),
  scriptAutocomplete: apiCreator<{ url: string }, string[]>(
    "POST",
    `${SCRIPTS_ENDPOINT_ENDING}/autocomplete`,
  ),
  getScripts: apiCreator<GetScriptsRequest, GetScriptsResponse>("GET", SCRIPTS_ENDPOINT_ENDING),
  getScriptsCount: apiCreator<GetScriptsCountRequest, GetScriptsCountResponse>(
    "GET",
    `${SCRIPTS_ENDPOINT_ENDING}/count`,
  ),

  deleteScript: apiCreator<DeleteScriptRequest, any>(
    "DELETE",
    (params) => `${SCRIPTS_ENDPOINT_ENDING}/${params.id}`,
  ),
  closeScript: apiCreator<CloseScriptRequest, CloseScriptResponse>(
    "POST",
    () => `${SCRIPTS_ENDPOINT_ENDING}/close`,
  ),
  createScript: apiCreator<CreateScriptRequest, CreateScriptResponse>(
    "POST",
    SCRIPTS_ENDPOINT_ENDING,
  ),
  editScript: apiCreator<EditScriptRequest, EditScriptResponse>(
    "PUT",
    (params) => `${SCRIPTS_ENDPOINT_ENDING}/${params.id}`,
  ),
  patchScript: apiCreator<PatchScriptRequest, PatchScriptResponse>(
    "PATCH",
    (params) => `${SCRIPTS_ENDPOINT_ENDING}/${params.id}`,
  ),
  pasteSteps: apiCreator<PasteStepsRequest, CreateStepResponse[]>(
    "PATCH",
    `${STEPS_ENDPOINT_ENDING}/paste`,
  ),
  createStep: apiCreator<CreateStepRequest, CreateStepResponse>(
    "POST",
    STEPS_ENDPOINT_ENDING + "/createAndRenumerate",
  ),
  getSteps: apiCreator<GetStepRequest, GetStepsResponse>("GET", STEPS_ENDPOINT_ENDING),
  editStep: apiCreator<EditStepRequest, EditStepResponse>(
    "PATCH",
    (params) => `${STEPS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteStep: apiCreator<DeleteStepRequest, any>(
    "DELETE",
    (params) => `${STEPS_ENDPOINT_ENDING}/${params.id}`,
  ),
  moveToStep: apiCreator<MoveToStepRequest, any>("POST", `${STEPS_ENDPOINT_ENDING}/moveTo`),

  getScriptChangelog: apiCreator<GetScriptChangelogRequest, GetScriptChangelogResponse>(
    "GET",
    SCRIPT_CHANGELOG_ENDPOINT_ENDING,
  ),
  getScriptChangelogCount: apiCreator<
    GetScriptChangelogCountRequest,
    GetScriptChangelogCountResponse
  >("GET", `${SCRIPT_CHANGELOG_ENDPOINT_ENDING}/count`),
  createScriptChangelog: apiCreator<CreateScriptChangelogRequest, CreateScriptChangelogResponse>(
    "POST",
    SCRIPT_CHANGELOG_ENDPOINT_ENDING,
  ),
  createUser: apiCreator<CreateUserRequest, CreateUserResponse>("POST", USERS_ENDPOINT_ENDING),
  auth: apiCreator<{}, User>("GET", `${USERS_ENDPOINT_ENDING}/auth`),
  getUsers: apiCreator<GetUsersRequest, GetUsersResponse>("GET", USERS_ENDPOINT_ENDING),
  editUser: apiCreator<EditUserRequest, EditUserResponse>(
    "PATCH",
    (params) => `${USERS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteUser: apiCreator<DeleteUserRequest, any>(
    "DELETE",
    (params) => `${USERS_ENDPOINT_ENDING}/${params.id}`,
  ),
  getUsersCount: apiCreator<GetUsersCountRequest, GetUsersCountResponse>(
    "GET",
    `${USERS_ENDPOINT_ENDING}/count`,
  ),
  getCountGroupByStatistic: apiCreator<GetLogsStatisticsRequest, GetLogsStatisticsResponse>(
    "GET",
    `${LOGS_ENDPOINT_ENDING}/countGroupBy`,
  ),
  getProjectExecutionStatistics: apiCreator<GetLogsStatisticsRequest, GetLogsStatisticsResponse>(
    "GET",
    `${LOGS_ENDPOINT_ENDING}/projectExecutionStatistics`,
  ),

  getStepsScreenshots: apiCreator<GetStepsScreenshotsRequest, GetStepsScreenshotsResponse>(
    "GET",
    `${LOGS_ENDPOINT_ENDING}/stepsScreenshots`,
  ),

  getStepsErrorData: apiCreator<GetStepsErrorDataRequest, GetStepsErrorDataResponse>(
    "GET",
    `${LOGS_ENDPOINT_ENDING}/stepsErrorData`,
  ),
  getVariableCount: apiCreator<GetVariableCountRequest, GetVariableCountResponse>(
    "GET",
    `${VARIABLES_ENDPOINT_ENDING}/count`,
  ),
  getVariables: apiCreator<GetVariablesRequest, GetVariablesResponse>(
    "GET",
    VARIABLES_ENDPOINT_ENDING,
  ),
  createVariable: apiCreator<PatchVariableRequest, PatchVariableResponse>(
    "POST",
    VARIABLES_ENDPOINT_ENDING,
  ),
  deleteVariable: apiCreator<DeleteScriptRequest, any>(
    "DELETE",
    (params) => `${VARIABLES_ENDPOINT_ENDING}/${params.id}`,
  ),
  patchVariable: apiCreator<PatchVariableRequest, PatchVariableResponse>(
    "PATCH",
    VARIABLES_ENDPOINT_ENDING,
  ),
  patchVariables: apiCreator<PatchVariablesRequest, PatchVariablesResponse>(
    "PATCH",
    `${VARIABLES_ENDPOINT_ENDING}/patchDiff`,
  ),
  createGlobalVariable: apiCreator<CreateGlobalVariableRequest, CreateGlobalVariableResponse>(
    "POST",
    GLOBAL_VARIABLES_ENDPOINT_ENDING,
  ),
  getGlobalVariables: apiCreator<GetGlobalVariablesRequest, GetGlobalVariablesResponse>(
    "GET",
    GLOBAL_VARIABLES_ENDPOINT_ENDING,
  ),
  editGlobalVariable: apiCreator<EditGlobalVariableRequest, EditGlobalVariableResponse>(
    "PATCH",
    (params) => `${GLOBAL_VARIABLES_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteGlobalVariable: apiCreator<DeleteGlobalVariableRequest, any>(
    "DELETE",
    (params) => `${GLOBAL_VARIABLES_ENDPOINT_ENDING}/${params.id}`,
  ),
  getGlobalVariableCount: apiCreator<GetGlobalVariableCountRequest, GetGlobalVariableCountResponse>(
    "GET",
    `${GLOBAL_VARIABLES_ENDPOINT_ENDING}/count`,
  ),
  createCodeTemplateGroup: apiCreator<
    CreateCodeTempleteGroupRequest,
    CreateCodeTempleteGroupResponse
  >("POST", CODE_TEMPLATE_GROUPS_ENDPOINT_ENDING),
  getCodeTemplateGroups: apiCreator<GetCodeTempleteGroupsRequest, GetCodeTempleteGroupsResponse>(
    "GET",
    CODE_TEMPLATE_GROUPS_ENDPOINT_ENDING,
  ),
  editCodeTemplateGroup: apiCreator<EditCodeTempleteGroupsRequest, EditCodeTempleteGroupResponse>(
    "PATCH",
    (params) => `${CODE_TEMPLATE_GROUPS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteCodeTemplateGroup: apiCreator<DeleteCodeTempleteGroupRequest, any>(
    "DELETE",
    (params) => `${CODE_TEMPLATE_GROUPS_ENDPOINT_ENDING}/${params.id}`,
  ),
  getCodeTemplateGroupCount: apiCreator<
    GetCodeTempleteGroupsCountRequest,
    GetCodeTempleteGroupsCountResponse
  >("GET", `${CODE_TEMPLATE_GROUPS_ENDPOINT_ENDING}/count`),
  createKpi: apiCreator<CreateKpiRequest, CreateKpiResponse>("POST", KPI_ENDPOINT_ENDING),
  getKpi: apiCreator<GetKpisRequest, GetKpisResponse>("GET", KPI_ENDPOINT_ENDING),
  editKpi: apiCreator<EditKpiRequest, EditKpiResponse>(
    "PATCH",
    (params) => `${KPI_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteKpi: apiCreator<DeleteKpiRequest, any>(
    "DELETE",
    (params) => `${KPI_ENDPOINT_ENDING}/${params.id}`,
  ),
  getKpiCount: apiCreator<GetKpiCountRequest, GetGlobalVariableCountResponse>(
    "GET",
    `${KPI_ENDPOINT_ENDING}/count`,
  ),
  getExecutionStatistics: apiCreator<
    GetKpiExecutionStatisticsRequest,
    GetKpiExecutionStatisticsResponse
  >("GET", `${KPI_ENDPOINT_ENDING}/executionStatistics`),

  getExecutionJobStatistics: apiCreator<
    GetKpiJobExecutionStatisticsRequest,
    GetKpiJobExecutionStatisticsResponse
  >("GET", `${KPI_ENDPOINT_ENDING}/jobStatistics`),

  uploadFile: apiCreator<UploadFileRequest, UploadFileResponse>(
    "POST",
    (params) => `${FILES_ENDPOINT_ENDING}/uploadFile/${params.parentType}/${params.parentId}`,
    {
      defaultContentType: false,
      bodyBuilder: (params) => {
        const formData = new FormData();
        if (params && params.files) {
          params.files.forEach((file) => formData.append(file.name, file));
        }
        return formData;
      },
    },
  ),
  reportTemplateUpload: apiCreator<
    ReportTemplateUploadFileRequest,
    ReportTemplateUploadFileResponse
  >("POST", (params) => `${FILES_ENDPOINT_ENDING}/reportTemplateUpload`, {
    defaultContentType: false,
    bodyBuilder: (params) => {
      const formData = new FormData();

      if (params && params.files) {
        params.files.forEach((file) => formData.append(file.name, file));
      }
      if (params && params.values) {
        Object.keys(params.values).forEach((key) => formData.append(key, params.values[key]));
      }
      return formData;
    },
  }),

  downloadFile: (fileName, originalFilename) => {
    const link = document.createElement("a");
    link.href = `/api/files/datasources/download/${fileName}`;
    link.download = originalFilename;
    link.click();
  },

  downloadReportTemplate: (fileName, originalFilename) => {
    const link = document.createElement("a");
    link.href = `/api/files/report-templates/download/${fileName}`;
    link.download = originalFilename;
    link.click();
  },

  generateReport: apiCreator<
    { logId: number; reportName: string; tableId: string; extension?: ReportExtension },
    any
  >("POST", `${LOGS_ENDPOINT_ENDING}/generateReport`, {
    fileRequest: true,
    isAuthenticated: true,
    bodyBuilder: (params) =>
      qs.stringify({
        ...params,
        utcOffset: moment().utcOffset(),
        wsId: ws?.id,
      }),
  }),
  downloadReport: apiCreator<{ reportName: string }, any>(
    "POST",
    `${LOGS_ENDPOINT_ENDING}/report`,
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  getScreenshot: apiCreator<GetScreenshotRequest, any>(
    "GET",
    (params) => `files/${params.sessionId}/download/${params.name}`,
  ),

  getDataSources: apiCreator<GetDataSourcesRequest, GetDataSourcesResponse>(
    "GET",
    () => `${DATASOURCE_ENDPOINT_ENDING}`,
  ),

  deleteDataSource: apiCreator<DeleteDataSourceRequest, DeleteDataSourceResponse>(
    "DELETE",
    (params) => `${DATASOURCE_ENDPOINT_ENDING}/${params.datasourceId}`,
  ),

  createSchedulerJob: apiCreator<CreateSchedulerJobRequest, CreateSchedulerJobResponse>(
    "POST",
    SCHEDULER_ENDPOINT_ENDING,
  ),

  getSchedulerJob: apiCreator<GetSchedulerJobRequest, GetSchedulerJobResponse>(
    "GET",
    (params) => `${SCHEDULER_ENDPOINT_ENDING}/${params.id}`,
  ),
  getSchedulerJobs: apiCreator<GetSchedulerJobsRequest, GetSchedulerJobsResponse>(
    "GET",
    SCHEDULER_ENDPOINT_ENDING,
  ),

  getSchedulerJobCount: apiCreator<GetSchedulerJobsCountRequest, GetSchedulerJobsCountResponse>(
    "GET",
    `${SCHEDULER_ENDPOINT_ENDING}/count`,
  ),

  editSchedulerJob: apiCreator<EditSchedulerJobRequest, EditSchedulerJobResponse>(
    "PATCH",
    (params) => `${SCHEDULER_ENDPOINT_ENDING}/${params.id}`,
  ),

  deleteSchedulerJob: apiCreator<DeleteSchedulerJobsRequest, any>(
    "DELETE",
    (params) => `${SCHEDULER_ENDPOINT_ENDING}/${params.id}`,
  ),
  closeSchedulerJob: apiCreator<CloseSchedulerJobsRequest, any>(
    "POST",
    () => `${SCHEDULER_ENDPOINT_ENDING}/close`,
  ),
  runProjectJob: apiCreator<RunSchedulerJobRequest, RunSchedulerJobResponse>(
    "POST",
    `${SCHEDULER_ENDPOINT_ENDING}/runProject`,
  ),
  currentExecution: apiCreator<CurrentExecutionRequest, CurrentExecutionResponse>(
    "GET",
    `${BACKGROUND_ENDPOINT_ENDING}/currentExecution`,
  ),

  createSchedulerJobMapping: apiCreator<
    CreateSchedulerJobMappingRequest,
    CreateSchedulerJobMappingResponse
  >("PATCH", `${SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING}/patchDiff`),

  getSchedulerJobMappings: apiCreator<
    GetSchedulerJobMappingRequest,
    GetSchedulerJobMappingResponse
  >("GET", SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING),

  playInBackground: apiCreator<PlayInBackgroundRequest, PlayInBackgroundResponse>(
    "POST",
    `${BACKGROUND_ENDPOINT_ENDING}/play`,
  ),

  killInBackgroundExecution: apiCreator<
    KillInBackgroundExecutionRequest,
    KillInBackgroundExecutionResponse
  >("GET", `${BACKGROUND_ENDPOINT_ENDING}/kill`),

  killBackgroundJob: apiCreator<
    KillBackgroundExecutionJobRequest,
    KillBackgroundExecutionJobResponse
  >("GET", `${BACKGROUND_ENDPOINT_ENDING}/killJob`),

  authenticateVirtualUser: apiCreator<
    { id: number; sensitiveData: { key: string; value: string }[] },
    any
  >("POST", `${VIRTUAL_USERS_ENDPOINT_ENDING}/authenticate`),
  getVirtualUsers: apiCreator<GetVirtualUsersRequest, GetVirtualUsersResponse>(
    "GET",
    VIRTUAL_USERS_ENDPOINT_ENDING,
  ),
  createVirtualUser: apiCreator<CreateVirtualUserRequest, CreateVirtualUserResponse>(
    "POST",
    VIRTUAL_USERS_ENDPOINT_ENDING,
  ),
  editVirtualUser: apiCreator<EditVirtualUserRequest, EditVirtualUserResponse>(
    "PATCH",
    (params) => `${VIRTUAL_USERS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteVirtualUser: apiCreator<DeleteVirtualUserRequest, any>(
    "DELETE",
    (params) => `${VIRTUAL_USERS_ENDPOINT_ENDING}/${params.id}`,
  ),
  getVirtualUsersCount: apiCreator<GetVitualUsersCountRequest, CountResponse>(
    "GET",
    `${VIRTUAL_USERS_ENDPOINT_ENDING}/count`,
  ),

  createSystem: apiCreator<CreateSystemRequest, CreateSystemResponse>(
    "POST",
    SYSTEM_DICTIONARY_ENDPOINT_ENDING,
  ),
  getSystems: apiCreator<GetSystemsRequest, GetSystemsResponse>(
    "GET",
    SYSTEM_DICTIONARY_ENDPOINT_ENDING,
  ),
  editSystem: apiCreator<EditSystemRequest, EditSystemResponse>(
    "PATCH",
    (params) => `${SYSTEM_DICTIONARY_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteSystem: apiCreator<DeleteSystemRequest, any>(
    "DELETE",
    (params) => `${SYSTEM_DICTIONARY_ENDPOINT_ENDING}/${params.id}`,
  ),
  getSystemCount: apiCreator<GetSystemCountRequest, GetSystemCountResponse>(
    "GET",
    `${SYSTEM_DICTIONARY_ENDPOINT_ENDING}/count`,
  ),
  createStorage: apiCreator<CreateStorageRequest, CreateStorageResponse>(
    "POST",
    STORAGE_ENDPOINT_ENDING,
  ),
  getStorages: apiCreator<GetStoragesRequest, GetStoragesResponse>("GET", STORAGE_ENDPOINT_ENDING),
  editStorage: apiCreator<EditStorageRequest, EditStorageResponse>(
    "PATCH",
    (params) => `${STORAGE_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteStorage: apiCreator<DeleteStorageRequest, any>(
    "DELETE",
    (params) => `${STORAGE_ENDPOINT_ENDING}/${params.id}`,
  ),
  getStorageCount: apiCreator<GetStorageCountRequest, GetStorageCountResponse>(
    "GET",
    `${STORAGE_ENDPOINT_ENDING}/count`,
  ),
  createSequence: apiCreator<CreateSequenceRequest, CreateSequenceResponse>(
    "POST",
    SEQUENCES_ENDPOINT_ENDING,
  ),
  getSequences: apiCreator<GetSequencesRequest, GetSequencesResponse>(
    "GET",
    SEQUENCES_ENDPOINT_ENDING,
  ),
  editSequence: apiCreator<EditSequenceRequest, EditSequenceResponse>(
    "PATCH",
    (params) => `${SEQUENCES_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteSequence: apiCreator<DeleteSequenceRequest, any>(
    "DELETE",
    (params) => `${SEQUENCES_ENDPOINT_ENDING}/${params.id}`,
  ),
  getSequenceCount: apiCreator<GetSequenceCountRequest, GetSequenceCountResponse>(
    "GET",
    `${SEQUENCES_ENDPOINT_ENDING}/count`,
  ),

  getGuards: apiCreator<GuardAPITypes["loadRequest"], GuardAPITypes["loadResponse"]>(
    "GET",
    GUARDS_ENDPOINT_ENDING,
  ),
  getGuardsCount: apiCreator<GuardAPITypes["countRequest"], GuardAPITypes["countResponse"]>(
    "GET",
    `${GUARDS_ENDPOINT_ENDING}/count`,
  ),
  createGuard: apiCreator<GuardAPITypes["createRequest"], GuardAPITypes["createResponse"]>(
    "POST",
    `${GUARDS_ENDPOINT_ENDING}`,
  ),
  editGuard: apiCreator<GuardAPITypes["editRequest"], GuardAPITypes["editResponse"]>(
    "PATCH",
    (params) => `${GUARDS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteGuard: apiCreator<GuardAPITypes["deleteRequest"], GuardAPITypes["deleteResponse"]>(
    "DELETE",
    (params) => `${GUARDS_ENDPOINT_ENDING}/${params.id}`,
  ),

  getRoles: apiCreator<RoleAPITypes["loadRequest"], RoleAPITypes["loadResponse"]>(
    "GET",
    ROLES_ENDPOINT_ENDING,
  ),
  getRolesCount: apiCreator<RoleAPITypes["countRequest"], RoleAPITypes["countResponse"]>(
    "GET",
    `${ROLES_ENDPOINT_ENDING}/count`,
  ),
  createRole: apiCreator<RoleAPITypes["createRequest"], RoleAPITypes["createResponse"]>(
    "POST",
    `${ROLES_ENDPOINT_ENDING}`,
  ),
  editRole: apiCreator<RoleAPITypes["editRequest"], RoleAPITypes["editResponse"]>(
    "PATCH",
    (params) => `${ROLES_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteRole: apiCreator<RoleAPITypes["deleteRequest"], RoleAPITypes["deleteResponse"]>(
    "DELETE",
    (params) => `${ROLES_ENDPOINT_ENDING}/${params.id}`,
  ),

  getProjects: apiCreator<ProjectAPITypes["loadRequest"], ProjectAPITypes["loadResponse"]>(
    "GET",
    PROJECTS_ENDPOINT_ENDING,
  ),
  createProject: apiCreator<ProjectAPITypes["createRequest"], ProjectAPITypes["createResponse"]>(
    "POST",
    `${PROJECTS_ENDPOINT_ENDING}`,
  ),
  editProject: apiCreator<ProjectAPITypes["editRequest"], ProjectAPITypes["editResponse"]>(
    "PATCH",
    (params) => `${PROJECTS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteProject: apiCreator<DeleteProjectRequest, ProjectAPITypes["deleteResponse"]>(
    "DELETE",
    (params) => `${PROJECTS_ENDPOINT_ENDING}/${params.id}`,
  ),
  getProjectCategories: apiCreator<
    ProjectCategoryAPITypes["loadRequest"],
    ProjectCategoryAPITypes["loadResponse"]
  >("GET", PROJECT_CATEGORIES_ENDPOINT_ENDING),
  getProjectCategoriesCount: apiCreator<
    ProjectCategoryAPITypes["countRequest"],
    ProjectCategoryAPITypes["countResponse"]
  >("GET", `${PROJECT_CATEGORIES_ENDPOINT_ENDING}/count`),
  createProjectCategory: apiCreator<
    ProjectCategoryAPITypes["createRequest"],
    ProjectCategoryAPITypes["createResponse"]
  >("POST", `${PROJECT_CATEGORIES_ENDPOINT_ENDING}`),
  editProjectCategory: apiCreator<
    ProjectCategoryAPITypes["editRequest"],
    ProjectCategoryAPITypes["editResponse"]
  >("PATCH", (params) => `${PROJECT_CATEGORIES_ENDPOINT_ENDING}/${params.id}`),
  deleteProjectCategory: apiCreator<
    ProjectCategoryAPITypes["deleteRequest"],
    ProjectCategoryAPITypes["deleteResponse"]
  >("DELETE", (params) => `${PROJECT_CATEGORIES_ENDPOINT_ENDING}/${params.id}`),

  getUserGroups: apiCreator<UserGroupsAPITypes["loadRequest"], UserGroupsAPITypes["loadResponse"]>(
    "GET",
    USER_GROUPS_ENDPOINT_ENDING,
  ),
  getUserGroupsCount: apiCreator<
    UserGroupsAPITypes["countRequest"],
    UserGroupsAPITypes["countResponse"]
  >("GET", `${USER_GROUPS_ENDPOINT_ENDING}/count`),
  createUserGroup: apiCreator<
    UserGroupsAPITypes["createRequest"],
    UserGroupsAPITypes["createResponse"]
  >("POST", `${USER_GROUPS_ENDPOINT_ENDING}`),
  editUserGroup: apiCreator<UserGroupsAPITypes["editRequest"], UserGroupsAPITypes["editResponse"]>(
    "PATCH",
    (params) => `${USER_GROUPS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteUserGroup: apiCreator<
    UserGroupsAPITypes["deleteRequest"],
    UserGroupsAPITypes["deleteResponse"]
  >("DELETE", (params) => `${USER_GROUPS_ENDPOINT_ENDING}/${params.id}`),

  getLogs: apiCreator<LogsAPITypes["loadRequest"], LogsAPITypes["loadResponse"]>(
    "GET",
    LOGS_ENDPOINT_ENDING,
  ),
  getLogsCount: apiCreator<LogsAPITypes["countRequest"], LogsAPITypes["countResponse"]>(
    "GET",
    `${LOGS_ENDPOINT_ENDING}/count`,
  ),
  getStepLogs: apiCreator<StepLogsAPITypes["loadRequest"], StepLogsAPITypes["loadResponse"]>(
    "GET",
    STEP_LOGS_ENDPOINT_ENDING,
  ),
  getStepLogsCount: apiCreator<StepLogsAPITypes["countRequest"], StepLogsAPITypes["countResponse"]>(
    "GET",
    `${STEP_LOGS_ENDPOINT_ENDING}/count`,
  ),
  getAggregatedJobLogs: apiCreator<
    AggregatedJobLogsAPITypes["loadRequest"],
    AggregatedJobLogsAPITypes["loadResponse"]
  >("GET", `${JOB_LOGS_ENDPOINT_ENDING}/aggregatedJobLogs`),
  getAggregatedJobLogsCount: apiCreator<
    AggregatedJobLogsAPITypes["countRequest"],
    AggregatedJobLogsAPITypes["countResponse"]
  >("GET", `${JOB_LOGS_ENDPOINT_ENDING}/aggregatedJobLogsCount`),
  toggleJobLogsExclusion: apiCreator<ToggleJobLogsExclusionRequest, ToggleJobLogsExclusionResponse>(
    "GET",
    `${JOB_LOGS_ENDPOINT_ENDING}/toggleJobLogsExclusion`,
  ),
  exportToCsv: apiCreator<ExportToCsvRequest, ExportToCsvResponse>("POST", `exportToCsv/export`, {
    fileRequest: true,
    isAuthenticated: true,
    returnHeaders: true,
  }),
  deleteTemporaryCsv: apiCreator<DeleteExportCsvRequest, DeleteExportCsvResponse>(
    "POST",
    `exportToCsv/deleteFile`,
  ),
  createToken: apiCreator<CreateTokenRequest, CreateTokenResponse>("POST", TOKENS_ENDPOINT_ENDING),
  getTokens: apiCreator<GetTokensRequest, GetTokensResponse>("GET", TOKENS_ENDPOINT_ENDING),
  editToken: apiCreator<EditTokenRequest, EditTokenResponse>(
    "PATCH",
    (params) => `${TOKENS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteToken: apiCreator<DeleteTokenRequest, any>(
    "DELETE",
    (params) => `${TOKENS_ENDPOINT_ENDING}/${params.id}`,
  ),
  getTokenCount: apiCreator<GetTokenCountRequest, GetTokenCountResponse>(
    "GET",
    `${TOKENS_ENDPOINT_ENDING}/count`,
  ),

  getStoragesWithContainers: apiCreator<any, any>("GET", `${STORAGE_ENDPOINT_ENDING}/containers`),

  createWorkItem: apiCreator<CreateWorkItemRequest, CreateWorkItemResponse>(
    "POST",
    `${AZURE_DEVOPS_ENDPOINT_ENDING}/createWorkItem`,
  ),

  getIterations: apiCreator<GetIterationsRequest, GetIterationsResponse>(
    "GET",
    `${AZURE_DEVOPS_ENDPOINT_ENDING}/iterations`,
  ),

  getAreas: apiCreator<GetAreasRequest, GetAreasResponse>(
    "GET",
    `${AZURE_DEVOPS_ENDPOINT_ENDING}/areas`,
  ),

  getWorkItemTypes: apiCreator<GetWorkItemTypesRequest, GetWorkItemTypesResponse>(
    "GET",
    `${AZURE_DEVOPS_ENDPOINT_ENDING}/workItemTypes`,
  ),

  getItsProjects: apiCreator<GetProjectsRequest, GetProjectsResponse>(
    "GET",
    `${AZURE_DEVOPS_ENDPOINT_ENDING}/itsProjects`,
  ),

  getIts: apiCreator<ItsAPITypes["loadRequest"], ItsAPITypes["loadResponse"]>(
    "GET",
    ITS_ENDPOINT_ENDING,
  ),
  getItsCount: apiCreator<ItsAPITypes["countRequest"], ItsAPITypes["countResponse"]>(
    "GET",
    `${ITS_ENDPOINT_ENDING}/count`,
  ),
  createIts: apiCreator<ItsAPITypes["createRequest"], ItsAPITypes["createResponse"]>(
    "POST",
    `${ITS_ENDPOINT_ENDING}`,
  ),
  editIts: apiCreator<Partial<ItsAPITypes["editRequest"]>, ItsAPITypes["editResponse"]>(
    "PATCH",
    (params) => `${ITS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteIts: apiCreator<ItsAPITypes["deleteRequest"], ItsAPITypes["deleteResponse"]>(
    "DELETE",
    (params) => `${ITS_ENDPOINT_ENDING}/${params.id}`,
  ),

  fetchItsConfigurations: apiCreator<
    ItsConfigurationAPITypes["loadRequest"],
    ItsConfigurationAPITypes["loadResponse"]
  >("GET", `${ITS_CONFIGURATIONS_ENDPOINT_ENDING}/fetch`),
  getItsConfigurations: apiCreator<
    ItsConfigurationAPITypes["loadRequest"],
    ItsConfigurationAPITypes["loadResponse"]
  >("GET", ITS_CONFIGURATIONS_ENDPOINT_ENDING),
  getItsConfigurationCount: apiCreator<
    ItsConfigurationAPITypes["countRequest"],
    ItsConfigurationAPITypes["countResponse"]
  >("GET", `${ITS_CONFIGURATIONS_ENDPOINT_ENDING}/count`),
  createItsConfiguration: apiCreator<
    ItsConfigurationAPITypes["createRequest"],
    ItsConfigurationAPITypes["createResponse"]
  >("POST", `${ITS_CONFIGURATIONS_ENDPOINT_ENDING}`),
  editItsConfiguration: apiCreator<
    Partial<ItsConfigurationAPITypes["editRequest"]>,
    ItsConfigurationAPITypes["editResponse"]
  >("PATCH", (params) => `${ITS_CONFIGURATIONS_ENDPOINT_ENDING}/${params.id}`),
  deleteItsConfiguration: apiCreator<
    ItsConfigurationAPITypes["deleteRequest"],
    ItsConfigurationAPITypes["deleteResponse"]
  >("DELETE", (params) => `${ITS_CONFIGURATIONS_ENDPOINT_ENDING}/${params.id}`),

  getItsRule: apiCreator<ItsRuleAPITypes["loadRequest"], ItsRuleAPITypes["loadResponse"]>(
    "GET",
    ITS_RULES_ENDPOINT_ENDING,
  ),
  getItsRuleCount: apiCreator<ItsRuleAPITypes["countRequest"], ItsRuleAPITypes["countResponse"]>(
    "GET",
    `${ITS_RULES_ENDPOINT_ENDING}/count`,
  ),
  createItsRule: apiCreator<ItsRuleAPITypes["createRequest"], ItsRuleAPITypes["createResponse"]>(
    "POST",
    `${ITS_RULES_ENDPOINT_ENDING}`,
  ),
  editItsRule: apiCreator<Partial<ItsRuleAPITypes["editRequest"]>, ItsRuleAPITypes["editResponse"]>(
    "PATCH",
    (params) => `${ITS_RULES_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteItsRule: apiCreator<ItsRuleAPITypes["deleteRequest"], ItsRuleAPITypes["deleteResponse"]>(
    "DELETE",
    (params) => `${ITS_RULES_ENDPOINT_ENDING}/${params.id}`,
  ),
  getWorkItems: apiCreator<WorkItemAPITypes["loadRequest"], WorkItemAPITypes["loadResponse"]>(
    "GET",
    WORK_ITEMS_ENDPOINT_ENDING,
  ),
  getWorkItemCount: apiCreator<WorkItemAPITypes["countRequest"], WorkItemAPITypes["countResponse"]>(
    "GET",
    `${WORK_ITEMS_ENDPOINT_ENDING}/count`,
  ),
  getFields: apiCreator<GetWorkItemFieldsRequest, GetWorkItemFieldsResponse>(
    "GET",
    `${AZURE_DEVOPS_ENDPOINT_ENDING}/fields`,
  ),
  importScript: apiCreator<ImportScriptRequest, ImportScriptResponse>(
    "POST",
    (params) => `${SCRIPTS_ENDPOINT_ENDING}/importScript/${params.projectId}`,
    {
      defaultContentType: false,
      bodyBuilder: (params) => {
        let formData = new FormData();
        if (params && params.data) {
          params.data.forEach((d) => formData.append(d.name, d));
        }
        return formData;
      },
    },
  ),
  generateTemplate: apiCreator<GenerateTemplateRequest, GenerateTemplateResponse>(
    "POST",
    `${SCRIPTS_ENDPOINT_ENDING}/generateTemplate`,
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  exportScript: apiCreator<ExportScriptRequest, ExportScriptResponse>(
    "POST",
    `${SCRIPTS_ENDPOINT_ENDING}/exportScript`,
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  getVariableMap: apiCreator<GetVariableMapRequest, GetVariableMapResponse>(
    "GET",
    `${VARIABLE_MAP_ENDPOINT_ENDING}`,
  ),
  getTag: apiCreator<TagAPITypes["loadRequest"], TagAPITypes["loadResponse"]>(
    "GET",
    TAGS_ENDPOINT_ENDING,
  ),
  getTagCount: apiCreator<TagAPITypes["countRequest"], TagAPITypes["countResponse"]>(
    "GET",
    `${TAGS_ENDPOINT_ENDING}/count`,
  ),
  createTag: apiCreator<TagAPITypes["createRequest"], TagAPITypes["createResponse"]>(
    "POST",
    `${TAGS_ENDPOINT_ENDING}`,
  ),
  editTag: apiCreator<Partial<TagAPITypes["editRequest"]>, TagAPITypes["editResponse"]>(
    "PATCH",
    (params) => `${TAGS_ENDPOINT_ENDING}/${params.id}`,
  ),
  deleteTag: apiCreator<TagAPITypes["deleteRequest"], TagAPITypes["deleteResponse"]>(
    "DELETE",
    (params) => `${TAGS_ENDPOINT_ENDING}/${params.id}`,
  ),
  exportProjectsToTestPlans: apiCreator<
    ExportProjectsToTestPlansRequest,
    ExportProjectsToTestPlansResponse
  >("POST", `${INTEGRATION_ENDPOINT_ENDING}/export`),

  getTestPlans: apiCreator<GetTestPlansRequest, GetTestPlansResponse>(
    "GET",
    `${INTEGRATION_ENDPOINT_ENDING}/testPlans`,
  ),

  getTestSuiteSiblings: apiCreator<GetTestSuitesRequest, GetTestSuitesResponse>(
    "GET",
    `${INTEGRATION_ENDPOINT_ENDING}/testSuiteSiblings`,
  ),

  getTestCases: apiCreator<GetTestCasesRequest, GetTestCasesResponse>(
    "GET",
    `${INTEGRATION_ENDPOINT_ENDING}/testCases`,
  ),

  exportScriptToTestCase: apiCreator<ExportScriptToTestCaseRequest, ExportScriptToTestCaseResponse>(
    "POST",
    `${INTEGRATION_ENDPOINT_ENDING}/exportScript`,
  ),
  getAuditTrail: apiCreator<AuditTrailAPITypes["loadRequest"], AuditTrailAPITypes["loadResponse"]>(
    "GET",
    AUDIT_TRAIL_ENDPOINT_ENDING,
  ),
  getAuditTrailCount: apiCreator<
    AuditTrailAPITypes["countRequest"],
    AuditTrailAPITypes["countResponse"]
  >("GET", `${AUDIT_TRAIL_ENDPOINT_ENDING}/count`),
  getAuditTrailScript: apiCreator<
    AuditTrailAPITypes["loadRequest"],
    AuditTrailAPITypes["loadResponse"]
  >("GET", AUDIT_TRAIL_SCRIPT_ENDPOINT_ENDING),
  getAuditTrailScriptCount: apiCreator<
    AuditTrailAPITypes["countRequest"],
    AuditTrailAPITypes["countResponse"]
  >("GET", `${AUDIT_TRAIL_SCRIPT_ENDPOINT_ENDING}/count`),
  getAuditTrailProject: apiCreator<
    AuditTrailAPITypes["loadRequest"],
    AuditTrailAPITypes["loadResponse"]
  >("GET", AUDIT_TRAIL_PROJECT_ENDPOINT_ENDING),
  getAuditTrailProjectCount: apiCreator<
    AuditTrailAPITypes["countRequest"],
    AuditTrailAPITypes["countResponse"]
  >("GET", `${AUDIT_TRAIL_PROJECT_ENDPOINT_ENDING}/count`),
  getAuditTrailSettings: apiCreator<
    AuditTrailAPITypes["loadRequest"],
    AuditTrailAPITypes["loadResponse"]
  >("GET", AUDIT_TRAIL_SETTINGS_ENDPOINT_ENDING),
  getAuditTrailSettingsCount: apiCreator<
    AuditTrailAPITypes["countRequest"],
    AuditTrailAPITypes["countResponse"]
  >("GET", `${AUDIT_TRAIL_SETTINGS_ENDPOINT_ENDING}/count`),
  getDocumentationFile: apiCreator<{ id: number[] }, any>(
    "POST",
    `${DOCUMENTATION_ENDPOINT_ENDING}/file`,
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  getScriptDocumentationFiles: apiCreator<{ id: number[] }, any>(
    "POST",
    `${DOCUMENTATION_ENDPOINT_ENDING}/scriptFiles`,
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  getDocumentation: apiCreator<
    DocumentationAPITypes["loadRequest"],
    DocumentationAPITypes["loadResponse"]
  >("GET", DOCUMENTATION_ENDPOINT_ENDING),
  getDocumentationCount: apiCreator<
    DocumentationAPITypes["countRequest"],
    DocumentationAPITypes["countResponse"]
  >("GET", `${DOCUMENTATION_ENDPOINT_ENDING}/count`),
  editDocumentation: apiCreator<
    Partial<DocumentationAPITypes["editRequest"]>,
    DocumentationAPITypes["editResponse"]
  >("PATCH", (params) => `${DOCUMENTATION_ENDPOINT_ENDING}/${params.id}`),
  deleteDocumentation: apiCreator<
    DocumentationAPITypes["deleteRequest"],
    DocumentationAPITypes["deleteResponse"]
  >("DELETE", (params) => `${DOCUMENTATION_ENDPOINT_ENDING}/${params.id}`),
  getCapturedFiles: apiCreator<GetCapturedFilesRequest, GetCapturedFilesResponse>(
    "GET",
    `${LOGS_ENDPOINT_ENDING}/capturedFiles`,
  ),
  createLocalization: apiCreator<
    LocalizationAPITypes["createRequest"],
    LocalizationAPITypes["createResponse"]
  >("POST", LOCALIZATION_ENDPOINT_ENDING),
  getLocalization: apiCreator<
    LocalizationAPITypes["loadRequest"],
    LocalizationAPITypes["loadResponse"]
  >("GET", LOCALIZATION_ENDPOINT_ENDING),
  getLocalizationCount: apiCreator<
    LocalizationAPITypes["countRequest"],
    LocalizationAPITypes["countResponse"]
  >("GET", `${LOCALIZATION_ENDPOINT_ENDING}/count`),
  editLocalization: apiCreator<
    Partial<LocalizationAPITypes["editRequest"]>,
    LocalizationAPITypes["editResponse"]
  >("PATCH", (params) => `${LOCALIZATION_ENDPOINT_ENDING}/${params.id}`),
  deleteLocalization: apiCreator<
    LocalizationAPITypes["deleteRequest"],
    LocalizationAPITypes["deleteResponse"]
  >("DELETE", (params) => `${LOCALIZATION_ENDPOINT_ENDING}/${params.id}`),
  createReportTemplate: apiCreator<
    ReportTemplateAPITypes["createRequest"],
    ReportTemplateAPITypes["createResponse"]
  >("POST", REPORT_TEMPLATES_ENDPOINT_ENDING),
  getReportTemplates: apiCreator<
    ReportTemplateAPITypes["loadRequest"],
    ReportTemplateAPITypes["loadResponse"]
  >("GET", REPORT_TEMPLATES_ENDPOINT_ENDING),
  getReportTemplatesCount: apiCreator<
    ReportTemplateAPITypes["countRequest"],
    ReportTemplateAPITypes["countResponse"]
  >("GET", `${REPORT_TEMPLATES_ENDPOINT_ENDING}/count`),
  editReportTemplate: apiCreator<
    Partial<ReportTemplateAPITypes["editRequest"]>,
    ReportTemplateAPITypes["editResponse"]
  >("PATCH", (params) => `${REPORT_TEMPLATES_ENDPOINT_ENDING}/${params.id}`),
  deleteReportTemplate: apiCreator<
    ReportTemplateAPITypes["deleteRequest"],
    ReportTemplateAPITypes["deleteResponse"]
  >("DELETE", (params) => `${REPORT_TEMPLATES_ENDPOINT_ENDING}/${params.id}`),
  getTranslations: apiCreator<{ localizationId?: number }, any>(
    "GET",
    `${LOCALIZATION_ENDPOINT_ENDING}/translation`,
  ),
  getDefaultTranslations: apiCreator<any, any>(
    "GET",
    `${LOCALIZATION_ENDPOINT_ENDING}/defaultTranslations`,
  ),
  saveTranslations: apiCreator<{ localizationId?: string; translations }, any>(
    "POST",
    `${LOCALIZATION_ENDPOINT_ENDING}/saveTranslations`,
  ),
  exportTranslations: apiCreator<{ id: number[] }, any>(
    "POST",
    `${LOCALIZATION_ENDPOINT_ENDING}/export`,
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  importTranslation: apiCreator<{ data: File[]; overwriteExisting: boolean }, any>(
    "POST",
    ({ overwriteExisting }) =>
      `${LOCALIZATION_ENDPOINT_ENDING}/import${
        overwriteExisting ? `?overwriteExisting=${overwriteExisting}` : ""
      }`,
    {
      defaultContentType: false,
      bodyBuilder: (params) => {
        let formData = new FormData();
        if (params && params.data) {
          params.data.forEach((d) => formData.append(d.name, d));
        }
        return formData;
      },
    },
  ),

  upsertSchedulerGroups: apiCreator<UpsertSchedulerGroupRequest, UpsertSchedulerGroupResponse>(
    "POST",
    () => `${SCHEDULER_GROUPS_ENDPOINT_ENDING}/upsertAll`,
  ),
  removeSchedulerGroup: apiCreator<RemoveSchedulerGroupRequest, RemoveSchedulerGroupResponse>(
    "POST",
    () => `${SCHEDULER_GROUPS_ENDPOINT_ENDING}/customRemove`,
  ),
  removeSchedulerGroupsAndScripts: apiCreator<
    RemoveSchedulerGroupsAndScriptsRequest,
    RemoveSchedulerGroupsAndScriptsResponse
  >("POST", () => `${SCHEDULER_GROUPS_ENDPOINT_ENDING}/removeAll`),
  replaceAllSchedulerGroups: apiCreator<
    ReplaceAllSchedulerGroupRequest,
    UpsertSchedulerGroupResponse
  >("POST", () => `${SCHEDULER_GROUPS_ENDPOINT_ENDING}/replaceAll`),
  addToSchedulerGroup: apiCreator<
    AddSchedulerScriptsToGroupRequest,
    AddSchedulerScriptsToGroupResponse
  >("POST", () => `${SCHEDULER_GROUPS_ENDPOINT_ENDING}/scripts`),
  getLicense: apiCreator<{}, LicenseResponse>("GET", LICENSE_ENDPOINT_ENDING),
  setNewLicense: apiCreator<{ licenseId: string }, {}>("POST", `${LICENSE_ENDPOINT_ENDING}/id`),
  closeSessions: apiCreator<{ sessionIds: string[] }, any>("POST", "runner/closeSession"),
  logout: apiCreator<{}, {}>("POST", `${USERS_ENDPOINT_ENDING}/logout`),
  getPayPerUseCounter: apiCreator<any, PayPerUseCounterResponse>(
    "GET",
    `${STEP_LOGS_ENDPOINT_ENDING}/getPayPerUseCounter`,
  ),
  getPayPerUseStatistics: apiCreator<PayPerUseStatisticsRequest, PayPerUseStatisticsResponse>(
    "GET",
    `${STEP_LOGS_ENDPOINT_ENDING}/getPayPerUseStatistics`,
  ),
  createSchedulerFlowItem: apiCreator<
    SchedulerFlowAPITypes["createRequest"],
    SchedulerFlowAPITypes["createResponse"]
  >("POST", SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING),
  getSchedulerFlowItems: apiCreator<
    SchedulerFlowAPITypes["loadRequest"],
    SchedulerFlowAPITypes["loadResponse"]
  >("GET", SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING),
  getSchedulerFlowItemCount: apiCreator<
    SchedulerFlowAPITypes["countRequest"],
    SchedulerFlowAPITypes["countResponse"]
  >("GET", `${SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING}/count`),
  editSchedulerFlowItem: apiCreator<
    Partial<SchedulerFlowAPITypes["editRequest"]>,
    SchedulerFlowAPITypes["editResponse"]
  >("PATCH", (params) => `${SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING}/${params.id}`),
  deleteSchedulerFlowItem: apiCreator<
    SchedulerFlowAPITypes["deleteRequest"],
    SchedulerFlowAPITypes["deleteResponse"]
  >("DELETE", (params) => `${SCHEDULER_JOB_MAPPING_ENDPOINT_ENDING}/${params.id}`),
  deleteScreenshots: apiCreator<
    DeleteStepLogsScreenshotsRequest,
    DeleteStepLogsScreenshotsResponse
  >("DELETE", () => `${STEP_LOGS_ENDPOINT_ENDING}/screenshots`),
  getScreenshotsStats: apiCreator<
    GetStepLogsScreenshotsStatsRequest,
    GetStepLogsScreenshotsStatsResponse
  >("GET", () => `${STEP_LOGS_ENDPOINT_ENDING}/screenshotsStats`),
  getScreenshotsAutoCleanup: apiCreator<
    GetStepLogsScreenshotsAutoCleanupRequest,
    GetStepLogsScreenshotsAutoCleanupResponse
  >("GET", () => `${STEP_LOGS_ENDPOINT_ENDING}/screenshotsAutoCleanup`),
  switchScreenshotsAutoCleanup: apiCreator<
    PostStepLogsScreenshotsAutoCleanupRequest,
    PostStepLogsScreenshotsAutoCleanupResponse
  >("POST", () => `${STEP_LOGS_ENDPOINT_ENDING}/screenshotsAutoCleanup`),

  createCodeTemplatesItem: apiCreator<
    CodeTemplatesAPITypes["createRequest"],
    CodeTemplatesAPITypes["createResponse"]
  >("POST", CODE_TEMPLATES_ENDPOINT_ENDING),
  getCodeTemplatesItems: apiCreator<
    CodeTemplatesAPITypes["loadRequest"],
    CodeTemplatesAPITypes["loadResponse"]
  >("GET", CODE_TEMPLATES_ENDPOINT_ENDING),
  getCodeTemplatesItemCount: apiCreator<
    CodeTemplatesAPITypes["countRequest"],
    CodeTemplatesAPITypes["countResponse"]
  >("GET", `${CODE_TEMPLATES_ENDPOINT_ENDING}/count`),
  editCodeTemplatesItem: apiCreator<
    Partial<CodeTemplatesAPITypes["editRequest"]>,
    CodeTemplatesAPITypes["editResponse"]
  >("PATCH", (params) => `${CODE_TEMPLATES_ENDPOINT_ENDING}/${params.id}`),
  deleteCodeTemplatesItem: apiCreator<
    CodeTemplatesAPITypes["deleteRequest"],
    CodeTemplatesAPITypes["deleteResponse"]
  >("DELETE", (params) => `${CODE_TEMPLATES_ENDPOINT_ENDING}/${params.id}`),
  exportCodeTemplate: apiCreator<ExportCodeTemplateRequest, ExportCodeTemplateResponse>(
    "POST",
    `${CODE_TEMPLATES_ENDPOINT_ENDING}/exportCodeTemplate`,
    {
      fileRequest: true,
      isAuthenticated: true,
    },
  ),
  importCodeTemplate: apiCreator<ImportCodeTemplateRequest, ImportCodeTemplateResponse>(
    "POST",
    (params) => `${CODE_TEMPLATES_ENDPOINT_ENDING}/importCodeTemplate`,
    {
      defaultContentType: false,
      bodyBuilder: (params) => {
        let formData = new FormData();
        if (params && params.data) {
          params.data.forEach((d) => formData.append(d.name, d));
        }
        return formData;
      },
    },
  ),
};
