import moment from "moment";
import {
  EndType,
  JOB_TRIGGER_TYPE,
  SchedulerJobRunMode,
  SchedulerJobBasic,
} from "@ea/shared_types/types";
import { getToken, isLoggedIn } from "@ea/shared_components/utils/auth";
import { RecurrenceType } from "@ea/shared_types/types";

export const schedulerFormValuesToJob = (formValues, id?) => {
  if (!isLoggedIn()) {
    return null;
  }
  const { userId } = getToken();
  const {
    startDateTime,
    endDateTime,
    repeatsEvery,
    occurrences,
    endType,
    name,
    description,
    triggerType,
    storage,
    recurring,
    notificationEmails,
    notifyByEmail,
    runMode,
    mode,
    mapResultWithDevops,
    maximumParallelSessions,
    integrationMetadata,
    runParams,
  } = formValues;

  let end = endDateTime && moment(endDateTime);

  if (recurring && endType === EndType.afterOccurrences) {
    end = moment(formValues.startDateTime).add(
      formValues.repeatsEvery.offset * (formValues.occurrences - 1),
      formValues.repeatsEvery.recurrenceType,
    );
  }

  const storageId = storage && storage[0];
  const storageTriggerContainer = storage && storage[1];

  const values = {
    name,
    description,
    occurrences,
    userId,
    mode,
    startDateTime: startDateTime && moment(startDateTime).startOf("minute").toISOString(),
    maximumParallelSessions,
    runMode: runMode || SchedulerJobRunMode.SEQUENTIAL,
    endDateTime: end && end.startOf("minute").toISOString(),
    offset: recurring ? repeatsEvery && repeatsEvery.offset : null,
    recurrenceType: recurring ? repeatsEvery && repeatsEvery.recurrenceType : null,
    triggerType,
    storageId: triggerType === JOB_TRIGGER_TYPE.STORAGE ? storageId : null,
    storageTriggerContainer:
      triggerType === JOB_TRIGGER_TYPE.STORAGE ? storageTriggerContainer : null,
    notificationEmails: notifyByEmail ? notificationEmails : null,
    mapResultWithDevops,
    integrationMetadata,
    runParams,
  };

  if (id) {
    (values as any).id = id;
  }

  return undefinedToNull(values);
};

export const getEndType = ({
  endDateTime,
  recurrenceType,
  occurrences,
}: Partial<Pick<SchedulerJobBasic, "endDateTime" | "recurrenceType" | "occurrences">>) => {
  if (!recurrenceType) {
    return undefined;
  }
  if (!occurrences && !endDateTime) {
    return EndType.never;
  } else if (occurrences) {
    return EndType.afterOccurrences;
  }

  return EndType.onDate;
};

export const jobToFormValues = (job) => {
  const {
    endDateTime,
    startDateTime,
    recurrenceType,
    offset,
    occurrences,
    description,
    name,
    storageId,
    storageTriggerContainer,
    triggerType,
    notificationEmails,
    runMode,
    mode,
    mapResultWithDevops,
    maximumParallelSessions,
    integrationMetadata,
    runParams,
  } = nullToUndefiend(job) as any;

  return {
    occurrences,
    name,
    description,
    runMode: runMode || SchedulerJobRunMode.SEQUENTIAL,
    endDateTime: endDateTime ? moment(endDateTime) : undefined,
    startDateTime: startDateTime ? moment(startDateTime) : undefined,
    recurring: !!offset,
    repeatsEvery: getRepeatsEvery(job),
    triggerType,
    mode,
    endType: getEndType(job),
    storage: [storageId, storageTriggerContainer],
    notificationEmails,
    notifyByEmail: !!notificationEmails,
    mapResultWithDevops,
    maximumParallelSessions,
    integrationMetadata,
    runParams,
  };
};

export const getRepeatsEvery = ({ offset, recurrenceType: type }) => {
  const recurrenceType = type || RecurrenceType.hours;
  return {
    offset,
    recurrenceType,
  };
};

export const nullToUndefiend = (object) => {
  const out = { ...object };
  Object.keys(object).forEach((key) => {
    if (object[key] === null) {
      out[key] = undefined;
    }
  });
  return out;
};

export const undefinedToNull = (object) => {
  const out = { ...object };
  Object.keys(object).forEach((key) => {
    if (object[key] === undefined) {
      out[key] = null;
    }
  });
  return out;
};

export const createStorageSelectOptions = (storages) =>
  storages.map(({ id, name, containers }) => {
    const children = containers.map((container) => ({
      value: container.name,
      label: container.name,
    }));
    return {
      value: id,
      label: name,
      children,
      disabled: children.length === 0,
    };
  });
