import {
  Step,
  VariableType,
  EntityType,
  Variable,
  AssignedVariableType,
} from "@ea/shared_types/types";
import { getStepCommand } from "@app/packs/packs.helpers";
import { DEFAULT_PLATFORM_ID } from "@ea/runner_loader/ea.internal.types";
import { API } from "@app/services/api/api";

export const isVariableNew = (value) => value !== undefined && value !== "" && isNaN(value);

export const detectAndCreateNewVariables = async (
  value: Step["value"],
  commandId: string,
  scriptId: number,
  platformId?: string,
): Promise<Variable[] | null> => {
  if (value?.code?.assignedVariableType === AssignedVariableType.GLOBAL) {
    return null;
  }
  const command = getStepCommand({
    platform: { id: platformId || DEFAULT_PLATFORM_ID },
    commandId,
  });

  if (!command) {
    return null;
  }

  const codeValues = command.getCodeValues ? command.getCodeValues(value) : [];

  if (!codeValues || !codeValues.length) {
    return null;
  }

  const variablesMap = {};

  codeValues.forEach((c, index) => {
    if (c && c.assignedVariableId) {
      variablesMap[c.assignedVariableId] = index;
    }
  });

  const assignedVariablesIds = codeValues
    .filter((a) => a)
    .map((c) => c.assignedVariableId)
    .filter((a) => a)
    .filter((a) => isVariableNew(a));

  let createdVariables: Variable[] = [];

  for (const assignedVariableId of assignedVariablesIds) {
    const createdVariable = await API.patchVariable({
      type: VariableType.Normal,
      name: assignedVariableId as any as string,
      parentType: EntityType.SCRIPT,
      parentId: scriptId,
    });
    createdVariables.push(createdVariable);
    codeValues[variablesMap[assignedVariableId!]].assignedVariableId = createdVariable.id;
  }

  return createdVariables.length > 0 ? createdVariables : null;
};
