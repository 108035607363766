import * as React from "react";
import { getIn } from "final-form";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { CheckboxField } from "@ea/shared_components";
import { SelectField } from "@ea/shared_components/";
import {
  PlayerVisibility,
  ScriptSpecialExecutionModes,
  SaveVideoCondition,
} from "@ea/shared_types";
import { DataTestIds } from "@app/utils/dataTestIds";
import { InputNumberField } from "@ea/shared_components/src";
import { SchedulerJobRunMode } from "@ea/shared_types/types";
import { isDevelopment } from "@ea/shared_components/utils/env";

interface ISpecialExecutionModeFormChunkProps {
  values: any;
  change: (name: string, value: any) => void;
  prefix?: string;
  readOnly?: boolean;
  runMode?: SchedulerJobRunMode;
}

type Props = ISpecialExecutionModeFormChunkProps;

const documentOnlyOptions = [
  {
    value: ScriptSpecialExecutionModes.DOCUMENT,
    text: getTranslationKey("playMode", "document"),
    type: ScriptSpecialExecutionModes.DOCUMENT,
  },
];

const specialModeOptions = [
  ...documentOnlyOptions,
  {
    value: ScriptSpecialExecutionModes.VIDEO,
    text: getTranslationKey("playMode", "video"),
    type: ScriptSpecialExecutionModes.VIDEO,
  },
  {
    value: ScriptSpecialExecutionModes.DOC_AND_VIDEO,
    text: getTranslationKey("playMode", "docAndVideo"),
    type: ScriptSpecialExecutionModes.DOC_AND_VIDEO,
  },
];

const playerVisibilityOptions = [
  {
    value: PlayerVisibility.VISIBLE,
    text: getTranslationKey("playMode", "visible"),
    type: PlayerVisibility.VISIBLE,
  },
  {
    value: PlayerVisibility.HIDDEN,
    text: getTranslationKey("playMode", "hidden"),
    type: PlayerVisibility.HIDDEN,
  },
  {
    value: PlayerVisibility.ONLY_CURRENT_STEP,
    text: getTranslationKey("playMode", "onlyCurrentStep"),
    type: PlayerVisibility.ONLY_CURRENT_STEP,
  },
];

const saveVideoConditionOptions = [
  {
    value: SaveVideoCondition.ON_SUCCESS,
    text: getTranslationKey("playMode", "onSuccess"),
    type: SaveVideoCondition.ON_SUCCESS,
  },
  {
    value: SaveVideoCondition.ON_ERROR,
    text: getTranslationKey("playMode", "onError"),
    type: SaveVideoCondition.ON_ERROR,
  },
  {
    value: SaveVideoCondition.ALWAYS,
    text: getTranslationKey("playMode", "always"),
    type: SaveVideoCondition.ALWAYS,
  },
];
const SpecialExecutionModeFormChunk: React.FunctionComponent<Props> = ({
  readOnly,
  values,
  change,
  prefix,
  runMode,
}) => {
  const getFieldName = (key) => `${prefix ? `${prefix}.` : ""}${key}`;
  const getFormValue = (key) => getIn(values, getFieldName(key));

  const useSpecialMode = getFormValue("useSpecialMode");
  const isVideoType =
    getFormValue("specialMode") === ScriptSpecialExecutionModes.VIDEO ||
    getFormValue("specialMode") === ScriptSpecialExecutionModes.DOC_AND_VIDEO;

  return (
    <>
      <CheckboxField
        name={getFieldName("useSpecialMode")}
        label={getTranslationKey("playMode", "documentation")}
        readOnly={readOnly}
        onChange={() => {
          change(getFieldName("specialMode"), null);
          change(getFieldName("playerVisibilityMode"), null);
          change(getFieldName("slowExecution"), 0);
          change(getFieldName("saveVideoCondition"), null);
        }}
        data-testid={DataTestIds.FORM_CHECKBOX_DOCUMENTATION_MODE}
      />
      {useSpecialMode && (
        <SelectField
          name={getFieldName("specialMode")}
          placeholder={getTranslationKey("common", "placeholder", "selectType")}
          label={getTranslationKey("common", "label", "specialMode")}
          options={
            runMode === SchedulerJobRunMode.PARALLEL && !isDevelopment()
              ? documentOnlyOptions
              : specialModeOptions
          }
          readOnly={readOnly}
          required
          onChange={() => {
            change(getFieldName("playerVisibilityMode"), null);
            change(getFieldName("slowExecution"), 0);
          }}
          data-testid={DataTestIds.FORM_SELECT_DOCUMENTATION_MODE}
        />
      )}
      {isVideoType && (
        <>
          <SelectField
            name={getFieldName("playerVisibilityMode")}
            defaultValue={PlayerVisibility.HIDDEN}
            placeholder={getTranslationKey("common", "placeholder", "selectView")}
            label={getTranslationKey("common", "label", "playerView")}
            options={playerVisibilityOptions}
            readOnly={readOnly}
            data-testid={DataTestIds.FORM_SELECT_PLAYER_VISIBILITY}
          />
          <InputNumberField
            name={getFieldName("slowExecution")}
            defaultValue={0}
            placeholder={getTranslationKey("common", "placeholder", "slowExecution")}
            label={getTranslationKey("common", "label", "slowExecution")}
            readOnly={readOnly}
            data-testid={DataTestIds.FORM_SELECT_SLOW_EXECUTION}
          />
          <SelectField
            name={getFieldName("saveVideoCondition")}
            label={getTranslationKey("common", "label", "saveVideoCondition")}
            defaultValue={SaveVideoCondition.ON_SUCCESS}
            options={saveVideoConditionOptions}
            readOnly={readOnly}
            data-testid={DataTestIds.FORM_SELECT_SAVE_VIDEO_CONDITION}
          />
        </>
      )}
    </>
  );
};

export default SpecialExecutionModeFormChunk;
