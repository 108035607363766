import { Select as AntdSelect, Tooltip } from "antd";
import { SelectProps } from "antd/lib/select";
import React from "react";

import { ReadonlyFormItem, SelectableFormItem } from "../Form.common";
import createFormField from "../createFormField";
import withReadonly from "./withReadonly";
import { GetComponentProps } from "../../react.types";
import { FormattedMessage } from "react-intl";
import { DataTestIds } from "../../utils/dataTestHelpers";

const SelectWithReadonly = withReadonly(AntdSelect);
const Option = AntdSelect.Option;

const Select: React.SFC<SelectProps<any> & SelectableFormItem & ReadonlyFormItem> = ({
  options,
  children,
  ...rest
}) => (
  <SelectWithReadonly {...rest}>
    {options
      ? options.map((option, index) => (
          <Option
            {...option}
            value={option.value || option.key}
            data-testid={DataTestIds.getOptionTestId(option.text || option.value?.toString())}
            key={
              option.key
                ? option.key.toString()
                : option.value
                ? option.value.toString()
                : index.toString()
            }
          >
            {option.tooltip ? (
              <Tooltip placement="right" title={option.tooltip} mouseEnterDelay={1}>
                <FormattedMessage id={option.text || option.value.toString()} />
              </Tooltip>
            ) : (
              <FormattedMessage id={option.text || option.value.toString()} />
            )}
          </Option>
        ))
      : children}
  </SelectWithReadonly>
);

export const SelectField = createFormField<
  SelectProps<any> & SelectableFormItem & ReadonlyFormItem
>(Select, ({ name, onChange, value, onBlur, onFocus, ...rest }) => ({
  ...rest,
  onChange: (v) => {
    v === undefined ? onChange(null) : onChange(v);
  },
  value,
  onBlur: onBlur as any,
  onFocus: onFocus as any,
}));
export type SelectFieldProps = GetComponentProps<typeof SelectField>;

export default SelectField;
