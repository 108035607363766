import * as React from "react";

import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import ToggleCommandBarButton from "@ea/shared_components/CommandBar/ToggleCommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

interface SchedulerCommandBarProps {
  onNewClick: () => void;
  onRemoveClick: (ids: number[]) => void;
  onEditClick: () => void;
  onReload: () => void;
  onOpen: () => void;
  selected: number[];
  showClosed: boolean;
  onShowClosedClick: () => void;
  showInternal: boolean;
  onShowInternalClick: () => void;
  onlyClosedSelected: boolean;
  onlyInternalSelected: boolean;
}

class SchedulerCommandBar extends React.Component<SchedulerCommandBarProps> {
  public render() {
    return (
      <CommandBar data-testid={DataTestIds.COMMANDBAR_SCHEDULER}>
        <CommandBarButton
          name="new"
          onClick={this.props.onNewClick}
          text={getTranslationKey("commandBar", "add")}
          icon="PlusOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_NEW}
        />
        <CommandBarButton
          name="remove"
          onClick={() => this.props.onRemoveClick(this.props.selected)}
          text={getTranslationKey("commandBar", "removeClose")}
          icon="DeleteOutlined"
          disabled={
            this.props.selected.length === 0 ||
            this.props.onlyClosedSelected ||
            this.props.onlyInternalSelected
          }
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="refresh"
          onClick={this.props.onReload}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <ToggleCommandBarButton
          name="showClosed"
          onClick={this.props.onShowClosedClick}
          text={getTranslationKey(
            "commandBar",
            this.props.showClosed ? "hideClosed" : "showClosed",
          )}
          icon="LockOutlined"
          toggled={this.props.showClosed}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_SHOW_CLOSED}
        />
        <ToggleCommandBarButton
          name="showInternal"
          onClick={this.props.onShowInternalClick}
          text={getTranslationKey(
            "commandBar",
            this.props.showInternal ? "hideInternal" : "showInternal",
          )}
          icon="ClusterOutlined"
          toggled={this.props.showInternal}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_SHOW_INTERNAL}
        />
        <CommandBarButton
          name="open"
          onClick={this.props.onOpen}
          text={getTranslationKey("commandBar", "open")}
          icon="LoginOutlined"
          disabled={this.props.selected.length !== 1}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN}
          far
        />
      </CommandBar>
    );
  }
}

export default SchedulerCommandBar;
