import Select from "antd/lib/select";
import * as React from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { OptionType } from "@ea/shared_components/Form/Form.common";

import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import {
  JOB_TRIGGER_TYPE,
  EndType,
  SchedulerJobRunMode,
  SchedulerMode,
  ROLES,
} from "@ea/shared_types/types";
import { DatePickerField } from "@ea/shared_components/Form/Fields/DatePickerField";
import { SwitchField } from "@ea/shared_components/Form/Fields/SwitchField";
import { RepeatEveryField } from "@app/modules/scheduler/components/RepeatEveryField";
import { RadioType, RadioField } from "@ea/shared_components/Form/Fields/RadioField";
import { InputNumberField } from "@ea/shared_components/Form/Fields/InputNumberField";
import { isDateInPast, getDisabledTimeRanges } from "@ea/shared_components/utils/date";
import { PickerField } from "@ea/shared_components/Form/Fields/PickerField";
import { CascaderOptionType } from "antd/lib/cascader";
import { CheckboxField } from "@ea/shared_components/Form/Fields/CheckboxField";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import { getIn } from "final-form";
import WithAuthorization from "@app/modules/common/WithAuthorization";
import { DataTestIds } from "@app/utils/dataTestIds";
import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { CaretRightOutlined } from "@ant-design/icons";

interface ICreateSchedulerJobFormProps {
  readOnly?: boolean;
  values: any;
  storageOptions?: CascaderOptionType[];
  change: (name: string, value: any) => void;
  configurationOptions?: (OptionType & { project: string })[];
  environmentOptions?: OptionType[];
  virtualUserOptions?: OptionType[];
}

interface ICreateSchedulerJobFormState {}

type Props = ICreateSchedulerJobFormProps & InjectedIntlProps;
const AuthorizedCheckboxField = WithAuthorization([ROLES.admin, ROLES.logs], [])(CheckboxField);
const AdminSelectField = WithAuthorization([ROLES.admin], [])(SelectField);
const AdminCollapse = WithAuthorization([ROLES.admin], [])(Collapse);

class CreateSchedulerJobForm extends React.Component<Props, ICreateSchedulerJobFormState> {
  triggerTypes;

  constructor(props: Props) {
    super(props);

    this.triggerTypes = [
      {
        text: getTranslationKey("scheduler", "settings", JOB_TRIGGER_TYPE.TIME),
        value: JOB_TRIGGER_TYPE.TIME,
      },
      {
        text: getTranslationKey("scheduler", "settings", JOB_TRIGGER_TYPE.STORAGE),
        value: JOB_TRIGGER_TYPE.STORAGE,
      },
    ];
  }

  disabledEndDate = (endValue) => {
    const { values } = this.props;
    const startValue = getIn(values, "startDateTime");

    if (!endValue || !startValue) {
      return true;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onStartChange = (startValue) => {
    const { change, values } = this.props;
    const endValue = getIn(values, "endDateTime");

    if (endValue && startValue.valueOf() >= endValue.valueOf()) {
      change("endDateTime", undefined);
    }
  };

  renderEndingComponent = () => {
    const { values } = this.props;
    const endType = getIn(values, "endType");

    switch (endType) {
      case EndType.afterOccurrences:
        return (
          <InputNumberField
            name="occurrences"
            required
            min={1}
            label={getTranslationKey("scheduler", "settings", "occurrences")}
            data-testid={DataTestIds.FORM_INPUT_OCCURRENCES}
          />
        );
      case EndType.onDate:
        return (
          <DatePickerField
            required
            name="endDateTime"
            data-testid={DataTestIds.FORM_DATEPICKER_END_DATE_TIME}
            disabledDate={this.disabledEndDate}
            label={getTranslationKey("scheduler", "settings", "endDateTime")}
            showTime={{ format: "HH:mm" }}
            timeFormat="YYYY-MM-DD HH:mm"
          />
        );
      default:
        return null;
    }
  };

  renderTriggerSpecificElements = () => {
    const { readOnly, values, change } = this.props;
    const triggerType = getIn(values, "triggerType");
    const recurring = getIn(values, "recurring");

    switch (triggerType) {
      case JOB_TRIGGER_TYPE.TIME:
        return (
          <>
            <SwitchField
              name="recurring"
              data-testid={DataTestIds.FORM_SWITCH_RECURRING}
              label={getTranslationKey("scheduler", "settings", "recurring")}
            />
            {recurring && (
              <>
                <RepeatEveryField
                  name="repeatsEvery"
                  data-testid={DataTestIds.FORM_INPUT_REPEAT_EVERY}
                  readOnly={readOnly}
                  min={1}
                  required
                  label={getTranslationKey("scheduler", "settings", "repeatsEvery")}
                />
                <RadioField
                  name="endType"
                  data-testid={DataTestIds.FORM_RADIO_END_TYPE}
                  label={getTranslationKey("scheduler", "settings", "endType")}
                  type={RadioType.Normal}
                  required
                  onChange={(e) => {
                    if (e.target.value === EndType.never) {
                      change("endDateTime", undefined);
                      change("occurrences", undefined);
                    }
                    if (e.target.value === EndType.onDate) {
                      change("occurrences", undefined);
                    }
                  }}
                  options={[
                    {
                      text: getTranslationKey("scheduler", "settings", EndType.never),
                      value: EndType.never,
                    },
                    {
                      text: getTranslationKey("scheduler", "settings", EndType.afterOccurrences),
                      value: EndType.afterOccurrences,
                    },
                    {
                      text: getTranslationKey("scheduler", "settings", EndType.onDate),
                      value: EndType.onDate,
                    },
                  ]}
                />
                {this.renderEndingComponent()}
              </>
            )}
          </>
        );
      case JOB_TRIGGER_TYPE.STORAGE:
        return (
          <PickerField
            name="storage"
            data-testid={DataTestIds.FORM_SELECT_STORAGE}
            label={getTranslationKey("scheduler", "settings", "storage")}
            options={this.props.storageOptions || []}
            required
          />
        );
      default:
        return null;
    }
  };

  customRender = (option: any): JSX.Element => {
    return (
      <Select.Option value={option.value} key={option.value}>
        <span> {option.text}</span>
      </Select.Option>
    );
  };

  filterConfigurationOptions = () => {
    const { values, configurationOptions } = this.props;

    const selectedConfigurations =
      getIn(values, "integrationMetadata.azureDevops.configurations") || [];
    const selectedConfigurationsProjects = selectedConfigurations.map(
      (sC) => configurationOptions?.find((cO) => cO.value === sC)?.project,
    );

    return (configurationOptions || []).filter((cO) => {
      return (
        selectedConfigurations.includes(cO.value) ||
        (!selectedConfigurations.includes(cO.value) &&
          !selectedConfigurationsProjects.includes(cO.project))
      );
    });
  };

  render() {
    const { intl, values, virtualUserOptions, environmentOptions } = this.props;

    const notifyByEmail = getIn(values, "notifyByEmail");
    const mode = getIn(values, "mode");
    const runMode = getIn(values, "runMode");
    const mapResultWithDevops = getIn(values, "mapResultWithDevops");
    const { emailListValiadtor } = validators(intl);

    return (
      <>
        <InputField
          name="name"
          data-testid={DataTestIds.FORM_INPUT_NAME}
          required
          placeholder={getTranslationKey("scheduler", "label", "jobName")}
          label={getTranslationKey("common", "label", "name")}
        />
        <RadioField
          name="mode"
          data-testid={DataTestIds.FORM_RADIO_MODE}
          required
          defaultValue={SchedulerMode.BASIC}
          label={getTranslationKey("common", "label", "mode")}
          options={[
            {
              text: getTranslationKey("scheduler", "mode", "basic"),
              value: SchedulerMode.BASIC,
            },
            {
              text: getTranslationKey("scheduler", "mode", "gantt"),
              value: SchedulerMode.GANTT,
            },
          ]}
        />
        {mode !== SchedulerMode.GANTT && (
          <RadioField
            name="runMode"
            required
            data-testid={DataTestIds.FORM_RADIO_RUN_MODE}
            label={getTranslationKey("common", "label", "runMode")}
            options={[
              {
                text: getTranslationKey("scheduler", "runMode", "sequential"),
                value: SchedulerJobRunMode.SEQUENTIAL,
              },
              {
                text: getTranslationKey("scheduler", "runMode", "parallel"),
                value: SchedulerJobRunMode.PARALLEL,
              },
            ]}
          />
        )}
        <TextAreaField
          name="description"
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
          autoSize={{ minRows: 2, maxRows: 6 }}
          label={getTranslationKey("common", "label", "description")}
          placeholder={getTranslationKey("common", "placeholder", "description")}
        />
        {mode !== SchedulerMode.GANTT && (
          <>
            <DatePickerField
              label={getTranslationKey("scheduler", "settings", "startDateTime")}
              placeholder={getTranslationKey("scheduler", "placeholder", "startDate")}
              name="startDateTime"
              showTime={{ format: "HH:mm" }}
              timeFormat="YYYY-MM-DD HH:mm"
              disabledDate={isDateInPast}
              disabledTime={getDisabledTimeRanges}
              onChange={this.onStartChange}
              data-testid={DataTestIds.FORM_DATEPICKER_START_DATE_TIME}
              required
            />
            <SelectField
              name="triggerType"
              placeholder={getTranslationKey("scheduler", "placeholder", "triggerType")}
              label={getTranslationKey("scheduler", "label", "triggerType")}
              data-testid={DataTestIds.FORM_SELECT_TRIGGER_TYPE}
              options={this.triggerTypes}
            />
            {this.renderTriggerSpecificElements()}
          </>
        )}
        {runMode === SchedulerJobRunMode.PARALLEL && (
          <InputNumberField
            name="maximumParallelSessions"
            data-testid={DataTestIds.FORM_INPUT_MAX_PARALLEL_SESSIONS}
            min={0}
            label={getTranslationKey("scheduler", "label", "maximumParallelSessions")}
          />
        )}
        <CheckboxField
          name="notifyByEmail"
          data-testid={DataTestIds.FORM_CHECKBOX_NOTIFY_BY_EMAIL}
          label={getTranslationKey("scheduler", "settings", "notifyByEmail")}
        />
        {notifyByEmail && (
          <InputField
            name="notificationEmails"
            data-testid={DataTestIds.FORM_INPUT_NOTIFICATION_EMAIL}
            label={getTranslationKey("scheduler", "settings", "notificationEmails")}
            placeholder={getTranslationKey("scheduler", "placeholder", "notificationEmails")}
            validate={composeValidators(emailListValiadtor)}
          />
        )}
        <AuthorizedCheckboxField
          name="mapResultWithDevops"
          label={getTranslationKey("playMode", "mapWithDevops")}
          data-testid={DataTestIds.FORM_CHECKBOX_MAP_TO_DEVOPS}
        />
        {mapResultWithDevops && (
          <SelectField
            allowClear
            name={`integrationMetadata.azureDevops.configurations`}
            label={getTranslationKey("projects", "details", "label", "configuration")}
            placeholder={getTranslationKey("projects", "details", "placeholder", "configuration")}
            mode="multiple"
            data-testid={DataTestIds.FORM_SELECT_AZURE_CONFIGURATIONS}
          >
            {this.filterConfigurationOptions().map((configuration) => (
              <Select.Option key={configuration.value} value={configuration.value}>
                {configuration.text}
              </Select.Option>
            ))}
          </SelectField>
        )}
        <AdminCollapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          <CollapsePanel
            header={<FormattedMessage id={getTranslationKey("commandBar", "override")} />}
            key="debugFields"
          >
            <AdminSelectField
              allowClear
              name={`runParams.environmentId`}
              label={getTranslationKey("common", "label", "environment")}
              placeholder={getTranslationKey("common", "placeholder", "environment")}
              data-testid={DataTestIds.FORM_SELECT_SCHEDULER_ENVIRONMENT_OVERRIDE}
            >
              {(environmentOptions || []).map(({ value, text }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </AdminSelectField>
            <AdminSelectField
              allowClear
              name={`runParams.virtualUserId`}
              label={getTranslationKey("common", "label", "virtualUser")}
              placeholder={getTranslationKey("common", "placeholder", "virtualUser")}
              data-testid={DataTestIds.FORM_SELECT_SCHEDULER_VU_OVERRIDE}
            >
              {(virtualUserOptions || []).map(({ value, text }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </AdminSelectField>
          </CollapsePanel>
        </AdminCollapse>
      </>
    );
  }
}

export default injectIntl(CreateSchedulerJobForm);
