import { ModuleActions } from "./common.data.actions";
import { Item } from "@ea/shared_types/types";
import { ApplicationState } from "./state";
import { Query, Filter } from "../services/api.common.models";
import { OptionalRequestParams } from "@ea/shared_types/api.types";
import { makeAsyncEpic } from "./makeAsyncEpic";
import { ActionsObservable } from "redux-observable";
import { Store, Action } from "redux";
import { SelectMode } from "./common.models";
import { Observable } from "rxjs/Observable";
import { createGlobalErrorAction } from "./common.actions";

type Handlers<T extends Item<any>, State> = {
  getRequestParams: (state: State, tableId: string) => Query<T>;
  getSingleRequestParams: (query: Query<T>, state: State) => Query<T>;
  normalizeItem?: (item: T, state: State, mode: "SINGLE" | "ALL") => T;
  api: {
    getItems: (params: Query<T>, optionalParams?: OptionalRequestParams) => Promise<T[]>;
    getItemsCount?: (
      params: Filter<T> | undefined,
      optionalParams?: OptionalRequestParams,
    ) => Promise<{ count: number }>;
    createItem: (params: Partial<T>, optionalParams?: OptionalRequestParams) => Promise<T>;
    deleteItem: (
      params: { id: T["id"]; options: any },
      optionalParams?: OptionalRequestParams,
    ) => Promise<any>;
    editItem: (params: T, optionalParams?: OptionalRequestParams) => Promise<T>;
  };
};

const DEBOUNCE_TIME = 500;

export const createModuleEpics =
  <State>() =>
  <T extends Item<any>>(actionCreators: ModuleActions<T>, handlers: Handlers<T, State>) => {
    const isPaginated = handlers.api.getItemsCount !== undefined;

    const epics = {
      load: makeAsyncEpic<State>()(actionCreators.table.load, async (payload, state) => {
        const requestParams = handlers.getRequestParams(state, payload.tableId);
        const response = await handlers.api.getItems(
          requestParams,
          payload.reload ? { cache: "reload" } : undefined,
        );
        const normalizer = handlers.normalizeItem || ((item) => item);
        return response.map((item) => normalizer(item, state, "ALL"));
      }),
      loadPagination: makeAsyncEpic<State>()(
        actionCreators.table.loadPaginationConfig,
        async (payload, state) => {
          if (!isPaginated) {
            throw new Error("Pagination is not supported for this module");
          }

          const requestParams = handlers.getRequestParams(state, payload.tableId);
          const result = await handlers.api.getItemsCount!(requestParams.filter);

          return result;
        },
      ),
      loadSingle: makeAsyncEpic<State>()(actionCreators.data.loadSingle, async (payload, state) => {
        const params: Query<T> = {
          filter: {
            where: {
              id: payload.id,
            } as any, // how to tell Typescript that id exists on T? T extends Item doesn't work
          },
        };

        const response = await handlers.api.getItems(
          handlers.getSingleRequestParams(params, state),
        );

        const normalizer = handlers.normalizeItem || ((item) => item);
        return response.map((item) => normalizer(item, state, "SINGLE"))[0];
      }),
      create: makeAsyncEpic<State>()(actionCreators.table.create, (payload) =>
        handlers.api.createItem(payload.item),
      ),
      delete: makeAsyncEpic<State>()(actionCreators.table.delete, (payload) =>
        Promise.all(
          payload.ids.map(async (id) => handlers.api.deleteItem({ id, options: payload.options })),
        ),
      ),
      edit: makeAsyncEpic<State>()(actionCreators.data.edit, (payload) =>
        handlers.api.editItem(payload),
      ),
      reloadFilters: (action$: ActionsObservable<Action>, store: Store<ApplicationState>) =>
        action$
          .ofType(
            actionCreators.table.setFilter,
            actionCreators.table.setFilters,
            actionCreators.table.clearFilters,
            actionCreators.table.setPersistentQuery,
          )
          .groupBy((_: any) => _.payload.tableId)
          .mergeMap((group$) =>
            group$.debounceTime(DEBOUNCE_TIME).map((_: any) =>
              isPaginated
                ? actionCreators.table.loadPaginationConfig.started({
                    tableId: _.payload.tableId,
                  })
                : actionCreators.table.load.started({ tableId: _.payload.tableId }),
            ),
          ),
      unselectAll: (action$: ActionsObservable<Action>, store: Store<State>) =>
        action$
          .ofType(
            actionCreators.table.setFilter,
            actionCreators.table.setFilters,
            actionCreators.table.clearFilters,
            actionCreators.table.setPersistentQuery,
          )
          .groupBy((_: any) => _.payload.tableId)
          .mergeMap((group$) =>
            group$.debounceTime(DEBOUNCE_TIME).map((_: any) =>
              actionCreators.table.select({
                tableId: _.payload.tableId,
                mode: SelectMode.Replace,
                ids: [],
              }),
            ),
          ),
      selectAllItems: (action$: ActionsObservable<Action>, store: Store<State>) =>
        action$
          .ofType(actionCreators.table.select)
          .filter((action: any) => action.payload.mode === SelectMode.SelectAll)
          .groupBy((_: any) => _.payload.tableId)
          .mergeMap((group$: any) =>
            group$.debounceTime(DEBOUNCE_TIME).mergeMap((_: any) => {
              const getAllIds = async (): Promise<number[]> => {
                const requestParams = handlers.getRequestParams(
                  store.getState(),
                  _.payload.tableId,
                );
                (requestParams as any).fields = ["id"];
                if (requestParams.filter) {
                  if (requestParams.filter.offset) {
                    delete requestParams.filter.offset;
                  }
                  if (requestParams.filter.limit) {
                    delete requestParams.filter.limit;
                  }
                }

                const response = await handlers.api.getItems(requestParams);

                return response.map((r) => parseInt(r.id, 10));
              };
              return Observable.fromPromise(getAllIds())
                .map((result) =>
                  actionCreators.table.select({
                    tableId: _.payload.tableId,
                    mode: SelectMode.Replace,
                    ids: result,
                  }),
                )
                .catch((err) => Observable.of(createGlobalErrorAction(err)));
            }),
          ),
      reloadItems: (action$: ActionsObservable<Action>, store: Store<State>) =>
        action$
          .ofType(
            actionCreators.table.changePage,
            actionCreators.table.changeSorting,
            actionCreators.table.clearSorting,
            actionCreators.table.changePageSize,
            actionCreators.table.reload,
          )
          .groupBy((_: any) => _.payload.tableId)
          .mergeMap((group$) =>
            group$
              .debounceTime(DEBOUNCE_TIME)
              .map((_: any) => actionCreators.table.load.started({ tableId: _.payload.tableId })),
          ),
      asyncReloadItems: (action$: ActionsObservable<Action>, store: Store<State>) =>
        action$
          .ofType(actionCreators.table.loadPaginationConfig.done, actionCreators.table.create.done)
          .map((_: any) =>
            actionCreators.table.load.started({
              tableId: _.payload.params.tableId,
              backgroundReload: _.payload.params.backgroundReload,
            }),
          ),
      asyncClearReloadItemsOnDelete: (action$: ActionsObservable<Action>, store: Store<State>) =>
        action$.ofType(actionCreators.table.delete.done).map((_: any) =>
          actionCreators.table.load.started({
            tableId: _.payload.params.tableId,
            backgroundReload: _.payload.params.backgroundReload,
            clearPrevious: true,
          }),
        ),
    };

    return epics;
  };
