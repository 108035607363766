import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import {
  DOCUMENTATION_TABLES_CONFIG,
  DOCUMENTATION_SCRIPT_COLUMNS,
  DOCUMENTATION_PROJECT_COLUMNS,
} from "./documentation.table";
import {
  documentationDataSelectors,
  getSelectedPendingDocumentations,
  getNonPendingDocumentations,
} from "./documentation.selectors";
import { getDocumentationTableActions, documentationActions } from "./documentation.actions";
import DocumentationCommandBar from "./components/DocumentationCommandBar";
import CreateEditDocumentation from "./EditDocumentation.container";
import { withRouter, RouteComponentProps } from "react-router";
import { API } from "@app/services/api/api";
import { isCurrentUserDocumentationReader } from "@ea/shared_components/auth/auth.selectors";
import { DocumentationStatus, RunnerMode } from "@ea/shared_types/types";
import { disabledFeaturesSelector } from "../globalSettings/globalSettings.selectors";
import { ROUTES } from "@app/routes";
// import { startSession } from "@ea/shared_components/utils/start";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";
import { startSession } from "@app/utils/start";
import { formatScriptName } from "@app/utils/script";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
});

interface IDocumentationTableProps extends RouteComponentProps<any> {
  projectId: number;
}

interface IDocumentationTableState {
  editWindowVisibility: boolean;
  persistentQuery: any;
  isDownloadingDoc: boolean;
}

class DocumentationProjectTableContainer extends React.Component<
  IConnectProps,
  IDocumentationTableState
> {
  connectedTable: any;

  constructor(props: IConnectProps) {
    super(props);

    this.state = {
      editWindowVisibility: false,
      isDownloadingDoc: false,
      persistentQuery: {
        projectId: props.projectId,
      },
    };

    if (props.user?.settings?.displayOnlyPublishedDocuments) {
      this.state.persistentQuery.status = DocumentationStatus.PUBLISHED;
    }
  }

  componentDidUpdate(prevProps: IDocumentationTableProps & IConnectProps) {
    if (prevProps.projectId !== this.props.projectId) {
      this.setState({
        persistentQuery: {
          ...this.state.persistentQuery,
          projectId: this.props.projectId,
        },
      });
    }
  }

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = (edited?) => {
    if (edited && this.props.selectedDocumentation) {
      this.props.actions.loadSingle({ id: this.props.selectedDocumentation.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openDocumentation = (item) => {
    this.props.history.push(`${ROUTES.scripts}/${item.script.id}${ROUTES.documentation}/`);
  };

  reload = () => {
    if (this.connectedTable?.reload) {
      this.connectedTable.reload();
    }
  };

  downloadDoc = async () => {
    const { selectedDocumentation, selectedNonPendingDocumentations } = this.props;

    if (selectedNonPendingDocumentations?.length > 0) {
      this.setState({ isDownloadingDoc: true });

      const blob = await API.getDocumentationFile({
        id: selectedNonPendingDocumentations.map((wP) => wP.id),
      });

      if (selectedDocumentation) {
        const chunks = selectedDocumentation.path.split(".");
        const ext = chunks[chunks.length - 1];
        downloadFileBlob(blob, `${selectedDocumentation.prettyName}.${ext}`);
      } else {
        downloadFileBlob(blob, `Documentation.zip`);
      }

      this.setState({ isDownloadingDoc: false });
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  playScript = () => {
    if (!this.props.selectedDocumentation) {
      return;
    }

    startSession("", {
      isBackground: false,
      mode: RunnerMode.PLAYER,
      script: this.props.selectedDocumentation.script.id,
    });
  };

  render() {
    const { editWindowVisibility, persistentQuery, isDownloadingDoc } = this.state;
    const {
      selectedDocumentation,
      selected,
      user,
      projectId,
      isDocumentationReader,
      disabledFeatures,
      confirmPublishingWithCredentials,
      selectedDocumentations,
      selectedPendingDocumentations,
    } = this.props;

    return (
      <Container>
        <DocumentationCommandBar
          onRemoveClick={this.remove}
          selected={selected}
          onEditClick={this.openEditWindow}
          onDocumentationDownload={this.downloadDoc}
          onReload={this.reload}
          isDownloadingDoc={isDownloadingDoc}
          isDocumentationReader={isDocumentationReader}
          playScript={this.playScript}
          selectedDocumentations={selectedDocumentations}
          selectedPendingDocumentations={selectedPendingDocumentations}
        />
        <CreateEditDocumentation
          visibility={editWindowVisibility}
          onClose={this.closeEditWindow}
          onCreate={this.reload}
          selected={selectedDocumentation}
          isEdit={editWindowVisibility}
          confirmPublishingWithCredentials={confirmPublishingWithCredentials}
          user={user}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={DOCUMENTATION_PROJECT_COLUMNS}
          tableId={DOCUMENTATION_TABLES_CONFIG.PROJECT.id(projectId)}
          preferencesId={DOCUMENTATION_TABLES_CONFIG.PROJECT.preferencesId}
          persistentQuery={persistentQuery}
          onRowDoubleClick={this.openDocumentation}
          stateKey={"documentation"}
          tableActions={getDocumentationTableActions}
          disabledFeatures={disabledFeatures}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IDocumentationTableProps) => ({
  actions: {
    ...bindActionCreators(documentationActions, dispatch),
    ...bindActionCreators(
      getDocumentationTableActions(DOCUMENTATION_TABLES_CONFIG.PROJECT.id(props.projectId)),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IDocumentationTableProps) => ({
    ...props,
    selected: documentationDataSelectors.getSelectedSelector(
      state,
      DOCUMENTATION_TABLES_CONFIG.PROJECT.id(props.projectId),
    ),
    selectedDocumentation: documentationDataSelectors.getSelectedItemSelector(
      state,
      DOCUMENTATION_TABLES_CONFIG.PROJECT.id(props.projectId),
    ),
    selectedDocumentations: documentationDataSelectors.getSelectedItemsSelector(
      state,
      DOCUMENTATION_TABLES_CONFIG.PROJECT.id(props.projectId),
    ),
    selectedPendingDocumentations: getSelectedPendingDocumentations(
      state,
      DOCUMENTATION_TABLES_CONFIG.PROJECT.id(props.projectId),
    ),
    selectedNonPendingDocumentations: getNonPendingDocumentations(
      state,
      DOCUMENTATION_TABLES_CONFIG.PROJECT.id(props.projectId),
    ),
    user: state.auth.user,
    isDocumentationReader: isCurrentUserDocumentationReader(state),
    confirmPublishingWithCredentials: state.globalSettings?.PUBLISHING_CREDENTIALS_CONFIRMATION,
    disabledFeatures: disabledFeaturesSelector(state),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator> & InjectedIntlProps;

export default injectIntl(withRouter(connectCreator(DocumentationProjectTableContainer)));
