import * as React from "react";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import DatePickerField from "@ea/shared_components/Form/Fields/DatePickerField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { getIn } from "final-form";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { API } from "@app/services/api/api";
import {
  User,
  SchedulerJobRunMode,
  ScriptStatus,
  ScriptSpecialExecutionModes,
  FEATURES,
  ReportTemplate,
} from "@ea/shared_types/types";
import RadioField from "@ea/shared_components/Form/Fields/RadioField";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Select } from "antd";
import StatusTag from "@app/modules/common/components/StatusTag";
import HideableFeature from "@app/modules/common/HideableFeature";
import { HideWhenDocumentationWizard } from "@app/modules/common/HideWhenDocumentationWizard";
import InputNumberField from "@ea/shared_components/Form/Fields/InputNumberField";
import { DataTestIds } from "@app/utils/dataTestIds";
import SpecialExecutionModeFormChunk from "@app/modules/common/components/SpecialModeFormChunk";
import { isDevelopment } from "@ea/shared_components/utils/env";

interface IProjectFormProps {
  values: any;
  initialOwners: User[];
  additionalFormChunks?: ((props) => React.ReactNode)[];
  change: (name: string, value: any) => void;
  isAuditTrailView?: boolean;
}

interface IProjectFormState {
  userOptions: OptionType[];
  projectCategoryOptions: OptionType[];
  endOpen: boolean;
  tagOptions: OptionType[];
  localizationOptions: OptionType[];
  templatesOptions: OptionType[];
}

class ProjectForm extends React.Component<IProjectFormProps, IProjectFormState> {
  constructor(props: IProjectFormProps) {
    super(props);

    this.state = {
      userOptions: [],
      projectCategoryOptions: [],
      endOpen: false,
      tagOptions: [],
      localizationOptions: [],
      templatesOptions: [],
    };

    this.loadUserCompletions("");
    this.loadCategories();
    this.loadTags();
    this.loadLocales();
    this.loadTemplates();
  }

  loadUserCompletions = async (userName: string) => {
    const where =
      userName.length < 2
        ? {}
        : {
            username: { ilike: `%${userName}%` },
          };

    const results = await API.getUsers({ filter: { limit: 10, where } });
    const options = results.map((element) => ({
      key: element.id,
      value: element.id,
      text: element.username,
    }));

    this.setState({
      userOptions: options,
    });
  };

  loadTags = async () => {
    const tags = await API.getTag({});
    const createTagOptions = () =>
      tags.map((tag) => ({
        key: tag.id,
        text: tag.name,
        value: tag.id,
      }));

    this.setState({
      tagOptions: createTagOptions(),
    });
  };

  loadLocales = async () => {
    const localizations = await API.getLocalization({});
    const createLocalizationOption = () =>
      localizations.map((l) => ({
        key: l.id,
        text: l.locale,
        value: l.id,
      }));

    this.setState({
      localizationOptions: createLocalizationOption(),
    });
  };

  loadTemplates = async () => {
    const templates: ReportTemplate[] = await API.getReportTemplates({});
    const createTemplatesOptions = () =>
      templates.map((l) => ({
        key: l.id,
        text: l.name,
        value: l.id,
      }));

    this.setState({
      templatesOptions: createTemplatesOptions(),
    });
  };

  disabledStartDate = (startValue) => {
    const endValue = getIn(this.props.values, "dueDate");

    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > new Date(endValue).valueOf();
  };

  disabledEndDate = (endValue) => {
    const startValue = getIn(this.props.values, "startDate");

    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= new Date(startValue).valueOf();
  };

  loadCategories = async () => {
    const results = await API.getProjectCategories({});
    const options = results.map((element) => ({
      key: element.id,
      value: element.id,
      text: element.name,
    }));

    this.setState({
      projectCategoryOptions: options,
    });
  };

  addSelectedOptions = (options: OptionType[]) => {
    const owners = getIn(this.props.values, `owners`);
    if (!owners || owners.length === 0) {
      return options;
    }

    const fullObjects = owners.filter((v) => v.username !== undefined);
    const allOptions = options
      .concat(fullObjects.map((obj) => ({ key: obj.id, value: obj.id, text: obj.username })))
      .concat(
        this.props.initialOwners.map((o) => ({
          key: o.id,
          value: o.id,
          text: o.username,
        })),
      );

    const uniqueOptions = {};
    allOptions.forEach((o) => (uniqueOptions[o.value!] = o));

    return Object.keys(uniqueOptions).map((k) => uniqueOptions[k]);
  };

  onChange = (field: string, value) => {
    // todo: fix ts error later
    // @ts-ignore:
    this.setState({
      // @ts-ignore:
      [field]: value,
    });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  renderOption = (statusKey: ScriptStatus): JSX.Element => {
    return (
      <Select.Option value={statusKey} key={statusKey}>
        <StatusTag item={{ status: ScriptStatus[statusKey] }} />
      </Select.Option>
    );
  };

  render() {
    const { additionalFormChunks, values, change, isAuditTrailView } = this.props;
    const { tagOptions, localizationOptions, templatesOptions } = this.state;

    const runMode = getIn(values, "runMode");
    const useSpecialMode = getIn(values, "executionParams.useSpecialMode");
    const specialMode = getIn(values, "executionParams.specialMode");
    const documentationMode =
      specialMode === ScriptSpecialExecutionModes.DOCUMENT ||
      specialMode === ScriptSpecialExecutionModes.DOC_AND_VIDEO;
    return (
      <>
        <InputField
          name="name"
          placeholder={getTranslationKey("projects", "details", "placeholder", "name")}
          label={getTranslationKey("projects", "details", "label", "name")}
          required
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <SelectField
          name="category"
          allowClear
          filterOption={false}
          placeholder={getTranslationKey("projects", "details", "placeholder", "category")}
          label={getTranslationKey("projects", "details", "label", "category")}
          options={this.state.projectCategoryOptions}
          data-testid={DataTestIds.FORM_SELECT_CATEGORY}
        />
        <InputField
          name="version"
          placeholder={getTranslationKey("projects", "details", "placeholder", "version")}
          label={getTranslationKey("projects", "details", "label", "version")}
          data-testid={DataTestIds.FORM_INPUT_VERSION}
        />
        <DatePickerField
          name="startDate"
          disabledDate={this.disabledStartDate}
          placeholder={getTranslationKey("projects", "details", "placeholder", "startDate")}
          label={getTranslationKey("projects", "details", "label", "startDate")}
          data-testid={DataTestIds.FORM_DATEPICKER_START_DATE}
        />
        <DatePickerField
          name="dueDate"
          disabledDate={this.disabledEndDate}
          placeholder={getTranslationKey("projects", "details", "placeholder", "dueDate")}
          label={getTranslationKey("projects", "details", "label", "dueDate")}
          data-testid={DataTestIds.FORM_DATEPICKER_DUE_DATE}
        />
        <TextAreaField
          name="description"
          placeholder={getTranslationKey("common", "placeholder", "description")}
          label={getTranslationKey("common", "label", "description")}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
        {!isAuditTrailView && (
          <SelectField
            name="owners"
            mode="multiple"
            showSearch
            format={(vals) => {
              if (vals && vals.length > 0) {
                return vals.map((v) => (v.username ? v.id : v));
              }
              return vals;
            }}
            filterOption={false}
            onSearch={this.loadUserCompletions}
            placeholder={getTranslationKey("projects", "details", "placeholder", "owners")}
            label={getTranslationKey("projects", "details", "label", "owners")}
            options={this.addSelectedOptions(this.state.userOptions)}
            data-testid={DataTestIds.FORM_SELECT_OWNERS}
          />
        )}
        <RadioField
          name="runMode"
          required
          label={getTranslationKey("common", "label", "runMode")}
          data-testid={DataTestIds.FORM_RADIO_RUN_MODE}
          options={[
            {
              text: getTranslationKey("scheduler", "runMode", "sequential"),
              value: SchedulerJobRunMode.SEQUENTIAL,
            },
            {
              text: getTranslationKey("scheduler", "runMode", "parallel"),
              value: SchedulerJobRunMode.PARALLEL,
            },
          ]}
          onChange={({ target }) => {
            const { value } = target;
            const useSpecialMode = !!this.props.values?.executionParams?.useSpecialMode;

            if (useSpecialMode && value === SchedulerJobRunMode.PARALLEL && !isDevelopment()) {
              change("executionParams.specialMode", ScriptSpecialExecutionModes.DOCUMENT);
            }

            if (!useSpecialMode) {
              change("executionParams.specialMode", null);
            }
          }}
        />
        {runMode === SchedulerJobRunMode.PARALLEL && (
          <InputNumberField
            name="maximumParallelSessions"
            min={0}
            label={getTranslationKey("scheduler", "label", "maximumParallelSessions")}
            data-testid={DataTestIds.FORM_INPUT_MAX_PARALLEL_SESSIONS}
          />
        )}
        <SelectField
          name="statusesToRun"
          mode="multiple"
          label={getTranslationKey("projects", "details", "label", "statusToRun")}
          placeholder={getTranslationKey("projects", "details", "placeholder", "statusToRun")}
          data-testid={DataTestIds.FORM_SELECT_STATUSES_TO_RUN}
        >
          {Object.keys(ScriptStatus)
            .filter((s) => ScriptStatus[s] !== ScriptStatus.CLOSED)
            .map(this.renderOption)}
        </SelectField>
        <HideWhenDocumentationWizard>
          {() => (
            <HideableFeature feature={FEATURES.GXP_TAGS}>
              {() => (
                <SelectField
                  name="tagsToRun"
                  mode="multiple"
                  label={getTranslationKey("projects", "details", "label", "tagsToRun")}
                  placeholder={getTranslationKey("projects", "details", "placeholder", "tagsToRun")}
                  options={tagOptions}
                  data-testid={DataTestIds.FORM_SELECT_TAGS_TO_RUN}
                />
              )}
            </HideableFeature>
          )}
        </HideWhenDocumentationWizard>
        <HideableFeature feature={FEATURES.DOCUMENTATION}>
          {() => (
            <>
              <SpecialExecutionModeFormChunk
                {...this.props}
                prefix="executionParams"
                runMode={runMode}
              />
              {useSpecialMode && (
                <SelectField
                  allowClear
                  name="executionParams.documentationLocale"
                  label={getTranslationKey("projects", "details", "label", "documentationLocale")}
                  placeholder={getTranslationKey(
                    "projects",
                    "details",
                    "placeholder",
                    "documentationLocale",
                  )}
                  options={localizationOptions}
                  data-testid={DataTestIds.FORM_SELECT_DOCUMENTATION_LOCALE}
                />
              )}
              {documentationMode && (
                <>
                  <SelectField
                    name="documentationTemplate"
                    label={getTranslationKey("globalSettings", "docTemplate")}
                    placeholder={getTranslationKey("globalSettings", "selectDocumentationTemplate")}
                    options={templatesOptions}
                    allowClear
                    data-testid={DataTestIds.FORM_SELECT_DOCUMENTATION_TEMPLATE}
                  />
                </>
              )}
            </>
          )}
        </HideableFeature>

        <SelectField
          name="reportTemplate"
          label={getTranslationKey("globalSettings", "reportTemplate")}
          placeholder={getTranslationKey("globalSettings", "selectReportTemplate")}
          options={templatesOptions}
          allowClear
          data-testid={DataTestIds.FORM_SELECT_REPORT_TEMPLATE}
        />
        {additionalFormChunks && additionalFormChunks.map((chunkRender) => chunkRender(this.props))}
      </>
    );
  }
}

export default ProjectForm;
