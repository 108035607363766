import * as React from "react";
import SelectField from "@ea/shared_components/Form/Fields/SelectField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { StepTerminationType, StepTerminationAction } from "@ea/shared_types/types";
import { getIn } from "final-form";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { inputPositiveNumbersProps } from "../CreateStepCommonForm";
import { injectIntl, InjectedIntlProps } from "react-intl";

interface IStepTimeoutFormProps {
  values: any;
}

const getTypeOptions = (intl): OptionType[] => [
  {
    value: StepTerminationType.TIMEOUT,
    text: intl.formatMessage({ id: "step.detailsForm.termination.type.timeout" }),
  },
  {
    value: StepTerminationType.NUMBER_OF_EXECUTIONS,
    text: intl.formatMessage({ id: "step.detailsForm.termination.type.executions" }),
  },
];

const getActionOptions = (intl): OptionType[] => [
  {
    value: StepTerminationAction.TERMINATE_EXECUTION,
    text: intl.formatMessage({ id: "step.detailsForm.termination.action.error" }),
  },
  {
    value: StepTerminationAction.IGNORE_STEP,
    text: intl.formatMessage({ id: "step.detailsForm.termination.action.ignore" }),
  },
];

const StepTerminationForm: React.FunctionComponent<IStepTimeoutFormProps & InjectedIntlProps> = ({
  values,
  intl,
}) => {
  const type = getIn(values, "termination.type");

  return (
    <>
      <SelectField
        name="termination.type"
        label={intl.formatMessage({ id: "step.detailsForm.termination.label.type" })}
        placeholder={intl.formatMessage({ id: "step.detailsForm.termination.placeholder.type" })}
        allowClear
        options={getTypeOptions(intl)}
      />
      {type && (
        <>
          <InputField
            name="termination.value"
            label={intl.formatMessage({ id: "step.detailsForm.termination.placeholder.value" })}
            required
            placeholder={intl.formatMessage({
              id: "step.detailsForm.termination.placeholder.value",
            })}
            addonAfter={type === StepTerminationType.TIMEOUT ? "seconds" : "tries"}
            type="number"
            {...inputPositiveNumbersProps}
          />
          <SelectField
            name="termination.action"
            label={intl.formatMessage({ id: "step.detailsForm.termination.label.action" })}
            required
            placeholder={intl.formatMessage({
              id: "step.detailsForm.termination.placeholder.action",
            })}
            options={getActionOptions(intl)}
          />
        </>
      )}
    </>
  );
};

export default injectIntl(StepTerminationForm);
