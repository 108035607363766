import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "./scheduler.actions";
import { SchedulerJob, SchedulerMode, SchedulerRunTypes } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { SCHEDULER_COLUMNS_CONFIG, SCHEDULER_COLUMNS_CONFIG_GANTT } from "./scheduler.table";
import { schedulerDataSelectors } from "./scheduler.selectors";
import { schedulerActionCreators } from "./scheduler.actions";
import { makeAsyncEpic } from "@app/modules/app.reducers";

const includeFilter = [
  {
    relation: "schedulerJobMapping",
    scope: {
      fields: ["name", "schedulerJobId"],
    },
  },
  {
    relation: "groups",
    scope: {
      order: "lineNum ASC",
      include: {
        relation: "scripts",
        scope: {
          order: "lineNum ASC",
        },
      },
    },
  },
];

const schedulerModuleEpics = {
  ...createModuleEpics<SchedulerJob>(moduleActions, {
    normalizeItem: (scheduler: SchedulerJob) => {
      if (scheduler.mode !== SchedulerMode.GANTT) {
        return scheduler;
      }

      scheduler.groups.forEach((group) => {
        group.scripts?.forEach((script) => {
          if (script.runParams.run.type === SchedulerRunTypes.TIME) {
            script.runParams.run.time = new Date(script.runParams.run.time);
          }
        });
      });

      return scheduler;
    },
    getRequestParams: (state, tableId) => {
      const tableParams = schedulerDataSelectors.getParamsSelector(state, tableId!);
      const baseOrder = "id DESC";
      const params = createRequestParams(
        tableParams,
        { ...SCHEDULER_COLUMNS_CONFIG, ...SCHEDULER_COLUMNS_CONFIG_GANTT } as any,
        {
          defaultOrder: [baseOrder],
        },
      );

      params.filter = {
        ...params.filter,
        include: includeFilter,
      };

      return params;
    },
    getSingleRequestParams: (params) => {
      params.filter = {
        ...params.filter,
        include: includeFilter,
      };

      return params;
    },
    api: {
      createItem: API.createSchedulerJob,
      deleteItem: API.deleteSchedulerJob,
      editItem: API.editSchedulerJob,
      getItems: API.getSchedulerJobs,
      getItemsCount: API.getSchedulerJobCount,
    },
  }),
  closeSchedulerEpic: makeAsyncEpic(schedulerActionCreators.close, (payload) =>
    API.closeSchedulerJob({ ids: payload }),
  ),
};

export default schedulerModuleEpics;
