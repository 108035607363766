import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { Info } from "@ea/shared_components/common/LayoutElements";
import StepDetailsForm from "@app/modules/steps/components/Forms/StepDetailsForm";
import { STEPS_TABLE_ID } from "@app/modules/steps/steps.table";
import { checkAttribute } from "@ea/shared_components/utils/dom";
import { variableActions, VARIABLES_SECTIONS } from "../variables/variables.actions";
import {
  variableDataSelectors,
  getVariablesGroupsSelector,
} from "../variables/variables.selectors";
import { scriptsActions } from "../scripts/scripts.actions";
import { withRouter, RouteComponentProps } from "react-router";
import { isScriptReadonly } from "@app/utils/script";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import { CodeTemplateWithGroup, System, VirtualUser } from "@ea/shared_types/types";
import { getStepCommand } from "@app/packs/packs.helpers";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { getStepLabelParams } from "../steps/steps.utils";
import { getRunnerTableActions, runnerActions } from "./runner.actions";
import { runnerDataSelectors } from "./runner.selectors";
import { Project, ExecutionStep } from "@ea/shared_types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { FormattedMessage } from "react-intl";
import { detectAndCreateNewVariables } from "../variables/variables.helpers";
import { API } from "@app/services/api/api";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { CloseOutlined } from "@ant-design/icons";
import { getScriptGlobalMutables, getScriptGlobalConstants } from "../scripts/scripts.selectors";
import i18next from "i18next";
import { DataTestIds } from "@app/utils/dataTestIds";

interface IStepDetailsContainerProps {
  scriptId: number;
  systems: System[];
  virtualUsers: VirtualUser[];
  system?: System;
  project?: Project;
  virtualUser?: VirtualUser;
  sessionId: string;
  toggleEdit: () => void;
  codeTemplates?: CodeTemplateWithGroup[];
}

interface IStepDetailsContainerState {
  formValues: any;
}

const Container = styled.div({
  marginBottom: "20px",
  gridRow: "1 / span 2",
  overflow: "auto",
});

class StepDetailsContainer extends React.Component<
  IStepDetailsContainerProps & IConnectProps,
  IStepDetailsContainerState
> {
  editableAreaContainer: any;

  state: IStepDetailsContainerState = {
    formValues: undefined,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: IStepDetailsContainerProps & IConnectProps) {
    if (
      this.props.selectedStep === undefined ||
      prevProps.selectedStep === undefined ||
      (this.props.selectedStep !== undefined &&
        prevProps.selectedStep !== undefined &&
        (this.props.selectedStep.id !== prevProps.selectedStep.id ||
          this.props.selectedStep.updatedAt !== prevProps.selectedStep.updatedAt))
    ) {
      const isFormSubmitting =
        this.editableAreaContainer &&
        this.editableAreaContainer.props &&
        this.editableAreaContainer.props.form &&
        this.editableAreaContainer.props.form.submitting;
      if (
        this.editableAreaContainer &&
        this.editableAreaContainer.state.isEditing &&
        !isFormSubmitting
      ) {
        this.editableAreaContainer.cancel();
      }

      this.load();
    }
  }

  openLinked = () => {
    // TODO implement!
  };

  load = () => {
    if (this.props.selectedStep) {
      if (this.props.selectedStep.commandId === CoreCommandsIds.script) {
        this.props.actions.script.loadSingle({ id: this.props.selectedStep.linkedScriptId! });
      }
    }
  };

  onFocus = () => {
    if (
      this.editableAreaContainer === undefined ||
      (this.editableAreaContainer && !this.editableAreaContainer.state.isEditing)
    ) {
      this.reload();
    }
  };

  reload = () => {
    const { selectedScript } = this.props;
    if (selectedScript) {
      this.props.actions.load({ reload: true, clearPrevious: true });
    }
  };

  prettifyPickValue = (value) =>
    value
      .slice(3, value.length - 2)
      .split(".")
      .join(" / ");

  changeLabel = (step) => {
    const command = getStepCommand(step);
    if (command.getLabel) {
      return command.getLabel(step).label;
    }

    return step.label;
  };

  onSave = async (values: ExecutionStep) => {
    const { actions, scriptId, sessionId } = this.props;
    const editedStep = values;

    const command = getStepCommand(editedStep);
    if (!editedStep.manualLabel && command.getLabel) {
      const { labelKey } = command.getLabel(editedStep);
      editedStep.labelKey = labelKey;
      editedStep.label = undefined;
    }

    const anyCreated = await detectAndCreateNewVariables(
      values.value,
      values.commandId,
      scriptId,
      editedStep.platform.id,
    );

    if (anyCreated) {
      actions.variable.loadLocal(scriptId);
    }

    await API.runner.recorder.syncSteps({
      steps: [editedStep],
      sessionId: sessionId,
    });

    actions.commitEdit(editedStep);
  };

  guard = (evt) =>
    checkAttribute(evt.target, "role", "gridcell") || checkAttribute(evt.target, "role", "row");

  render() {
    const {
      selectedStep,
      basicVariables,
      selectedScript,
      steps,
      selected,
      linkedScript,
      variablesGroups,
      virtualUsers,
      systems,
      system,
      globalConstants,
      globalMutables,
      virtualUser,
      codeTemplates,
    } = this.props;

    if (selected.length > 1) {
      return (
        <Info>
          <FormattedMessage id={getTranslationKey("messages", "info", "moreThanOneStep")} />
        </Info>
      );
    }
    if (!selectedStep) {
      return (
        <Info>
          <FormattedMessage id={getTranslationKey("messages", "info", "noStepSelected")} />
        </Info>
      );
    }

    const labelParams = getStepLabelParams(selectedStep);

    return (
      <Container key={selectedStep.id}>
        <EditeableAreaFinal
          setRef={(element) => (this.editableAreaContainer = element)}
          guard={this.guard}
          onSave={this.onSave}
          disableEditing={isScriptReadonly(selectedScript)}
          initialValues={{
            ...selectedStep,
            labelParams,
            label: selectedStep.label || i18next.t(selectedStep.labelKey!, labelParams),
            value: selectedStep.value,
          }}
          additionalButtons={
            <CommandBarButton
              name="close"
              text={getTranslationKey("commandBar", "close")}
              icon={<CloseOutlined />}
              onClick={this.props.toggleEdit}
              data-testid={DataTestIds.COMMANDBAR_BUTTON_CLOSE}
            />
          }
          render={(props) => (
            <div key={selectedStep.id}>
              <StepDetailsForm
                {...props}
                globalConstants={globalConstants}
                globalMutables={globalMutables}
                script={selectedScript}
                variables={basicVariables}
                steps={steps}
                variablesGroups={variablesGroups}
                systems={systems}
                system={system}
                virtualUser={virtualUser}
                virtualUsers={virtualUsers}
                linkedScript={linkedScript}
                openLinked={this.openLinked}
                codeTemplates={codeTemplates || []}
              />
            </div>
          )}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IStepDetailsContainerProps) => ({
  actions: {
    ...bindActionCreators(runnerActions, dispatch),
    ...bindActionCreators(getRunnerTableActions(props.sessionId), dispatch),
    variable: {
      ...bindActionCreators(variableActions, dispatch),
    },
    script: {
      ...bindActionCreators(scriptsActions, dispatch),
    },
  },
});

const connectCreator = connect((state: ApplicationState, props: IStepDetailsContainerProps) => {
  const selectedItem = runnerDataSelectors.getSelectedItemSelector(state, props.sessionId);

  return {
    selectedScript: {} as any,
    selectedStep: selectedItem,
    selected: runnerDataSelectors.getSelectedItemsSelector(state, props.sessionId),
    steps: runnerDataSelectors.getOrderedDataSelector(state, props.sessionId),
    linkedScript: undefined,
    basicVariables: variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
    variablesGroups: getVariablesGroupsSelector(state, props.scriptId),
    globalMutables: getScriptGlobalMutables(state, props.scriptId),
    globalConstants: getScriptGlobalConstants(state, props.scriptId),
  };
}, mapDispatchToProps);

type IConnectProps = RouteComponentProps<any> & ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(StepDetailsContainer));
