import { createModuleEpics } from "@app/modules/app.reducers";
import { Sequence } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { moduleActions } from "./sequences.actions";
import { SEQUENCES_COLUMNS_CONFIG } from "./sequences.table";
import { sequencesDataSelectors } from "./sequences.selectors";

const sequenceModuleEpics = createModuleEpics<Sequence>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = sequencesDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, SEQUENCES_COLUMNS_CONFIG);
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createSequence,
    deleteItem: API.deleteSequence,
    editItem: API.editSequence,
    getItems: API.getSequences,
    getItemsCount: API.getSequenceCount,
  },
});

export default sequenceModuleEpics;
