import * as React from "react";
import ScreenshotGallery from "./ScreenshotGallery";
import { Log, StepScreenshots } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { toast } from "react-toastify";

interface IShowGalleryContainerProps {
  visible: boolean;
  onClose: () => void;
  selectedLog?: Log;
}

interface IShowGalleryContainerState {
  screenshots: StepScreenshots | null;
}

class ShowGalleryContainer extends React.Component<
  IShowGalleryContainerProps,
  IShowGalleryContainerState
> {
  state = {
    screenshots: null,
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.visible && !this.props.visible) {
      this.setState({
        screenshots: null,
      });
    }

    if (!prevProps.visible && this.props.visible) {
      const { selectedLog } = this.props;
      if (selectedLog) {
        this.setState({
          screenshots: await API.getStepsScreenshots({ id: selectedLog.id }),
        });
      }
    }
  }

  render() {
    const { onClose, visible } = this.props;
    const { screenshots } = this.state;

    return <ScreenshotGallery onClose={onClose} visible={visible} screenshots={screenshots} />;
  }
}

export default ShowGalleryContainer;
