import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Script, PlainObject } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("SCRIPT_MODULE");

export const moduleActions = createDataModuleActions<Script>(actionCreator);

export const getScriptsTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);

export const scriptsActionCreators = {
  ...moduleActions,
  close: actionCreator.async<
    { ids: number[]; options?: { updateTestCaseStatus?: boolean } },
    Script[],
    any
  >("CLOSE"),
  loadGroupsCounter: actionCreator.async<{}, PlainObject<number>>("LOAD_GROUPS_COUNTER"),
};

export const scriptsActions = {
  ...normalizeActions(moduleActions.data),
  close: scriptsActionCreators.close.started,
};
