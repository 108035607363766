import styled from "@emotion/styled";
import { Button, Tabs, Spin } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import ScriptVariablesContainer from "../scripts/ScriptVariables.container";
import ScriptGlobalVariablesContainer from "../scripts/ScriptGlobalVariables.container";
import { CloseOutlined } from "@ant-design/icons";
import "./DataView.css";
import LiveVariablesContainer from "./LiveVariables.container";
import { ADVANCED_RUNNER_VARIABLES_TABS_CLASS } from "@app/styles/styles";
import { Variable } from "@ea/shared_types";
import DataSourceContainer from "../scripts/DataSource.container";
import { EntityType } from "@ea/shared_types/types";
import { SequencesTableContainer } from "../sequences";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { SpinnerContainer } from "./RunnerComponentLayout";

interface IDataViewContainerProps {
  scriptId: number;
  toggleVariablesViewVisibility: () => void;
  sessionId: string;
  onLocalVariableSave?: (variables: Variable[]) => void;
  onDatasourceUpload?: () => void;
  isDirty: boolean;
}
interface IDataViewContainerState {
  activeTab: DataViewTabs;
}

enum DataViewTabs {
  LOCAL_VARIABLES = "LOCAL_VARIABLES",
  LIVE_VARIABLES = "LIVE_VARIABLES",
  DATASOURCES = "DATASOURCES",
  SEQUENCES = "SEQUENCES",
  GLOBAL = "GLOBAL",
}

const { TabPane } = Tabs;

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gridRow: "1 / span 2",
  position: "relative",
});

const DataSourcePane = styled.div({
  padding: 20,
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

class DataViewContainer extends React.Component<IDataViewContainerProps, IDataViewContainerState> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: DataViewTabs.LOCAL_VARIABLES,
    };
  }

  setActiveTab(key: DataViewTabs) {
    this.setState({ activeTab: key });
  }

  render() {
    const {
      scriptId,
      toggleVariablesViewVisibility,
      sessionId,
      onLocalVariableSave,
      onDatasourceUpload,
      isDirty,
    } = this.props;

    return (
      <Container className={ADVANCED_RUNNER_VARIABLES_TABS_CLASS}>
        {isDirty && (
          <SpinnerContainer>
            <Spin
              tip={
                (
                  <FormattedMessage id={getTranslationKey("messages", "info", "stepsSyncing")} />
                ) as any
              }
            />
          </SpinnerContainer>
        )}
        <Tabs
          defaultActiveKey={DataViewTabs.LOCAL_VARIABLES}
          onChange={(key) => this.setActiveTab(key as DataViewTabs)}
          type="card"
          tabBarExtraContent={
            <Button
              style={{ height: "40px", borderRadius: 0 }}
              icon={<CloseOutlined />}
              danger
              type="primary"
              onClick={toggleVariablesViewVisibility}
            >
              <FormattedMessage id={getTranslationKey("button", "close")} />
            </Button>
          }
        >
          <TabPane
            tab={<FormattedMessage id={getTranslationKey("runner", "data", "localVariables")} />}
            key={DataViewTabs.LOCAL_VARIABLES}
          >
            <ScriptVariablesContainer scriptId={scriptId} onSave={onLocalVariableSave} />
          </TabPane>
          <TabPane
            tab={<FormattedMessage id={getTranslationKey("runner", "data", "liveVariables")} />}
            key={DataViewTabs.LIVE_VARIABLES}
          >
            <LiveVariablesContainer sessionId={sessionId} />
          </TabPane>
          <TabPane
            tab={<FormattedMessage id={getTranslationKey("runner", "data", "datasource")} />}
            key={DataViewTabs.DATASOURCES}
          >
            <DataSourcePane>
              <DataSourceContainer
                parentId={scriptId}
                parentType={EntityType.SCRIPT}
                onUpload={onDatasourceUpload}
              />
            </DataSourcePane>
          </TabPane>
          <TabPane
            tab={<FormattedMessage id={getTranslationKey("runner", "data", "sequences")} />}
            key={DataViewTabs.SEQUENCES}
          >
            <SequencesTableContainer onChange={onDatasourceUpload} />
          </TabPane>
          <TabPane
            tab={<FormattedMessage id={getTranslationKey("runner", "data", "global")} />}
            key={DataViewTabs.GLOBAL}
          >
            <ScriptGlobalVariablesContainer scriptId={scriptId} sessionId={sessionId} />
          </TabPane>
        </Tabs>
      </Container>
    );
  }
}

export default DataViewContainer;
