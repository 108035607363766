import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "@app/modules/logs/StepLogs/stepLogs.actions";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { StepLog } from "@ea/shared_types/types";
import { logsDataSelectors } from "./stepLogs.selectors";
import { STEP_LOGS_COLUMNS_CONFIG } from "./stepLogs.table";

const DEFAULT_ORDER = ["startTime ASC"];

const logModuleEpics = createModuleEpics<StepLog>(moduleActions, {
  normalizeItem: (stepLog) => stepLog,
  getRequestParams: (state, tableId) => {
    const tableParams = logsDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, STEP_LOGS_COLUMNS_CONFIG(), {
      defaultOrder: DEFAULT_ORDER,
    });
    params.filter = {
      ...params.filter,
      include: "workItem",
    };
    return params;
  },
  getSingleRequestParams: (params) => {
    params.filter = {
      ...params.filter,
      include: "workItem",
    };
    return params;
  },
  api: {
    createItem: () => ({} as Promise<StepLog>),
    deleteItem: () => ({} as Promise<any>),
    editItem: () => ({} as Promise<StepLog>),
    getItems: API.getStepLogs,
    getItemsCount: API.getStepLogsCount,
  },
});

export default logModuleEpics;
