import { Spin } from "antd";
import styled from "@emotion/styled";
import { Alert } from "antd";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { CustomColumn } from "@ea/shared_components/Table/common.tables";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { COLORS } from "@app/styles/consts";
import { Statistic } from "@ea/shared_types/types";
import { FormattedMessage } from "react-intl";
import LogsPieChart from "../components/LogsPieChart";
import { logsDataSelectors } from "../logs.selectors";
import { getTranslationKey } from "@app/translations/translations.helpers";
import isEqual from "lodash/isEqual";
interface ExecutionStatusChartProps {
  tableId: string;
  columnsConfig: PlainObject<CustomColumn<any>>;
  title?: string | React.ReactNode;
  customFilter?: any;
}

interface ExecutionStatusChartState {
  data: Statistic[] | undefined;
  errorMessage: React.ReactNode | string | undefined;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  textAlign: "center",
});

const SpinContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: "450px",
  paddingTop: "20%",
});

class ExecutionStatusChart extends React.Component<IConnectProps & ExecutionStatusChartProps, any> {
  state: ExecutionStatusChartState = {
    data: undefined,
    errorMessage: undefined,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: IConnectProps & ExecutionStatusChartProps) {
    const { tableParams, tableId } = this.props;

    if (prevProps.tableId !== tableId || !isEqual(prevProps.tableParams.query, tableParams.query)) {
      this.setState({ data: undefined, errorMessage: undefined });
      this.loadData();
    }
  }

  loadData = () => {
    const params = this.createParams();
    API.getCountGroupByStatistic(params)
      .then((data) => {
        this.setState({ data, errorMessage: undefined });
      })
      .catch((error) => {
        this.setState({
          data: [],
          errorMessage: (
            <FormattedMessage
              id={getTranslationKey("messages", "error", "failedFetchStatistics")}
            />
          ),
        });
      });
  };

  createParams = () => {
    const params = createRequestParams(this.props.tableParams, this.props.columnsConfig, {
      defaultOrder: ["startTime DESC"],
    });
    if (params.filter) {
      delete params.filter.offset;
      delete params.filter.limit;
    }

    if (this.props.customFilter) {
      params.filter = { ...params.filter, ...this.props.customFilter };
    }

    params.groupBy = "status";

    return params;
  };

  getDataCount = () => this.state.data?.reduce((acc, val) => acc + val.value, 0);

  render() {
    const { data, errorMessage } = this.state;
    const { title } = this.props;

    return data ? (
      <Container>
        {errorMessage && <Alert type="error" message={errorMessage} />}
        <h2>{title}</h2>
        {data.length ? (
          <>
            <LogsPieChart data={data} colors={COLORS.STATUS} />
            <h3>
              <FormattedMessage id={getTranslationKey("common", "total")} />: {this.getDataCount()}
            </h3>
          </>
        ) : (
          <h3>
            <FormattedMessage id={getTranslationKey("common", "noData")} />
          </h3>
        )}
      </Container>
    ) : (
      <SpinContainer>
        <Spin
          tip={(<FormattedMessage id={getTranslationKey("common", "loadingStatistics")} />) as any}
        />
      </SpinContainer>
    );
  }
}

const connectCreator = connect((state: ApplicationState, props: ExecutionStatusChartProps) => ({
  tableParams: logsDataSelectors.getParamsSelector(state, props.tableId),
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(ExecutionStatusChart);
