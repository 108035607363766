import React, { useState, useEffect } from "react";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { User, Documentation, SSO, DocumentationStatus } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import DocumentationForm from "./components/DocumentationForm";
import { Modal, Button, Checkbox } from "antd";
import { FormattedMessage } from "react-intl";
import { DataTestIds } from "@ea/shared_components/utils/dataTestHelpers";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { COLORS } from "@ea/shared_components/styles/consts";
import styled from "@emotion/styled";

const FlexContainer = styled.div({
  display: "flex",
});

const HeaderContainer = styled.h3({
  marginLeft: 10,
});

interface ItsContainerProps {
  visibility: boolean;
  onClose: (edited?: boolean) => void;
  onCreate: (documentation: Documentation) => void;
  selected?: Documentation;
  isEdit?: boolean;
  user: User | undefined;
  confirmPublishingWithCredentials?: boolean;
}

const CreateEditDocumentation: React.FunctionComponent<ItsContainerProps> = ({
  visibility,
  onClose,
  onCreate,
  selected,
  isEdit,
  user,
  confirmPublishingWithCredentials,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [values, setValues] = useState<Documentation | undefined>(undefined);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const isAADUser = user?.sso === SSO.AzureActiveDirectory;

  useEffect(() => {
    setValues(selected);
  }, [selected?.id, selected?.status]);

  useEffect(() => {
    if (visibility === true) {
      setValues(selected);
    }
  }, [visibility]);

  const onModalNoClick = () => {
    setIsModalVisible(false);
    onClose();
    setIsConfirmDisabled(true);
  };

  const onModalConfirm = async () => {
    await saveValues();
    onClose(true);
    setIsModalVisible(false);
    setIsConfirmDisabled(true);
  };

  const onPanelSave = async (formValues) => {
    setValues(formValues);
    if (isAADUser && formValues.status === DocumentationStatus.PUBLISHED) {
      setIsModalVisible(true);
    } else {
      await saveValues(formValues);
    }
    onClose();
  };

  const saveValues = async (formValues?) => {
    const documentation = await API.editDocumentation(formValues || values);
    onCreate(documentation);
  };

  const getCloseConfirmFooter = () => [
    <Button key="cancel" onClick={onModalNoClick} data-testid={DataTestIds.MODAL_BUTTON_CANCEL}>
      <FormattedMessage id={getTranslationKey("button", "no")} />
    </Button>,
    <Button
      danger
      key="ok"
      onClick={onModalConfirm}
      data-testid={DataTestIds.MODAL_BUTTON_SUBMIT}
      disabled={isConfirmDisabled}
    >
      <FormattedMessage id={getTranslationKey("button", "yes")} />
    </Button>,
  ];
  return (
    <>
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.SMALL}
        headerText={getTranslationKey("documentation", "header", "edit")}
        onCancelClick={onClose}
        onOkClick={onPanelSave}
        cancelButtonText={getTranslationKey("button", "cancel")}
        key={"edit"}
        okButtonText={getTranslationKey("button", "save")}
        initialValues={isEdit ? values : {}}
        render={({ change, values }) => (
          <DocumentationForm
            change={change}
            values={values}
            confirmPublishingWithCredentials={confirmPublishingWithCredentials}
            isAADUser={isAADUser}
          />
        )}
      />
      {isAADUser && (
        <Modal
          visible={isModalVisible}
          closable={false}
          footer={getCloseConfirmFooter()}
          bodyStyle={{ display: "flex", alignContent: "flex-start", flexDirection: "column" }}
        >
          <FlexContainer>
            <ExclamationCircleOutlined
              style={{
                marginBottom: 20,
                width: "min-content",
                color: COLORS.BLUE1,
                fontSize: 25,
              }}
            />
            <HeaderContainer>
              <FormattedMessage id={getTranslationKey("messages", "confirm", "confirmation")} />
            </HeaderContainer>
          </FlexContainer>
          <FormattedMessage
            id={getTranslationKey("messages", "confirm", "consentDocumentationInfo")}
            values={{
              prettyName: <strong>{values?.prettyName}</strong>,
              status: <strong>{values?.status}</strong>,
            }}
          />
          <Checkbox
            style={{ marginTop: 10 }}
            key="consentCheckbox"
            checked={!isConfirmDisabled}
            onChange={(e) => {
              setIsConfirmDisabled(!isConfirmDisabled);
            }}
          >
            <FormattedMessage id={getTranslationKey("messages", "confirm", "consentCheckbox")} />
          </Checkbox>
        </Modal>
      )}
    </>
  );
};
export default CreateEditDocumentation;
