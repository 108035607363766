import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";

import CommandBar from "./components/VirtualUsersCommandBar";
import { VIRTUAL_USERS_COLUMNS, VIRTUAL_USERS_TABLES_CONFIG } from "./virtualUsers.table";
import {
  virtualUsersActions,
  getVirtualUsersTableActions,
} from "@app/modules/virtualUsers/virtualUsers.actions";
import CreateEditVirtualUserContainer from "@app/modules/virtualUsers/CreateEditVirtualUser.container";
import { virtualUsersDataSelectors } from "@app/modules/virtualUsers/virtualUsers.selectors";
import { VIRTUAL_USER_STATUS } from "@ea/shared_types/types";
import ErrorAuthBox from "./components/ErrorAuthBox";
import { API } from "@app/services/api/api";
import { VirtualUser, ErrorCodes } from "@ea/shared_types";
import BaseModalForm from "@ea/shared_components/ModalForm/BaseModalForm";
import SensitiveDataForm from "./components/SensitiveDataForm";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { openSessions } from "../../utils/openSessions";
import { toast } from "react-toastify";

interface IVirtualUsersTableProps {}

interface IVirtualUsersTableState {
  createWindowVisibility: boolean;
  editWindowVisibility: boolean;
  errorWarningListVisible: boolean;
  sensitiveDataModalVisibility: boolean;
  clickedStepLogId?: number;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

class VirtualUsersTableContainer extends React.Component<
  IVirtualUsersTableProps & IConnectProps,
  IVirtualUsersTableState
> {
  connectedTable: any;

  state: IVirtualUsersTableState = {
    createWindowVisibility: false,
    editWindowVisibility: false,
    errorWarningListVisible: false,
    sensitiveDataModalVisibility: false,
  };

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedVirtualUser) {
      this.props.actions.loadSingle({ id: this.props.selectedVirtualUser.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  openCreateWindow = () => {
    this.setState({
      createWindowVisibility: true,
    });
  };

  closeCreateWindow = () => {
    this.setState({
      createWindowVisibility: false,
      editWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected.map((v) => v.id) });
  };

  editSystem = async (values) => {
    await this.props.actions.edit({ ...values });
    this.reload();
    this.closeEditWindow();
  };

  createSystem = async (values) => {
    await this.props.actions.create({ ...values });
    this.closeCreateWindow();
  };

  authenticate = async (sensitiveData?) => {
    if (!this.props.selectedVirtualUser) {
      return;
    }
    const { isManual } = this.props.selectedVirtualUser.systemData;

    if (!isManual) {
      this.props.actions.authenticate({
        id: this.props.selectedVirtualUser.id,
        sensitiveData,
      });
      return;
    }
    const editedVU = await API.editVirtualUser({
      ...this.props.selectedVirtualUser,
      tokens: { cookies: undefined, localStorage: undefined, sessionStorage: undefined },
      status: VIRTUAL_USER_STATUS.NOT_AUTHENTICATED,
    } as any);
    this.props.actions.commitEdit(editedVU);

    const { url, sessionId, errorMessage } = await API.authenticateVirtualUser({
      id: this.props.selectedVirtualUser.id,
      sensitiveData,
    });

    // SESSIONS_LIMIT_REACHED shouldnt appear here because authentication in manual mode is not limited by license
    if (!url) {
      toast.error(
        <FormattedMessage
          id={getTranslationKey(
            "messages",
            "error",
            errorMessage?.code === ErrorCodes.SESSIONS_LIMIT_REACHED
              ? "sessionLimitReached"
              : "sessionStart",
          )}
        />,
      );
      return;
    }

    window.open(url);
  };

  onSensitiveDataModalOkClick = (values) => {
    const sensitiveData = values
      ? Object.keys(values).map((key) => ({ key, value: values[key] }))
      : undefined;

    this.authenticate(sensitiveData);

    this.setState({
      sensitiveDataModalVisibility: false,
    });
  };

  onSensitiveDataModalCancelClick = () => {
    this.setState({
      sensitiveDataModalVisibility: false,
    });
  };

  onLogoutClick = () => {
    if (this.props.selectedVirtualUser) {
      this.props.actions.edit({
        ...this.props.selectedVirtualUser,
        tokens: { cookies: undefined, localStorage: undefined, sessionStorage: undefined },
        status: VIRTUAL_USER_STATUS.NOT_AUTHENTICATED,
      } as any);
    }
  };

  onAuthenticateClick = () => {
    if (this.props.selectedVirtualUser) {
      if (
        this.props.selectedVirtualUser.isSystemAuthEnabled &&
        this.props.selectedVirtualUser.systemData.rememberSensitiveData
      ) {
        this.authenticate();
      } else {
        this.setState({
          sensitiveDataModalVisibility: true,
        });
      }
    }
  };

  openErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: true,
      clickedStepLogId: this.props.selected[0] && this.props.selected[0].id,
    });
  };

  closeErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: false,
    });
  };

  render() {
    return (
      <Container>
        <CommandBar
          onNewClick={this.openCreateWindow}
          onRemoveClick={this.remove}
          selected={this.props.selected}
          onEditClick={this.openEditWindow}
          onReload={this.reload}
          onAuthenticateClick={this.onAuthenticateClick}
          onLogoutClick={this.onLogoutClick}
          onOpenErrorWarningList={this.openErrorWarningList}
        />
        <BaseModalForm
          visible={this.state.sensitiveDataModalVisibility}
          headerText={<FormattedMessage id={getTranslationKey("virtualUsers", "sensitiveData")} />}
          onCancelClick={this.onSensitiveDataModalCancelClick}
          onOkClick={this.onSensitiveDataModalOkClick}
          allowPrisitineSubmit={true}
          cancelButtonText={<FormattedMessage id={getTranslationKey("button", "cancel")} />}
          okButtonText={<FormattedMessage id={getTranslationKey("commandBar", "authenticate")} />}
          render={() => <SensitiveDataForm virtualUser={this.props.selectedVirtualUser} />}
        />
        <CreateEditVirtualUserContainer
          visibility={this.state.createWindowVisibility || this.state.editWindowVisibility}
          onClose={this.state.editWindowVisibility ? this.closeEditWindow : this.closeCreateWindow}
          onCreate={this.reload}
          selected={this.props.selectedVirtualUser as any}
          isEdit={this.state.editWindowVisibility}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={VIRTUAL_USERS_COLUMNS}
          tableId={VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()}
          preferencesId={VIRTUAL_USERS_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"virtualUsers"}
          tableActions={getVirtualUsersTableActions}
        />
        {this.props.selectedVirtualUser && (
          <ErrorAuthBox
            onClose={this.closeErrorWarningList}
            visible={this.state.errorWarningListVisible}
            selectedVirtualUser={this.props.selectedVirtualUser}
          />
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(virtualUsersActions, dispatch),
    ...bindActionCreators(
      getVirtualUsersTableActions(VIRTUAL_USERS_TABLES_CONFIG.MAIN.id()),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState) => ({
    selected: virtualUsersDataSelectors.getSelectedItemsSelector(
      state,
      VIRTUAL_USERS_TABLES_CONFIG.MAIN.id(),
    ),
    selectedVirtualUser: virtualUsersDataSelectors.getSelectedItemSelector(
      state,
      VIRTUAL_USERS_TABLES_CONFIG.MAIN.id(),
    ) as any as VirtualUser,
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(VirtualUsersTableContainer);
