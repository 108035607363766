import styled from "@emotion/styled";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import CommandBar from "./components/CodeTemplateGroupsCommandBar";

import {
  CODE_TEMPLATE_GROUPS_COLUMNS,
  CODE_TEMPLATE_GROUPS_TABLES_CONFIG,
} from "@app/modules/codeTemplateGroups/codeTemplateGroups.table";

import { getCodeTemplateGroupsTableActions } from "@app/modules/codeTemplateGroups/codeTemplateGroups.actions";
import { codeTemplateGroupsDataSelectors } from "@app/modules/codeTemplateGroups/codeTemplateGroups.selectors";
import CreateCodeTemplateGroupPanel from "@app/modules/codeTemplateGroups/CreateEditCodeTemplateGroupPanel";
import { Modal } from "antd";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds, DataTestIdProp } from "@app/utils/dataTestIds";

enum Panels {
  CREATE = "create",
  EDIT = "edit",
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const { confirm } = Modal;
export const CodeTemplateGroupsTableContainer: React.FunctionComponent<InjectedIntlProps> = ({
  intl,
}) => {
  const dispatch = useDispatch();
  const [openedPanel, setOpenedPanel] = useState<Panels | undefined>(undefined);

  const onAddClick = () => {
    setOpenedPanel(Panels.CREATE);
  };
  const onEditClick = () => {
    setOpenedPanel(Panels.EDIT);
  };

  const closePanel = () => {
    setOpenedPanel(undefined);
  };

  const selected = useSelector((state: ApplicationState) =>
    codeTemplateGroupsDataSelectors.getSelectedSelector(
      state,
      CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.id(),
    ),
  );
  const selectedCodeTemplateGroups = useSelector((state: ApplicationState) =>
    codeTemplateGroupsDataSelectors.getSelectedItemSelector(
      state,
      CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.id(),
    ),
  );

  const onReloadClick = useCallback(
    () =>
      dispatch(
        getCodeTemplateGroupsTableActions(CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.id()).load({}),
      ),
    [],
  );

  const onRemoveClick = () => {
    const { formatMessage } = intl;
    confirm({
      title: formatMessage({
        id: getTranslationKey(
          "messages",
          "confirm",
          selected.length > 1 ? "delCodeTemplateGroups" : "delCodeTemplateGroup",
        ),
      }),
      okText: formatMessage({ id: getTranslationKey("button", "delete") }),
      okType: "danger",
      cancelText: formatMessage({ id: getTranslationKey("button", "cancel") }),
      onOk: () => {
        dispatch(
          getCodeTemplateGroupsTableActions(CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.id()).delete({
            ids: selected,
          }),
        );
      },
      okButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_DELETE },
      cancelButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_CANCEL },
    });
  };
  return (
    <Container>
      <CommandBar
        onAddClick={onAddClick}
        onEditClick={onEditClick}
        isEditDisabled={!selectedCodeTemplateGroups}
        onReloadClick={onReloadClick}
        onRemoveClick={onRemoveClick}
        selected={selected}
      />
      <CreateCodeTemplateGroupPanel
        visibility={!!openedPanel}
        onClose={closePanel}
        onSave={onReloadClick}
        isEdit={openedPanel === Panels.EDIT}
        selected={selectedCodeTemplateGroups}
      />
      <ConnectedTable
        pageable
        columnsConfig={CODE_TEMPLATE_GROUPS_COLUMNS}
        tableId={CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.id()}
        preferencesId={CODE_TEMPLATE_GROUPS_TABLES_CONFIG.MAIN.preferencesId}
        stateKey={"codeTemplateGroups"}
        tableActions={getCodeTemplateGroupsTableActions}
      />
    </Container>
  );
};

export default injectIntl(CodeTemplateGroupsTableContainer);
