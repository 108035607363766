import { PlainObject, SchedulerJobMapping } from "@ea/shared_types/types";
import { CustomColumn, ColumnConfig } from "@ea/shared_components/src/Table/common.tables";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { ColumnFilters } from "@ea/shared_components/src/redux/common.filters";

export const SCHEDULER_FLOW_COLUMNS_CONFIG: PlainObject<CustomColumn<SchedulerJobMapping>> = {
  taskScriptId: {
    label: getTranslationKey("table", "scriptId"),
    dataIndex: "taskScriptId",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  lineNum: {
    label: getTranslationKey("table", "lineNum"),
    dataIndex: "lineNum",
    sortable: true,
    filter: {
      filterType: ColumnFilters.RANGE,
    },
  },
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  projectName: {
    label: getTranslationKey("table", "projectName"),
    dataIndex: "projectId",
  },
  projectEnvName: {
    label: getTranslationKey("table", "scriptEnvironment"),
    dataIndex: "projectEnvName",
  },
  projectEnvType: {
    label: getTranslationKey("table", "envType"),
    dataIndex: "projectEnvType",
  },
  repeatCount: {
    label: getTranslationKey("table", "repeatCount"),
    dataIndex: "repeatCount",
    sortable: true,
    filter: {
      filterType: ColumnFilters.RANGE,
    },
  },
  overrideRunParams: {
    label: getTranslationKey("commandBar", "override"),
    dataIndex: "overrideRunParams",
    sortable: false,
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        { label: getTranslationKey("commandBar", "true"), value: true as any },
        { label: getTranslationKey("commandBar", "false"), value: false as any },
      ],
    },
  },
  virtualUserId: {
    label: getTranslationKey("table", "overridenVu"),
    dataIndex: "runParams.virtualUserId" as any,
    sortable: false,
  },
  environmentId: {
    label: getTranslationKey("table", "overridenEnv"),
    dataIndex: "runParams.environmentId" as any,
    sortable: false,
  },
};

export const SCHEDULER_FLOW_COLUMNS: ColumnConfig<SchedulerJobMapping>[] = [
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.taskScriptId,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.lineNum,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.projectName,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.projectEnvName,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.projectEnvType,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.repeatCount,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.overrideRunParams,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.virtualUserId,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: SCHEDULER_FLOW_COLUMNS_CONFIG.environmentId,
    frameworkProps: {
      width: "10%",
    },
  },
];

export const SCHEDULER_FLOW_TABLES_CONFIG = {
  SINGLE_SCHEDULER_FLOW: {
    id: (id: number) => `SINGLE_SCHEDULER_FLOW_${id}`,
    preferencesId: "SINGLE_SCHEDULER_FLOW",
  },
};
