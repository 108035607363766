import actionCreatorFactory from "typescript-fsa";

import { normalizeActions } from "@ea/shared_components/redux/common.actions";
import {
  createDataModuleActions,
  createTableActionsGetter,
} from "@ea/shared_components/redux/common.data.actions";
import { Sequence } from "@ea/shared_types/types";

const actionCreator = actionCreatorFactory("SEQUENCES_MODULE");

export const moduleActions = createDataModuleActions<Sequence>(actionCreator);
export const getSequencesTableActions = createTableActionsGetter(
  normalizeActions(moduleActions.table),
);
export const sequencesActions = {
  ...normalizeActions(moduleActions.data),
};
export const sequencesActionCreators = {
  ...moduleActions,
};
