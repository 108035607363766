import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { FormattedMessage } from "react-intl";
import { ApplicationState } from "@app/modules/app.reducers";
import { ColumnConfig } from "@ea/shared_components/Table/common.tables";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { LogsState } from "@app/modules/logs";
import CommandBar from "@app/modules/logs/components/LogsCommandBar";
import { API } from "@app/services/api/api";
import StatisticsModal from "../components/StatisticsModal";
import { logsDataSelectors } from "../logs.selectors";
import { LOGS_COLUMNS_CONFIG, LOGS_TABLES_CONFIG } from "../logs.table";
import ExecutionStatusChartContainer from "../statistics/ExecutionStatusChart.container";
import { getLogsTableActions } from "../logs.actions";
import {
  CsvExportAdditionalFields,
  ExportToCsvModels,
  Log,
  ReportExtension,
} from "@ea/shared_types/types";
import { toast } from "react-toastify";
import ShowGalleryContainer from "../components/ShowGalleryContainer";
import ErrorWarningList from "../components/ErrorWarningList";
import { getProjectsTreeSelector } from "@app/modules/projects/projects.selectors";
import { PROJECTS_TABLES_CONFIG } from "@app/modules/projects/projects.tables";
import { ITS_TABLES_CONFIG } from "@app/modules/issueTrackingTool/its.table";
import { getIstTableActions } from "@app/modules/issueTrackingTool/its.actions";
import { itsDataSelectors } from "@app/modules/issueTrackingTool/its.selectors";
import {
  currentUserIdSelector,
  currentUserSettingsSelector,
} from "@ea/shared_components/auth/auth.selectors";
import { generateReportName } from "@app/utils/reports";
import CapturedFilesModal from "../components/CapturedFilesModal";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { disabledFeaturesSelector } from "@app/modules/globalSettings/globalSettings.selectors";
import KpiStatisticsModal from "@app/modules/kpis/components/KpiStatisticsModal";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { exportToCsv } from "@app/utils/exportCsv";
import { PanelType } from "@ea/shared_components/Panel";
import { LogsCsvAdditionalFieldForm } from "../components/LogsCsvAdditionalFieldForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
});

const SCRIPT_LOGS_COLUMNS: ColumnConfig<Log>[] = [
  {
    props: LOGS_COLUMNS_CONFIG.sessionId,
    frameworkProps: {
      width: "11.1%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.projectId,
    frameworkProps: {
      width: "11.1%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.envName,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.virtualUserName,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptStatus,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.scriptVersion,
    frameworkProps: {
      width: "5%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.startTime,
    frameworkProps: {
      width: "11.1%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.endTime,
    frameworkProps: {
      width: "11.1%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.duration,
    frameworkProps: {
      width: "11.1%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.state,
    frameworkProps: {
      width: "11.1%",
    },
  },
  {
    props: LOGS_COLUMNS_CONFIG.status,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.apiKeyName,
    frameworkProps: {},
  },
  {
    props: LOGS_COLUMNS_CONFIG.executorName,
  },
  {
    props: LOGS_COLUMNS_CONFIG.integrationMetadata,
  },
];

interface IScriptLogsState extends LogsState {
  screenshotGalleryVisible: boolean;
  errorWarningListVisible: boolean;
  capturedFilesVisible: boolean;
  clickedStepLogId?: number;
  kpiVisible: boolean;
  isExporting: boolean;
  exportWithAdditionalFieldsVisibile: boolean;
}
class ScriptLogs extends React.Component<IConnectProps, IScriptLogsState> {
  connectedTable: any;

  constructor(props: IConnectProps) {
    super(props);

    this.state = {
      persistentQuery: {
        and: [{ scriptId: props.id, sessionId: props.match.params.sessionId }],
      },
      statisticsVisible: false,
      capturedFilesVisible: false,
      screenshotGalleryVisible: false,
      errorWarningListVisible: false,
      kpiVisible: false,
      isExporting: false,
      exportWithAdditionalFieldsVisibile: false,
    };
  }

  componentDidMount() {
    this.props.actions.its.load({});
  }

  componentWillReceiveProps(nextProps: IConnectProps) {
    if (this.props.id !== nextProps.id) {
      this.setState({
        persistentQuery: {
          and: [{ scriptId: nextProps.id, sessionId: nextProps.match.params.sessionId }],
        },
      });
    }
  }

  compareQuery = (currentQuery, prevQuery) =>
    currentQuery.and[0].scriptId !== prevQuery.and[0].scriptId ||
    currentQuery.and[0].sessionId !== prevQuery.and[0].sessionId ||
    currentQuery?.mappedWithDevops !== prevQuery?.mappedWithDevops;

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  openDetails = (item?: Log) => {
    const { selectedLog, location } = this.props;
    const selectedItem = (item && item.sessionId ? item : null) || selectedLog;

    // A browser automatically adds a trailing slash => '/' character to the end of a url if we *reload* the site or we *paste* the url into url bar (doesnt happen when we are on the site and we click some route button / tab).
    // this behaviour breaks our navigation in nested routes because we push path like `history/134` to the existing route and there is difference when we do it with url ending with '/' or not
    // url without '/': app/script/54/history after pushing `history/134` is `app/script/54/history/134`
    // url with '/': app/script/54/history/ after pushing `history/134` is `app/script/54/history/history/134   WRONG URL
    // so we get a double nested history in our url address. Right now we only have this problem in history section so we can temporary fix it with this workaround. We should investigate how to fix it completely in the future

    const containsTrailingSlash = location.pathname.includes("history/");
    if (selectedItem) {
      this.props.history.push({
        pathname: `${containsTrailingSlash ? "" : "history/"}${selectedItem.sessionId}`,
        search: `?scriptLogId=${selectedItem.id}`,
      });
    }
  };

  openStatistics = () => {
    this.setState({
      statisticsVisible: true,
    });
  };

  closeStatistics = () => {
    this.setState({
      statisticsVisible: false,
    });
  };

  onTerminate = async () => {
    const { selectedLogs } = this.props;
    if (selectedLogs) {
      try {
        await API.killInBackgroundExecution({
          selectedLogs,
        });
      } catch (error) {
        toast.error(
          <FormattedMessage id={getTranslationKey("messages", "error", "termination")} />,
        );
        console.error(error);
        return;
      }
      toast.success(
        <FormattedMessage id={getTranslationKey("messages", "success", "termination")} />,
      );
      this.reload();
    }
  };

  generateReport = async () => {
    const { selectedLog, userSettings, tableId, actions } = this.props;
    if (selectedLog) {
      try {
        const extension = userSettings.reportExtension || ReportExtension.docx;

        actions.setIsGeneratingReport({
          isGeneratingReport: true,
        });

        await API.generateReport({
          logId: selectedLog.id,
          extension,
          tableId,
          reportName: generateReportName(selectedLog, extension),
        });
      } catch (error) {
        toast.error(error.message);
        actions.setIsGeneratingReport({
          isGeneratingReport: false,
        });
      }
    }
  };

  openGallery = () => {
    this.setState({
      screenshotGalleryVisible: true,
      clickedStepLogId: this.props.selectedLog && this.props.selectedLog.id,
    });
  };

  closeScreenshotGallery = () => {
    this.setState({
      screenshotGalleryVisible: false,
    });
  };

  openErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: true,
      clickedStepLogId: this.props.selectedLog && this.props.selectedLog.id,
    });
  };

  closeErrorWarningList = () => {
    this.setState({
      errorWarningListVisible: false,
    });
  };

  toggleFilterDevopsExecutions = () => {
    const { persistentQuery } = this.state;
    const { mappedWithDevops, ...rest } = persistentQuery;

    if (!mappedWithDevops) {
      this.setState({
        persistentQuery: {
          ...persistentQuery,
          mappedWithDevops: true,
        },
      });
    } else {
      this.setState({
        persistentQuery: rest,
      });
    }
  };

  onPerformanceCountersClick = () => {
    this.setState(({ kpiVisible }) => ({
      kpiVisible: !kpiVisible,
    }));
  };

  exportToExcel = async (additionalFields?: CsvExportAdditionalFields) => {
    const { tableParams, selectedLogs, userId } = this.props;
    const preferencesId = LOGS_TABLES_CONFIG.SCRIPT_LOGS.preferencesId;

    const params = createRequestParams(tableParams, LOGS_COLUMNS_CONFIG, {
      defaultOrder: ["startTime DESC"],
    });

    const fields = SCRIPT_LOGS_COLUMNS.map((log) => log.props.dataIndex).filter(
      (field) => field !== LOGS_COLUMNS_CONFIG.datasource.dataIndex,
    );

    params.filter = {
      ...params.filter,
      include: {
        relation: "executionStepLogs",
        scope: {
          fields: [
            "messages",
            "parentStepLogId",
            "lineNum",
            "label",
            "labelKey",
            "labelParams",
            "id",
          ],
          order: "id desc",
        },
      },
    };

    await exportToCsv({
      params,
      fields,
      modelName: ExportToCsvModels.LOGS,
      selected: selectedLogs,
      onExportStart: () => this.setState({ isExporting: true }),
      onExportFinish: () => this.setState({ isExporting: false }),
      userId: userId!,
      preferencesId,
      additionalFields,
    });
  };

  onExportWithAdditionalFieldsClick = async () => {
    this.setState({ exportWithAdditionalFieldsVisibile: true });
  };
  closeExportWithAdditionalFields = () => {
    this.setState({ exportWithAdditionalFieldsVisibile: false });
  };

  exportWithAdditionalFields = async (additionalFields: CsvExportAdditionalFields) => {
    this.closeExportWithAdditionalFields();
    this.exportToExcel(additionalFields);
  };

  render() {
    const {
      selectedLogs,
      tableId,
      selectedLog,
      projects,
      its,
      disabledFeatures,
      selectedItems,
      tableParams,
    } = this.props;
    const {
      persistentQuery,
      statisticsVisible,
      capturedFilesVisible,
      screenshotGalleryVisible,
      errorWarningListVisible,
      kpiVisible,
      exportWithAdditionalFieldsVisibile,
      isExporting,
    } = this.state;
    return (
      <Container>
        <CommandBar
          selectedLogs={selectedLogs}
          selectedLog={selectedLog}
          onTerminate={this.onTerminate}
          onOpen={this.openDetails}
          onReload={this.reload}
          onShowStatistics={this.openStatistics}
          onReportGenerate={selectedLog ? this.generateReport : undefined}
          selectedItems={selectedItems}
          onOpenScreenshotGallery={this.openGallery}
          screenshotSelectedMode
          onOpenErrorWarningList={this.openErrorWarningList}
          isGeneratingReport={tableParams.isGeneratingReport}
          onOpenCapturedFilesModal={() => {
            this.setState({ capturedFilesVisible: true });
          }}
          onFilterDevopsExecutions={this.toggleFilterDevopsExecutions}
          showOnlyDevopsExecutions={persistentQuery.mappedWithDevops}
          onPerformanceCountersClick={() => this.onPerformanceCountersClick()}
          onExport={this.exportToExcel}
          onExportWithAdditionalFieldsClick={this.onExportWithAdditionalFieldsClick}
          isExporting={isExporting}
        />
        <StatisticsModal
          visible={!!statisticsVisible}
          title={<FormattedMessage id={getTranslationKey("common", "label", "statistics")} />}
          onClose={this.closeStatistics}
        >
          {statisticsVisible && (
            <ExecutionStatusChartContainer
              columnsConfig={LOGS_COLUMNS_CONFIG}
              tableId={tableId}
              title={
                <FormattedMessage id={getTranslationKey("common", "label", "executionStatus")} />
              }
            />
          )}
        </StatisticsModal>
        <CapturedFilesModal
          visible={!!capturedFilesVisible}
          title={<FormattedMessage id={getTranslationKey("commandBar", "capturedFiles")} />}
          onClose={() => {
            this.setState({ capturedFilesVisible: false });
          }}
          selectedLog={selectedLog}
        />
        {kpiVisible && (
          <KpiStatisticsModal
            visible={kpiVisible}
            onClose={this.onPerformanceCountersClick}
            executionLogId={this.props.selectedLog?.id!}
          />
        )}
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={SCRIPT_LOGS_COLUMNS}
          comparePersistentQuery={this.compareQuery}
          tableId={tableId}
          preferencesId={LOGS_TABLES_CONFIG.SCRIPT_LOGS.preferencesId}
          stateKey={"logs"}
          tableActions={getLogsTableActions}
          onRowDoubleClick={this.openDetails}
          persistentQuery={persistentQuery}
          autoReload
          dynamicFilterValues={{
            projectId: projects,
          }}
          disabledFeatures={disabledFeatures}
        />
        <ShowGalleryContainer
          onClose={this.closeScreenshotGallery}
          selectedLog={selectedLog}
          visible={screenshotGalleryVisible}
        />
        <ErrorWarningList
          onClose={this.closeErrorWarningList}
          visible={errorWarningListVisible}
          selectedLogs={selectedItems}
          its={its}
        />
        <PanelFormFinal
          visibility={exportWithAdditionalFieldsVisibile}
          panelType={PanelType.MODAL}
          customWidth={500}
          headerText={getTranslationKey("common", "header", "exportWithAdditional")}
          onCancelClick={this.closeExportWithAdditionalFields}
          onOkClick={this.exportWithAdditionalFields}
          cancelButtonText={getTranslationKey("button", "cancel")}
          okButtonText={getTranslationKey("button", "export")}
          initialValues={{}}
          allowPrisitineSubmit
          render={({ change, values }) => (
            <LogsCsvAdditionalFieldForm values={values} change={change} />
          )}
        />
      </Container>
    );
  }
}

type ScriptLogsProps = RouteComponentProps<any> & { id: number };
const mapDispatchToProps = (dispatch: Dispatch, { id }: ScriptLogsProps) => ({
  actions: {
    ...bindActionCreators(getLogsTableActions(LOGS_TABLES_CONFIG.SCRIPT_LOGS.id(id)), dispatch),
    its: {
      ...bindActionCreators(getIstTableActions(ITS_TABLES_CONFIG.MAIN.id()), dispatch),
    },
  },
});

const mapStateToProps = (state: ApplicationState, { id }: ScriptLogsProps) => {
  const userSettings = currentUserSettingsSelector(state);

  return {
    ...state.logs,
    id,
    tableId: LOGS_TABLES_CONFIG.SCRIPT_LOGS.id(id),
    tableParams: logsDataSelectors.getParamsSelector(state, LOGS_TABLES_CONFIG.SCRIPT_LOGS.id(id)),
    selectedLogs: logsDataSelectors.getSelectedSelector(
      state,
      LOGS_TABLES_CONFIG.SCRIPT_LOGS.id(id),
    ),
    selectedItems: logsDataSelectors.getSelectedItemsSelector(
      state,
      LOGS_TABLES_CONFIG.SCRIPT_LOGS.id(id),
    ),
    selectedLog: logsDataSelectors.getSelectedItemSelector(
      state,
      LOGS_TABLES_CONFIG.SCRIPT_LOGS.id(id),
    ),
    projects: getProjectsTreeSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()),
    its: userSettings.its ? itsDataSelectors.getItemSelector(state, userSettings.its) : undefined,
    userSettings,
    disabledFeatures: disabledFeaturesSelector(state),
    userId: currentUserIdSelector(state),
  };
};

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator> & RouteComponentProps<any>;

export default withRouter(connectCreator(ScriptLogs));
