import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";

import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ILocalizationFormProps {
  readOnly?: boolean;
  change: (name: string, value: any) => void;
}

class LocalizationForm extends React.Component<ILocalizationFormProps, any> {
  render() {
    const { readOnly } = this.props;

    return (
      <>
        <InputField
          name="locale"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          parse={(value) => (value && value.trim ? value.trim() : value)}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <TextAreaField
          name="description"
          placeholder={getTranslationKey("common", "placeholder", "description")}
          readOnly={readOnly}
          autoSize={{ minRows: 2, maxRows: 6 }}
          label={getTranslationKey("common", "label", "description")}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
      </>
    );
  }
}

export default LocalizationForm;
