import { Col, Row } from "antd";
import { getIn } from "final-form";
import * as React from "react";
import { CodeTemplateWithGroup, CodeType } from "@ea/shared_types/types";
import { VariablesGroup } from "../redux/common.models";
import { LayoutContext } from "../Form/FormLayoutContext";
import FormLayout from "../Form/FormLayout";
import validators from "../Form/validators";
import { faEquals } from "@fortawesome/fontawesome-free-solid";
import RadioField, { RadioType } from "../Form/Fields/RadioField";
import InputField from "../Form/Fields/InputField";
import ValuePickerField from "./CodePickerField";
import { CodeTemplateField } from "../CodeTemplateField/CodeTemplateField";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import MonacoEditorField from "./Monaco/MonacoEditorField";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import { DataTestIds } from "../utils/dataTestHelpers";

type LabelNode = () => React.ReactNode;
type ICodeFieldOptionalProps = {
  variablesGroups?: VariablesGroup[];
  codeTemplates?: CodeTemplateWithGroup[];
  readOnly?: boolean;
  label?: string | LabelNode | null;
  required?: boolean;
  limitOptions?: CodeType[];
};

type ICodeFieldRequiredProps = {
  prefix: string;
  values: any;
  change: any;
};

type ICodeFieldProps = ICodeFieldRequiredProps & ICodeFieldOptionalProps;

const labels = {
  [CodeType.ADVANCED]: "Advanced Code",
  [CodeType.EXPRESSION]: "Expression",
  [CodeType.PICK]: "Picker",
  [CodeType.CONSTANT]: "Constant",
  [CodeType.CODE_TEMPLATE]: "Code template",
};

const options = [
  { text: labels[CodeType.CONSTANT], value: CodeType.CONSTANT },
  { text: labels[CodeType.PICK], value: CodeType.PICK },
  { text: labels[CodeType.EXPRESSION], value: CodeType.EXPRESSION },
  { text: labels[CodeType.ADVANCED], value: CodeType.ADVANCED },
  { text: labels[CodeType.CODE_TEMPLATE], value: CodeType.CODE_TEMPLATE },
];

class CodeField extends React.Component<ICodeFieldProps & InjectedIntlProps, any> {
  constantInput: any;

  valuesMemory = {};

  renderValueContainer(valueField: any) {
    const { label, values } = this.props;
    const isNode = !(label && typeof label === "string");

    const additionalLabelColSpan = !getIn(values, "value.code.assignedVariableType") ? 0 : 1;

    if (!isNode) {
      return (
        <LayoutContext.Consumer>{(layout) => valueField(layout.readOnly)}</LayoutContext.Consumer>
      );
    }

    return (
      <LayoutContext.Consumer>
        {(layout) => (
          <Row gutter={6}>
            <Col
              {...layout.labelCol}
              span={
                layout.labelCol && layout.labelCol.span
                  ? parseInt(layout.labelCol.span as any, 10) - additionalLabelColSpan
                  : 0
              }
              data-testid={DataTestIds.CODEFIELD_LABEL_WRAPPER}
            >
              <FormLayout
                wrapperCol={{ span: 22, offset: 2 }}
                labelCol={{ span: 0 }}
                readOnly={layout.readOnly}
              >
                {label && (label as any)()}
              </FormLayout>
            </Col>
            {label && (
              <Col
                span={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40px", // todo: how to set it dynamically
                }}
              >
                <FontAwesomeIcon icon={faEquals} />
              </Col>
            )}
            <Col {...layout.wrapperCol} data-testid={DataTestIds.CODEFIELD_VALUE_WRAPPER}>
              <FormLayout
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 0 }}
                readOnly={layout.readOnly}
              >
                {valueField(layout.readOnly)}
              </FormLayout>
            </Col>
          </Row>
        )}
      </LayoutContext.Consumer>
    );
  }

  componentDidMount() {
    const mode = getIn(this.props.values, `${this.props.prefix}.type`);
    if (!mode) {
      this.props.change(`${this.props.prefix}.type`, CodeType.CONSTANT);
    }
  }

  componentDidUpdate(prevProps: ICodeFieldProps) {
    const prevMode = getIn(prevProps.values, `${prevProps.prefix}.type`);
    const mode = getIn(this.props.values, `${this.props.prefix}.type`);

    if (
      prevMode !== undefined &&
      prevMode !== mode &&
      mode === CodeType.CONSTANT &&
      this.constantInput
    ) {
      this.constantInput.focus();
    }
  }

  onModeChange = (v: any) => {
    const oldValue = getIn(this.props.values, `${this.props.prefix}.value`);
    const oldType = getIn(this.props.values, `${this.props.prefix}.type`);

    this.valuesMemory[oldType] = oldValue;

    const memoryValue = this.valuesMemory[v.target ? v.target.value : v];
    const newInitValue =
      memoryValue === undefined && v.target.value === CodeType.CONSTANT ? "" : memoryValue;

    if (this.props.change) {
      this.props.change(`${this.props.prefix}.value`, newInitValue);
      this.props.change(`${this.props.prefix}.params`, undefined);
    }
  };

  saveInMemory = (type: string) => (v: any) => {};

  render() {
    const {
      prefix,
      values,
      variablesGroups,
      label,
      required,
      intl,
      limitOptions,
      codeTemplates,
      change,
    } = this.props;
    const mode = getIn(values, `${prefix}.type`);
    const selectedStepId = getIn(values, "id");

    const normalizedLabel = typeof label === "string" ? label : undefined;

    const { monacoValidator } = validators({ formatMessage: intl.formatMessage });
    const isPassword =
      values && values.commandId === CoreCommandsIds.password ? "password" : undefined;

    const limitedOptions =
      limitOptions && limitOptions.length > 0
        ? options.filter((opt) => limitOptions.find((opt2) => opt2 === opt.value))
        : options;
    return (
      <>
        <RadioField
          name={`${prefix}.type`}
          type={RadioType.Button}
          onChange={this.onModeChange}
          required={required}
          options={limitedOptions}
          defaultValue={CodeType.CONSTANT}
          formItemControlStyle={{ display: "flex", justifyContent: "center" }}
        />
        {this.renderValueContainer((readOnly) => (
          <>
            {mode === CodeType.CONSTANT && (
              <InputField
                name={`${prefix}.value`}
                onChange={this.saveInMemory(CodeType.CONSTANT)}
                label={normalizedLabel}
                parse={(value) => (value === null ? "" : value)}
                placeholder="Write value"
                ref={(element) => (this.constantInput = element)}
                type={isPassword}
                data-testid={DataTestIds.FORM_INPUT_CODE_VALUE}
              />
            )}
            {mode === CodeType.PICK && (
              <ValuePickerField
                name={`${prefix}.value`}
                onChange={this.saveInMemory(CodeType.PICK)}
                variablesGroups={variablesGroups || []}
                required={required}
                label={normalizedLabel}
                data-testid={DataTestIds.FORM_VALUE_PICKER}
              />
            )}
            {mode === CodeType.EXPRESSION && (
              <MonacoEditorField
                name={`${prefix}.value`}
                onChange={this.saveInMemory(CodeType.EXPRESSION)}
                width="100%"
                height="32"
                readOnly={readOnly}
                key={`${selectedStepId ? `${selectedStepId}_` : ""}expressionEditor`}
                validate={monacoValidator}
                required={required}
                variablesGroups={variablesGroups}
                mode={CodeType.EXPRESSION}
                label={normalizedLabel}
                data-testid={DataTestIds.FORM_MONACO_EDITOR_EXPRESSION}
              />
            )}
            {mode === CodeType.ADVANCED && (
              <MonacoEditorField
                name={`${prefix}.value`}
                onChange={this.saveInMemory(CodeType.ADVANCED)}
                width="100%"
                height="400"
                readOnly={readOnly}
                key={`${selectedStepId ? `${selectedStepId}_` : ""}advancedEditor`}
                required={required}
                validate={monacoValidator}
                variablesGroups={variablesGroups}
                mode={CodeType.ADVANCED}
                label={normalizedLabel}
                data-testid={DataTestIds.FORM_MONACO_EDITOR_ADVANCED}
                codeTemplates={codeTemplates}
              />
            )}
            {mode === CodeType.CODE_TEMPLATE && (
              <CodeTemplateField
                codeTemplates={codeTemplates}
                prefix={prefix}
                values={values}
                change={change}
                variablesGroups={variablesGroups}
              />
            )}
          </>
        ))}
      </>
    );
  }
}

export default injectIntl(CodeField);
