import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "../app.reducers";
import { RouteProps, RouteComponentProps, withRouter, Redirect } from "react-router";
import { toast } from "react-toastify";
import { saveToken } from "@ea/shared_components/src/utils/auth";
import { authActions } from "./auth.actions";
import { ROUTES } from "@app/routes";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";

interface ISSOProps {}

interface ISSOState {
  hasBeenLoggedIn: boolean;
  hasBeenChecked: boolean;
}

class SSO extends React.Component<ISSOProps & IConnectProps & RouteProps, ISSOState> {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenLoggedIn: false,
      hasBeenChecked: false,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get("token");
    const user = params.get("user");
    const error = params.get("error");

    if (!token || !user) {
      toast.error(
        <>
          <FormattedMessage id={getTranslationKey("messages", "error", "failedLogin")} />
          {": "}
          <span> {error} </span>
        </>,
      );
      this.setState({
        hasBeenChecked: true,
      });
      return;
    }

    saveToken({ id: token, userId: user });

    this.props.init({ isLoggedIn: true });

    this.setState({
      hasBeenLoggedIn: true,
      hasBeenChecked: true,
    });
  }

  render() {
    const { hasBeenLoggedIn, hasBeenChecked } = this.state;

    if (!hasBeenChecked) {
      return null;
    }

    if (hasBeenLoggedIn) {
      return <Redirect to={ROUTES.projects} />;
    }

    return <Redirect to={ROUTES.login} />;
  }
}

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any>) => props,
  authActions,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(SSO));
