import * as React from "react";
import { API } from "@app/services/api/api";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { Step, Project } from "@ea/shared_types/types";
import { getStepCommand } from "@app/packs/packs.helpers";
import { FormChunkApi } from "@ea/runner_loader/ea.internal.types";
import { EmptyField } from "@ea/shared_components";
import CreateStepCommonForm from "./Forms/CreateStepCommonForm";
import { detectAndCreateNewVariables } from "@app/modules/variables/variables.helpers";

type INewStepPanelProps = Omit<FormChunkApi, "steps" | "values" | "change"> & {
  isOpen: boolean;
  close: () => void;
  onCreate?: (values: any, platform: string) => void;
  afterCreate?: (step: Step) => void;
  commandId: string;
  headerText: string | React.ReactNode;
  existingSteps: Step[];
  loadLocalVariables: any;
  platform: string;
  project: Project;
};

export class NewStepPanel extends React.Component<INewStepPanelProps> {
  create = async (values) => {
    const { script, commandId, platform, onCreate, afterCreate, close, loadLocalVariables } =
      this.props;

    if (onCreate) {
      onCreate(values, platform);
      close();
      return;
    }

    const anyCreated = await detectAndCreateNewVariables(
      values.value,
      commandId,
      script.id,
      platform,
    );

    if (anyCreated) {
      loadLocalVariables(script.id);
    }

    const response = await API.createStep({
      label: values.label,
      delay: values.delay,
      taskScriptId: script.id,
      value: values.value,
      lineNum: values.lineNum + 1,
      commandId,
      platform: {
        id: platform,
      },
    });

    if (afterCreate) {
      afterCreate(response);
    }

    close();
  };

  getFormChunk = (api: FormChunkApi) => {
    const { commandId, platform } = this.props;
    const command = getStepCommand({ platform: { id: platform }, commandId });
    const FormCommandBody = command ? command.getFormChunk(api) : () => {};

    return FormCommandBody();
  };

  render() {
    const { isOpen, close, commandId, existingSteps, project, script, headerText, platform } =
      this.props;

    const command = getStepCommand({ platform: { id: platform }, commandId });

    if (!command) {
      console.warn(`No support for command: ${commandId}`);
      return null;
    }

    if (!command.getInitialValue) {
      throw new Error(`Command: ${commandId} doesn't implement default values`);
    }

    return (
      <PanelFormFinal
        visibility={isOpen}
        validate={command ? command.validate : undefined}
        onCancelClick={close}
        onOkClick={this.create}
        initialValues={{ value: command.getInitialValue({ project, script }) }}
        headerText={headerText}
        panelType={PanelType.MEDIUM}
        render={({ values, form }) => (
          <>
            <EmptyField name="commandId" defaultValue={commandId} />
            <CreateStepCommonForm steps={existingSteps} />
            {this.getFormChunk({
              ...this.props,
              steps: existingSteps,
              values,
              change: form.change,
            })}
          </>
        )}
      />
    );
  }
}
