import styled from "@emotion/styled";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import CommandBar from "./components/KpisCommandBar";

import { KPIS_COLUMNS, KPIS_TABLES_CONFIG } from "@app/modules/kpis/kpis.table";

import { getKpisTableActions } from "@app/modules/kpis/kpis.actions";
import { kpisDataSelectors } from "@app/modules/kpis/kpis.selectors";
import CreateEditKpiContainer from "@app/modules/kpis/CreateEditKpi.container";
import { Modal } from "antd";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds, DataTestIdProp } from "@app/utils/dataTestIds";

enum Panels {
  CREATE = "create",
  EDIT = "edit",
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

const { confirm } = Modal;
export const KpisTableContainer: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const dispatch = useDispatch();
  const [openedPanel, setOpenedPanel] = useState<Panels | undefined>(undefined);

  const onAddClick = () => {
    setOpenedPanel(Panels.CREATE);
  };
  const onEditClick = () => {
    setOpenedPanel(Panels.EDIT);
  };

  const closePanel = () => {
    setOpenedPanel(undefined);
  };

  const selected = useSelector((state: ApplicationState) =>
    kpisDataSelectors.getSelectedSelector(state, KPIS_TABLES_CONFIG.MAIN.id()),
  );
  const selectedKpi = useSelector((state: ApplicationState) =>
    kpisDataSelectors.getSelectedItemSelector(state, KPIS_TABLES_CONFIG.MAIN.id()),
  );

  const onReloadClick = useCallback(
    () => dispatch(getKpisTableActions(KPIS_TABLES_CONFIG.MAIN.id()).load({})),
    [],
  );

  const onRemoveClick = () => {
    const { formatMessage } = intl;
    confirm({
      title: formatMessage({
        id: getTranslationKey("messages", "confirm", selected.length > 1 ? "delKpis" : "delKpi"),
      }),
      okText: formatMessage({ id: getTranslationKey("button", "delete") }),
      okType: "danger",
      cancelText: formatMessage({ id: getTranslationKey("button", "cancel") }),
      onOk: () => {
        dispatch(getKpisTableActions(KPIS_TABLES_CONFIG.MAIN.id()).delete({ ids: selected }));
      },
      okButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_DELETE },
      cancelButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_CANCEL },
    });
  };
  return (
    <Container>
      <CommandBar
        onAddClick={onAddClick}
        onEditClick={onEditClick}
        isEditDisabled={!selectedKpi}
        onReloadClick={onReloadClick}
        onRemoveClick={onRemoveClick}
        selected={selected}
      />
      <CreateEditKpiContainer
        visibility={!!openedPanel}
        onClose={closePanel}
        onSave={onReloadClick}
        isEdit={openedPanel === Panels.EDIT}
        selected={selectedKpi}
      />
      <ConnectedTable
        pageable
        columnsConfig={KPIS_COLUMNS}
        tableId={KPIS_TABLES_CONFIG.MAIN.id()}
        preferencesId={KPIS_TABLES_CONFIG.MAIN.preferencesId}
        stateKey={"kpis"}
        tableActions={getKpisTableActions}
      />
    </Container>
  );
};

export default injectIntl(KpisTableContainer);
