import { createModuleEpics } from "@app/modules/app.reducers";
import { GlobalVariable } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { moduleActions } from "./globalVariables.actions";
import { GLOBAL_VARIABLES_COLUMNS_CONFIG } from "./globalVariables.table";
import { globalVariablesDataSelectors } from "./globalVariables.selectors";

const sequenceModuleEpics = createModuleEpics<GlobalVariable>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = globalVariablesDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, GLOBAL_VARIABLES_COLUMNS_CONFIG);
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createGlobalVariable,
    deleteItem: API.deleteGlobalVariable,
    editItem: API.editGlobalVariable,
    getItems: API.getGlobalVariables,
    getItemsCount: API.getGlobalVariableCount,
  },
});

export default sequenceModuleEpics;
