import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { Sequence } from "@ea/shared_types/types";
import { GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";

export const SEQUENCES_COLUMNS_CONFIG: PlainObject<CustomColumn<Sequence>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  format: {
    label: getTranslationKey("table", "format"),
    dataIndex: "format",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  nextval: {
    label: getTranslationKey("table", "nextval"),
    dataIndex: "nextval",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  updatedAt: {
    label: getTranslationKey("table", "updatedAt"),
    dataIndex: "updatedAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "updatedAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
};

export const SEQUENCES_COLUMNS: ColumnConfig<Sequence>[] = [
  {
    props: SEQUENCES_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: SEQUENCES_COLUMNS_CONFIG.format,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: SEQUENCES_COLUMNS_CONFIG.nextval,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: SEQUENCES_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: SEQUENCES_COLUMNS_CONFIG.updatedAt,
    frameworkProps: {
      width: "20%",
    },
  },
];

export const SEQUENCES_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_SEQUENCES",
  },
};
