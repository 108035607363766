import { IS_DOCUMENTATION_WIZARD } from "@app/modules/common/HideWhenDocumentationWizard";

const translationsConsts = {
  script: {
    singular: IS_DOCUMENTATION_WIZARD ? "Process" : "Script",
    plural: IS_DOCUMENTATION_WIZARD ? "Processes" : "Scripts",
  },
  error: {
    checkConsole: "Check console logs for more informations",
  },
};

const translations = {
  globalSettings: {
    elementDetection: "Element detection",
    timeouts: {
      inactivityTimeout: "Inactivity timeout",
      step: {
        findElementTimeout: "Step find element timeout",
      },
      optionalStep: {
        findElementTimeout: "Optional step find element timeout",
      },
      redirectTimeout: "Redirection to different environment timeout",
    },
    resolvers: {
      info: {
        intro:
          "This is a mechanism responsible for detecting and finding elements during recording or playback.",
        SMART:
          "Smart - a mechanism that generates paths to page elements in a way that improves stability and longevity of scripts",
        FALLBACK:
          "Mix - this mode uses 'SMART' as default but it also switches to the 'LEGACY' in case of any problems in detection",
        LEGACY: "Legacy - a basic mechanism based on XPATHs and SELECTORS",
      },
      mode: "Element detection mode",
      modes: {
        SMART: "Smart",
        FALLBACK: "Mix",
        LEGACY: "Legacy",
      },
      generateOnPlaybackInfo:
        "WARNING! Requires legacy mode to be set to work properly. This option enables regeneration of SMART detection mode data for steps. It's executed during scripts playback. It should be used temporarily in situations where steps don't work correctly.",
      generateOnPlayback:
        "Regenerate SMART detection mode data for steps. Requires Legacy mode to work properly",
      positionResolverThreshold: "Position resolver score threshold",
      positionResolverThresholdInfo:
        "The mechanism of finding and element on a webpage by its position is checking the metadata properties of found element and compares them with the recorded element data. This threshold determines the minimum amount of meta properties that must match.",
    },
    publishingCredentialsConfirmation: "Confirm credentials on status change",
    documentationTemplate: "Documentation template",
    docTemplate: "Doc template",
    selectDocumentationTemplate: "Select documentation template",
    reportTemplate: "Report template",
    selectReportTemplate: "Select report template",
    documentationSettings: "Documentation settings",
    powerBiSettings: "Power BI settings",
    placeholder: {
      powerBiPlaceholder: "Enter PowerBI URL",
    },
  },
  navigation: {
    unknownRoute: "Can't find page",
    loading: "Loading...",
  },
  button: {
    cancel: "Cancel",
    create: "Create",
    change: "Change",
    edit: "Edit",
    close: "Close",
    download: "Download",
    delete: "Delete",
    save: "Save",
    yes: "Yes",
    no: "No",
    clone: "Clone",
    publish: "Publish",
    openWorkItem: "Open work item",
    createWorkItem: "Create work item",
    run: "Run",
    record: "Record",
    changeStatus: "Change status",
    export: "Export",
    import: "Import",
    add: "Add",
    reload: "Reload",
    goToCloned: "Go to cloned script",
    default: "Default",
    custom: "Custom",
    generatePdf: "Generate PDF",
  },
  messageBuilder: {
    clone: "Clone",
    publish: "Publish",
    succesfully: "successful",
    goTo: "Go to",
    hasBeen: "has been",
    created: "created",
    published: "published",
    cloned: "cloned",
    publishNew: "Publish a new",
    dearUser: "Dear user,",
    pleaseContact: "Please contact",
  },
  commandBar: {
    addToGroup: "Add to group",
    createGroup: "Create a group",
    moveGroup: "Move to group",
    compare: "Compare",
    tokens: "Auth",
    actions: "Actions",
    add: "Add",
    create: "Create",
    close: "Close",
    refresh: "Refresh",
    copy: "Copy",
    fetch: "Fetch",
    view: "View",
    code: "Code",
    clone: "Clone",
    downloadDoc: "Download documentation",
    download: "Download",
    showClosed: "Show closed",
    hideClosed: "Hide closed",
    showInternal: "Show internal",
    hideInternal: "Hide internal",
    nonSupervisedRecord: "Non supervised Record",
    supervisedRecord: "Supervised record",
    script: translationsConsts.script.singular,
    shortcut: "Shortcut",
    open: "Open",
    start: "Start",
    record: "Record",
    play: "Play",
    flat: "Flat",
    grouped: "Grouped",
    statistics: "Statistics",
    generate: "Generate report",
    terminate: "Terminate",
    removeClose: "Remove/Close",
    password: "Change password",
    import: "Import",
    remove: "Remove",
    removeSelected: "Remove selected",
    edit: "Edit",
    editMode: "Edit mode",
    authenticate: "Authenticate",
    logout: "Logout",
    removeLinks: "Remove links",
    save: "Save",
    refreshApiKey: "Refresh API Key",
    assignValue: "Assign value to variable",
    newStep: "New step",
    assert: "Conditional",
    login: "Login",
    paste: "Paste",
    reorder: "Reorder",
    rework: "Record new steps",
    setRework: "Set recording position",
    resetRework: "Reset recording position",
    stopReorder: "Stop reordering",
    quickAction: "Quick actions",
    enableSteps: "Enable step(s)",
    disbaleSteps: "Disable step(s)",
    setStepsOptional: "Set step(s) optional",
    unsetStepsOptional: "Unset step(s) optional",
    aggregated: "Aggregated logs",
    nonAggregated: "Non-aggregated logs",
    screenshotGallery: "Screenshot gallery",
    showErrors: "Show errors/warnings",
    goTo: "Go to",
    export: "Export to file",
    exportToCsv: "Export to csv",
    exportSelectedToCsv: "Export selected to csv",
    override: "Override",
    markDefault: "Set as default",
    showWithWorkItems: `Show ${translationsConsts.script.plural.toLowerCase()} with work items`,
    hideWithWorkItems: `Show all ${translationsConsts.script.plural.toLowerCase()}`,
    addons: "Add-ons",
    capturedFiles: "Captured files",
    editTranslations: "Edit translations",
    showTranslations: "Show translations",
    excludeJobs: "Exclude results",
    ganttStartTime: "Change gantt start time",
    scriptsStartTime: `Change ${translationsConsts.script.plural.toLowerCase()} start time`,
    functions: "Functions",
    azureDevOps: "Azure DevOps",
    openTestCase: "Open test case",
    openTestResult: "Open test result",
    openTestSuite: "Open test suite",
    openTestSuiteResults: "Open test suite results",
    showDevopsExecutions: "Show test run executions",
    details: "Details",
    createAndAssign: "Create and assign",
    unassign: "Unassign",
    assign: "Assign",
    data: "Data",
    enableDrawOverlay: "Mark step element",
    disableDrawOverlay: "Stop marking step element",
    showActiveSessions: "Show active sessions",
    showLogs: "Show logs",
    performanceCounters: "Performance counters",
    addScripts: "Add scripts",
    true: "True",
    false: "False",
    usedIn: "Used in",
  },
  topbar: {
    scripts: translationsConsts.script.plural,
    history: "History",
    scheduler: "Scheduler",
    users: "User",
    shortcuts: "Shortcuts",
    journeys: "Journeys",
    settings: "Settings",
    projects: "Projects",
    documentation: "Documentation",
    reports: "Reports",
  },
  projects: {
    name: "Project name",
    noProjects: "No projects",
    notFound: "Project not found",
    loading: "Loading projects...",
    scheduleExecution: "Schedule execution",
    schedulerCreated: "Scheduler job has been created for project",
    schedulerCreatedTitle: "Project execution has beed scheduled",
    terminateExecution: "Abort",
    dispatching: "Dispatching",
    exportToDevOps: "Export to Test Plans",
    removeProject: "Are you sure you want to remove selected project?",
    removeTestPlanSuite: "Remove connected Test Plan / Suite?",
    tabs: {
      details: "Details",
      scripts: translationsConsts.script.plural,
      userGroups: "User groups",
      history: "History",
      virtualUsers: "Virtual users",
      systems: "Systems",
      testResults: "Test Results",
      issueTracking: "Issue tracking",
      documentationScripts: `Documented ${translationsConsts.script.plural.toLowerCase()}`,
      documentation: "Documentations",
    },
    details: {
      label: {
        category: "Category",
        version: "Version",
        dueDate: "Due date",
        startDate: "Start date",
        name: "Name",
        owners: "Project owners",
        statusToRun: "Status to run",
        tagsToRun: "Tags to run",
        attachedTestPlan: "Attached test plan",
        attachedTestSuite: "Attached test suite",
        openAttachedTestPlan: "Open attached test plan",
        openAttachedTestSuite: "Open attached test suite",
        documentationLocale: "Reports locale",
        configuration: "Configuration",
      },
      placeholder: {
        name: "Enter name",
        category: "Select category",
        search: "Search projects",
        version: "Enter version",
        dueDate: "Select due date",
        startDate: "Select start date",
        owners: "Select project owners",
        statusToRun: "Select status to run",
        tagsToRun: "Select tags to run",
        attachedTestPlan: "Select test plan",
        attachedTestSuite: "Select test suite",
        documentationLocale: "Select reports locale",
        configuration: "Select configuration",
      },
    },
  },
  validators: {
    required: "Required",
    whitespaces: "Whitespace characters are forbidden",
    startLetter: " Can't start with a number",
    mustBeNumber: "Must be a number",
    minValue: "Should be greater than",
    whitespacesAndSpecialChars: "Whitespace and special characters are forbidden",
    numSequenceFormat: "Wrong format, add at least one # for sequence number",
    numSequenceBreak: "Wrong format, cannot break # strings",
    emailList: "Please enter valid email addresses separated by commas",
    email: "Please enter a valid email address",
    null: "Field value cannot be null",
    userName: "Please enter current username",
  },
  messages: {
    help: {
      pick: "Variable will be created",
    },
    info: {
      noUserIts: "User has not configured issue tracking tool",
      stepsSyncing: "Steps are syncing, please wait",
      connectionEstablished: "Connection established.",
      reconnectAttempt: "Attempting to reconnect...",
      scriptNoProjectSystem: "Project doesn't have default environment",
      scriptNoProjectDefaultVu: "Project doesn't have default virtual user",
      dropZone: "Try dropping an Excel spreadsheet, or click to select a file to upload.",
      dropZipZone: "Try dropping a file, or click to select a file to upload.",
      noDataSource: "No data source uploaded.",
      nothingCopied: "Nothing copied",
      moreThanOneStep: "Selected more than one step",
      noStepSelected: "No step selected",
      error: "Something went wrong. Please refresh the page or contact system administrator.",
      browserOutdated: "Your browser is outdated. Please use Google Chrome.",
      dataSource: "Data source contains another row",
      noScript: `Cannot find system for ${translationsConsts.script.singular.toLowerCase()}`,
      openDraft: "Do you want to open the new draft?",
      overrideDraft: "Are you sure to override existing draft?",
      closeScript: `Are you sure you want to close selected ${translationsConsts.script.singular.toLowerCase()}?`,
      closeScripts: `Are you sure you want to close selected ${translationsConsts.script.plural.toLowerCase()}?`,
      exporting: "Exporting data to file",
      commandNotInstalled: "Command support is not installed",
      commandQuickEditNotAvailable: "Command doesn't support quick edition",
      selectProject: "Please select project from project tree",
      uploadingFiles: "Some files are still uploading",
    },
    success: {
      reconnected: "Successfully reconnected.",
      fileUpload: "File uploaded",
      fileRemoved: "File removed",
      stepsCopied: "Steps have been copied",
      termination: "Terminated succesfully",
      stepsDeleted: "Steps have been deleted",
      cloneScripts: `${translationsConsts.script.plural} has been cloned`,
      workItem: "Work item has been created",
      userSettingsEdited: "User settings have been changed successfully",
      excludeJobs: "Exclude state has been changed successfully",
      scriptStatusChange: `${translationsConsts.script.singular} status changed successfully`,
    },
    error: {
      noReportLink: "User did not set Power BI url",
      failedLogin: "Failed to log in",
      loading: "Problem with loading",
      connectionError: "Connection to server lost.",
      connectionLost: "Connection lost.",
      fileGet: `Error while getting files. ${translationsConsts.error.checkConsole}`,
      fileUpload: `Error while uploading file. ${translationsConsts.error.checkConsole}`,
      fileDownload: "Error while downloading file.",
      fileDelete: `Error while deleting file. ${translationsConsts.error.checkConsole}`,
      noScriptSelect: `No ${translationsConsts.script.singular.toLowerCase()} select`,
      passwordNotSame: "Passwords are not the same",
      failedFetchStatistics: "Error while fetching statistics",
      termination: "Termination failed",
      fetchError: "Problem with fetching system",
      notLogin: "Not logged in.",
      noDraftId: "No draft id",
      cloneScript: `Cannot clone ${translationsConsts.script.singular.toLowerCase()}.`,
      publshScript: `Cannot publish ${translationsConsts.script.singular.toLowerCase()}.`,
      noStartEndStepDelete: "Can't delete Start / End step.",
      export: "Error while exporting to file",
      sessionStart: "Error while starting the player",
      sessionLimitReached: "Foreground sessions limit reached",
      checkConsole: `Error while starting the player. ${translationsConsts.error.checkConsole}`,
      publishScriptConsole: `Error while publishing ${translationsConsts.script.singular.toLowerCase()}. ${
        translationsConsts.error.checkConsole
      }`,
      workItem: "Error while creating work item",
      workItemNoIts:
        "Error while creating work item. Please set Issue Tracking System in user settings.",
      preferences: "Error while saving preferences",
      cannotFetchFiles: "Cannot fetch captured files",
      userSettingsEdited: "Error while saving user settings",
      excludeJobs: "Error while changing exclude state",
      fileRequired: "File is required",
      captchaUnavailable: "ReCaptcha service is unavailable. Check your internet connection.",
      captchaExpired: "ReCaptcha challenge has expired.",
      dispatchingFailed: "Dispatching failed",
      reportGenerationFailed: "Report generation failed",
      licenseFileError: "It seems that the license file that was provided is not valid.",
      notEnoughWorkerCapacity: "Not enough worker capacity",
      stepWrongRecord:
        "Step was not recorded correctly. Please contact support for more information.",
    },
    warn: {
      singleFile: "Only single excel spreadsheet file is accepted",
    },
    confirm: {
      delStep: "Are you sure you want to delete this step ?",
      delSteps: "Are you sure you want to delete selected steps ?",
      delGlobalVar: "Are you sure you want to delete this global variable?",
      delGlobalVars: "Are you sure you want to delete selected global variables?",
      delKpi: "Are you sure you want to delete this kpi?",
      delKpis: "Are you sure you want to delete selected kpis?",
      delCodeTemplateGroup: "Are you sure you want to delete this code template group?",
      delCodeTemplateGroups: "Are you sure you want to delete code template groups?",
      reactivateScheduler:
        "Are you sure you want to reactivate closed scheduler? Please keep in mind that it might contain closed or removed scripts which may have impact on execution results.",
      consentDocumentationInfo:
        "I consent that the document: {prettyName} is verified and hereby I want to approve the change to status: {status}",
      consentScriptInfo:
        "I consent that the script: {name} is verified and hereby I want to approve the change to status: {status}",
      consentCheckbox: "I consent",
      confirmation: "Confirmation",
    },
    tooltip: {
      reorder: "Use drag & drop to change order of steps",
      pickColumns: "Click to select table columns",
      goToProject: "Go to project",
      saveBeforeCreateGroup: "Please save before creating a group",
    },
  },
  scripts: {
    error: {
      errorGenerateTemplate: "Error while generating data source template",
    },
    label: {
      newScript: `Create new ${translationsConsts.script.singular.toLowerCase()}`,
      shortcut: "Shortcut",
      status: "Status",
      attachedTestCase: "Attached test case",
      openAttachedTestCase: "Open",
      exportToTestCase: "Export to test case",
      updateTestCaseStatus: "Update test case status",
      takeScreenshots: "Take screenshots",
      screenshotsOnlyOnInterruption: "Screenshots only on interruption",
      clickOnDisabledButtons: "Click on disabled buttons",
      useDefaultEnvironment: "Use default environement",
      topProjectName: "Top project name",
      virtualUserName: "Virtual user name",
    },
    placeholder: {
      shortcut: "Select shortcut",
      status: "Select status",
      attachedTestCase: "Select test case",
    },
    uploadingFiles: "Uploading files",
    emptyField: "Please input the file name!",
    header: {
      noScriptSelect: `No ${translationsConsts.script.singular.toLowerCase()} selected`,
      generalInfo: "General info",
      import: `${translationsConsts.script.singular} import`,
      edit: `Edit ${translationsConsts.script.singular.toLowerCase()}`,
      create: `Create new ${translationsConsts.script.singular.toLowerCase()}`,
      shortcut: "Create shortcut",
      clone: "Clone",
      changeStatus: "Change status",
    },
    state: {
      notFound: `${translationsConsts.script.singular} not found`,
    },
    actions: {
      openDraft: "Open draft",
      saveDraft: "Save to draft",
    },
    status: {
      PUBLISHED: "PUBLISHED",
      PENDING: "PENDING",
      DRAFT: "DRAFT",
      CLOSED: "CLOSED",
      IN_PROGRESS: "IN PROGRESS",
      READY_TO_TEST: "READY TO TEST",
      READY_FOR_REVIEW: "READY FOR REVIEW",
    },
  },
  guards: {
    type: {
      label: "React on",
      elementNotFound: "Element not found",
      systemError: "System error",
      systemWarning: "System warning",
      error: "Error",
    },
    steps: "Guard steps",
    action: {
      label: "Do action",
      value: "Action value",
      continue: "Continue",
      repeat: "Repeat",
      goTo: "Jump to step",
    },
    header: "Create guard",
  },
  userGroups: {
    noGroup: "No group",
    header: {
      edit: "Edit user group",
      create: "Create user group",
    },
  },
  virtualUsers: {
    customScriptId: `Custom ${translationsConsts.script.singular.toLowerCase()} ID`,
    customScript: `Custom ${translationsConsts.script.singular.toLowerCase()}`,
    customScriptLogin: `Custom ${translationsConsts.script.singular.toLowerCase()} - unable to get login`,
    isSensitive: "Is sensitive",
    isSystemLogin: "System login",
    rememberSensitiveData: "Remember sensitive data",
    noSensitiveData: "No sensitive data",
    systemAuthEnabled: "Enable system authentication",
    systemAuthentication: "System authentication",
    reauthenticate: "Re-authenticate",
    authenticate: "Authenticate",
    platform: "Platform",
    authErrorBox: "Error message",
    customUrl: "Custom url",
    hoursPlural: "Hours",
    retailServerUrl: "Retail server URL",
    cposUrl: "Cloud POS URL",
    reauthenticateInterval: "Repeats every",
    systemType: "System type",
    header: {
      edit: "Edit virtual user",
      create: "Create virtual user",
    },
    domain: "Domain",
    workstation: "Workstation",
    user: "User",
    operatorId: "Operator ID",
    deviceId: "Device ID",
    registerNumber: "Register number",
    serverUrl: "Server URL",
    operatorPassword: "Operator password",
    pairHardwareStation: "Pair hardware station",
    hardwareStationName: "Hardware station host name",
    pin: "Pin",
    authType: {
      label: "Additional authentication",
      basic: "Basic",
      ntlm: "NTLM",
      system: "System",
      none: "None",
    },
    systemOptions: {
      microsoftLogin: "Microsoft login",
      cpos: "Cloud point of service",
      microsoftLoginOkta: "Microsoft login & Okta",
      transporeon: "Transporeon",
    },
    extraNtlm: "Additional NTLM",
    sensitiveData: "Sensitive data",
  },
  systemDictionary: {
    label: {
      expandAll: "Expand all tabs automatically",
      forceOpenPopup: "Force popups to open",
      forceOpenContextMenu: "Force context menu to open",
    },
    type: {
      development: "Development",
      test: "Test",
      production: "Production",
      uat: "User Acceptance Tests",
      other: "Other",
    },
    placeholder: {
      environmentType: "Select environment type",
      virtualUsers: "Enter virtual user username",
    },
    header: {
      edit: "Edit system definition",
      create: "Create system definition",
    },
  },
  user: {
    header: {
      editUser: "Edit user settings",
      createUser: "Create user",
      setting: "Change Setting",
    },
    label: {
      currentPassword: "Current password",
      newPassword: "New password",
    },
    placeholder: {
      currentPassword: "Enter current password",
      newPassword: "Enter new password",
      repeatedNewPassword: "Repeat new password",
    },
  },
  sequences: {
    placeholder: {
      name: "Enter sequence name",
      format: "Enter sequence format",
      nextValue: "Enter sequence next value",
    },
    label: {
      format: "Format",
      nextValue: "Next value",
    },
    message: {
      noDecrease: "Cannot decrease next value",
    },
    header: {
      edit: "Edit sequence",
      create: "Create sequence",
    },
  },
  storage: {
    placeholder: {
      name: "Enter storage name",
      apiKey: "Enter API Key",
      type: "Select storage type",
    },
    label: {
      apiKey: "Enter API Key",
      type: "Storage type",
    },
    header: {
      edit: "Edit storage",
      create: "Create a new storage",
    },
  },
  playMode: {
    playMode: "Play mode",
    foreground: "Foreground",
    mapWithDevops: "Result in DevOps",
    background: "Background",
    autoStart: "Auto start",
    stepByStep: "Step by step",
    slowMode: "Slow mode",
    selectPlayMode: "Select play mode",
    documentation: "Documentation",
    document: "Document",
    video: "Video",
    docAndVideo: "Document and Video",
    visible: "Visible",
    hidden: "Hidden",
    onlyCurrentStep: "Only current step",
    onError: "On error",
    onSuccess: "On success",
    always: "Always",
  },
  recordMode: {
    selectRecordMode: "Select record mode",
    recorderType: "Recorder type",
    basic: "Basic",
    advanced: "Advanced",
    newWindow: "New window",
    currentWindow: "Current window",
    openInNewWindow: "Open in new window",
  },
  recorderPosition: {
    left: "Left side",
    right: "Right side",
  },
  advancedRecorder: {
    tooltip: {
      hideStepsPanel: "Hide steps pane",
      showStepsPanel: "Show steps pane",
      back: "Back",
      refreshPage: "Refresh page",
      close: "Close",
    },
  },
  field: {
    emptyField: "Field cannot be empty",
  },
  password: {
    changePassword: "Change password",
    diffPassword: "Passwords are not the same",
    currentPassword: "Current password",
    newPassword: "New password",
    repeatPassword: "Repeat password",
    passwordChanged: "Password changed successfully",
    passwordChangeError: "Cannot change password",
    length: "Password must be at least 12 characters",
    lowerCase: "Password must contain at least one lowercase letter",
    capitalLetter: "Password must contain at least one capital letter",
    digit: "Password must contain at least one digit",
    specialCharacter: "Password must contain at least one special character",
    notAllowedCharacter: "Password contains not allowed characters or whitespaces",
  },
  variable: {
    label: {
      variableName: "Variable name",
      noScript: "No variables to select",
      select: "Please select variable",
      value: "Value",
      removeVariable: "Remove Variable",
      local: "Local",
      global: "Global",
    },
    placeholder: {
      selectType: "Select type",
      enterValue: "Enter value",
      defaultValue: "Default value",
      name: "Variable name",
      typeValue: "Value Type",
      initialValue: "Initial value",
      noValue: "No value",
    },
    input: {
      name: "Name:",
      type: "Type:",
      defaultValue: "Default value:",
    },
    type: {
      text: "Text",
      number: "Number",
      date: "Date",
    },
  },
  logs: {
    header: {
      message: "Messages",
      inputHeader: "Input variables",
      executedCode: "Executed code",
      outputValue: "Output value",
    },
    lastErrorMessage: "Last issues",
    scriptLogUrl: "Script log url",
  },
  scheduler: {
    mode: {
      basic: "Basic",
      gantt: "Gantt",
    },
    runMode: {
      sequential: "Sequential",
      parallel: "Parallel",
      gantt: "Gantt",
    },
    activate: "Activate",
    deactivate: "Deactivate",
    label: {
      existingGroup: "Existing group",
      jobName: "Enter job name",
      triggerType: "Trigger type",
      maximumParallelSessions: "Max parallel sessions",
      createAsGroup: "Add as group",
      groupName: "Group name",
    },
    placeholder: {
      existingGroup: "Select group",
      triggerType: "Select trigger type",
      startDate: "Select start date",
      notificationEmails: "Enter email addresses",
    },
    settings: {
      recurring: "Recurring",
      repeatsEvery: "Repeats every",
      endType: "End type",
      never: "Never",
      afterOccurrences: "After occurrences",
      onDate: "On date",
      storage: "Storage",
      startDateTime: "Start date",
      occurrences: "Occurrences",
      endDateTime: "End date",
      minutes: "Minute",
      hours: "Hour",
      days: "Day",
      weeks: "Week",
      months: "Month",
      years: "Year",
      minutesPlural: "Minutes",
      hoursPlural: "Hours",
      daysPlural: "Days",
      weeksPlural: "Weeks",
      monthsPlural: "Months",
      yearsPlural: "Years",
      time: "Time",
      notifyByEmail: "Notify by email",
      notificationEmails: "Email addresses",
      schedulerJobFlow: "Scheduler Job Flow",
    },
    message: {
      notFound: "Scheduler not found",
    },
    header: {
      createGroup: "Create a group",
      moveGroup: "Move to group",
      edit: "Edit scheduler",
      create: "Create a new scheduler",
      setGanttStart: "Change start time",
      setScriptsStart: `Change ${translationsConsts.script.plural.toLowerCase()} start time`,
    },
    statuses: {
      ACTIVE: "ACTIVE",
      COMPLETED: "COMPLETED",
      INACTIVE: "INACTIVE",
      CLOSED: "CLOSED",
      RUNNING: "RUNNING",
    },
    flow: {
      addPanel: {
        addScripts: `Add ${translationsConsts.script.plural.toLowerCase()} to flow`,
        selectScripts: `Select ${translationsConsts.script.plural.toLowerCase()}`,
        scriptsDetails: `${translationsConsts.script.plural} details`,
        scriptName: `${translationsConsts.script.singular} name`,
        scriptLabel: `${translationsConsts.script.singular} label`,
        repeatCount: "Repeat count",
      },
    },
  },
  table: {
    action: "Action",
    rowData: "Row data",
    rowId: "Row Id",
    changedFields: "Modified fields",
    tableName: "Table name",
    version: "Version",
    selectAll: "Select all data",
    selectAllColumns: "Select all",
    clear: "Clear",
    pickColumns: "Pick table columns",
    noValue: "No value",
    noTitle: "No label",
    noColumnsSelected: "No columns selected",
    dataSource: "Data source",
    valueType: "Value type",
    defaultValue: "Default value",
    groupId: "Id",
    scriptId: `${translationsConsts.script.singular} Id`,
    id: "Id",
    parentId: "ParentId",
    default: "Default",
    groupName: "Group name",
    projectName: "Project name",
    envName: "Environment",
    envNameShort: "Env.",
    envType: "Environment Type",
    envTypeShort: "Env. Type",
    publishName: "Published Name",
    startUrl: "Start url",
    status: "Status",
    scriptStatus: "Script status",
    createdAt: "Created At",
    creator: "Created By",
    parentType: "Type",
    sessionId: "Session Id",
    startTime: "Start Time",
    startTimeShort: "Start",
    signedBy: "Signed by",
    endTime: "End Time",
    endTimeShort: "End",
    duration: "Duration",
    durationShort: "Dur.",
    parent: "Name",
    repeatCount: "Repeat count",
    schedulerJobName: "Job name",
    schedulerJobNameShort: "Job",
    screenshotPath: "Screenshot",
    lineNum: "Line no.",
    label: "Label",
    username: "Username",
    email: "Email",
    apiKey: "API Key",
    name: "Name",
    filename: "Filename",
    envAddress: "Address",
    applicationName: "Application Name",
    applicationNameShort: "App name",
    description: "Description",
    virtualUsers: "Virtual Users",
    jobId: "Job Id",
    startDateTime: "First occurrence",
    endDateTime: "Ending date",
    triggerType: "Trigger type",
    reccurrence: "Recurrence",
    ttl: "Time to live",
    type: "Type",
    types: {
      bug: "Bug",
      task: "Task",
      issue: "Issue",
    },
    account: "Account",
    storageType: "Storage Type",
    format: "Format",
    nextval: "Next Value",
    updatedAt: "Updated At",
    login: "Login",
    systemLogin: "System login",
    serverLogin: "Server login",
    line: "Line",
    optional: "Optional",
    avgTime: "Average time",
    state: "State",
    states: {
      none: "NONE",
      running: "RUNNING",
      paused: "PAUSED",
      finished: "FINISHED",
      terminated: "TERMINATED",
      success: "SUCCESS",
      warning: "WARNING",
      error: "ERROR",
    },
    statesCapitalLetter: {
      none: "None",
      running: "Running",
      paused: "Paused",
      finished: "Finished",
      terminated: "Terminated",
      success: "Success",
      warning: "Warning",
      error: "Error",
    },
    all: "All",
    projectId: "Project Id",
    schedulerJobId: "Scheduler job Id",
    scriptName: `${translationsConsts.script.singular} name`,
    columns: "Columns",
    noFilters: "No filter",
    host: "Host",
    project: "Project",
    token: "Token",
    title: "Title",
    its: "Issue tracking system",
    stepLabel: "Step",
    link: "Link",
    hasConditions: "Conditional",
    previousStatus: "Previous status",
    newStatus: "New status",
    comment: "Comment",
    manualUrl: "Manual URL",
    projectDefaultEnv: "Default project environment",
    projectUnknown: "Project unknown",
    tags: "Tags",
    scriptVersion: `${translationsConsts.script.singular} version`,
    language: "Language",
    isExcluded: "Excluded",
    virtualUser: "Virtual user",
    virtualUserShort: "VU",
    isSensitive: "Sensitive",
    value: "Value",
    active: "Active",
    scriptEnvironment: "Script environment",
    overridenEnv: "Overriden Environment",
    overridenVu: "Overriden virtual user",
    lastExecutionStatus: "Last execution status",
    lastExecutionDate: "Last execution date",
    lastExecutorName: "Last executor name",
    activeVersion: "Active version",
  },
  settings: {
    auditTrail: "Audit trail",
    globalSettings: "Global settings",
    globalActions: "Global actions",
    application: "Application",
    systemDefinitions: "System definition",
    storages: "Storages",
    sequences: "Sequences",
    globalVariables: "Global variables",
    virtualUser: "Virtual user",
    apiKey: "API key",
    profil: "Profile",
    users: "Users",
    basicSettings: "Basic Settings",
    executionSettings: "Execution Settings",
    userGroups: "User groups",
    projectCategory: "Project categories",
    issueTrackingTools: "Issue tracking",
    itsRules: "Rules",
    itsConfigurations: "Configurations",
    reportExtension: "Execution report extension",
    reportExtensionPlaceholder: "Select execution report extension",
    tags: "Tags",
    displayOnlyPublishedDocuments: "Display only published documentation",
    localizations: "Localizations",
    reportTemplates: "Report templates",
    translate: "Translate",
    recorderType: "Recorder type",
    recorderOpenMode: "Recorder open mode",
    recorderPosition: "Recorder position",
    playerType: "Player type",
    playerOpenMode: "Player open mode",
    playerPosition: "Player position",
    scriptsReportsLocale: `${translationsConsts.script.plural} and reports locale`,
    selectScriptsReportsLocale: `Select ${translationsConsts.script.plural.toLowerCase()} and reports locale`,
    license: "License",
    codeTemplateGroups: "Code template groups",
    kpi: "KPI",
    codeTemplates: "Code templates",
    code: "Code",
  },
  localization: {
    import: "Import localization file",
    overwriteExisting: "Overwrite existing translations",
    needsTranslation: "Needs translation",
  },
  apiKey: {
    label: {
      ttl: "Time to live",
    },
    placeholder: {
      ttl: "Select Time to Live",
    },
    header: {
      edit: "Edit API Key",
      create: "Create API Key",
    },
  },
  platform: {
    default: "Default platform",
    microsoftLogin: "Microsoft Login",
    d365fo: "Dynamics 365 For Finance and Operations",
    other: "Other",
    cpos: "Cloud POS",
  },
  loadingLabel: "Loading...",
  none: "None",
  step: {
    newPanel: {
      create: "Create",
      step: "step",
    },
    tokens: {
      header: {
        create: "Create tokens step",
      },
    },
    hasConditions: "Step is checked against conditions on execution",
    hasCodeTemplate: "Step has a code template assigned",
    diagram: {
      noSteps: "No step to display",
    },
    detailsForm: {
      column: "Column",
      rows: "Rows",
      advancedFields: "Advanced fields",
      emailPreview: "Email preview",
      overrideVariables: "Override variables",
      termination: {
        type: {
          timeout: "Timeout",
          executions: "Number of executions",
        },
        action: {
          error: "Error",
          ignore: "Ignore step",
        },
        label: {
          type: "Termination type",
          value: "Value",
          action: "Action",
        },
        placeholder: {
          type: "Select type",
          value: "Enter value",
          action: "Select action",
        },
        version: "Platform version",
      },
      label: {
        linkName: "Link name",
        disableLinkedStartStep: "Disable linked start step",
        skipLinkedScriptsInDocumentation: "Skip linked scripts while generating documentation",
        timeout: "Custom timeout",
        isTimeoutEnabled: "Enable custom timeout",
        urlRedirect: "Url to redirect",
        variable: "Variable",
        label: "Label",
        manualLabel: "Manual label",
        delay: "Delay",
        isOptional: "Optional",
        menuIsNotSimulated: "Not simulated menu",
        scriptSettings: `${translationsConsts.script.singular} settings`,
        comment: "Comment",
        rule: "Rule ID",
        ruleVersion: "Rule version",
        rulePlatform: "Rule platform",
        path: "Path",
        pathType: "Path type",
        eventType: "Event type",
        commandId: "Registration type",
        isDisabled: "Disabled",
        isOneTime: "One time",
        originalValue: "Original value",
        version: "Version",
        disableStartStep: "Disable start step",
        clickOnDisabledButtons: "Click on disabled buttons",
        autoRefreshDatasource: "Auto-refresh datasource",
        customResolversMode: "Custom resolvers mode",
        generateTemplate: "Generate data source template",
        platformVersion: "Platform version",
      },
      placeholder: {
        selectKey: "Select key",
      },
    },
    createStep: {
      conditional: {
        inspectCondition: "INSPECT VALUE",
        checkbox: "Create with condition",
        defaultLabel: "Condition for inspect element",
        title: "Conditional step",
      },
      label: {
        delay: "Delay",
        lineNum: "Create after step",
        label: "Label",
      },
      placeholder: {
        selectStep: "Select step",
        enterLabel: "Enter label",
      },
      scriptStep: {
        header: `Create ${translationsConsts.script.singular} Step`,
        select: `Select ${translationsConsts.script.singular} Step`,
        stepDetails: `Enter ${translationsConsts.script.singular} Step Details`,
        label: "Label",
        linkName: "Link name",
      },
    },
    placeholder: {
      selectOperator: "Select operator",
      selectType: "Select type",
      selectStep: "Select step",
      selectSheet: "Select sheet",
      enterMessage: "Enter message",
      selectVariable: "Select variable",
      pick: "Select or create variable",
    },
    label: {
      selectAction: "Select action",
      selectStep: "Select step",
      assertType: "Conditional type",
      condition: "Condition",
      message: "Message",
      conditional: "Conditional",
      conditionalReadOnly: "Conditional readOnly",
      noVariables: "No variables found",
    },
    assign: {
      header: {
        create: "Create 'Assign' Step",
      },
    },
    login: {
      header: {
        create: "Create 'Login' Step",
      },
    },
    assert: {
      resetOnLastRow: "Reset on last row",
      header: {
        if: "IF",
        then: "THEN",
        else: "ELSE",
        createStep: "Create 'Conditional' Step",
      },
      assertionType: {
        checkValue: "Check value",
        dataSource: "Data source",
      },
      conditionType: {
        const: "Constant",
        stepValue: "From Step Value",
        variable: "From Variable",
        globalConst: "From Global constant",
        globalMutable: "From Global variable",
        hasNext: "The Data source has a next row",
      },
      option: {
        continue: "Continue",
        warning: "Warning",
        log: "Log and continue",
        error: "Stop and show an error message",
        dataSourceAndJump: "Take a next row from the data source and jump to a step",
        jump: "Jump to a step",
        repeat: "Repeat",
        increaseKpi: "Increase KPI",
      },
      comparisonOption: {
        equal: "Equal",
        notEqual: "Not Equal",
        greater: "Greater",
        greaterOrEqual: "Greater Equal",
        less: "Less",
        lessOrEqual: "Less equal",
      },
    },
  },
  common: {
    min: "Min",
    max: "Max",
    selected: "Selected",
    total: "Total",
    loadingStatistics: "Loading statistics...",
    loadingLicense: "Loading license...",
    running: "Running",
    noData: "No data",
    notEnoughData: "Not enough data. At least 2 executions should be performed",
    header: {
      compare: "Compare",
      dataSource: "Data Source",
      generalInfo: "General Information",
      exportWithAdditional: "Export with additional fields",
    },
    login: {
      microsoft: "Log in with Microsoft",
      pleaseLogIn: "Please log in",
      loggingIn: "Logging In...",
      loginButton: "Log in",
      activeSession:
        "This user currently has an active session - do you want to close that session and log in here?",
    },
    label: {
      state: "State",
      sso: "Single sign-on",
      mode: "Mode",
      documentation: "Documentation",
      specialMode: "Mode",
      playerView: "Player view",
      slowExecution: "Slow execution (s)",
      saveVideoCondition: "Save video condition",
      meta: "Meta",
      runMode: "Run mode",
      isManual: "Manual mode",
      defaultSystem: "Use project default environment",
      defaultVirtualUser: "Use project default virtual user",
      timeout: "Execution timeout",
      name: "Name",
      script: translationsConsts.script.singular,
      takeScreenshots: "Take screenshots",
      screenshotsOnlyOnInterruption: "Screenshots only on interruption",
      showActionHint: "Show overlay hint",
      hideComments: "Hide comments",
      url: "URL",
      type: "Type",
      expirationTime: "Expiration date",
      showPasswordField: "Show password field",
      lineNumber: "Line no.",
      description: "Description",
      manualUrl: "Manual URL",
      general: "General",
      steps: "Steps",
      history: "History",
      account: "Account",
      variables: "Variables",
      environment: "Environment",
      published: "Published",
      diagram: "Diagram",
      guards: "Advanced conditionals",
      overrode: "Overrode Variables",
      login: "Login",
      password: "Password",
      username: "Username",
      email: "Email",
      virtualUser: "Virtual user",
      label: "Label",
      statistics: "Statistics",
      flow: "Flow",
      data: "Data",
      gantt: "Gantt",
      executionStatus: "Execution status",
      progression5: "Last 5 results",
      progressionChart: "Progression chart",
      environmentAddress: "Environment address",
      applicationName: "Application name",
      environmentType: "Environment type",
      language: "Language",
      settings: "Settings",
      logout: "Logout",
      project: "Project",
      roles: "Roles",
      linked: "Linked In",
      workItems: "Work items",
      comment: "Comment",
      changelog: "Changelog",
      systemPlatform: "System platform",
      enableMultiWindowsSupport: "Multi-window support",
      disableResourceSharing: "Disable resource sharing",
      tags: "Tags",
      projectsToExport: "Projects to export",
      before: "Before",
      after: "After",
      useVirtualUser: "Use virtual user",
      isActive: "Is active",
      confluence: "Confluence knowledge base",
      jira: "Report issue in Jira",
      contactUs: "Contact us via email",
    },
    placeholder: {
      state: "Select state",
      sso: "Select single sign-on provider",
      language: "Select language",
      systemPlatform: "System platform",
      selectType: "Select type",
      selectView: "Select view",
      slowExecution: "Time in miliseconds",
      username: "Username",
      account: "Enter account name",
      applicationName: "Enter application name",
      name: "Enter name",
      login: "Enter login",
      enterUrl: "Enter URL",
      environment: "Select environment",
      enterEnvironment: "Enter environment",
      description: "Enter description",
      selectVariable: "Select variable",
      virtualUser: "Select virtual user",
      enterPassword: "Enter password",
      rememberPassword: "Remember password",
      password: "Password",
      email: "Enter email",
      environmentAddress: "Enter environment address",
      environmentType: "Select environment type",
      project: "Select project",
      roles: "Assign roles",
      comment: "Enter comment",
      tags: "Select tags",
    },
  },
  errorList: {
    title: "Errors and warnings of selected executions",
    noErrors: "No errors or warnings found for selected executions",
    errorWhileLoading: "Error while loading execution data",
  },
  screenshotGallery: {
    noScreenshots: "No screenshots available",
    loading: "Loading...",
    errorLoadingScreenshots: "Error while loading screenshots",
  },
  manyToMany: {
    source: {
      users: "All users",
      userGroups: "All user groups",
      virtualUsers: "All virtual users",
      systemDictionary: "All systems",
      itsRules: "All issue tracking rules",
    },
    destination: {
      users: "Selected users",
      userGroups: "Selected user groups",
      virtualUsers: "Selected virtual users",
      systemDictionary: "Selected systems",
      itsRules: "Selected issue tracking rules",
    },
  },
  projectCategory: {
    header: {
      create: "Create project category",
      edit: "Edit project category",
    },
  },
  globalVariable: {
    type: {
      constant: "Constant",
      mutable: "Variable",
    },
    label: {
      isSensitive: "Is sensitive",
      variableType: "Variable type",
    },
    header: {
      create: "Create global variable",
      edit: "Edit global variable",
      assign: "Assign global variable to script",
    },
    errors: {
      create: "Error while creating global variable",
      edit: "Error while editing global variable",
      assign: "Error while assigning variable to script",
      unassign: "Error while usassigning variable from script",
    },
  },
  kpi: {
    header: {
      create: "Create kpi",
      edit: "Edit kpi",
      performanceCountersStats: "Performance counters statistics",
    },
    errors: {
      create: "Error while creating kpi",
      edit: "Error while editing kpi",
      loadingStats: "Error while loading performance statistics",
      noStatsAvailable: "No performance statistics available",
    },
    label: {
      selectKpi: "Select KPI",
      kpiOccurrenceInTime: "KPI occurrence in time",
    },
    table: {
      kpiName: "KPI name",
      totalOccurrences: "Total occurrences",
      occurrencesPerMinute: "Occurrences per minute",
      occurrencesPerHour: "Occurrences per hour",
    },
  },
  codeTemplateGroup: {
    header: {
      create: "Create code template group",
      edit: "Edit code template group",
    },
    errors: {
      create: "Error while creating code template group",
      edit: "Error while editing code template group",
    },
  },
  its: {
    cannotFetchProjects: "Cannot fetch projects. Check connection credentials",
    cannotFetchIterations: "Cannot fetch iterations. Check connection credentials",
    cannotFetchAreas: "Cannot fetch areas. Check connection credentials",
    cannotFetchWorkItemTypes: "Cannot fetch work item types. Check connection credentials",
    fetchProjects: "Fetch projects",
    addCustomField: "Add custom field",
    removeCustomField: "Remove custom field",
    loadingCustomFields: "Loading custom fields",
    cannotFetchWorkItemFields: "Cannot fetch work item fields. Check connection credentials",
    cannotFetchTestPlans: "Cannot fetch test plans. Check connection credentials",
    cannotFetchTestSuites: "Cannot fetch test suites. Check connection credentials",
    cannotFetchConfigurations: "Cannot fetch configurations. Check connection credentials",
    cannotFetchTestCases: "Cannot fetch test cases. Check connection credentials",
    header: {
      create: "Create issue tracking tool definition",
      edit: "Edit issue tracking tool definition",
      createRule: "Create issue tracking tool rule",
      editRule: "Edit issue tracking tool rule",
    },
    label: {
      active: "Active",
      inactive: "Inactive",
      removed: "Removed",
      its: "Issue tracking tool",
      host: "Host",
      token: "Access token",
      defaultPriority: "Default priority",
      defaultSeverity: "Default severity",
      defaultIteration: "Default iteration",
      priority: "Priority",
      severity: "Severity",
      iteration: "Iteration",
      triggers: "Triggers",
      workItemType: "Work item type",
      defaultWorkItemType: "Default work item type",
      issueTracking: "Issue tracking",
      testPlans: "Test plans",
      area: "Area path",
      defaultArea: "Default area path",
    },
    placeholder: {
      its: "Select issue tracking tool",
      host: "Enter host",
      token: "Enter access token",
      defaultPriority: "Select default priority",
      defaultSeverity: "Select default severity",
      defaultIteration: "Enter default iteration",
      priority: "Select priority",
      severity: "Select severity",
      iteration: "Select iteration",
      triggers: "Select triggers",
      defaultWorkItemType: "Select default work item type",
      workItemType: "Select work item type",
      area: "Select area path",
      defaultArea: "Select default area path",
    },
  },
  conditionals: {
    createHeader: "Create advanced conditional",
    type: "Type",
    SUCCESS: "Success",
    or: "or",
    ERROR: "Error",
    WARNING: "Warning",
    overrideStatusTo: "Override status to",
    INTERRUPTION: "Interruption",
    reaction: "Reaction",
    addReaction: "Add reaction",
    simple: "Simple",
    advanced: "Advanced",
    label: { reactOn: "React on", activityType: "Activity type", activityValue: "Activity value" },
    placeholder: {
      reactOn: "Select event to react on",
      activityType: "Select activity type",
      activityValue: "Activity type",
    },
    advancedConditionNo: "Advanced condition no.",
    reactionOptions: {
      SYSTEM_ERROR: "System error",
      SYSTEM_WARNING: "System warning",
      ELEMENT_NOT_FOUND: "Element not found",
      ERROR: "Error",
      WARNING: "Warning",
    },
    addCondition: "Add condition",
  },
  documentation: {
    type: {
      VIDEO: "VIDEO",
      DOCUMENT: "DOCUMENT",
    },
    header: {
      edit: "Edit documentation",
    },
    noDocs: "No documents",
  },
  reportTemplate: {
    header: {
      edit: "Edit report template",
      create: "Create report template",
    },
  },
  tags: {
    header: {
      edit: "Edit tag",
      create: "Create a tag",
    },
    noTags: "No tags",
  },
  tablesFriendlyNames: {
    project: "Projects",
    projectitsrules: "Project rules",
    projectowners: "Project owners",
    projectsystemdictionary: "Projects system definition",
    projectscheduler: "Scheduler",
    projectusergroup: "Project user groups",
    projectvirtualuser: "Project virtual user",
    systemdictionary: "System definition",
    guardssteps: "Step guards",
    guard: "Guards",
    taskstep: "Steps",
    taskscript: translationsConsts.script.plural,
    variable: "Variables",
    taskscripttag: "TaskScript Tag",
    virtualuser: "Virtual user",
    globalvariable: "Global variable",
  },
  charts: {
    jobId: "Job Id",
    scriptCount: `${translationsConsts.script.singular} count`,
  },
  runner: {
    inspect: {
      header: "Create inspect step",
      stepLabel: "Step label",
      stepLabelPlaceholder: "Enter a label for the step",
      variableName: "Variable name",
    },
    sessionLoadError: "Session id is invalid or expired.",
    mode: {
      player: "Player",
      recorder: "Recorder",
    },
    playMode: {
      normal: "Normal",
      stepByStep: "Step by step",
      until: "Until recording path",
    },
    data: {
      datasource: "Datasource",
      sequences: "Sequences",
      localVariables: "Local variables",
      liveVariables: "Live variables",
      global: "Global",
    },
    syncError: "Synchronization error",
    liveVariablesSaveError: "Error while saving variables",
    stepDetails: "Step details",
  },
  license: {
    license: "License",
    licenseType: "License type",
    licenseNumber: "License number",
    licenseExpirationDate: "License expiration date",
    supportRenewalDate: "Support Plan Renewal date",
    maximumConcurrentUsers: "Maximum concurrent users",
    maximumParallelSessions: "Maximum parallel sessions",
    releaseNotes: "Release notes",
    errorReleaseNotes: "Cannot load release notes.",
    payPerUse: "Pay-Per-Use script executions",
    total: "Total",
    thisMonth: "This month",
    lastMonth: "Last month",
    executedScriptCount: "Executed scripts count",
    scriptsCount: "Scripts count",
    statistics: "Statistics",
    licenseOverview: "License overview",
    from: "from",
    to: "to",
    generationDate: "Generation date",
    installNewLicense: "Install new license",
    errorWhileUpdating: "Error while updating new license",
    licenseUpdated: "Your license has been updated. Your environment will be restarted shortly.",
    modal: {
      title: "Confirm",
      content:
        "Would you like to continue? Your environment will be restarted and will be offline for a few minutes.",
      okText: "Yes",
      cancelText: "No",
    },
  },
  globalActions: {
    screenshots: {
      info: {
        title: "Remove logs screenshots",
        older: "older than",
        days: "days",
        hour: "at",
        complete: "Cleanup procedure has been completed",
      },
      action: {
        deleteOnce1: "Started removing [",
        deleteOnce2: "] screenshots",
      },
      mode: {
        once: "once",
        every: "every 24 hours",
      },
      progress1: "Removing",
      progress2: "screenshots from",
      progress3: "days",
      button: {
        enable: "Enable",
        disable: "Disable",
        remove: "Remove",
        loading: "Fetching information",
      },
      modal: {
        title: "Confirm",
        content:
          "This action can't be reverted or interrupted. Are you sure that you want to continue?",
        okText: "Ok",
        cancelText: "Cancel",
      },
    },
  },
  codeTemplates: {
    header: {
      edit: "Edit code template",
      create: "Create a code template",
      import: "Code template import",
      setAsActive: "Set new code template version as an active?",
      usedIn: "Code template used in",
    },
    label: {
      group: "Group",
      functionName: "Function name",
      version: "Version",
      activeVersion: "Active version",
      currentlyModifedVersion: "Currently modifed version",
      code: "Code",
      addFunctionParam: "Add function parameters",
      currentModified: "Currently modified",
      asNewVersion: "As a new version",
      asNewActiveVersion: "As a new active version",
      saveMode: "Save mode",
    },
    placeholder: {
      group: "Select template group",
      functionName: "Enter a function name",
    },
    codeSection: "Code section",
    error: {
      failedToImport: `Failed to import following code templates: {codeTemplateNames}. Check console for additional info.`,
    },
  },
  scriptChangelog: {
    signedBy: "Signed by",
  },
};

export type TranslationDefinition = typeof translations;
export default translations;
