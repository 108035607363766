import Select from "antd/lib/select";
import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import SystemTypeField from "@app/modules/systemDictionary/components/SystemTypeField";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { SYSTEM_TYPE } from "@ea/shared_types/types";
import { getWebPlatforms } from "@ea/runner_loader/runner.loader";
import { getIn } from "final-form";
import { OptionType } from "@ea/shared_components/build/Form/Form.common";
import { DEFAULT_PLATFORM_ID } from "@ea/runner_loader/ea.internal.types";
import CheckboxField from "@ea/shared_components/Form/Fields/CheckboxField";
import { getValidatedPlatforms } from "@app/packs/packs.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";

interface ICreateSystemFormProps {
  readOnly?: boolean;
  values: any;
}

interface ICreateSystemFormState {}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class CreateSystemForm extends React.Component<ICreateSystemFormProps, ICreateSystemFormState> {
  envTypes;
  systemPlatform;

  constructor(props: ICreateSystemFormProps) {
    super(props);

    this.envTypes = [
      {
        text: getTranslationKey("systemDictionary", "type", SYSTEM_TYPE.DEV),
        value: SYSTEM_TYPE.DEV,
      },
      {
        text: getTranslationKey("systemDictionary", "type", SYSTEM_TYPE.TEST),
        value: SYSTEM_TYPE.TEST,
      },
      {
        text: getTranslationKey("systemDictionary", "type", SYSTEM_TYPE.PROD),
        value: SYSTEM_TYPE.PROD,
      },
      {
        text: getTranslationKey("systemDictionary", "type", SYSTEM_TYPE.UAT),
        value: SYSTEM_TYPE.UAT,
      },
      {
        text: getTranslationKey("systemDictionary", "type", SYSTEM_TYPE.OTHER),
        value: SYSTEM_TYPE.OTHER,
      },
    ];
  }

  customRender = (option: any): JSX.Element => {
    return (
      <Select.Option
        value={option.value}
        key={option.value}
        data-testid={DataTestIds.getOptionTestId(option.value)}
      >
        {option.value && <SystemTypeField type={option.value} />}
      </Select.Option>
    );
  };

  render() {
    const { readOnly, values } = this.props;

    const platforms = getValidatedPlatforms();

    const platformOptions: OptionType[] = platforms.map((p) => ({
      value: p.id,
      text: p.getFriendlyName(),
    }));

    const platformOption = getIn(values, "systemPlatform");
    const platform = platforms.find((p) => p.id === platformOption);

    return (
      <FormLayout {...formItemLayout} readOnly={readOnly}>
        <InputField
          name="envName"
          required
          placeholder={getTranslationKey("common", "placeholder", "enterEnvironment")}
          label={getTranslationKey("common", "label", "environment")}
          data-testid={DataTestIds.FORM_INPUT_ENV_NAME}
        />
        <InputField
          name="envAddress"
          required
          placeholder={getTranslationKey("common", "placeholder", "environmentAddress")}
          label={getTranslationKey("common", "label", "environmentAddress")}
          data-testid={DataTestIds.FORM_INPUT_ENV_ADDRESS}
        />
        <InputField
          name="applicationName"
          required
          placeholder={getTranslationKey("common", "placeholder", "applicationName")}
          label={getTranslationKey("common", "label", "applicationName")}
          data-testid={DataTestIds.FORM_INPUT_APPLICATION_NAME}
        />
        <SelectField
          name="envType"
          placeholder={getTranslationKey("common", "placeholder", "environmentType")}
          required
          label={getTranslationKey("common", "label", "environmentType")}
          data-testid={DataTestIds.FORM_INPUT_ENV_TYPE}
        >
          {this.envTypes.map(this.customRender)}
        </SelectField>
        <TextAreaField
          name="description"
          placeholder={getTranslationKey("common", "placeholder", "description")}
          autoSize={{ minRows: 2, maxRows: 6 }}
          label={getTranslationKey("common", "label", "description")}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
        <CheckboxField
          name="options.enableMultiWindowsSupport"
          defaultValue={false}
          label={getTranslationKey("common", "label", "enableMultiWindowsSupport")}
          data-testid={DataTestIds.FORM_CHECKBOX_MULTI_WINDOWS_SUPPORT}
        />
        <CheckboxField
          name="options.disableWebSecurity"
          defaultValue={false}
          label={getTranslationKey("common", "label", "disableResourceSharing")}
          data-testid={DataTestIds.FORM_CHECKBOX_DISABLE_WEB_SECURITY}
        />
        <SelectField
          name="systemPlatform"
          defaultValue={DEFAULT_PLATFORM_ID}
          label={getTranslationKey("common", "label", "systemPlatform")}
          placeholder={getTranslationKey("common", "placeholder", "systemPlatform")}
          required
          data-testid={DataTestIds.FORM_SELECT_SYSTEM_PLATFORM}
          options={platformOptions}
        />
        {platform &&
          platform.systemExtension &&
          platform.systemExtension.getSystemFormChunk({ values, readOnly })}
      </FormLayout>
    );
  }
}

export default CreateSystemForm;
