import * as React from "react";
import { FormattedMessage, injectIntl, InjectedIntlProps } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";
import { Tag as AntdTag, Button as AntdButton, Tooltip } from "antd";
import styled from "@emotion/styled";
import difference from "lodash.difference";

import { CommonAPI } from "../services/api.common";
import { isLoggedIn } from "../utils/auth";
import { User } from "@ea/shared_types/types";
import { ApplicationState } from "../redux/state";
import { currentUserSettingsSelector } from "../auth/auth.selectors";
import { SettingFilled } from "@ant-design/icons";
import { ColumnProps } from "antd/lib/table";
import { Panel, PanelType } from "../Panel";
import { DataTestIds } from "../utils/dataTestHelpers";
// todo: FIX translations in shared_components module
function getTranslationKey(...props: string[]): string {
  return props.join(".");
}

const { CheckableTag } = AntdTag;

const ColumnsMenu = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  flexDirection: "column",
  marginTop: "20px",
});

const RecordCounterContainer = styled.span({
  marginLeft: "10px",
});

interface ITagOpts {
  label: string;
  value: string;
  checked: boolean;
}

export interface ICustomizableColumnsProps {
  columns: ColumnProps<any>[];
  user?: User;
  isLoading: boolean;
  children: (props: any) => any;
  preferencesId: string;
  showSettings: boolean;
  filterActions: any;
  showHeader?: boolean;
  length: { selected: number; total: number; items: number };
}

interface ICustomizableColumnsState {
  visible: boolean;
  isReloading: boolean;
  checked: string[];
  columnTags: ITagOpts[];
  filterIcon: any;
}

class CustomizableColumns extends React.Component<
  IConnectProps & InjectedIntlProps & ICustomizableColumnsProps,
  ICustomizableColumnsState
> {
  state: ICustomizableColumnsState = {
    visible: false,
    isReloading: true,
    checked: [],
    columnTags: [],
    filterIcon: null,
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.columns.length === 0 && this.props.columns.length > 0) {
      await this.getColumnsPreferences();
      this.setState({ isReloading: false });
    }
  }

  async getColumnsPreferences() {
    const { user, preferencesId } = this.props;

    if (!user) {
      return;
    }

    // TODO: WHY WE NEED THIS REQUEST? CHECK THE LOGIC
    const [latestUser] = await CommonAPI.getUsers({ filter: { where: { id: user!.id } } });

    const dataIndexes = this.dataIndexes();

    if (latestUser && latestUser.settings.table && latestUser.settings.table[preferencesId]) {
      const userHiddenColumns = this.getHiddenColumns(latestUser);

      const checked = difference(dataIndexes, userHiddenColumns);

      this.setState({
        checked,
        columnTags: this.createColumnTags(this.props.columns, checked),
      });
      return;
    }

    this.setState({
      checked: dataIndexes,
      columnTags: this.createColumnTags(this.props.columns, dataIndexes),
    });
  }

  async saveColumnsPreferences() {
    const { user, preferencesId } = this.props;

    if (!user) {
      return;
    }

    const table = {
      ...(user.settings.table ? user.settings.table : {}),
      [preferencesId]: {
        hiddenColumns: difference(this.dataIndexes(), this.state.checked),
      },
    };

    try {
      await CommonAPI.editUser({
        ...user,
        settings: {
          ...user.settings,
          table,
        },
      });
    } catch (e) {
      if (!isLoggedIn()) {
        return;
      }
      toast.error(
        <FormattedMessage id={getTranslationKey("messages", "error", "preferences")} />,
        e,
      );
    }
  }

  dataIndexes = (cols = this.props.columns) => cols.map(this.getColumnsIdentifier);

  getColumnsIdentifier = (column: ColumnProps<any>): string => {
    if (!column.dataIndex) {
      return column.title && typeof column.title === "string"
        ? column.title
        : this.props.intl.formatMessage({ id: getTranslationKey("table", "noValue") });
    }
    return column.dataIndex.toString();
  };

  getHiddenColumns = (user: User) =>
    user.settings.table![this.props.preferencesId].hiddenColumns
      ? user.settings.table![this.props.preferencesId].hiddenColumns
      : [];

  isCheckedSafe = (column: ColumnProps<any>, checked: string[]): boolean =>
    checked.includes(this.getColumnsIdentifier(column) as string);

  createColumnTags = (columns: ColumnProps<any>[], checked: string[]): ITagOpts[] =>
    columns.map((column) => ({
      label:
        (column.title as string) ||
        this.props.intl.formatMessage({ id: getTranslationKey("table", "noTitle") }),
      value: this.getColumnsIdentifier(column),
      checked: this.isCheckedSafe(column, checked),
    }));

  onTagCheck = (tag: ITagOpts) => {
    const checkedTag = { ...tag, checked: !tag.checked };
    const columnTags = this.state.columnTags.map((opt) =>
      opt.value === tag.value ? checkedTag : opt,
    );

    this.setState({
      columnTags,
    });
  };

  onSaveColumns = async () => {
    const { filterActions, columns } = this.props;
    const checked = this.state.columnTags.filter((tag) => tag.checked).map((tag) => tag.value);

    const hiddenColumns = columns
      .map((columnConfig) => columnConfig.dataIndex)
      .filter((dataIndex) => dataIndex && !checked.includes(dataIndex?.toString()));
    filterActions.clearFilters(hiddenColumns);
    this.setState(
      {
        visible: false,
        checked,
      },
      () => this.saveColumnsPreferences(),
    );
  };

  onClosePanel = () => this.setState({ visible: false });

  openPanel = () => this.setState({ visible: true });

  togglePanel = () => this.setState({ visible: !this.state.visible });

  clear = () =>
    this.setState({
      columnTags: this.state.columnTags.map((tag) => ({ ...tag, checked: false })),
    });

  selectAll = () =>
    this.setState({
      columnTags: this.state.columnTags.map((tag) => ({ ...tag, checked: true })),
    });

  renderNoColumnsSelected = () => {
    return (
      <div className="antd.table-placeholder">
        <div className="ant-empty ant-empty-normal">
          <p className="ant-empty-description">
            <FormattedMessage id={getTranslationKey("table", "noColumnsSelected")} />
          </p>
          <AntdButton size="small" type="link" onClick={this.openPanel}>
            <FormattedMessage id={getTranslationKey("messages", "tooltip", "pickColumns")} />
          </AntdButton>
        </div>
      </div>
    );
  };

  footerItemRender = () => {};

  paginationItemRender = (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>,
  ) => {
    const { showHeader, length } = this.props;
    const { selected, items, total } = length;
    if (showHeader === false) {
      return originalElement;
    }

    if (type === "prev") {
      return (
        <>
          <div style={{ position: "absolute", left: 25 }}>
            <Tooltip
              title={
                <FormattedMessage id={getTranslationKey("messages", "tooltip", "pickColumns")} />
              }
            >
              <SettingFilled
                className="anticon-filter"
                onClick={this.togglePanel}
                data-testid={DataTestIds.BUTTON_OPEN_TABLE_SETTINGS}
              />
            </Tooltip>
            <RecordCounterContainer>
              <FormattedMessage id={getTranslationKey("common", "selected")} />: {selected}/{items}{" "}
              | <FormattedMessage id={getTranslationKey("common", "total")} />: {total}
            </RecordCounterContainer>
          </div>
          {originalElement}
        </>
      );
    }

    return originalElement;
  };

  render() {
    const { checked, columnTags, filterIcon, isReloading } = this.state;
    const { columns, children } = this.props;

    const allHidden = columns.length > 0 && this.state.checked.length === 0;

    return (
      <>
        <Panel
          // TODO intl for headerText
          headerText={this.props.intl.formatMessage({
            id: getTranslationKey("table", "pickColumns"),
          })}
          type={PanelType.XSMALL}
          visibility={this.state.visible}
          onRenderFooterContent={() => (
            <>
              <AntdButton
                type="primary"
                onClick={this.onSaveColumns}
                data-testid={DataTestIds.PANEL_BUTTON_SUBMIT}
              >
                <FormattedMessage id={getTranslationKey("button", "save")} />
              </AntdButton>
              <AntdButton onClick={this.onClosePanel} data-testid={DataTestIds.PANEL_BUTTON_CANCEL}>
                <FormattedMessage id={getTranslationKey("button", "close")} />
              </AntdButton>
            </>
          )}
        >
          <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
              <AntdButton
                onClick={this.clear}
                style={{ marginRight: 5 }}
                data-testid={DataTestIds.PANEL_BUTTON_CLEAR}
              >
                {<FormattedMessage id={getTranslationKey("table", "clear")} />}
              </AntdButton>
              <AntdButton
                onClick={this.selectAll}
                data-testid={DataTestIds.PANEL_BUTTON_SELLECT_ALL}
              >
                {<FormattedMessage id={getTranslationKey("table", "selectAllColumns")} />}
              </AntdButton>
            </div>

            <ColumnsMenu>
              {columnTags.map((opt) => {
                return (
                  <div key={opt.value} style={{ flex: 1, marginBottom: 10 }}>
                    <CheckableTag
                      checked={opt.checked}
                      onChange={() => this.onTagCheck(opt)}
                      className="ant-tag-checkable"
                      data-testid={DataTestIds.getTagTestId(opt.value)}
                    >
                      {opt.label}
                    </CheckableTag>
                  </div>
                );
              })}
            </ColumnsMenu>
          </>
        </Panel>

        {isReloading
          ? children({})
          : allHidden
          ? this.renderNoColumnsSelected()
          : children({
              sortedColumns: checked,
              filterIcon,
              paginationItemRender: this.paginationItemRender,
            })}
      </>
    );
  }
}

const connectCreator = connect((state: ApplicationState, props: ICustomizableColumnsProps) => ({
  // todo: how to fix casting
  ...props,
  user: (state as any).auth.user,
  userSettings: currentUserSettingsSelector(state),
}));

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(injectIntl(CustomizableColumns));
