import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import {
  Documentation,
  DocumentationStatus,
  FEATURES,
  DOCUMENTATION_TYPE,
} from "@ea/shared_types/types";
import * as React from "react";
import { GetAsTime } from "@app/modules/common/time";
import { getTranslationKey } from "@app/translations/translations.helpers";
import StatusTag from "../common/components/StatusTag";
import { Tag, Tooltip } from "antd";
import DocumentationTypeTag from "./components/DocumentationTypeTag";

export const DOCUMENTATION_COLUMNS_CONFIG: PlainObject<CustomColumn<Documentation>> = {
  prettyName: {
    label: getTranslationKey("table", "name"),
    dataIndex: "prettyName",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  scriptId: {
    label: getTranslationKey("table", "scriptId"),
    dataIndex: "script.id" as any,
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  scriptName: {
    label: getTranslationKey("table", "scriptName"),
    dataIndex: "script.name" as any,
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text, record) => record.script.name,
  },
  type: {
    label: getTranslationKey("table", "type"),
    dataIndex: "type",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("documentation", "type", DOCUMENTATION_TYPE.DOCUMENT),
          value: DOCUMENTATION_TYPE.DOCUMENT,
        },
        {
          label: getTranslationKey("documentation", "type", DOCUMENTATION_TYPE.VIDEO),
          value: DOCUMENTATION_TYPE.VIDEO,
        },
      ],
    },
    render: (text, record, index) => <DocumentationTypeTag item={record} />,
  },
  description: {
    label: getTranslationKey("table", "description"),
    dataIndex: "description",
    sortable: true,
    onCell: () => {
      return {
        style: {
          whiteSpace: "nowrap",
          maxWidth: 150,
        },
      };
    },
    render: (description) => (
      <Tooltip placement="topLeft" title={description}>
        <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
          {description}
        </div>
      </Tooltip>
    ),
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  status: {
    label: getTranslationKey("table", "status"),
    dataIndex: "status",
    filter: {
      filterType: ColumnFilters.SELECT,
      values: [
        {
          label: getTranslationKey("scripts", "status", DocumentationStatus.DRAFT),
          value: DocumentationStatus.DRAFT,
        },
        {
          label: getTranslationKey("scripts", "status", DocumentationStatus.PUBLISHED),
          value: DocumentationStatus.PUBLISHED,
        },
      ],
    },
    render: (text, record, index) => <StatusTag item={record} />,
  },
  signedBy: {
    label: getTranslationKey("table", "signedBy"),
    dataIndex: "signedBy",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    feature: FEATURES.ELECTRONIC_SIGNOFF,
    sortable: true,
  },
  createdAt: {
    label: getTranslationKey("table", "createdAt"),
    dataIndex: "createdAt",
    sortable: true,
    render: (text, record, index) => {
      return <span>{GetAsTime({ rowData: record, dataKey: "createdAt" })}</span>;
    },
    filter: {
      filterType: ColumnFilters.DATE_RANGE,
    },
  },
  version: {
    label: getTranslationKey("table", "scriptVersion"),
    dataIndex: "version",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    feature: FEATURES.SCRIPT_VERSIONING,
    sortable: true,
    render: (text) => (text ? <Tag>{text}</Tag> : null),
  },
  locale: {
    label: getTranslationKey("table", "language"),
    dataIndex: "locale",
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    sortable: true,
    render: (text) => (text ? <Tag>{text}</Tag> : null),
  },
};

export const DOCUMENTATION_COLUMNS: ColumnConfig<Documentation>[] = [
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.prettyName,
    frameworkProps: {
      width: "19%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.scriptId,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.version,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "18%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.signedBy,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "18%",
    },
  },
];

export const DOCUMENTATION_SCRIPT_COLUMNS: ColumnConfig<Documentation>[] = [
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.prettyName,
    frameworkProps: {
      width: "21%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.version,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.locale,
    frameworkProps: {
      width: "6%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "21%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.signedBy,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "15%",
    },
  },
];

export const DOCUMENTATION_PROJECT_COLUMNS: ColumnConfig<Documentation>[] = [
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.prettyName,
    frameworkProps: {
      width: "18%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.scriptName,
    frameworkProps: {
      width: "15%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.version,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.locale,
    frameworkProps: {
      width: "10%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "20%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "8%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.signedBy,
    frameworkProps: {
      width: "7%",
    },
  },
  {
    props: DOCUMENTATION_COLUMNS_CONFIG.createdAt,
    frameworkProps: {
      width: "10%",
    },
  },
];

export const DOCUMENTATION_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_DOCUMENTATION",
  },
  SCRIPT: {
    id: (id: number) => `SCRIPT_${id}_DOCUMENTATION`,
    preferencesId: "SCRIPT_DOCUMENTATION",
  },
  PROJECT: {
    id: (id: number) => `PROJECT_${id}_DOCUMENTATION`,
    preferencesId: "PROJECT_DOCUMENTATION",
  },
};
