import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { PanelType } from "@ea/shared_components/Panel";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import { API } from "@app/services/api/api";
import ConditionalForm from "@app/modules/guards/components/ConditionalForm";
import { getStepsSelector } from "@app/modules/steps/steps.selectors";
import { STEPS_TABLE_ID } from "@app/modules/steps/steps.table";
import { variableDataSelectors } from "../variables/variables.selectors";
import { VARIABLES_SECTIONS } from "../variables/variables.actions";
import {
  scriptsDataSelectors,
  getScriptGlobalConstants,
  getScriptGlobalMutables,
} from "../scripts/scripts.selectors";
import { guardActions } from "./guards.actions";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { kpisDataSelectors } from "../kpis/kpis.selectors";
import { KPIS_TABLES_CONFIG } from "../kpis/kpis.table";
import { getKpisTableActions } from "../kpis/kpis.actions";
import { Guard } from "@ea/shared_types/types";

interface IGuardsNewPanelProps {
  visibility: boolean;
  onClose: () => void;
  scriptId: number;
  onOk: (result: Guard) => void;
}

export const GuardsNewPanelContainer: React.FunctionComponent<IGuardsNewPanelProps> = ({
  onClose,
  scriptId,
  visibility,
  onOk,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getKpisTableActions(KPIS_TABLES_CONFIG.MAIN.id()).load({}));
  }, []);

  const script = useSelector((state: ApplicationState) =>
    scriptsDataSelectors.getItemSelector(state, scriptId),
  );
  const steps = useSelector((state: ApplicationState) => getStepsSelector(state, STEPS_TABLE_ID));
  const basicVariables = useSelector((state: ApplicationState) =>
    variableDataSelectors.getOrderedDataSelector(state, VARIABLES_SECTIONS.LOCAL),
  );
  const kpis = useSelector((state: ApplicationState) =>
    kpisDataSelectors.getOrderedDataSelector(state, KPIS_TABLES_CONFIG.MAIN.id()),
  );

  const globalMutable = useSelector((state: ApplicationState) =>
    getScriptGlobalMutables(state, scriptId),
  );

  const globalConstant = useSelector((state: ApplicationState) =>
    getScriptGlobalConstants(state, scriptId),
  );

  const onOkClick = async (values) => {
    const normalizedValues = {
      scriptId,
      ...values,
    };

    const result = await API.createGuard(normalizedValues);
    dispatch(guardActions.commitCreate(normalizedValues));

    onOk(result);

    onClose();
  };

  return (
    <PanelFormFinal
      visibility={visibility}
      panelType={PanelType.MEDIUM}
      headerText={getTranslationKey("conditionals", "createHeader")}
      onCancelClick={onClose}
      onOkClick={onOkClick}
      cancelButtonText={getTranslationKey("button", "cancel")}
      okButtonText={getTranslationKey("button", "create")}
      initialValues={undefined}
      render={({ change, values }) => (
        <ConditionalForm
          values={values}
          steps={steps}
          change={change}
          kpis={kpis}
          variables={basicVariables}
          script={script}
          globalMutable={globalMutable}
          globalConstant={globalConstant}
        />
      )}
    />
  );
};

export default GuardsNewPanelContainer;
