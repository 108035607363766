import * as PropTypes from "prop-types";
import * as React from "react";
import { PanelType } from "@ea/shared_components/PanelForm";
import PanelFormFinal from "@ea/shared_components/PanelForm/PanelFormFinal";
import {
  User,
  IssueTrackingTool,
  IssueTrackingToolConfiguration,
  IssueTrackingToolConfigurationState,
} from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import CreateEditConfigurationForm from "./CreateEditConfigurationForm";
import { FormattedMessage } from "react-intl";

interface ItsCreateEditConfigurationContainerProps {
  visibility: boolean;
  onClose: () => void;
  onCreate: (its: IssueTrackingToolConfiguration) => void;
  selected?: IssueTrackingToolConfiguration;
  isEdit?: boolean;
  user: User | undefined;

  issueTrackingSystem: IssueTrackingTool | undefined;
}

class CreateEditConfigurationContainer extends React.Component<ItsCreateEditConfigurationContainerProps> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  onOk = async (values) => {
    const { isEdit, onCreate, onClose, issueTrackingSystem, user } = this.props;
    if (isEdit) {
      await API.editItsConfiguration(values);
    } else {
      values.state = IssueTrackingToolConfigurationState.active;
      values.itsId = issueTrackingSystem?.id;
      if (user) {
        const result = await API.createItsConfiguration(values);
        if (onCreate) {
          onCreate(result);
        }
      }
    }
    onClose();
  };

  render() {
    const { visibility, isEdit, onClose, selected, issueTrackingSystem } = this.props;
    return (
      <PanelFormFinal
        visibility={visibility}
        panelType={PanelType.MEDIUM}
        headerText={getTranslationKey("its", "header", isEdit ? "edit" : "create")}
        onCancelClick={onClose}
        onOkClick={this.onOk}
        cancelButtonText={getTranslationKey("button", "cancel")}
        key={isEdit ? "edit" : "create"}
        okButtonText={getTranslationKey("button", isEdit ? "save" : "create")}
        initialValues={
          isEdit
            ? selected
            : ({
                integrationMetadata: {
                  azureDevops: {
                    project: issueTrackingSystem?.project,
                  },
                },
              } as Partial<IssueTrackingToolConfiguration>)
        }
        render={({ values, change, form }) =>
          issueTrackingSystem ? (
            <CreateEditConfigurationForm
              values={values}
              change={change}
              isEdit={isEdit}
              its={issueTrackingSystem}
            />
          ) : (
            <FormattedMessage id={getTranslationKey("messages", "info", "noUserIts")} />
          )
        }
      />
    );
  }
}

export default CreateEditConfigurationContainer;
