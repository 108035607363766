import {
  WebsocketMessageTypes,
  SocketMessage,
  WebsocketData,
  SubscribeUpdateModelNames,
  SubscribeUpdateQuery,
} from "@ea/shared_types/types";
import { v4 as uuidv4 } from "uuid";

const config = require("../../../config/config");

export let ws;
const host = window.location.host;
const isProtocolSecured = window.location.protocol === "https:" ? true : false;

const websocketHost = `${
  config.WEBSOCKET_PROTOCOL || isProtocolSecured ? "wss" : "ws"
}://${host}/api`;

let websocketHandlers = {} as any;
let onOpenWebsocketActions: { [key: string]: () => any } = {};
let onCloseWebsocketActions: { [key: string]: () => any } = {};
let onModelChangeActions = {} as any;
let websocketMessageQueue: any[] = [];

const isWebsocketReady = () => (ws && ws.readyState === WebSocket.OPEN && ws.id ? true : false);

export const startWebSocket = (userId: number) => {
  ws = new WebSocket(websocketHost);
  ws.id = uuidv4();
  ws.onopen = () => {
    const data: SocketMessage<WebsocketMessageTypes.OPEN_CONNECTION> = {
      type: WebsocketMessageTypes.OPEN_CONNECTION,
      value: { userId, wsId: ws.id },
    };
    if (isWebsocketReady()) {
      sendToServer(data);
      onWebsocketOpen();
      resolveMessageQueue();
    }
  };
  ws.onmessage = (message) => {
    const data: SocketMessage<any> = JSON.parse(message.data);
    if (data.type === WebsocketMessageTypes.PERFORM_REFRESH) {
      performOnModelChangeAction(data.value);
    }
    if (data.type === WebsocketMessageTypes.HEARTBEAT) {
      return;
    }
    onWebsocketMessage(data);
  };
  ws.onclose = () => {
    onWebsocketClose();
  };
  ws.onerror = (event) => {
    console.error("WS error", event);
  };
};

export const unregisterWebsocketHandler = <T extends WebsocketMessageTypes>(
  wsType: T,
  handler: (message: WebsocketData[T]) => any,
) => {
  if (websocketHandlers[wsType]) {
    const handlersCopy = [...websocketHandlers[wsType]];
    const handlerIndex = handlersCopy.indexOf(handler);
    if (handlerIndex > -1) {
      handlersCopy.splice(handlerIndex, 1);
      if (handlersCopy.length > 0) {
        websocketHandlers[wsType] = [...handlersCopy];
      } else {
        delete websocketHandlers[wsType];
      }
    }
  }
};

// If you are going to register websocketHandler which is changing data in component state
// register function with binded this. example in ProjectLoader.container.tsx
export const registerWebsocketHandler = <T extends WebsocketMessageTypes>(
  wsType: T,
  handler: (message: WebsocketData[T]) => any,
) => {
  if (websocketHandlers[wsType]) {
    const handlerIndex = websocketHandlers[wsType].indexOf(handler);
    if (handlerIndex < 0) {
      websocketHandlers[wsType] = [...(websocketHandlers[wsType] || []), handler];
    } else {
      websocketHandlers[wsType][handlerIndex] = handler;
    }
  } else {
    websocketHandlers[wsType] = [handler];
  }

  return () => {
    unregisterWebsocketHandler(wsType, handler);
  };
};

export const registerOnOpenWebsocketAction = (handler: () => any, functionName: string) => {
  onOpenWebsocketActions[functionName] = handler;
};
export const registerOnCloseWebsocketAction = (handler: () => any, functionName: string) => {
  onCloseWebsocketActions[functionName] = handler;
};

const onWebsocketOpen = async () => {
  const actions = Object.values(onOpenWebsocketActions);
  await Promise.all(actions.map(async (action) => action()));
};

const onWebsocketClose = async () => {
  const actions = Object.values(onCloseWebsocketActions);
  await Promise.all(actions.map(async (action) => action()));
};

const onWebsocketMessage = (data: SocketMessage<any>) => {
  const { type, value } = data;
  const handlers = websocketHandlers[type];
  if (handlers) {
    handlers.forEach((handler) => {
      handler(value);
    });
  }
};

export const sendToServer = <T extends WebsocketMessageTypes>(data: SocketMessage<T>) => {
  if (isWebsocketReady()) {
    data.value.wsId = ws.id;
    ws.send(JSON.stringify(data));
  } else {
    websocketMessageQueue.push(data);
  }
};

export const closeWebsocket = () => {
  websocketHandlers = {};
  onOpenWebsocketActions = {};
  onCloseWebsocketActions = {};
  onModelChangeActions = {};
  ws.close();
};

export const subscribeToModelUpdates = (
  modelName: SubscribeUpdateModelNames,
  query: SubscribeUpdateQuery,
  handler: () => any,
) => {
  const wsId: string = ws && ws.id;
  const queryId: string = uuidv4();

  const data: SocketMessage<WebsocketMessageTypes.SUBSCRIBE_TO_UPDATES> = {
    type: WebsocketMessageTypes.SUBSCRIBE_TO_UPDATES,
    value: { queryId, wsId, modelName, query },
  };
  onModelChangeActions[queryId] = { handler, reSubscribeData: data };
  sendToServer(data);
  return () => {
    unsubscribeToModelUpdates(queryId);
  };
};

const unsubscribeToModelUpdates = (queryId: string) => {
  delete onModelChangeActions[queryId];

  const data: SocketMessage<WebsocketMessageTypes.UNSUBSCRIBE_TO_UPDATES> = {
    type: WebsocketMessageTypes.UNSUBSCRIBE_TO_UPDATES,
    value: { queryId },
  };

  sendToServer(data);
};

const performOnModelChangeAction = (value) => {
  const { queryId } = value;
  if (typeof onModelChangeActions[queryId].handler === "function") {
    onModelChangeActions[queryId].handler();
  }
};

export const filterMessageQueue = (messageQueue: SocketMessage<any>[]) => {
  const filtred = messageQueue.filter(
    (i) =>
      !messageQueue.some(
        (item) => i.value.queryId && i.value.queryId === item.value.queryId && i.type !== item.type,
      ),
  );
  return filtred;
};

const resolveMessageQueue = () => {
  if (websocketMessageQueue.length > 0) {
    const toSend = filterMessageQueue(websocketMessageQueue);
    websocketMessageQueue = [...toSend];

    while (websocketMessageQueue.length > 0) {
      sendToServer(websocketMessageQueue.shift());
    }
  } else {
    reSubscribe();
  }
};

const reSubscribe = () => {
  const keys = Object.keys(onModelChangeActions);
  for (const key of keys) {
    const data: SocketMessage<any> = onModelChangeActions[key].reSubscribeData;
    sendToServer(data);
  }
};
