export default class UserError extends Error {
  type: string;
  constructor(...args: any[]) {
    super(...args);
    Error.captureStackTrace(this, UserError);
    this.constructor = UserError;
    (this as any).__proto__ = UserError.prototype;
    this.type = "USER";
    this.name = "UserError";
  }
}
