import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";

import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import { DataTestIds } from "@app/utils/dataTestIds";

interface IProjectCategoryFormProps {
  readOnly?: boolean;
  values: any;
}

interface IProjectCategoryFormState {
  virtualUserOptions: OptionType[];
}

class ProjectCategoryForm extends React.Component<
  IProjectCategoryFormProps,
  IProjectCategoryFormState
> {
  envTypes;
  render() {
    const { readOnly } = this.props;

    return (
      <>
        <InputField
          name="name"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <TextAreaField
          name="description"
          placeholder={getTranslationKey("common", "placeholder", "description")}
          readOnly={readOnly}
          autoSize={{ minRows: 2, maxRows: 6 }}
          label={getTranslationKey("common", "label", "description")}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
      </>
    );
  }
}

export default ProjectCategoryForm;
