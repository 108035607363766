import { createModuleEpics } from "@app/modules/app.reducers";
import { moduleActions } from "./apiKey.actions";
import { Role, Token } from "@ea/shared_types/types";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { API_KEY_COLUMNS_CONFIG } from "./apiKey.table";
import { apiKeyDataSelectors } from "./apiKey.selectors";

const apiKeyModuleEpics = createModuleEpics<Token>(moduleActions, {
  normalizeItem: (role) => role,
  getRequestParams: (state, tableId) => {
    const tableParams = apiKeyDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, API_KEY_COLUMNS_CONFIG, {
      defaultOrder: ["created DESC"],
    });

    params.filter = {
      where: {
        apiKey: true,
      },
    };

    return params;
  },
  getSingleRequestParams: (params) => {
    params.filter = {
      where: {
        apiKey: true,
      },
    };

    return params;
  },
  api: {
    createItem: API.createToken,
    deleteItem: API.deleteToken,
    editItem: API.editToken,
    getItems: API.getTokens,
    getItemsCount: API.getTokenCount,
  },
});

export default apiKeyModuleEpics;
