import { toast } from "react-toastify";
import { Action } from "redux";
import { ActionsObservable, combineEpics } from "redux-observable";

import { getNotifiableActionMessage, notifiableActions } from "@app/modules/app.actions";
import { getAction } from "@ea/shared_components/redux/common.models";
import { usersEpics } from "@app/modules/users";

import { authEpics } from "./auth";
import { logsEpics } from "./logs";
import { steplogsEpics } from "./logs/StepLogs";
import { aggregatedJobLogsEpics } from "./logs/AggregatedJobLogs";
import { scriptsEpics } from "./scripts";
import { stepsEpics } from "./steps";
import { variablesEpics } from "./variables";
import { schedulerEpics } from "@app/modules/scheduler";
import { systemDictionaryEpics } from "@app/modules/systemDictionary";
import { sequencesEpics } from "@app/modules/sequences";
import { virtualUsersEpics } from "@app/modules/virtualUsers";
import { guardEpics } from "@app/modules/guards";
import { apiKeyEpics } from "@app/modules/apiKey";
import { storageEpics } from "@app/modules/storage";
import { projectsEpics } from "@app/modules/projects";
import { formatToastText } from "@ea/shared_components/utils/toast";
import { userGroupEpics } from "@app/modules/userGroups";
import { roleEpics } from "@app/modules/roles";
import { projectCategoryEpics } from "@app/modules/projectCategory";
import { itsEpics } from "@app/modules/issueTrackingTool";
import { itsRulesEpics } from "@app/modules/issueTrackingToolRules";
import { workItemEpics } from "./workItems";
import { tagEpics } from "@app/modules/tags";
import { auditTrailEpics } from "@app/modules/auditTrail";
import { globalSettingsEpics } from "@app/modules/globalSettings";
import { runnerEpics } from "@app/modules/runner";

import { scriptChangelogEpics } from "./scriptChangelog";
import { documentationEpics } from "./documentation";
import { localizationEpics } from "./localization";
import { reportTemplatesEpics } from "./reportTemplate";
import { globalVariablesEpics } from "@app/modules/globalVariables";
import { kpisEpics } from "@app/modules/kpis";
import { codeTemplateGroupsEpics } from "@app/modules/codeTemplateGroups";
import { schedulerFlowEpics } from "@app/modules/schedulerFlow";
import { itsConfigurationsEpics } from "./issueTrackingToolConfigurations";
import { codeTemplatesEpics } from "./codeTemplates";

const showError = (message: string) => toast.error(formatToastText(message));
const showSuccess = (message: string) => toast.success(formatToastText(message));

export const displayErrorEpic = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(...notifiableActions.map((notifiableAction) => getAction(notifiableAction).failed.type))
    .map((notifiableAction: any) => {
      const messages = getNotifiableActionMessage(notifiableAction);

      const errorMessage =
        messages !== undefined && messages.errorMessage !== undefined
          ? messages.errorMessage
          : notifiableAction.payload.error.toString();

      if (errorMessage === "") {
        return;
      }

      return showError(errorMessage);
    })
    .filter((x) => false);

export const displaySuccessEpic = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(
      ...notifiableActions.map((x) => {
        return getAction(x).done.type;
      }),
    )
    .map((action) => {
      return getNotifiableActionMessage(action as any);
    })
    .filter((x) => x !== undefined && x.successMessage !== undefined)
    .map((x: any) => {
      showSuccess(x.successMessage);
    })
    .filter((x) => false);

const otherEpics = [];

const rootEpic = combineEpics(
  displayErrorEpic,
  displaySuccessEpic,
  ...authEpics,
  ...scriptsEpics,
  ...stepsEpics,
  ...usersEpics,
  ...variablesEpics,
  ...logsEpics,
  ...steplogsEpics,
  ...aggregatedJobLogsEpics,
  ...schedulerEpics,
  ...systemDictionaryEpics,
  ...sequencesEpics,
  ...virtualUsersEpics,
  ...guardEpics,
  ...apiKeyEpics,
  ...storageEpics,
  ...projectsEpics,
  ...userGroupEpics,
  ...roleEpics,
  ...projectCategoryEpics,
  ...itsEpics,
  ...itsRulesEpics,
  ...workItemEpics,
  ...scriptChangelogEpics,
  ...otherEpics,
  ...tagEpics,
  ...auditTrailEpics,
  ...globalSettingsEpics,
  ...documentationEpics,
  ...localizationEpics,
  ...runnerEpics,
  ...reportTemplatesEpics,
  ...globalVariablesEpics,
  ...kpisEpics,
  ...codeTemplateGroupsEpics,
  ...schedulerFlowEpics,
  ...itsConfigurationsEpics,
  ...codeTemplatesEpics,
);

export default rootEpic;
