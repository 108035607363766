import "./App.css";

import * as React from "react";
import { Redirect, Route, RouteProps, Switch, withRouter, RouteComponentProps } from "react-router";

import "./modules/app.reducers";

import NavContainer from "@app/modules/common/Nav.container";
import SchedulerLoaderContainer from "@app/modules/scheduler/SchedulerLoader.container";
import { ROUTES } from "@app/routes";

import ScriptLoaderContainer from "./modules/scripts/ScriptLoader.container";
import LogsPage from "./pages/Logs.page";
import SchedulerPage from "./pages/Scheduler.page";
import ScriptsPage from "./pages/Scripts.page";
import SettingsPage from "./pages/Settings.page";
import ProjectsPage from "./pages/Projects.page";
import { ROLES } from "@ea/shared_types/types";
import WithAuthorization from "./modules/common/WithAuthorization";
import { ApplicationState } from "./modules/app.reducers";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { projectActions } from "./modules/projects/projects.actions";
import { PROJECTS_TABLES_CONFIG } from "./modules/projects/projects.tables";
import { projectSelectors } from "./modules/projects/projects.selectors";
import { areGlobalSettingsLoaded } from "./modules/globalSettings/globalSettings.selectors";
import { InjectedIntlProps, FormattedMessage } from "react-intl";
import {
  currentUserRolesSelector,
  currentUserIdSelector,
} from "@ea/shared_components/auth/auth.selectors";

import ApiOnline from "./modules/common/ApiOnline";

import { getTranslationKey } from "./translations/translations.helpers";
import NonIdealState from "@ea/shared_components/common/NonIdealState";
import { getTagTableActions } from "./modules/tags/tag.actions";
import { TAG_TABLES_CONFIG } from "./modules/tags/tag.table";
import { globalSettingsActions } from "./modules/globalSettings/globalSettings.actions";
import DocumentationLoaderContainer from "./modules/documentation/DocumentationLoader.container";
import { getDefaultRoute } from "./utils/routing";
import RunnerLoaderContainer from "./modules/runner/RunnerLoader.container";
import { HideWhenDocumentationWizardHOC } from "./modules/common/HideWhenDocumentationWizard";
import { AppSection, ContentSection } from "./modules/common/components/Basic/Layout";
import GlobalNotifications from "./GlobalNotifications";
import { getValidatedPlatforms } from "./packs/packs.helpers";
import ReportsPage from "./pages/Reports.page";
import { loadRunnerPacks } from "./packs/packs.loader";
import { API } from "./services/api/api";

const LogsRoute = HideWhenDocumentationWizardHOC(
  WithAuthorization([ROLES.logs], [ROLES.documentationReader], () => <Redirect to="/" />)(Route),
);
const SchedulerRoute = HideWhenDocumentationWizardHOC(
  WithAuthorization([ROLES.scheduler], [ROLES.documentationReader], () => <Redirect to="/" />)(
    Route,
  ),
);
const SettingsRoute = WithAuthorization([ROLES.settings], [ROLES.documentationReader], () => (
  <Redirect to="/" />
))(Route);
const ProjectsRoute = WithAuthorization([ROLES.projects], [], () => <Redirect to="/" />)(Route);
const ReportsRoute = WithAuthorization([ROLES.reports], [], () => <Redirect to="/" />)(Route);

class App extends React.Component<
  IConnectProps & RouteProps & InjectedIntlProps & { reloadTranslations(): void | undefined },
  { hasPacksBeenLoaded: boolean }
> {
  state = {
    hasPacksBeenLoaded: false,
  };

  tokenRefreshTimer;

  componentWillMount() {
    const loadPacks = async () => {
      try {
        await loadRunnerPacks();
        this.props?.reloadTranslations();
      } catch (err) {
        console.error("Problem with loading commands chunks");
      }

      this.setState({
        hasPacksBeenLoaded: true,
      });
    };

    loadPacks();

    this.props.actions.loadGlobalSettings();
    this.props.actions.loadTags({});
    this.props.actions.load({});

    // we call it here to check if all platforms have been loaded correctly
    getValidatedPlatforms();
  }

  componentWillUnmount() {
    this.tokenRefreshTimer && clearTimeout(this.tokenRefreshTimer);
  }

  componentDidMount(): void {
    const { user } = this.props;
    if (user?.sso) {
      const startSSOCheck = async () => {
        const refresh = async () => {
          const response = await API.refreshToken({});
          const exp = new Date(response.expiresOn);
          const interval = exp.getTime() - new Date().getTime();
          this.tokenRefreshTimer = setTimeout(refresh, interval);
        };
        refresh();
      };
      startSSOCheck();
    }
  }

  isNavbarHidden() {
    return this.props.location?.pathname.includes(ROUTES.runner);
  }

  render() {
    const { currentUserId, isLoading, hasBeenLoaded, userRoles } = this.props;
    const { hasPacksBeenLoaded } = this.state;
    const isNavbarHidden = this.isNavbarHidden();

    const body = (element) => (
      <>
        <GlobalNotifications />
        <AppSection>
          <ApiOnline currentUserId={currentUserId}>{element}</ApiOnline>
        </AppSection>
      </>
    );

    if ((isLoading && !hasBeenLoaded) || !currentUserId || !hasPacksBeenLoaded) {
      return body(
        <ContentSection isNavbarHidden={isNavbarHidden}>
          <NonIdealState
            text={<FormattedMessage id={getTranslationKey("navigation", "loading")} />}
          />
        </ContentSection>,
      );
    }

    const routes: JSX.Element[] = [];

    routes.push(
      <Route
        exact
        path={ROUTES.main}
        render={() => <Redirect to={getDefaultRoute(userRoles)} />}
        key={ROUTES.main}
      />,
      <Route exact path={ROUTES.scripts} component={ScriptsPage} key={ROUTES.scripts} />,
      <ProjectsRoute
        path={`${ROUTES.projects}/:id?`}
        component={ProjectsPage}
        key={ROUTES.projects}
      />,
      <LogsRoute path={ROUTES.history} component={LogsPage} key={ROUTES.history} />,
      <SchedulerRoute
        path={ROUTES.scheduler}
        exact
        component={SchedulerPage}
        key={ROUTES.scheduler}
      />,
      <SettingsRoute
        path={ROUTES.settings}
        exact
        render={() => <Redirect to={`${ROUTES.settings}/sequences`} />}
        key={`${ROUTES.settings}/sequences`}
      />,
      <ReportsRoute path={ROUTES.reports} exact component={ReportsPage} key={ROUTES.reports} />,
      <SettingsRoute
        path={`${ROUTES.settings}/:key/:id?`}
        component={SettingsPage}
        key={ROUTES.settings}
      />,
      <Route path="/scripts/:id" component={ScriptLoaderContainer} key={`${ROUTES.scripts}/id`} />,
      <Route
        path={`${ROUTES.documentation_tree}/:id?`}
        component={DocumentationLoaderContainer}
        key={`${ROUTES.documentation_tree}`}
      />,
      <Route path={ROUTES.runner} component={RunnerLoaderContainer} key={ROUTES.runner} />,
      <SchedulerRoute
        path={`${ROUTES.scheduler}/:id`}
        component={SchedulerLoaderContainer}
        key={`${ROUTES.scheduler}/id`}
      />,
      <Route path="*" render={() => <Redirect to={getDefaultRoute(userRoles)} />} key={"*"} />,
    );

    return body(
      <>
        {!isNavbarHidden && <NavContainer {...this.props} />}
        <ContentSection isNavbarHidden={isNavbarHidden}>
          <Switch>{routes}</Switch>
        </ContentSection>
      </>,
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(globalSettingsActions, dispatch),
    ...bindActionCreators(projectActions, dispatch),
    ...bindActionCreators(
      {
        loadTags: getTagTableActions(TAG_TABLES_CONFIG.MAIN.id()).load,
      },
      dispatch,
    ),
  },
});

const connectCreator = connect((state: ApplicationState, props: RouteComponentProps<any>) => {
  const globalSettings = !!areGlobalSettingsLoaded(state);
  return {
    ...props,
    globalSettings,
    isLoading: projectSelectors.getIsLoadingSelector(state) || !globalSettings,
    userRoles: currentUserRolesSelector(state),
    currentUserId: currentUserIdSelector(state),
    hasBeenLoaded:
      projectSelectors.getParamsSelector(state, PROJECTS_TABLES_CONFIG.TREE.id()).loadedTime !==
        undefined && globalSettings,
    user: state.auth.user,
  };
}, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(App));
