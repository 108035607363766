import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";

import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { SelectField } from "@ea/shared_components/Form/Fields/SelectField";
import { DocumentationStatus, FEATURES, GLOBAL_SETTINGS_KEYS } from "@ea/shared_types/types";
import { Select } from "antd";
import StatusTag from "@app/modules/common/components/StatusTag";
import { DataTestIds } from "@app/utils/dataTestIds";
import HideableFeature from "@app/modules/common/HideableFeature";
import { getIn } from "final-form";

interface IDocumentationFormProps {
  values: any;
  readOnly?: boolean;
  change: (name: string, value: any) => void;
  confirmPublishingWithCredentials?: boolean;
  isAADUser: boolean;
}

const statusOptions = [
  {
    key: DocumentationStatus.DRAFT,
    value: DocumentationStatus.DRAFT,
    text: getTranslationKey("scripts", "status", DocumentationStatus.DRAFT),
  },
  {
    key: DocumentationStatus.PUBLISHED,
    value: DocumentationStatus.PUBLISHED,
    text: getTranslationKey("scripts", "status", DocumentationStatus.PUBLISHED),
  },
];

class DocumentationForm extends React.Component<IDocumentationFormProps, any> {
  renderOption = (option: { text: string; value: DocumentationStatus }): JSX.Element => {
    return (
      <Select.Option
        value={option.value}
        key={`${option.value}`}
        data-testid={DataTestIds.getOptionTestId(option.value)}
      >
        <StatusTag
          item={{ status: DocumentationStatus[option.value] }}
          data-testid={DataTestIds.getTagTestId(option.value)}
        />
      </Select.Option>
    );
  };

  render() {
    const { readOnly, values, confirmPublishingWithCredentials, isAADUser } = this.props;

    const isPublishing = getIn(values, "status") === DocumentationStatus.PUBLISHED;
    return (
      <>
        <InputField
          name="prettyName"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("common", "placeholder", "name")}
          label={getTranslationKey("common", "label", "name")}
          data-testid={DataTestIds.FORM_INPUT_NAME}
        />
        <TextAreaField
          name="description"
          placeholder={getTranslationKey("common", "placeholder", "description")}
          readOnly={readOnly}
          autoSize={{ minRows: 2, maxRows: 6 }}
          label={getTranslationKey("common", "label", "description")}
          data-testid={DataTestIds.FORM_TEXTAREA_DESCRIPTION}
        />
        <SelectField
          name="status"
          required
          label={getTranslationKey("scripts", "label", "status")}
          placeholder={getTranslationKey("scripts", "placeholder", "status")}
          data-testid={DataTestIds.FORM_SELECT_STATUS}
        >
          {statusOptions.map(this.renderOption)}
        </SelectField>
        {isPublishing && confirmPublishingWithCredentials && !isAADUser && (
          <HideableFeature
            feature={FEATURES.ELECTRONIC_SIGNOFF}
            globalSettingKey={GLOBAL_SETTINGS_KEYS.PUBLISHING_CREDENTIALS_CONFIRMATION}
          >
            {() => (
              <>
                <InputField
                  name="username"
                  label={getTranslationKey("common", "label", "username")}
                  placeholder={getTranslationKey("common", "placeholder", "username")}
                  required
                  data-testid={DataTestIds.FORM_INPUT_USERNAME}
                />
                <InputField
                  name="password"
                  label={getTranslationKey("common", "label", "password")}
                  placeholder={getTranslationKey("common", "placeholder", "password")}
                  required
                  type="password"
                  data-testid={DataTestIds.FORM_INPUT_PASSWORD}
                />
              </>
            )}
          </HideableFeature>
        )}
      </>
    );
  }
}

export default DocumentationForm;
