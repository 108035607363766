import * as React from "react";
import styled from "@emotion/styled";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import { DOCUMENTATION_TABLES_CONFIG, DOCUMENTATION_SCRIPT_COLUMNS } from "./documentation.table";
import {
  documentationDataSelectors,
  getSelectedPendingDocumentations,
  getNonPendingDocumentations,
} from "./documentation.selectors";
import { getDocumentationTableActions, documentationActions } from "./documentation.actions";
import DocumentationCommandBar from "./components/DocumentationCommandBar";
import CreateEditDocumentation from "./EditDocumentation.container";
import { withRouter, RouteComponentProps } from "react-router";
import { API } from "@app/services/api/api";
import { isCurrentUserDocumentationReader } from "@ea/shared_components/auth/auth.selectors";
import { DocumentationStatus } from "@ea/shared_types/types";
import { disabledFeaturesSelector } from "../globalSettings/globalSettings.selectors";
import { downloadFileBlob } from "@ea/shared_components/helpers/file";

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

interface IDocumentationTableProps extends RouteComponentProps<any> {
  scriptId: number;
}

interface IDocumentationTableState {
  editWindowVisibility: boolean;
  persistentQuery: any;
  isDownloadingDoc: boolean;
}

class DocumentationTableContainer extends React.Component<IConnectProps, IDocumentationTableState> {
  connectedTable: any;

  constructor(props: IConnectProps) {
    super(props);

    this.state = {
      editWindowVisibility: false,
      isDownloadingDoc: false,
      persistentQuery: {
        scriptId: props.scriptId,
      },
    };

    if (props.user && props.user.settings.displayOnlyPublishedDocuments) {
      this.state.persistentQuery.status = DocumentationStatus.PUBLISHED;
    }
  }

  componentDidUpdate(prevProps: IDocumentationTableProps) {
    if (prevProps.scriptId !== this.props.scriptId) {
      this.setState({
        persistentQuery: {
          scriptId: this.props.scriptId,
        },
      });
    }
  }

  openEditWindow = () => {
    this.setState({
      editWindowVisibility: true,
    });
  };

  closeEditWindow = () => {
    if (this.props.selectedDocumentation) {
      this.props.actions.loadSingle({ id: this.props.selectedDocumentation.id });
    }
    this.setState({
      editWindowVisibility: false,
    });
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  downloadDoc = async () => {
    const { selectedDocumentation, selectedNonPendingDocumentations } = this.props;
    if (selectedNonPendingDocumentations.length > 0) {
      this.setState({ isDownloadingDoc: true });

      const blob = await API.getDocumentationFile({
        id: selectedNonPendingDocumentations.map((wP) => wP.id),
      });

      if (selectedDocumentation) {
        const chunks = selectedDocumentation.path.split(".");
        const ext = chunks[chunks.length - 1];
        downloadFileBlob(blob, `${selectedDocumentation.prettyName}.${ext}`);
      } else {
        downloadFileBlob(blob, `Documentation.zip`);
      }

      this.setState({ isDownloadingDoc: false });
    }
  };

  remove = () => {
    this.props.actions.delete({ ids: this.props.selected });
  };

  render() {
    const { editWindowVisibility, persistentQuery, isDownloadingDoc } = this.state;
    const {
      selectedDocumentation,
      selected,
      user,
      scriptId,
      isDocumentationReader,
      disabledFeatures,
      selectedDocumentations,
      selectedPendingDocumentations,
      confirmPublishingWithCredentials,
    } = this.props;

    return (
      <Container>
        <DocumentationCommandBar
          onRemoveClick={this.remove}
          selected={selected}
          onEditClick={this.openEditWindow}
          onDocumentationDownload={this.downloadDoc}
          onReload={this.reload}
          isDownloadingDoc={isDownloadingDoc}
          isDocumentationReader={isDocumentationReader}
          selectedDocumentations={selectedDocumentations}
          selectedPendingDocumentations={selectedPendingDocumentations}
        />
        <CreateEditDocumentation
          visibility={editWindowVisibility}
          onClose={this.closeEditWindow}
          onCreate={this.reload}
          selected={selectedDocumentation}
          isEdit={editWindowVisibility}
          confirmPublishingWithCredentials={confirmPublishingWithCredentials}
          user={user}
        />
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={DOCUMENTATION_SCRIPT_COLUMNS}
          tableId={DOCUMENTATION_TABLES_CONFIG.SCRIPT.id(scriptId)}
          preferencesId={DOCUMENTATION_TABLES_CONFIG.SCRIPT.preferencesId}
          persistentQuery={persistentQuery}
          stateKey={"documentation"}
          tableActions={getDocumentationTableActions}
          disabledFeatures={disabledFeatures}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IDocumentationTableProps) => ({
  actions: {
    ...bindActionCreators(documentationActions, dispatch),
    ...bindActionCreators(
      getDocumentationTableActions(DOCUMENTATION_TABLES_CONFIG.SCRIPT.id(props.scriptId)),
      dispatch,
    ),
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: IDocumentationTableProps) => ({
    ...props,
    selected: documentationDataSelectors.getSelectedSelector(
      state,
      DOCUMENTATION_TABLES_CONFIG.SCRIPT.id(props.scriptId),
    ),
    selectedDocumentation: documentationDataSelectors.getSelectedItemSelector(
      state,
      DOCUMENTATION_TABLES_CONFIG.SCRIPT.id(props.scriptId),
    ),
    selectedDocumentations: documentationDataSelectors.getSelectedItemsSelector(
      state,
      DOCUMENTATION_TABLES_CONFIG.SCRIPT.id(props.scriptId),
    ),
    selectedPendingDocumentations: getSelectedPendingDocumentations(
      state,
      DOCUMENTATION_TABLES_CONFIG.SCRIPT.id(props.scriptId),
    ),
    selectedNonPendingDocumentations: getNonPendingDocumentations(
      state,
      DOCUMENTATION_TABLES_CONFIG.SCRIPT.id(props.scriptId),
    ),
    user: state.auth.user,
    confirmPublishingWithCredentials: state.globalSettings?.PUBLISHING_CREDENTIALS_CONFIRMATION,
    isDocumentationReader: isCurrentUserDocumentationReader(state),
    disabledFeatures: disabledFeaturesSelector(state),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(DocumentationTableContainer));
