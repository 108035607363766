import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Modal } from "antd";
import { ApplicationState } from "@app/modules/app.reducers";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { schedulerActions } from "@app/modules/scheduler";
import { schedulerDataSelectors } from "@app/modules/scheduler/scheduler.selectors";
import { JOB_STATUS_TYPE, SchedulerJobMapping } from "@ea/shared_types/types";
import { API } from "@app/services/api/api";
import {
  createStorageSelectOptions,
  jobToFormValues,
  schedulerFormValuesToJob,
} from "@app/modules/scheduler/formUtils";
import { CascaderOptionType } from "antd/lib/cascader";
import CreateSchedulerJobForm from "@app/modules/scheduler/components/CreateSchedulerJobForm";
import EditeableAreaFinal from "@ea/shared_components/EditeableArea/EditeableAreaFinal";
import styled from "@emotion/styled";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds, DataTestIdProp } from "@app/utils/dataTestIds";
import { getItsConfigurationsTableActions } from "../issueTrackingToolConfigurations/itsConfigurations.actions";
import { ITS_CONFIGURATIONS_TABLES_CONFIG } from "../issueTrackingToolConfigurations/itsConfigurations.table";
import { bindActionCreators, Dispatch } from "redux";
import { getItsConfigurationOptions } from "../issueTrackingToolConfigurations/itsConfigurations.selectors";
import { getSystemFormOptions } from "../systemDictionary/systemDictionary.selectors";
import { getVirtualUserFormOptions } from "../virtualUsers/virtualUsers.selectors";
import { getSystemTableActions } from "../systemDictionary/systemDictionary.actions";
import { getVirtualUsersTableActions } from "../virtualUsers/virtualUsers.actions";
import { SYSTEM_DICTIONARY_TABLES_CONFIG } from "../systemDictionary/systemDictionary.table";
import { VIRTUAL_USERS_TABLES_CONFIG } from "../virtualUsers/virtualUsers.table";

type SchedulerFlowState = {
  schedulerJobMappingItems: SchedulerJobMapping[];
  schedulerJobMappingItemsSelected: number[];
  schedulerJobMappingItemsSelectedMap: PlainObject<number>;
  isDraggable: boolean;
  selectedTab: string;
  isLoadingSchedulerJobMappingItems: boolean;
  storageOptions: CascaderOptionType[];
};

type SchedulerFlowProps = {
  schedulerJobId: number;
};

const initialState = {
  schedulerJobMappingItems: [],
  schedulerJobMappingItemsSelected: [],
  schedulerJobMappingItemsSelectedMap: {},
  isDraggable: false,
  selectedTab: "scripts",
  isLoadingSchedulerJobMappingItems: false,
  storageOptions: [],
};

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
});
const { confirm } = Modal;

class SchedulerFlowContainer extends React.Component<
  IConnectProps & InjectedIntlProps,
  SchedulerFlowState
> {
  state: SchedulerFlowState = initialState;
  editAreaContainer: any;

  componentDidMount() {
    this.loadStorages();
    this.props.itsConfigurations.load({});
    this.props.virtualUsersActions.load({});
    this.props.environmentsActions.load({});
  }

  componentWillReceiveProps(nextProps: IConnectProps & { schedulerJobId: number }) {
    if (this.props.schedulerJobId !== nextProps.schedulerJobId) {
      this.loadStorages();
      this.props.itsConfigurations.load({});
    }
  }

  loadStorages = async () => {
    const result = await API.getStoragesWithContainers({});
    const storageOptions = createStorageSelectOptions(result);
    this.setState({ storageOptions });
  };

  saveAndLoadScheduler = async (job) => {
    await API.editSchedulerJob({
      ...schedulerFormValuesToJob(job),
      id: this.props.schedulerJobId,
      status: JOB_STATUS_TYPE.INACTIVE,
    });
    this.props.loadSingle({ id: this.props.schedulerJobId });
  };

  onSave = async (job) => {
    const { intl, schedulerJob, schedulerJobId, loadSingle } = this.props;

    if (schedulerJob.status === JOB_STATUS_TYPE.CLOSED) {
      confirm({
        title: intl.formatMessage({
          id: getTranslationKey("messages", "confirm", "reactivateScheduler"),
        }),
        okText: intl.formatMessage({ id: getTranslationKey("button", "yes") }),
        okType: "danger",
        cancelText: intl.formatMessage({ id: getTranslationKey("button", "cancel") }),
        onOk: async () => {
          await this.saveAndLoadScheduler(job);
        },
        onCancel: () => {
          loadSingle({ id: schedulerJobId });
        },
        okButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_YES } as any,
        cancelButtonProps: { [`${DataTestIdProp}`]: DataTestIds.MODAL_BUTTON_CANCEL } as any,
      });
    } else {
      await this.saveAndLoadScheduler(job);
    }
  };

  onCancel = () => {};

  render() {
    const { schedulerJob, configurationOptions, environmentOptions, virtualUserOptions } =
      this.props;
    const { storageOptions } = this.state;

    return (
      <Container style={{ width: "550px" }}>
        <EditeableAreaFinal
          onSave={this.onSave}
          disableEditing={schedulerJob.isInternal || schedulerJob.status === JOB_STATUS_TYPE.ACTIVE}
          initialValues={jobToFormValues(schedulerJob)}
          onCancel={this.onCancel}
          render={({ change, values }) => (
            <CreateSchedulerJobForm
              storageOptions={storageOptions}
              values={values}
              change={change}
              configurationOptions={configurationOptions}
              environmentOptions={environmentOptions}
              virtualUserOptions={virtualUserOptions}
            />
          )}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(schedulerActions, dispatch),
  itsConfigurations: {
    ...bindActionCreators(
      getItsConfigurationsTableActions(ITS_CONFIGURATIONS_TABLES_CONFIG.ALL.id()),
      dispatch,
    ),
  },
  virtualUsersActions: {
    load: bindActionCreators(
      getSystemTableActions(SYSTEM_DICTIONARY_TABLES_CONFIG.ALL.id()),
      dispatch,
    ).load,
  },
  environmentsActions: {
    load: bindActionCreators(
      getVirtualUsersTableActions(VIRTUAL_USERS_TABLES_CONFIG.ALL.id()),
      dispatch,
    ).load,
  },
});

const connectCreator = connect(
  (state: ApplicationState, props: RouteComponentProps<any> & SchedulerFlowProps) => ({
    ...props,
    schedulerJob: schedulerDataSelectors.getItemSelector(state, props.match.params.id),
    configurationOptions: getItsConfigurationOptions(
      state,
      ITS_CONFIGURATIONS_TABLES_CONFIG.ALL.id(),
    ),
    environmentOptions: getSystemFormOptions(state, SYSTEM_DICTIONARY_TABLES_CONFIG.ALL.id()),
    virtualUserOptions: getVirtualUserFormOptions(state, VIRTUAL_USERS_TABLES_CONFIG.ALL.id()),
  }),
  mapDispatchToProps,
);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(injectIntl(SchedulerFlowContainer)));
