import { StartForegroundSessionParams } from "@ea/shared_types/types";
import "@ea/shared_types/api.types";
import { endpointsCreator } from "../utils/endpointCreator";

const apiCreator = endpointsCreator("api");

// TODO: refactor and remove edit user request
export const CommonAPI = {
  startSession: apiCreator<StartForegroundSessionParams, { url: string }>("POST", "runner/start"),
  editUser: apiCreator<any, any>("PATCH", (params) => `Appusers/${params.id}`),
  getUsers: apiCreator<any, any>("GET", "Appusers"),
  downloadFile: apiCreator<{ filePath: string }, any>("POST", `files/download-file`, {
    fileRequest: true,
  }),
  uploadTemporaryFile: apiCreator<{ files: File[] }, any>(
    "POST",
    (params) => "files/temporary-upload",
    {
      defaultContentType: false,
      bodyBuilder: (params) => {
        const formData = new FormData();
        if (params && params.files) {
          params.files.forEach((file) => formData.append(file.name, file));
        }
        return formData;
      },
    },
  ),
};
