import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import FormLayout from "@ea/shared_components/Form/FormLayout";
import { InputField } from "@ea/shared_components/Form/Fields/InputField";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import { InputNumberField } from "@ea/shared_components/Form/Fields/InputNumberField";
import { Sequence } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
interface ICreateSequenceFormProps {
  readOnly?: boolean;
  edit?: boolean;
  initialValues?: Sequence;
}

interface ICreateSequenceFormState {}

type Props = ICreateSequenceFormProps & InjectedIntlProps;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class CreateSequenceForm extends React.Component<Props, ICreateSequenceFormState> {
  render() {
    const { readOnly, intl, edit, initialValues } = this.props;

    const {
      whiteSpaceAndSpecialCharValidator,
      mustStartWithLetter,
      numberSequenceFormatValidator,
    } = validators(intl);

    return (
      <FormLayout {...formItemLayout}>
        {!edit && (
          <InputField
            name="name"
            required
            readOnly={readOnly}
            placeholder={getTranslationKey("sequences", "placeholder", "name")}
            label={getTranslationKey("common", "label", "name")}
            validate={composeValidators(whiteSpaceAndSpecialCharValidator, mustStartWithLetter)}
            data-testid={DataTestIds.FORM_INPUT_NAME}
          />
        )}
        <InputField
          name="format"
          required
          readOnly={readOnly}
          placeholder={getTranslationKey("sequences", "placeholder", "format")}
          label={getTranslationKey("sequences", "label", "format")}
          validate={composeValidators(numberSequenceFormatValidator)}
          data-testid={DataTestIds.FORM_INPUT_FORMAT}
        />
        {edit && (
          <InputNumberField
            name="nextval"
            required
            readOnly={readOnly}
            label={getTranslationKey("sequences", "label", "nextValue")}
            validate={composeValidators((value) =>
              initialValues && initialValues.nextval > value
                ? intl.formatMessage({ id: "sequences.message.noDecrease" })
                : undefined,
            )}
            data-testid={DataTestIds.FORM_INPUT_NEXT_VAL}
          />
        )}
      </FormLayout>
    );
  }
}

export default injectIntl(CreateSequenceForm);
