import * as React from "react";
import styled from "@emotion/styled";
import { RouteComponentProps, withRouter } from "react-router";
import RunnerContainer from "./Runner.container";
import qs from "qs";

const SectionContainer = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
});

class RunnerLoader extends React.Component<RouteComponentProps<any>, { sessionId: string }> {
  constructor(props) {
    super(props);

    const { sessionId } = qs.parse(props.location.search.substr(1));
    this.state = {
      sessionId,
    };
  }

  loadSession = (sessionId: string) => {
    this.setState({
      sessionId,
    });
  };

  render() {
    return (
      <SectionContainer>
        <RunnerContainer sessionId={this.state.sessionId} />
      </SectionContainer>
    );
  }
}

export default withRouter(RunnerLoader);
