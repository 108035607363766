const fs = require("fs");

module.exports = {
  revProxy: process.env.REV_PROXY ? true : false,
  sslOptions: process.env.SSL_KEY
    ? () => ({
        key: fs.readFileSync(process.env.SSL_KEY),
        cert: fs.readFileSync(process.env.SSL_CERT),
      })
    : false,
  API_HOST: `${process.env.API_PROTOCOL || "http"}://${process.env.API_HOST || "localhost"}:${
    process.env.API_PORT || 3000
  }`,
  PROXY_HOST: `${process.env.PROXY_PROTOCOL || "http"}://${process.env.PROXY_HOST || "localhost"}:${
    process.env.PROXY_PORT || 1500
  }`,
  WEBSOCKET_PROTOCOL: process.env.WEBSOCKET_PROTOCOL,
  HOST_ADDRESS: process.env.HOST_IP,
  RELEASE_NOTES_HOST: process.env.RELEASE_NOTES_HOST,
};
