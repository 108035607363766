import { Action } from "redux";
import { ActionsObservable } from "redux-observable";

import { makeAsyncEpic } from "@app/modules/app.reducers";
import { API } from "@app/services/api/api";

import * as actionCreators from "./auth.actions";
import { notificationListener, removeClientCookies } from "@app/utils/notifications";
import { getToken, saveToken } from "@ea/shared_components/utils/auth";
import { registerWebsocketHandler } from "../common/websocket";
import { WebsocketMessageTypes, GLOBAL_SETTINGS_KEYS } from "@ea/shared_types/types";
import { globalSettingsActionCreators } from "../globalSettings/globalSettings.actions";
import { localizationActionCreators } from "../localization/localization.actions";
import { LOCALIZATION_TABLES_CONFIG } from "../localization/localization.table";
import { bcApi } from "../common/broadcastChannel";

export const loginEpic = makeAsyncEpic(actionCreators.loginCreator, async (payload) => {
  const response = await API.login(payload);
  saveToken(response);
  bcApi.login();
  return response;
});

export const initAuth = makeAsyncEpic(actionCreators.initAuthCreator, async (payload) => {
  if (payload.isLoggedIn) {
    const response = await API.auth({});

    return response;
  } else {
    throw new Error("Your session has expired or you have been logged in on another device.");
  }
});

export const loadUserAfterLog = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(actionCreators.loginCreator.done)
    .map(() => actionCreators.initAuthCreator.started({ isLoggedIn: true }));

export const registerToWebsocketAfterAuthInit = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(actionCreators.initAuthCreator.done)
    .do(() => {
      registerWebsocketHandler(WebsocketMessageTypes.NOTIFICATION, notificationListener);
      registerWebsocketHandler(WebsocketMessageTypes.REMOVE_CLIENT_COOKIES, removeClientCookies);
    })
    .ignoreElements();

export const setLanguageAfterAuthInit = (action$: ActionsObservable<Action>) =>
  action$.ofType(actionCreators.initAuthCreator.done).map((_: any) =>
    localizationActionCreators.table.load.started({
      tableId: LOCALIZATION_TABLES_CONFIG.MAIN.id(),
    }),
  );

export const setWindowAddressInGlobalSettingsAfterAuthInit = (action$: ActionsObservable<Action>) =>
  action$.ofType(actionCreators.initAuthCreator.done).map((_: any) =>
    globalSettingsActionCreators.editGlobalSettings.started({
      id: GLOBAL_SETTINGS_KEYS.ADMINISTRATION_ADDRESS,
      value: `${window.location.protocol}//${window.location.host}/`,
    }),
  );
