/** @jsx jsx */ import { jsx } from "@emotion/core";
import { css } from "@emotion/core";
import { Button, Tooltip } from "antd";
import styled from "@emotion/styled";
import * as React from "react";

import { COLORS } from "../styles/consts";
import CommandBarItem from "./CommandBarItem";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/fontawesome-free-regular";
import { FormattedMessage } from "react-intl";
import isPropValid from "@emotion/is-prop-valid";
import DynamicIcon from "../common/DynamicIcon";
import { DataTestIdProp, OptionDataTestIdProp } from "../utils/dataTestHelpers";

interface ICommandBarButtonProps {
  name: string;
  onClick?: (event?) => void;
  text: string;
  icon?: string | JSX.Element;
  far?: boolean;
  disabled?: boolean;
  size?: "small" | "default" | "large" | undefined;
  checkable?: boolean;
  checked?: boolean;
  style?: React.CSSProperties;
  tooltip?: string;
  className?: string;
  htmlType?: string;
  type?: string;
  hoverColor?: string;
  onOptionsClick?: () => void;
  loading?: boolean;
  checkedColor?: string;
  [DataTestIdProp]: string;
}

const CommandButton = styled(Button)(
  {
    borderStyle: "none",
    borderRadius: 0,
    textAlign: "left",
    height: "40px !important",
    border: 0,
    width: "100%",
  },
  ({
    disabled,
    type,
    hovercolor = COLORS.HOVER_BUTTON_TEXT,
    ischeckable,
    checked,
    checkedcolor,
  }: {
    disabled: boolean;
    type: any;
    hovercolor?: string;
    ischeckable?: boolean;
    checked?: boolean;
    checkedcolor?: string;
  }) => ({
    border: ischeckable && checked && checkedcolor ? `2px solid ${checkedcolor}` : "",
    textAlign: "left" as any,
    borderRadius: 0,
    backgroundColor: !type ? `${COLORS.TRANSPARENT} !important` : undefined,
    // todo: create/find simple utiity to simplify this kind of construction, something simillar to classnames?
    ...(disabled
      ? {}
      : type
      ? {
          ":hover": {
            backgroundColor: `${COLORS.PRIMARY_HOVER}`,
          },
        }
      : {
          ":hover": {
            backgroundColor: `${COLORS.HOVER_BUTTON_BACKGROUND} !important`,
            color: `${hovercolor} !important`,
          },
          ":active": {
            backgroundColor: COLORS.TRANSPARENT,
            color: COLORS.BUTTON_TEXT,
          },
          ":focus": {
            backgroundColor: COLORS.TRANSPARENT,
            color: COLORS.BUTTON_TEXT,
          },
        }),
  }),
);

const renderCheckIcon = ({ checkable, checked }: { checkable?: boolean; checked?: boolean }) => {
  if (checkable && checked) {
    return <FontAwesomeIcon icon={faCheckSquare} style={{ marginRight: "8px" }} />;
  } else if (checkable && !checked) {
    return <FontAwesomeIcon icon={faSquare} style={{ marginRight: "8px" }} />;
  }

  return null;
};

const renderIcon = ({ icon, disabled }) => {
  if (icon && typeof icon === "string") {
    return <DynamicIcon type={icon} />;
  }

  return icon && <i className="anticon">{icon}</i>;
};

const renderButton = (props) => (
  <div
    style={{
      width: "100%",
      display: "flex",
    }}
  >
    <CommandButton
      disabled={props.disabled}
      key={props.name}
      onClick={props.onClick ? props.onClick : () => {}}
      size={props.size ? props.size : "default"}
      className={props.className}
      ischeckable={props.checkable}
      checked={props.checked}
      checkedcolor={props.checkedColor}
      htmlType={props.htmlType}
      type={props.type}
      hovercolor={props.hoverColor}
      loading={props.loading}
      data-testid={props[DataTestIdProp]}
    >
      {renderCheckIcon(props)}
      {props.loading || renderIcon(props)}
      <FormattedMessage id={props.text} />
      {props.children}
    </CommandButton>
    {props.onOptionsClick && (
      <CommandButton
        css={{
          width: "50px",
        }}
        disabled={props.disabled}
        key={"optionsButton"}
        onClick={props.onOptionsClick ? props.onOptionsClick : () => {}}
        size={props.size ? props.size : "default"}
        className={props.className}
        htmlType={props.htmlType}
        type={props.type}
        hovercolor={props.hoverColor}
        data-testid={`${props[DataTestIdProp]}-options`}
      >
        {renderIcon({ icon: "EllipsisOutlined", disabled: props.disabled })}
      </CommandButton>
    )}
  </div>
);

const CommandBarButton: React.FunctionComponent<ICommandBarButtonProps> = (props) => {
  return (
    <CommandBarItem far={props.far}>
      {props.tooltip ? (
        <Tooltip placement="topLeft" title={props.tooltip}>
          {renderButton(props)}
        </Tooltip>
      ) : (
        renderButton(props)
      )}
    </CommandBarItem>
  );
};

export default CommandBarButton;
