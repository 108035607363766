import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import { authActions } from "@app/modules/auth/auth.actions";
import PrivateRouteContainer from "@app/modules/auth/PrivateRoute.container";
import PublicRouteContainer from "@app/modules/auth/PublicRoute.container";

import App from "./App";
import Login from "./Login";
import TranslatorProviderContainer from "./modules/common/TranslatorProvider.container";
import { getToken } from "@ea/shared_components/utils/auth";
import { configureStore } from "./store";
import ErrorPage from "./ErrorPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { loadMonaco } from "@ea/shared_components/CodeField/Monaco/MonacoEditorField.helpers";
import { withTranslation, WithTranslation } from "react-i18next";
import NonIdealState from "@ea/shared_components/common/NonIdealState";
import { FormattedMessage } from "react-intl";
import { getTranslationKey } from "./translations/translations.helpers";
import history from "./utils/history";
import { AppSection, ContentSection } from "./modules/common/components/Basic/Layout";
import { startBroadcastChannel } from "./modules/common/broadcastChannel";
import SSO from "./modules/auth/SSO";

const store = configureStore(history)();
let token;
try {
  token = getToken();
} catch (error) {
  token = null;
}

try {
  store.dispatch(authActions.init({ isLoggedIn: token !== null }));
} catch(err) {
  console.error(err);
}

export class Root extends React.Component<WithTranslation> {
  componentDidMount() {
    loadMonaco();
    startBroadcastChannel();
  }
  render() {
    return (
      <Provider store={store}>
        <TranslatorProviderContainer
          render={({ reloadTranslations }) => (
            <ErrorPage>
            {this.props.tReady ? (
              <>
                <ToastContainer
                  position="bottom-center"
                  autoClose={10000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  pauseOnHover
                />
                <ConnectedRouter history={history}>
                  <div>
                    <Switch>
                      <Route
                        path="/login"
                        children={(props) => {
                          return (
                            <PublicRouteContainer
                              location={props.location}
                              path="/login"
                              component={Login}
                            />
                          );
                        }}
                      />
                      <Route
                        path="/auth*"
                        children={(props) => {
                          return (
                            <PublicRouteContainer
                              location={props.location}
                              path="/auth*"
                              component={SSO}
                            />
                          );
                        }}
                      />
                      <Route
                        children={(props) => {
                          return (
                            <PrivateRouteContainer location={props.location} component={App} componentProps={{ reloadTranslations }} />
                          );
                        }}
                      />
                    </Switch>
                  </div>
                </ConnectedRouter>
              </>
            ) : (
              <AppSection>
                <ContentSection>
                  <NonIdealState
                    text={<FormattedMessage id={getTranslationKey("navigation", "loading")} />}
                  />
                </ContentSection>
              </AppSection>
            )}
          </ErrorPage>
          )}
        >

        </TranslatorProviderContainer>
      </Provider>
    );
  }
}

export default withTranslation()(Root);
