import * as React from "react";

import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { DataTestIds } from "@app/utils/dataTestIds";
import { FormattedMessage } from "react-intl";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faEdit, faHandRock, faSave, faTimes } from "@fortawesome/fontawesome-free-solid";
import { TableDisplayMode, tableDisplayModes } from "../schedulerFlow.helpers";

interface SchedulerFlowCommandBarProps {
  onAddScriptsClick: () => void;
  onExportToCsvClick: () => void;
  onRefresh: () => void;
  toggleDragMode: () => void;
  onSave: () => void;
  onCancel: () => void;
  onEdit: () => void;
  isExporting: boolean;
  isSaving: boolean;
  isDragEnabled: boolean;
  displayMode: TableDisplayMode;
  selected: number[];
  onRemove: (ids: number[]) => void;
  readOnly?: boolean;
  isEditDisabled: boolean;
}

const SchedulerFlowCommandBar = ({
  onAddScriptsClick,
  onExportToCsvClick,
  onRefresh,
  isExporting,
  toggleDragMode,
  isDragEnabled,
  onSave,
  onCancel,
  onEdit,
  displayMode,
  selected,
  onRemove,
  isSaving,
  readOnly,
  isEditDisabled,
}: SchedulerFlowCommandBarProps) => (
  <CommandBar data-testid={DataTestIds.COMMANDBAR_SCHEDULER_FLOW}>
    {displayMode === tableDisplayModes.READ && (
      <>
        <CommandBarButton
          name="edit"
          text="Edit"
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={onEdit}
          disabled={readOnly || isEditDisabled}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EDIT}
        />
        <CommandBarButton
          name="refresh"
          onClick={onRefresh}
          text={getTranslationKey("commandBar", "refresh")}
          icon="ReloadOutlined"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
        />
        <CommandBarButton
          name="remove"
          onClick={() => onRemove(selected)}
          text={getTranslationKey("commandBar", "remove")}
          icon="DeleteOutlined"
          disabled={readOnly || selected.length === 0}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REMOVE}
        />
        <CommandBarButton
          name="export"
          onClick={onExportToCsvClick}
          text={getTranslationKey("commandBar", "exportToCsv")}
          icon="FileExcelOutlined"
          loading={isExporting}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT}
        />
        <CommandBarButton
          name="addScripts"
          onClick={onAddScriptsClick}
          text={getTranslationKey("commandBar", "addScripts")}
          icon="PlusOutlined"
          disabled={readOnly || isExporting}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_ADD_SCRIPTS}
          far
        />
      </>
    )}
    {displayMode === tableDisplayModes.EDIT && (
      <>
        <CommandBarButton
          name="save"
          text={getTranslationKey("commandBar", "save")}
          icon={<FontAwesomeIcon icon={faSave} />}
          type="primary"
          disabled={isSaving}
          loading={isSaving}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_SAVE}
          onClick={onSave}
        />
        <CommandBarButton
          name="cancel"
          text={getTranslationKey("button", "cancel")}
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={onCancel}
          hoverColor="red"
          data-testid={DataTestIds.COMMANDBAR_BUTTON_CANCEL}
          disabled={isSaving}
        />

        <CommandBarButton
          name="reorder"
          onClick={toggleDragMode}
          disabled={isSaving}
          text={
            !isDragEnabled
              ? getTranslationKey("commandBar", "reorder")
              : getTranslationKey("commandBar", "stopReorder")
          }
          icon={<FontAwesomeIcon icon={faHandRock} />}
          tooltip={
            (<FormattedMessage id={getTranslationKey("messages", "tooltip", "reorder")} />) as any
          }
          data-testid={DataTestIds.COMMANDBAR_BUTTON_REORDER}
        />
      </>
    )}
  </CommandBar>
);

export default SchedulerFlowCommandBar;
