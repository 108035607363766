import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { InjectedIntlProps, injectIntl, FormattedMessage } from "react-intl";

import { ApplicationState } from "../redux/state";
import { CallableTableActions, SortSection } from "../redux/reducers/common.data.reducer";
import { Item, FEATURES } from "@ea/shared_types/types";

import { ColumnFilterConfig, ColumnFilters, Filter } from "../redux/common.filters";
import {
  OnSelectHandler,
  OnSortHandler,
  pageSizes,
  PlainObject,
  SelectMode,
} from "../redux/common.models";
import { createDataSelectors } from "../redux/common.selectors";
import { ColumnConfig } from "./common.tables";
import DateTimeRangeFilter from "./filters/DateTimeRangeFilter";
import FilterContainer from "./filters/FilterContainer";
import InputFilter from "./filters/InputFilter";
import SelectFilter from "./filters/SelectFilter";
import Table, { ITableProps } from "./elements/Table";
import CustomizableColumns from "./CustomizableColumns";
import { TablePaginationConfig } from "antd/lib/table/interface";

import { calculateTimeDifferenceFromNow } from "../utils/date";
import { TABLE_CACHE_TIME } from "./config";
import TreeFilter from "./filters/TreeFilter";
import RangeFilter from "./filters/RangeFilter";
import { FilterOutlined } from "@ant-design/icons";
import delay from "../utils/delay";
import { COLORS } from "../styles/consts";

type TableProps<T> = {
  columns: ColumnProps<T>[];
  selected: number[];
  items: T[];
  isLoading?: boolean;
  onSelect: OnSelectHandler;
  pagination?: TablePaginationConfig | false;
  sort?: {
    config: SortSection<T> | undefined;
    onSortChange: OnSortHandler<T>;
    onClearSorting: ({}: any) => void;
  };
  onReload: () => void;
  onRowDoubleClick?: (record: T, index: number, event: Event) => any;
  showHeader?: boolean;
};

type ITableWrapperProps<T extends Item<string | number>, State> = {
  modifySelectors?: (value: any) => any; // todo: do it in a better way
  dynamicFilterValues?: PlainObject<any>;
  pageable: boolean;
  columnsConfig: ColumnConfig<T>[];
  disabledFeatures?: FEATURES[];
  filters?: Filter<T>[];
  mapItems?: (items: T[]) => T[];
  render?: (props: TableProps<T>) => React.ReactNode;
  tableId: string;
  stateKey: keyof State;
  preferencesId: string;
  tableActions: (id: string) => CallableTableActions<T>;
  setRef?: (comp: any) => void;
  selectable?: boolean;
  onRowDoubleClick?: (record: T, index: number) => any;
  expandedRowRender?: (item: T) => any;
  shouldRowBeExpandable?: (item: T) => boolean;
  items?: T[];
  className?: string;
  persistentQuery?: any;
  autoReload?: boolean | number;
  multiselect?: boolean;
  comparePersistentQuery?: (currentQuery: any, prevQuery: any) => boolean;
  showHeader?: boolean;
  onReload?: () => void;
  position?: TablePaginationConfig["position"];
  footer?: () => string;
  isRowDisabled?: (record) => boolean;
  notSelectableIds?: (number | string)[];
  isDragEnabled?: boolean;
  markPositionClass?: (record: T) => string;
  disableSelectAll?: boolean;
  expandedRowKeys?: string[] | number[];
  disbaleExpandingOnDragEnabled?: boolean;
  nestedInnerShadow?: (record: T) => string;
  isExpandedRowEmpty?: (record: T) => boolean;
  autoScrollAnchor?: (record: T) => string;
  id?: string;
  disableRowSelection?: boolean;
};

type TableWrapperState<T> = {
  columns: ColumnProps<T>[];
  columnFiltersVisibility: any;
  pageSizeIsLoading: boolean;
};

interface ExtendedColumnProps<T> extends ColumnProps<T> {
  editable?: (record: T) => boolean;
}

const timer = {};

class ConnectedTable<T extends Item, State> extends React.Component<
  ITableWrapperProps<T, State> & IConnectProps & InjectedIntlProps,
  TableWrapperState<T>
> {
  state: TableWrapperState<T> = {
    columns: [],
    columnFiltersVisibility: {},
    pageSizeIsLoading: false,
  };

  componentDidMount() {
    const {
      filters,
      actions,
      pageable,
      setRef,
      persistentQuery,
      autoReload,
      tableParams,
      comparePersistentQuery,
    } = this.props;

    if (filters) {
      filters.forEach((filter) =>
        actions.setFilter({
          fieldName: filter.fieldName,
          fieldValue: filter.fieldValue,
        }),
      );
    }

    const persistentQueryChanged =
      comparePersistentQuery &&
      tableParams.persistentQuery !== undefined &&
      persistentQuery !== undefined
        ? comparePersistentQuery(persistentQuery, tableParams.persistentQuery)
        : persistentQuery && persistentQuery !== tableParams.persistentQuery;
    if (persistentQueryChanged) {
      actions.setPersistentQuery({ query: persistentQuery });
    }

    const firstLoad = () => {
      if (!persistentQueryChanged && !tableParams.loadedTime) {
        if (pageable) {
          actions.loadPaginationConfig({});
        } else {
          actions.load({});
        }
      }
    };

    firstLoad();

    const refreshLoad = () => {
      if (!persistentQueryChanged && tableParams.loadedTime) {
        const timeDiff = calculateTimeDifferenceFromNow(tableParams.loadedTime);
        if (timeDiff > TABLE_CACHE_TIME) {
          actions.load({});
        }
      }
    };

    refreshLoad();

    this.setState({
      columns: this.generateColumns({}),
    });

    if (setRef) {
      setRef(this);
    }

    if (autoReload) {
      this.setAutoReload();
    }
  }

  componentDidUpdate(
    prevProps: ITableWrapperProps<T, State> & IConnectProps & InjectedIntlProps,
    prevState: TableWrapperState<T> & InjectedIntlProps,
  ) {
    if (
      prevProps.columnsConfig !== this.props.columnsConfig ||
      prevProps.tableParams.query !== this.props.tableParams.query ||
      prevProps.tableParams.sort !== this.props.tableParams.sort ||
      prevProps.intl.locale !== this.props.intl.locale ||
      prevState.columnFiltersVisibility !== this.state.columnFiltersVisibility ||
      prevProps.persistentQuery !== this.props.persistentQuery ||
      prevProps.disabledFeatures !== this.props.disabledFeatures
    ) {
      this.setState({
        columns: this.generateColumns({}),
      });
    }

    if (this.props.persistentQuery && prevProps.persistentQuery !== this.props.persistentQuery) {
      if (this.props.comparePersistentQuery) {
        const diff = this.props.comparePersistentQuery(
          this.props.persistentQuery,
          prevProps.persistentQuery,
        );
        if (diff) {
          this.props.actions.setPersistentQuery({ query: this.props.persistentQuery });
        }
      } else {
        this.props.actions.setPersistentQuery({ query: this.props.persistentQuery });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(timer[this.props.tableId]);
  }

  setAutoReload = () => {
    const { autoReload } = this.props;
    const timeout =
      autoReload &&
      typeof autoReload === "number" &&
      Number.isInteger(autoReload as any) &&
      autoReload > 0
        ? autoReload
        : 10000;

    timer[this.props.tableId] = setInterval(() => {
      if (document.visibilityState === "visible") {
        this.reload(true, true);
      }
    }, timeout);
  };

  getSortOrder = (key: string) => {
    const {
      tableParams: { sort },
    } = this.props;
    if (!sort) {
      return undefined;
    }

    const { sortBy, sortDirection } = sort;
    if (key === sortBy) {
      const direction = sortDirection === "ASC" ? "ascend" : "descend";
      return direction;
    } else {
      return undefined;
    }
  };

  changeFilterVisibility = (fieldName: Extract<keyof T, string>, visibility) => {
    this.setState({
      columnFiltersVisibility: {
        ...this.state.columnFiltersVisibility,
        [fieldName]: visibility,
      },
    });
  };

  getColumnFilter = (fieldName: Extract<keyof T, string>, filterConfig: ColumnFilterConfig) => {
    const { filterType, values, filterKey, noValueLabel } = filterConfig;
    const value = this.props.tableParams.query
      ? this.props.tableParams.query[filterKey || fieldName]
      : undefined;
    const filterVisibility = this.state.columnFiltersVisibility[fieldName] || false;

    const dynamicValues =
      !values && this.props.dynamicFilterValues && this.props.dynamicFilterValues[fieldName]
        ? this.props.dynamicFilterValues[fieldName]
        : values;

    let filterDropdown: JSX.Element | null = null;

    const renderFiltersMap = {
      [ColumnFilters.TEXT]: (props) => <InputFilter {...props} />,
      [ColumnFilters.SELECT]: (props) => (
        <SelectFilter
          {...props}
          values={dynamicValues || []}
          noValueLabel={dynamicValues && dynamicValues.length > 0 && noValueLabel}
        />
      ),
      [ColumnFilters.RANGE]: (props) => <RangeFilter {...props} />,
      [ColumnFilters.DATE_RANGE]: (props) => <DateTimeRangeFilter {...props} />,
      [ColumnFilters.TREE]: (props) => <TreeFilter {...props} values={dynamicValues} />,
    };

    filterDropdown = (
      <FilterContainer
        isOpen={filterVisibility}
        changeVisibility={this.changeFilterVisibility}
        fieldKey={filterKey || fieldName}
        clearFilters={this.props.actions.clearFilters}
        setFilter={this.props.actions.setFilter}
        value={value}
        render={renderFiltersMap[filterType]}
      />
    );

    // do not display filter when there's no values to choose
    const hasValues = (values && values.length > 0) || (dynamicValues && dynamicValues.length > 0);
    if ((filterType === ColumnFilters.SELECT || filterType === ColumnFilters.TREE) && !hasValues) {
      return null;
    }

    return {
      filterDropdownVisible: filterVisibility,
      filterIcon: (props = {}) => (
        <FilterOutlined style={{ color: value ? COLORS.BLUE2 : COLORS.GRAY }} {...props} />
      ),
      onFilterDropdownVisibleChange: (visible) => this.changeFilterVisibility(fieldName, visible),
      filterDropdown,
    };
  };

  generateColumnFromConfig = (columnConfig: ColumnConfig<T>) => {
    const { props, frameworkProps } = columnConfig;

    let createdColumn: ExtendedColumnProps<T> = {
      dataIndex: props.dataIndex,
      key: props.dataIndex,
      title: <FormattedMessage id={props.label} />,
      render: props.render,
      width: props.width,
      ellipsis: props.ellipsis,
      editable: props.editable,
    };

    if (props.sortable) {
      createdColumn.sorter = true;
      createdColumn.sortOrder = this.getSortOrder(props.dataIndex);
      createdColumn.onHeaderCell = () => ({
        style: {
          cursor: "pointer",
        },
        onClick: (event: any) => {
          if (
            event.target.classList?.contains("ant-table-column-sorter-down") ||
            event.target.classList?.contains("ant-table-column-sorter-up") ||
            event.target.classList?.contains("anticon-filter")
          ) {
            return;
          }
          const currentSorting = this.props.tableParams.sort;
          if (currentSorting?.sortBy === props.dataIndex) {
            if (currentSorting.sortDirection === "DESC") {
              return this.props.actions.clearSorting({
                sortBy: props.dataIndex,
              });
            } else {
              return this.props.actions.changeSorting({
                sortBy: props.dataIndex,
                sortDirection: "DESC",
              });
            }
          }
          return this.props.actions.changeSorting({
            sortBy: props.dataIndex,
            sortDirection: "ASC",
          });
        },
      });
    }
    if (props.filter) {
      createdColumn = {
        ...createdColumn,
        ...this.getColumnFilter(props.dataIndex, props.filter),
      };
    }

    if (props.editable) {
      createdColumn = {
        ...createdColumn,
        onCell: (record) => ({
          record,
          editable: props.editable!(record),
          dataIndex: props.dataIndex,
          title: this.props.intl.formatMessage({ id: props.label }),
          handleSave: props.handleSave,
          translateLabel: props.translateLabel,
        }),
      };
    }

    return {
      ...createdColumn,
      ...frameworkProps,
    };
  };

  generateColumns = ({
    filterIcon,
    sortedColumns,
  }: { filterIcon?; sortedColumns?: string[] } = {}) => {
    const { columnsConfig, disabledFeatures } = this.props;
    let generatedCols;

    // filter out columns which are connected with disabled features, e.g. tags, scriptVersion
    const filteredOutColumnConfig = columnsConfig
      .filter((column) =>
        column.props.feature && disabledFeatures && disabledFeatures.length > 0
          ? !disabledFeatures.includes(column.props.feature)
          : true,
      )
      .filter((column) => (column.props.shouldHide ? !column.props.shouldHide() : true));

    if (sortedColumns) {
      generatedCols = filteredOutColumnConfig
        .filter((column) => sortedColumns.includes(column.props.dataIndex))
        .map((columnConfig) => this.generateColumnFromConfig(columnConfig));
    } else {
      generatedCols = filteredOutColumnConfig.map((columnConfig) =>
        this.generateColumnFromConfig(columnConfig),
      );
    }

    if (filterIcon) {
      const lastColumn = generatedCols[generatedCols.length - 1];

      if (!lastColumn.filterDropdown) {
        lastColumn.filterDropdown = <> </>;
        lastColumn.onFilterDropdownVisibleChange = null;
      }
      lastColumn.filterIcon = () => filterIcon;

      return [...generatedCols.slice(0, generatedCols.length - 1), lastColumn];
    }
    return generatedCols;
  };

  resetSelection = () => {
    this.props.actions.select({ mode: SelectMode.Replace, ids: [] });
  };

  reload = (backgroundReload = false, persistCurrentPage = false) => {
    this.props.actions.loadPaginationConfig({ backgroundReload, persistCurrentPage });
    if (this.props.onReload) {
      this.props.onReload();
    }
  };

  changePage = (newPage: number) => {
    this.props.actions.changePage({ page: newPage });
  };

  changePageSize = async (newPageSize: number) => {
    this.setState({ pageSizeIsLoading: true });
    this.props.actions.changePageSize({ pageSize: newPageSize });
    await delay(50);
    this.setState({ pageSizeIsLoading: false });
  };

  render() {
    const {
      paging,
      actions,
      render,
      selected,
      isLoading,
      items,
      selectable,
      onRowDoubleClick,
      expandedRowRender,
      shouldRowBeExpandable,
      multiselect,
      className,
      pageable,
      showHeader,
      intl,
      preferencesId,
      mapItems,
      footer,
      isRowDisabled,
      notSelectableIds,
      isDragEnabled,
      markPositionClass,
      disableSelectAll,
      expandedRowKeys,
      disbaleExpandingOnDragEnabled,
      nestedInnerShadow,
      isExpandedRowEmpty,
      id,
      autoScrollAnchor,
      disableRowSelection,
    } = this.props;
    const paginationConfig =
      pageable && paging
        ? {
            pageSize: paging.pageSize,
            total: paging.total,
            current: paging.currentPage,
            pageSizes: pageSizes.map((p) => p.toString()),
            onPageChange: this.changePage,
            onPageSizeChange: this.changePageSize,
            showSizeChanger: true,
            position: ["bottomRight"] as TablePaginationConfig["position"],
          }
        : false;

    const sort = {
      config: this.props.tableParams.sort,
      onSortChange: this.props.actions.changeSorting,
      onClearSorting: this.props.actions.clearSorting,
    };

    const mappedItems = mapItems ? mapItems(items) : items;

    if (render) {
      return render({
        selected,
        items: mappedItems,
        isLoading,
        sort,
        onRowDoubleClick,
        pagination: paginationConfig,
        columns: this.state.columns,
        onSelect: actions.select,
        onReload: this.reload,
        showHeader: showHeader,
      });
    }

    return (
      <CustomizableColumns
        columns={this.state.columns}
        preferencesId={preferencesId}
        showSettings={!(isLoading || this.state.pageSizeIsLoading)}
        isLoading={isLoading}
        filterActions={actions}
        showHeader={showHeader}
        length={{
          items: items?.length || 0,
          selected: selected?.length || 0,
          total: paging?.total || 0,
        }}
      >
        {({ sortedColumns, filterIcon, paginationItemRender }) => {
          return (
            <Table
              selectAllLabel={intl.formatMessage({ id: "table.selectAll" })}
              selected={selected}
              onSelect={actions.select}
              size="small"
              dataSource={mappedItems}
              columns={this.generateColumns({ filterIcon, sortedColumns })}
              pagination={
                paginationConfig
                  ? { ...paginationConfig, itemRender: paginationItemRender }
                  : paginationConfig
              }
              isLoading={isLoading}
              sort={sort as any}
              selectable={selectable}
              onRowDoubleClick={onRowDoubleClick}
              expandedRowRender={expandedRowRender}
              shouldRowBeExpandable={shouldRowBeExpandable}
              className={className}
              multiselect={multiselect !== undefined ? multiselect : true}
              showHeader={showHeader}
              footer={footer}
              isRowDisabled={isRowDisabled}
              notSelectableIds={notSelectableIds}
              isDragEnabled={isDragEnabled}
              actions={actions}
              markPositionClass={markPositionClass}
              disableSelectAll={disableSelectAll}
              expandedRowKeys={expandedRowKeys}
              disbaleExpandingOnDragEnabled={disbaleExpandingOnDragEnabled}
              nestedInnerShadow={nestedInnerShadow}
              isExpandedRowEmpty={isExpandedRowEmpty}
              id={id}
              autoScrollAnchor={autoScrollAnchor}
              disableRowSelection={disableRowSelection}
            />
          );
        }}
      </CustomizableColumns>
    );
  }
}

const mapStateToProps = (state: ApplicationState, props: ITableWrapperProps<any, any>) => {
  // typescript problem
  const { stateKey, tableId } = props;

  const defaultModifier = (v) => v;
  const selectorsModifier = props.modifySelectors || defaultModifier;
  const dataSelectors = selectorsModifier(createDataSelectors<any, any>()()(stateKey));

  return {
    tableParams: {
      ...dataSelectors.getParamsSelector(state, tableId),
    },
    isLoading: dataSelectors.getIsLoadingSelector(state),
    items: dataSelectors.getOrderedDataSelector(state, tableId),
    selected: dataSelectors.getSelectedSelector(state, tableId),
    selectedItem: dataSelectors.getSelectedItemSelector(state, tableId),
    paging: dataSelectors.getParamsSelector(state, tableId).paging
      ? {
          ...dataSelectors.getParamsSelector(state, tableId).paging!,
          pageSize: dataSelectors.getPageSizeSelector(state, tableId),
          currentPage: dataSelectors.getCurrentPageSelector(state, tableId),
        }
      : undefined,
    pagingOffset: dataSelectors.getOffsetSelector(state, tableId),
    totalPages: dataSelectors.getTotalPagesSelector(state, tableId),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, props: ITableWrapperProps<any, any>) => ({
  actions: {
    ...bindActionCreators(props.tableActions(props.tableId), dispatch),
  },
});

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(injectIntl(ConnectedTable as any) as any); // typescript problem
