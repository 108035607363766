import * as PropTypes from "prop-types";
import * as React from "react";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import styled from "@emotion/styled";
import SchedulerCommandBar from "@app/modules/scheduler/components/SchedulerCommandBar";
import SchedulerJobCreatorContainer from "@app/modules/scheduler/SchedulerJobCreator.container";
import { ApplicationState } from "@app/modules/app.reducers";
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { JOB_STATUS_TYPE, SchedulerJob } from "@ea/shared_types/types";
import { replaceColumn } from "@ea/shared_components/Table/common.tables";
import { RouterChildContext } from "react-router";
import { TableMode } from "@ea/shared_components/redux/common.models";

import {
  areOnlyClosedSelectedSelector,
  getSelectedFilterClosedSelector,
  schedulerDataSelectors,
  getShowInternalSelector,
  areOnlyInternalSelectedSelector,
} from "@app/modules/scheduler/scheduler.selectors";
import {
  SCHEDULER_COLUMNS,
  SCHEDULER_COLUMNS_CONFIG,
  SCHEDULER_TABLES_CONFIG,
} from "@app/modules/scheduler/scheduler.table";
import { getSchedulerTableActions, schedulerActions } from "./scheduler.actions";

interface ISchedulerContainerProps {}

interface SchedulerTableContainerState {
  isCreatorOpen: boolean;
  mode: keyof typeof TableMode;
  persistentQuery: any;
  columns: any;
}

const Container = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
});

class SchedulerTableContainer extends React.Component<
  ISchedulerContainerProps & IConnectProps,
  SchedulerTableContainerState
> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };
  connectedTable;
  context: RouterChildContext<any>;

  constructor(props: ISchedulerContainerProps & IConnectProps) {
    super(props);

    const Columns = SCHEDULER_COLUMNS;

    const ModifiedColumns = replaceColumn(
      Columns,
      "status",
      SCHEDULER_COLUMNS_CONFIG.statusWithClosed,
    );

    this.state = {
      isCreatorOpen: false,
      mode: TableMode.FLAT,
      persistentQuery: undefined,
      columns: {
        Columns,
        ModifiedColumns,
      },
    };
  }

  componentWillMount() {
    this.setState({
      persistentQuery: this.props.persistentQuery || {
        status: { neq: JOB_STATUS_TYPE.CLOSED },
        isInternal: false,
      },
    });
  }

  openSelected = () => {
    if (this.props.selectedSchedulerJob) {
      this.openJob(this.props.selectedSchedulerJob);
    }
  };

  openJob = (item: SchedulerJob) => {
    this.context.router.history.push(`/scheduler/${item.id}`);
  };

  reload = () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  filterItemsToClose = (
    items: {
      [key: string]: any;
    },
    selected: number[],
  ) => {
    return selected.filter((id) => items[id].status !== JOB_STATUS_TYPE.CLOSED);
  };

  toggleShowClosed = () => {
    if (this.props.showClosed) {
      this.setState({
        persistentQuery: { ...this.state.persistentQuery, status: { neq: JOB_STATUS_TYPE.CLOSED } },
      });
    } else {
      const { status, ...rest } = this.state.persistentQuery;
      this.setState({
        persistentQuery: rest,
      });
    }
  };

  toggleShowInternal = () => {
    if (this.props.showInternal) {
      this.setState({
        persistentQuery: { ...this.state.persistentQuery, isInternal: false },
      });
    } else {
      const { isInternal, ...rest } = this.state.persistentQuery;
      this.setState({
        persistentQuery: rest,
      });
    }
  };

  onRemove = () => {
    const { items, selected } = this.props;

    const itemsToClose = this.filterItemsToClose(items, selected);

    this.props.actions.close(itemsToClose);
    this.reload();
  };

  closeCreatePanel = () => {
    this.setState({ isCreatorOpen: false });
    this.reload();
  };

  render() {
    const { columns, persistentQuery } = this.state;
    const { showClosed, areOnlyClosedSelected, showInternal, areOnlyInternalSelected } = this.props;

    return (
      <Container>
        <SchedulerJobCreatorContainer
          visibility={this.state.isCreatorOpen}
          close={this.closeCreatePanel}
          onCreate={this.reload}
        />
        <SchedulerCommandBar
          onNewClick={() => this.setState({ isCreatorOpen: true })}
          onRemoveClick={this.onRemove}
          selected={this.props.selected}
          onEditClick={() => {}}
          onReload={this.reload}
          onOpen={this.openSelected}
          showClosed={showClosed}
          onShowClosedClick={this.toggleShowClosed}
          onlyClosedSelected={areOnlyClosedSelected}
          showInternal={showInternal}
          onShowInternalClick={this.toggleShowInternal}
          onlyInternalSelected={areOnlyInternalSelected}
        />
        <ConnectedTable
          key={"scheduler_table"}
          setRef={(component) => (this.connectedTable = component)}
          pageable
          columnsConfig={showClosed ? columns.ModifiedColumns : columns.Columns}
          onRowDoubleClick={this.openJob}
          tableId={SCHEDULER_TABLES_CONFIG.MAIN.id()}
          preferencesId={SCHEDULER_TABLES_CONFIG.MAIN.preferencesId}
          stateKey={"scheduler"}
          tableActions={getSchedulerTableActions}
          persistentQuery={persistentQuery}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  ...state.scheduler,
  selected: getSelectedFilterClosedSelector(state, SCHEDULER_TABLES_CONFIG.MAIN.id()),
  selectedSchedulerJob: schedulerDataSelectors.getSelectedItemSelector(
    state,
    SCHEDULER_TABLES_CONFIG.MAIN.id(),
  ),
  persistentQuery: schedulerDataSelectors.getPersistentQuerySelector(
    state,
    SCHEDULER_TABLES_CONFIG.MAIN.id(),
  ),
  areOnlyClosedSelected: areOnlyClosedSelectedSelector(state, SCHEDULER_TABLES_CONFIG.MAIN.id()),
  areOnlyInternalSelected: areOnlyInternalSelectedSelector(
    state,
    SCHEDULER_TABLES_CONFIG.MAIN.id(),
  ),
  showClosed: schedulerDataSelectors.getClosedSelector(state, SCHEDULER_TABLES_CONFIG.MAIN.id()),
  showInternal: getShowInternalSelector(state, SCHEDULER_TABLES_CONFIG.MAIN.id()),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(schedulerActions, dispatch),
    ...bindActionCreators(getSchedulerTableActions(SCHEDULER_TABLES_CONFIG.MAIN.id()), dispatch),
  },
});

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default connectCreator(SchedulerTableContainer);
