import * as React from "react";
import {
  Step,
  Variable,
  VirtualUser,
  Script,
  LabelParams,
  System,
  GlobalVariable,
  CodeTemplateWithGroup,
} from "@ea/shared_types/types";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { Collapse } from "antd";
import { FormattedMessage } from "react-intl";
import { CaretRightOutlined } from "@ant-design/icons";
import { getStepCommand } from "@app/packs/packs.helpers";
import { getIn } from "final-form";
import { VariablesGroup } from "@ea/shared_components/redux/common.models";
import PathsTableForm from "../steps/components/Forms/Chunks/PathsTableForm";
interface IStepQuickEditFormProps {
  values: Step;
  variables: Variable[];
  variablesGroups: VariablesGroup[];
  script: Script;
  steps: Step[];
  change: any;
  virtualUsers?: VirtualUser[];
  systems?: System[];
  system?: System;
  labelParams?: LabelParams;
  globalMutables: GlobalVariable[];
  globalConstants: GlobalVariable[];
  openLinked: () => void;
  codeTemplates: CodeTemplateWithGroup[];
  readOnly?: boolean;
}
const { Panel: CollapsePanel } = Collapse;

const StepQuickEditForm = (props: IStepQuickEditFormProps) => {
  const { values } = props;

  const commandId = getIn(values, "commandId");
  const platform = getIn(values, "platform");
  const command = getStepCommand({ commandId, platform });

  const getCommandFormChunk = () => {
    if (!command) {
      return () => (
        <div>
          <FormattedMessage id={getTranslationKey("messages", "info", "commandNotInstalled")} />
        </div>
      );
    }
    if (command.disableQuickEdit) {
      return () => (
        <div>
          <FormattedMessage
            id={getTranslationKey("messages", "info", "commandQuickEditNotAvailable")}
          />
        </div>
      );
    }
    return command.getFormChunk(props);
  };

  const commandFormChunk = getCommandFormChunk();

  return (
    <div key={values.id}>
      <TextAreaField
        name="comment"
        label={getTranslationKey("step", "detailsForm", "label", "comment")}
        autoSize={{ minRows: 1, maxRows: 4 }}
      />
      {commandFormChunk()}
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        style={{ overflow: "hidden" }}
      >
        <CollapsePanel
          header={
            <FormattedMessage id={getTranslationKey("step", "detailsForm", "advancedFields")} />
          }
          key="debugFields"
        >
          <PathsTableForm />
        </CollapsePanel>
      </Collapse>
    </div>
  );
};

export default StepQuickEditForm;
