"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROJECT_ROOT = exports.SupportedLanguages = exports.VariableGroups = exports.Extension = exports.StepTerminationAction = exports.StepTerminationType = exports.ROLES = exports.STEP_MESSAGE_ICON_MAP = exports.MESSAGE_TYPE = exports.STATUS_STRENGTH = exports.JOB_STATUS_TYPE = exports.STATUS_TYPE = exports.TEST_RUN_OUTCOME_STATUS_MAP = exports.EXECUTION_STATUS = exports.TEST_RUN_STATE = exports.EXECUTION_STATE = exports.RECORDER_EXECUTION_STATE = exports.PLAY_MODE = exports.URL_SELECT_MODE = exports.SYSTEM_TYPE_LABELS = exports.STORAGE_TYPE_LABELS = exports.STORAGE_TYPE = exports.TTL_LABELS = exports.TTL = exports.SYSTEM_TYPE = exports.TOKEN_TYPE = exports.VIRTUAL_USER_AUTH_TYPE = exports.VIRTUAL_USER_STATUS = exports.SSO = exports.DOCUMENTATION_TYPE = exports.EntityType = exports.Platform = exports.ScriptStatus = exports.DocumentationStatus = exports.OBFUSCATE_PATTERN = exports.CodeType = exports.EXECUTION_SCRIPT_TYPES = exports.PlayerVisibility = exports.ScriptSpecialExecutionModes = exports.PlayScope = exports.RunMode = exports.RunnerVersion = exports.RunnerMode = exports.OverrideType = exports.BoolValue = exports.SwitchMode = exports.PathType = exports.AssignedVariableType = exports.DEFAULT_THROTTLING = exports.ImportMode = void 0;
exports.initialConditionActions = exports.ScheduledProjectState = exports.SubscribeUpdateModelNames = exports.WebsocketMessageTypes = exports.ReportExtension = exports.ApplicationStyles = exports.GlobalSettingsResolversModes = exports.PUBLIC_SETTINGS_KEYS = exports.INTERNAL_SETTINGS_KEYS = exports.GLOBAL_SETTINGS_KEYS = exports.FEATURES = exports.PredefinedVariable = exports.PredefinedVariablesContainerName = exports.GuardsReactions = exports.INTERRUPTION_TYPE = exports.CONDITIONAL_ACTION_TYPE = exports.CONDITIONAL_TYPE = exports.PLATFORM = exports.AllowedWorkItemTypes = exports.ExecuteEntityType = exports.IssueTrackingToolTrigger = exports.IssueTrackingToolConfigurationState = exports.WorkItemSeverity = exports.MqttMessages = exports.SPECIAL_KEY_CODES = exports.Commands = exports.CommandsVeeva = exports.CommandsBEFashion = exports.CommandsD365FS = exports.CommandsD365FO = exports.CommandsCPOS = exports.CommandsBasic = exports.DefaultLanguage = exports.DataSourceComparisonType = exports.AssertionType = exports.AssertActions = exports.ConditionTypes = exports.ComparisonsOperatorsSymbols = exports.ComparisonsOperators = exports.GlobalVariableType = exports.VariableType = exports.VariableDefaultValueType = exports.TimeScheluderLabel = exports.RecurrenceTypeSingular = exports.RecurrenceType = exports.EndType = exports.JOB_TRIGGER_TYPE_LABELS = exports.JOB_TRIGGER_TYPE = exports.SchedulerJobRunMode = exports.SchedulerMode = void 0;
exports.LogCsvAdditionalFields = exports.CodeTemplateSaveMode = exports.CodeParamType = exports.ScriptsCsvAdditionalFields = exports.SaveVideoCondition = exports.ScreenshotOptions = exports.ErrorCodes = exports.LicenseErrors = exports.PayPerUseStatisticsPrecision = exports.LicenseType = exports.ExportToCsvModels = exports.BroadcastMessages = exports.FileConverterOutputExtension = exports.RUNNER_FRAME_ID = exports.SchedulerRunTypes = exports.RUNNER_POSITION = exports.RUNNER_OPEN_MODE = exports.RUNNER_TYPE = exports.TEMPLATE_TYPE = exports.CHECK_REFERENCE_TYPE = exports.DEFAULT_LOCALE = exports.createInternalStep = exports.createInternalSteps = exports.INITIAL_STRUCTURE_VERSION = exports.initialCheckValueCondition = exports.initialDataSourceCondition = void 0;
var ImportMode;
(function (ImportMode) {
    ImportMode["CLONE"] = "CLONE";
    ImportMode["EXPORT"] = "EXPORT";
})(ImportMode = exports.ImportMode || (exports.ImportMode = {}));
exports.DEFAULT_THROTTLING = {
    beforeExecution: 500,
    afterExecution: 500,
};
var AssignedVariableType;
(function (AssignedVariableType) {
    AssignedVariableType["LOCAL"] = "local";
    AssignedVariableType["GLOBAL"] = "global";
})(AssignedVariableType = exports.AssignedVariableType || (exports.AssignedVariableType = {}));
var PathType;
(function (PathType) {
    PathType["XPATH"] = "XPATH";
    PathType["SELECTOR"] = "SELECTOR";
})(PathType = exports.PathType || (exports.PathType = {}));
var SwitchMode;
(function (SwitchMode) {
    SwitchMode["OFF"] = "Off";
    SwitchMode["ON"] = "On";
})(SwitchMode = exports.SwitchMode || (exports.SwitchMode = {}));
var BoolValue;
(function (BoolValue) {
    BoolValue["TRUE"] = "true";
    BoolValue["FALSE"] = "false";
    BoolValue["NOT_DEFINED"] = "not_defined";
})(BoolValue = exports.BoolValue || (exports.BoolValue = {}));
var OverrideType;
(function (OverrideType) {
    OverrideType["VIRTUAL_USER"] = "virtual_user";
    OverrideType["SYSTEM"] = "system";
})(OverrideType = exports.OverrideType || (exports.OverrideType = {}));
var RunnerMode;
(function (RunnerMode) {
    RunnerMode["RECORDER"] = "RECORDER";
    RunnerMode["PLAYER"] = "PLAYER";
    RunnerMode["COMBINED"] = "COMBINED";
})(RunnerMode = exports.RunnerMode || (exports.RunnerMode = {}));
var RunnerVersion;
(function (RunnerVersion) {
    RunnerVersion["BASIC"] = "Basic";
    RunnerVersion["ADVANCED"] = "Advanced";
})(RunnerVersion = exports.RunnerVersion || (exports.RunnerVersion = {}));
var RunMode;
(function (RunMode) {
    RunMode["SILENT"] = "SILENT";
    RunMode["FULL"] = "FULL";
})(RunMode = exports.RunMode || (exports.RunMode = {}));
var PlayScope;
(function (PlayScope) {
    PlayScope["SCRIPT"] = "Script";
})(PlayScope = exports.PlayScope || (exports.PlayScope = {}));
var ScriptSpecialExecutionModes;
(function (ScriptSpecialExecutionModes) {
    ScriptSpecialExecutionModes["DOCUMENT"] = "DOCUMENT";
    ScriptSpecialExecutionModes["VIDEO"] = "VIDEO";
    ScriptSpecialExecutionModes["DOC_AND_VIDEO"] = "DOC_AND_VIDEO";
})(ScriptSpecialExecutionModes = exports.ScriptSpecialExecutionModes || (exports.ScriptSpecialExecutionModes = {}));
var PlayerVisibility;
(function (PlayerVisibility) {
    PlayerVisibility["VISIBLE"] = "VISIBLE";
    PlayerVisibility["HIDDEN"] = "HIDDEN";
    PlayerVisibility["ONLY_CURRENT_STEP"] = "ONLY_CURRENT_STEP";
})(PlayerVisibility = exports.PlayerVisibility || (exports.PlayerVisibility = {}));
var EXECUTION_SCRIPT_TYPES;
(function (EXECUTION_SCRIPT_TYPES) {
    EXECUTION_SCRIPT_TYPES["VIRTUAL_USER_AUTH"] = "VIRTUAL_USER_AUTH";
})(EXECUTION_SCRIPT_TYPES = exports.EXECUTION_SCRIPT_TYPES || (exports.EXECUTION_SCRIPT_TYPES = {}));
var CodeType;
(function (CodeType) {
    CodeType["CONSTANT"] = "CONSTANT";
    CodeType["PICK"] = "PICK";
    CodeType["EXPRESSION"] = "EXPRESSION";
    CodeType["ADVANCED"] = "ADVANCED";
    CodeType["CODE_TEMPLATE"] = "CODE_TEMPLATE";
})(CodeType = exports.CodeType || (exports.CodeType = {}));
exports.OBFUSCATE_PATTERN = "********";
var DocumentationStatus;
(function (DocumentationStatus) {
    DocumentationStatus["PUBLISHED"] = "PUBLISHED";
    DocumentationStatus["DRAFT"] = "DRAFT";
    DocumentationStatus["PENDING"] = "PENDING";
})(DocumentationStatus = exports.DocumentationStatus || (exports.DocumentationStatus = {}));
var ScriptStatus;
(function (ScriptStatus) {
    ScriptStatus["PUBLISHED"] = "PUBLISHED";
    ScriptStatus["DRAFT"] = "DRAFT";
    ScriptStatus["CLOSED"] = "CLOSED";
    ScriptStatus["IN_PROGRESS"] = "IN_PROGRESS";
    ScriptStatus["READY_TO_TEST"] = "READY_TO_TEST";
    ScriptStatus["READY_FOR_REVIEW"] = "READY_FOR_REVIEW";
})(ScriptStatus = exports.ScriptStatus || (exports.ScriptStatus = {}));
var Platform;
(function (Platform) {
    Platform["D365FO"] = "D365FO";
    Platform["D365FS"] = "D365FS";
    Platform["TRANSPOREON"] = "TRANSPOREON";
    Platform["GOOGLE"] = "GOOGLE";
    Platform["STANDARD"] = "STANDARD";
    Platform["VEEVA"] = "VEEVA";
    Platform["MICROSOFT_LOGIN"] = "MICROSOFT_LOGIN";
    Platform["MICROSOFT_LOGIN_OKTA"] = "MICROSOFT_LOGIN_OKTA";
    Platform["OUTLOOK"] = "OUTLOOK";
    Platform["SAPUI"] = "SAPUI";
    Platform["OTHER"] = "OTHER";
    Platform["EP"] = "EP";
    Platform["DEFAULT_PLATFORM"] = "DEFAULT_PLATFORM";
    Platform["CLOUD_POS"] = "CLOUD_POS";
})(Platform = exports.Platform || (exports.Platform = {}));
var EntityType;
(function (EntityType) {
    EntityType["SCRIPT"] = "TaskScript";
})(EntityType = exports.EntityType || (exports.EntityType = {}));
var DOCUMENTATION_TYPE;
(function (DOCUMENTATION_TYPE) {
    DOCUMENTATION_TYPE["VIDEO"] = "VIDEO";
    DOCUMENTATION_TYPE["DOCUMENT"] = "DOCUMENT";
})(DOCUMENTATION_TYPE = exports.DOCUMENTATION_TYPE || (exports.DOCUMENTATION_TYPE = {}));
var SSO;
(function (SSO) {
    SSO["AzureActiveDirectory"] = "AzureActiveDirectory";
})(SSO = exports.SSO || (exports.SSO = {}));
var VIRTUAL_USER_STATUS;
(function (VIRTUAL_USER_STATUS) {
    VIRTUAL_USER_STATUS["NOT_AUTHENTICATED"] = "NOT_AUTHENTICATED";
    VIRTUAL_USER_STATUS["AUTHENTICATED"] = "AUTHENTICATED";
    VIRTUAL_USER_STATUS["AUTHENTICATING"] = "AUTHENTICATING";
    VIRTUAL_USER_STATUS["ERROR"] = "ERROR";
})(VIRTUAL_USER_STATUS = exports.VIRTUAL_USER_STATUS || (exports.VIRTUAL_USER_STATUS = {}));
var VIRTUAL_USER_AUTH_TYPE;
(function (VIRTUAL_USER_AUTH_TYPE) {
    VIRTUAL_USER_AUTH_TYPE["NONE"] = "NONE";
    VIRTUAL_USER_AUTH_TYPE["BASIC_AUTH"] = "BASIC_AUTH";
    VIRTUAL_USER_AUTH_TYPE["NTLM"] = "NTLM";
    VIRTUAL_USER_AUTH_TYPE["SYSTEM"] = "SYSTEM";
})(VIRTUAL_USER_AUTH_TYPE = exports.VIRTUAL_USER_AUTH_TYPE || (exports.VIRTUAL_USER_AUTH_TYPE = {}));
var TOKEN_TYPE;
(function (TOKEN_TYPE) {
    TOKEN_TYPE["COOKIES"] = "COOKIES";
    TOKEN_TYPE["STORAGE"] = "STORAGE";
})(TOKEN_TYPE = exports.TOKEN_TYPE || (exports.TOKEN_TYPE = {}));
var SYSTEM_TYPE;
(function (SYSTEM_TYPE) {
    SYSTEM_TYPE["PROD"] = "production";
    SYSTEM_TYPE["DEV"] = "development";
    SYSTEM_TYPE["TEST"] = "test";
    SYSTEM_TYPE["UAT"] = "uat";
    SYSTEM_TYPE["OTHER"] = "other";
})(SYSTEM_TYPE = exports.SYSTEM_TYPE || (exports.SYSTEM_TYPE = {}));
var TTL;
(function (TTL) {
    TTL[TTL["T90"] = 7776000] = "T90";
    TTL[TTL["T180"] = 15552000] = "T180";
    TTL[TTL["T365"] = 31536000] = "T365";
})(TTL = exports.TTL || (exports.TTL = {}));
exports.TTL_LABELS = (_a = {},
    _a[TTL.T90] = "90 Days",
    _a[TTL.T180] = "180 Days",
    _a[TTL.T365] = "1 Year",
    _a);
var STORAGE_TYPE;
(function (STORAGE_TYPE) {
    STORAGE_TYPE["AZURE"] = "azureStorage";
})(STORAGE_TYPE = exports.STORAGE_TYPE || (exports.STORAGE_TYPE = {}));
exports.STORAGE_TYPE_LABELS = (_b = {},
    _b[STORAGE_TYPE.AZURE] = "Azure Storage",
    _b);
exports.SYSTEM_TYPE_LABELS = (_c = {},
    _c[SYSTEM_TYPE.PROD] = "Production",
    _c[SYSTEM_TYPE.DEV] = "Development",
    _c[SYSTEM_TYPE.TEST] = "Test",
    _c[SYSTEM_TYPE.UAT] = "Uat",
    _c[SYSTEM_TYPE.OTHER] = "Other",
    _c);
var URL_SELECT_MODE;
(function (URL_SELECT_MODE) {
    URL_SELECT_MODE["MANUAL"] = "manual";
    URL_SELECT_MODE["ENVIRONMENT"] = "environment";
})(URL_SELECT_MODE = exports.URL_SELECT_MODE || (exports.URL_SELECT_MODE = {}));
var PLAY_MODE;
(function (PLAY_MODE) {
    PLAY_MODE["FOREGROUND"] = "foreground";
    PLAY_MODE["BACKGROUND"] = "background";
})(PLAY_MODE = exports.PLAY_MODE || (exports.PLAY_MODE = {}));
var RECORDER_EXECUTION_STATE;
(function (RECORDER_EXECUTION_STATE) {
    RECORDER_EXECUTION_STATE["NONE"] = "NONE";
    RECORDER_EXECUTION_STATE["RECORDING"] = "RECORDING";
    RECORDER_EXECUTION_STATE["PAUSED"] = "PAUSED";
    RECORDER_EXECUTION_STATE["FINISHED"] = "FINISHED";
})(RECORDER_EXECUTION_STATE = exports.RECORDER_EXECUTION_STATE || (exports.RECORDER_EXECUTION_STATE = {}));
var EXECUTION_STATE;
(function (EXECUTION_STATE) {
    EXECUTION_STATE["NONE"] = "NONE";
    EXECUTION_STATE["RUNNING"] = "RUNNING";
    EXECUTION_STATE["PAUSED"] = "PAUSED";
    EXECUTION_STATE["TERMINATED"] = "TERMINATED";
    EXECUTION_STATE["FINISHED"] = "FINISHED";
})(EXECUTION_STATE = exports.EXECUTION_STATE || (exports.EXECUTION_STATE = {}));
var TEST_RUN_STATE;
(function (TEST_RUN_STATE) {
    TEST_RUN_STATE["UNSPECIFIED"] = "Unspecified";
    TEST_RUN_STATE["NOT_STARTED"] = "NotStarted";
    TEST_RUN_STATE["IN_PROGRESS"] = "InProgress";
    TEST_RUN_STATE["COMPLETED"] = "Completed";
    TEST_RUN_STATE["WAITING"] = "Waiting";
    TEST_RUN_STATE["ABORTED"] = "Aborted";
    TEST_RUN_STATE["QUEUED"] = "Queued";
})(TEST_RUN_STATE = exports.TEST_RUN_STATE || (exports.TEST_RUN_STATE = {}));
var EXECUTION_STATUS;
(function (EXECUTION_STATUS) {
    EXECUTION_STATUS["ERROR"] = "ERROR";
    EXECUTION_STATUS["WARNING"] = "WARNING";
    EXECUTION_STATUS["SUCCESS"] = "SUCCESS";
    EXECUTION_STATUS["NONE"] = "NONE";
    EXECUTION_STATUS["UNTESTED"] = "UNTESTED";
})(EXECUTION_STATUS = exports.EXECUTION_STATUS || (exports.EXECUTION_STATUS = {}));
exports.TEST_RUN_OUTCOME_STATUS_MAP = (_d = {},
    _d[EXECUTION_STATUS.ERROR] = "Failed",
    _d[EXECUTION_STATUS.SUCCESS] = "Passed",
    _d[EXECUTION_STATUS.WARNING] = "Warning",
    _d);
var STATUS_TYPE;
(function (STATUS_TYPE) {
    STATUS_TYPE["ERROR"] = "ERROR";
    STATUS_TYPE["WARNING"] = "WARNING";
    STATUS_TYPE["SUCCESS"] = "SUCCESS";
    STATUS_TYPE["RUNNING"] = "RUNNING";
    STATUS_TYPE["TERMINATED"] = "TERMINATED";
    STATUS_TYPE["NONE"] = "NONE";
    STATUS_TYPE["UNTESTED"] = "UNTESTED";
    // TODO: \/ to remove, mark execution as background
    STATUS_TYPE["RUNNING_IN_BACKGROUND"] = "RUNNING IN BACKGROUND";
})(STATUS_TYPE = exports.STATUS_TYPE || (exports.STATUS_TYPE = {}));
var JOB_STATUS_TYPE;
(function (JOB_STATUS_TYPE) {
    JOB_STATUS_TYPE["ACTIVE"] = "ACTIVE";
    JOB_STATUS_TYPE["INACTIVE"] = "INACTIVE";
    JOB_STATUS_TYPE["COMPLETED"] = "COMPLETED";
    JOB_STATUS_TYPE["CLOSED"] = "CLOSED";
    JOB_STATUS_TYPE["RUNNING"] = "RUNNING";
})(JOB_STATUS_TYPE = exports.JOB_STATUS_TYPE || (exports.JOB_STATUS_TYPE = {}));
exports.STATUS_STRENGTH = (_e = {},
    _e[STATUS_TYPE.SUCCESS] = 0,
    _e[STATUS_TYPE.WARNING] = 1,
    _e[STATUS_TYPE.ERROR] = 2,
    _e);
var MESSAGE_TYPE;
(function (MESSAGE_TYPE) {
    MESSAGE_TYPE["ERROR"] = "ERROR";
    MESSAGE_TYPE["WARNING"] = "WARNING";
    MESSAGE_TYPE["INFO"] = "INFO";
    MESSAGE_TYPE["ANALYSIS"] = "ANALYSIS";
})(MESSAGE_TYPE = exports.MESSAGE_TYPE || (exports.MESSAGE_TYPE = {}));
exports.STEP_MESSAGE_ICON_MAP = (_f = {},
    _f[MESSAGE_TYPE.WARNING] = "warning",
    _f[MESSAGE_TYPE.ERROR] = "exclamation-circle-o",
    _f[MESSAGE_TYPE.INFO] = "info-circle-o",
    _f);
var LabelParamsKeys;
(function (LabelParamsKeys) {
    LabelParamsKeys["value"] = "value";
    LabelParamsKeys["type"] = "type";
    LabelParamsKeys["controlName"] = "controlName";
    LabelParamsKeys["counter"] = "counter";
    LabelParamsKeys["limit"] = "limit";
    LabelParamsKeys["timeout"] = "timeout";
    LabelParamsKeys["status"] = "status";
    LabelParamsKeys["result"] = "result";
    LabelParamsKeys["stepLabel"] = "stepLabel";
    LabelParamsKeys["paths"] = "paths";
    LabelParamsKeys["element"] = "element";
    LabelParamsKeys["lookup"] = "lookup";
    LabelParamsKeys["containerName"] = "containerName";
    LabelParamsKeys["scriptName"] = "scriptName";
    LabelParamsKeys["leftConditionValue"] = "leftConditionValue";
    LabelParamsKeys["operator"] = "operator";
    LabelParamsKeys["menuPathLabel"] = "menuPathLabel";
    LabelParamsKeys["menuItemName"] = "menuItemName";
    LabelParamsKeys["message"] = "message";
    LabelParamsKeys["segmentName"] = "segmentName";
    LabelParamsKeys["rightConditionValue"] = "rightConditionValue";
    LabelParamsKeys["logicalOperator"] = "logicalOperator";
    LabelParamsKeys["rightCondition"] = "rightCondition";
    LabelParamsKeys["leftCondition"] = "leftCondition";
    LabelParamsKeys["comparisonResult"] = "comparisonResult";
    LabelParamsKeys["stepLabelKey"] = "stepLabelKey";
    LabelParamsKeys["kpiNames"] = "kpiNames";
    LabelParamsKeys["virtualUserName"] = "virtualUserName";
    LabelParamsKeys["systemName"] = "systemName";
})(LabelParamsKeys || (LabelParamsKeys = {}));
var ROLES;
(function (ROLES) {
    ROLES["scheduler"] = "scheduler";
    ROLES["reports"] = "reports";
    ROLES["logs"] = "logs";
    ROLES["settings"] = "settings";
    ROLES["freedocs"] = "freedocs";
    ROLES["admin"] = "admin";
    ROLES["projects"] = "projects";
    ROLES["documentationCreator"] = "documentation_creator";
    ROLES["documentationReader"] = "documentation_reader";
})(ROLES = exports.ROLES || (exports.ROLES = {}));
var StepTerminationType;
(function (StepTerminationType) {
    StepTerminationType["TIMEOUT"] = "TIMEOUT";
    StepTerminationType["NUMBER_OF_EXECUTIONS"] = "NUMBER_OF_EXECUTIONS";
})(StepTerminationType = exports.StepTerminationType || (exports.StepTerminationType = {}));
var StepTerminationAction;
(function (StepTerminationAction) {
    StepTerminationAction["IGNORE_STEP"] = "IGNORE_STEP";
    StepTerminationAction["TERMINATE_EXECUTION"] = "TERMINATE_EXECUTION";
})(StepTerminationAction = exports.StepTerminationAction || (exports.StepTerminationAction = {}));
var Extension;
(function (Extension) {
    Extension["XLSX"] = ".xlsx";
})(Extension = exports.Extension || (exports.Extension = {}));
var VariableGroups;
(function (VariableGroups) {
    VariableGroups["SCRIPT"] = "Script";
    VariableGroups["SCRIPTS"] = "Scripts";
    VariableGroups["DATA_SOURCE"] = "DataSource";
    VariableGroups["SEQUENCE"] = "Sequence";
    VariableGroups["GLOBAL"] = "Global";
})(VariableGroups = exports.VariableGroups || (exports.VariableGroups = {}));
var SupportedLanguages;
(function (SupportedLanguages) {
    SupportedLanguages["PL"] = "PL";
    SupportedLanguages["EN"] = "EN";
})(SupportedLanguages = exports.SupportedLanguages || (exports.SupportedLanguages = {}));
exports.PROJECT_ROOT = "root";
var SchedulerMode;
(function (SchedulerMode) {
    SchedulerMode["BASIC"] = "BASIC";
    SchedulerMode["GANTT"] = "GANTT";
})(SchedulerMode = exports.SchedulerMode || (exports.SchedulerMode = {}));
var SchedulerJobRunMode;
(function (SchedulerJobRunMode) {
    SchedulerJobRunMode["PARALLEL"] = "PARALLEL";
    SchedulerJobRunMode["SEQUENTIAL"] = "SEQUENTIAL";
})(SchedulerJobRunMode = exports.SchedulerJobRunMode || (exports.SchedulerJobRunMode = {}));
var JOB_TRIGGER_TYPE;
(function (JOB_TRIGGER_TYPE) {
    JOB_TRIGGER_TYPE["TIME"] = "time";
    JOB_TRIGGER_TYPE["STORAGE"] = "storage";
})(JOB_TRIGGER_TYPE = exports.JOB_TRIGGER_TYPE || (exports.JOB_TRIGGER_TYPE = {}));
exports.JOB_TRIGGER_TYPE_LABELS = (_g = {},
    _g[JOB_TRIGGER_TYPE.TIME] = "Time",
    _g[JOB_TRIGGER_TYPE.STORAGE] = "Storage",
    _g);
var EndType;
(function (EndType) {
    EndType["afterOccurrences"] = "afterOccurrences";
    EndType["onDate"] = "onDate";
    EndType["never"] = "never";
})(EndType = exports.EndType || (exports.EndType = {}));
var RecurrenceType;
(function (RecurrenceType) {
    RecurrenceType["minutes"] = "minutes";
    RecurrenceType["hours"] = "hours";
    RecurrenceType["days"] = "days";
    RecurrenceType["weeks"] = "weeks";
    RecurrenceType["months"] = "months";
    RecurrenceType["years"] = "years";
})(RecurrenceType = exports.RecurrenceType || (exports.RecurrenceType = {}));
var RecurrenceTypeSingular;
(function (RecurrenceTypeSingular) {
    RecurrenceTypeSingular["minutes"] = "minute";
    RecurrenceTypeSingular["hours"] = "hour";
    RecurrenceTypeSingular["days"] = "day";
    RecurrenceTypeSingular["weeks"] = "week";
    RecurrenceTypeSingular["months"] = "month";
    RecurrenceTypeSingular["years"] = "year";
})(RecurrenceTypeSingular = exports.RecurrenceTypeSingular || (exports.RecurrenceTypeSingular = {}));
var TimeScheluderLabel;
(function (TimeScheluderLabel) {
    TimeScheluderLabel["once"] = "once";
    TimeScheluderLabel["times"] = "times";
})(TimeScheluderLabel = exports.TimeScheluderLabel || (exports.TimeScheluderLabel = {}));
var VariableDefaultValueType;
(function (VariableDefaultValueType) {
    VariableDefaultValueType["Text"] = "Text";
    VariableDefaultValueType["Number"] = "Number";
    VariableDefaultValueType["Date"] = "Date";
})(VariableDefaultValueType = exports.VariableDefaultValueType || (exports.VariableDefaultValueType = {}));
var VariableType;
(function (VariableType) {
    VariableType["Normal"] = "Normal";
    VariableType["DataSource"] = "DataSource";
    VariableType["Sequence"] = "Sequence";
})(VariableType = exports.VariableType || (exports.VariableType = {}));
var GlobalVariableType;
(function (GlobalVariableType) {
    GlobalVariableType["CONSTANT"] = "Constant";
    GlobalVariableType["MUTABLE"] = "Mutable";
})(GlobalVariableType = exports.GlobalVariableType || (exports.GlobalVariableType = {}));
var ComparisonsOperators;
(function (ComparisonsOperators) {
    ComparisonsOperators["EQUAL"] = "EQUAL";
    ComparisonsOperators["NOT_EQUAL"] = "NOT_EQUAL";
    ComparisonsOperators["LESS"] = "LESS";
    ComparisonsOperators["LESS_OR_EQUAL"] = "LESS_OR_EQUAL";
    ComparisonsOperators["GREATER"] = "GREATER";
    ComparisonsOperators["GREATER_OR_EQUAL"] = "GREATER_OR_EQUAL";
})(ComparisonsOperators = exports.ComparisonsOperators || (exports.ComparisonsOperators = {}));
var ComparisonsOperatorsSymbols;
(function (ComparisonsOperatorsSymbols) {
    ComparisonsOperatorsSymbols["EQUAL"] = "=";
    ComparisonsOperatorsSymbols["NOT_EQUAL"] = "\u2260";
    ComparisonsOperatorsSymbols["LESS"] = "<";
    ComparisonsOperatorsSymbols["LESS_OR_EQUAL"] = "<=";
    ComparisonsOperatorsSymbols["GREATER"] = ">";
    ComparisonsOperatorsSymbols["GREATER_OR_EQUAL"] = ">=";
})(ComparisonsOperatorsSymbols = exports.ComparisonsOperatorsSymbols || (exports.ComparisonsOperatorsSymbols = {}));
var ConditionTypes;
(function (ConditionTypes) {
    ConditionTypes["VARIABLE"] = "VARIABLE";
    ConditionTypes["STEP_VALUE"] = "STEP_VALUE";
    ConditionTypes["CONST"] = "CONST";
    ConditionTypes["GLOBAL_CONSTANT"] = "GLOBAL_CONSTANT";
    ConditionTypes["GLOBAL_MUTABLE"] = "GLOBAL_MUTABLE";
})(ConditionTypes = exports.ConditionTypes || (exports.ConditionTypes = {}));
var AssertActions;
(function (AssertActions) {
    AssertActions["JUMP"] = "JUMP";
    AssertActions["LOG"] = "LOG";
    AssertActions["WARNING"] = "WARNING";
    AssertActions["CONTINUE"] = "CONTINUE";
    AssertActions["ERROR"] = "ERROR";
    AssertActions["DATA_SOURCE_AND_JUMP"] = "DATA_SOURCE_AND_JUMP";
    AssertActions["REPEAT"] = "REPEAT";
    AssertActions["INCREASE_KPI"] = "INCREASE_KPI";
})(AssertActions = exports.AssertActions || (exports.AssertActions = {}));
var AssertionType;
(function (AssertionType) {
    AssertionType["CHECK_VALUE"] = "CHECK_VALUE";
    AssertionType["DATA_SOURCE"] = "DATA_SOURCE";
})(AssertionType = exports.AssertionType || (exports.AssertionType = {}));
var DataSourceComparisonType;
(function (DataSourceComparisonType) {
    DataSourceComparisonType["HAS_NEXT"] = "HAS_NEXT";
})(DataSourceComparisonType = exports.DataSourceComparisonType || (exports.DataSourceComparisonType = {}));
// todo: take from browser?
exports.DefaultLanguage = SupportedLanguages.EN;
var CommandsBasic;
(function (CommandsBasic) {
    CommandsBasic["start"] = "start";
    CommandsBasic["end"] = "end";
    CommandsBasic["login"] = "login";
    CommandsBasic["inspect"] = "inspect";
})(CommandsBasic = exports.CommandsBasic || (exports.CommandsBasic = {}));
var CommandsCPOS;
(function (CommandsCPOS) {
})(CommandsCPOS = exports.CommandsCPOS || (exports.CommandsCPOS = {}));
var CommandsD365FO;
(function (CommandsD365FO) {
})(CommandsD365FO = exports.CommandsD365FO || (exports.CommandsD365FO = {}));
var CommandsD365FS;
(function (CommandsD365FS) {
    CommandsD365FS["fastTabClick"] = "fastTabClick";
    CommandsD365FS["gridSelectRow"] = "gridSelectRow";
    CommandsD365FS["gridSelectRow2"] = "gridSelectRow2";
    CommandsD365FS["selectSegmentEntry"] = "selectSegmentEntry";
    CommandsD365FS["lookup"] = "lookup";
})(CommandsD365FS = exports.CommandsD365FS || (exports.CommandsD365FS = {}));
var CommandsBEFashion;
(function (CommandsBEFashion) {
    CommandsBEFashion["beFashionExcel"] = "beFashionExcel";
    CommandsBEFashion["beFashionExcelEditStart"] = "beFashionExcelEditStart";
    CommandsBEFashion["beFashionExcelEditEnd"] = "beFashionExcelEditEnd";
})(CommandsBEFashion = exports.CommandsBEFashion || (exports.CommandsBEFashion = {}));
var CommandsVeeva;
(function (CommandsVeeva) {
})(CommandsVeeva = exports.CommandsVeeva || (exports.CommandsVeeva = {}));
var Commands;
(function (Commands) {
    Commands["click"] = "click";
    Commands["focus"] = "focus";
    Commands["blur"] = "blur";
    Commands["menuItem"] = "menuItem";
    Commands["mousedown"] = "mousedown";
    Commands["keyup"] = "keyup";
    Commands["executeCode"] = "executeCode";
    Commands["password"] = "password";
    Commands["hotkey"] = "hotkey";
    Commands["assignValue"] = "assignValue";
    Commands["script"] = "script";
    Commands["change"] = "change";
    Commands["assert"] = "assert";
    Commands["check"] = "check";
    Commands["upload"] = "upload";
    Commands["start"] = "start";
    Commands["end"] = "end";
    Commands["login"] = "login";
    Commands["inspect"] = "inspect";
    Commands["veevaLookup"] = "veevaLookup";
    Commands["cposAuth"] = "cposAuth";
    Commands["CPOSCardHoverClick"] = "CPOSCardHoverClick";
    Commands["CPOSToggleClick"] = "CPOSToggleClick";
    Commands["CPOSDisassemblyKit"] = "CPOSDisassemblyKit";
    Commands["d365FSAdditionsSelectValue"] = "d365FSAdditionsSelectValue";
    Commands["d365FSMenu"] = "d365FSMenu";
    Commands["d365FSLookup"] = "d365FSLookup";
    Commands["dialogBox"] = "dialogBox";
    Commands["contextmenu"] = "contextmenu";
    Commands["lookup"] = "lookup";
    Commands["gridSelectRow"] = "gridSelectRow";
    Commands["gridSelectRow2"] = "gridSelectRow2";
    Commands["selectSegmentEntry"] = "selectSegmentEntry";
    Commands["fastTabClick"] = "fastTabClick";
    Commands["beFashionExcel"] = "beFashionExcel";
    Commands["beFashionExcelEditStart"] = "beFashionExcelEditStart";
    Commands["beFashionExcelEditEnd"] = "beFashionExcelEditEnd";
    Commands["slider"] = "slider";
})(Commands = exports.Commands || (exports.Commands = {}));
exports.SPECIAL_KEY_CODES = {
    ENTER: 13,
    SPACE: 32,
    G: 71,
    D: 68,
    H: 72,
    L: 76,
    Q: 81,
    M: 77,
    N: 78,
    S: 83,
    DEL: 68,
    F9: 120,
    F5: 116,
    F2: 113,
    E: 69,
    F: 70,
    A: 65,
    ARROW_DOWN: 40,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_LEFT: 37,
    TAB: 9,
    HOME: 36,
    END: 35,
    ESC: 27,
};
var MqttMessages;
(function (MqttMessages) {
    MqttMessages["Dispatched"] = "dispatched";
    MqttMessages["DispatchError"] = "dispatchError";
    MqttMessages["Kill"] = "kill";
    MqttMessages["Close"] = "close";
    MqttMessages["Screenshot"] = "screenshot";
})(MqttMessages = exports.MqttMessages || (exports.MqttMessages = {}));
var WorkItemSeverity;
(function (WorkItemSeverity) {
    WorkItemSeverity["critical"] = "1 - Critical";
    WorkItemSeverity["high"] = "2 - High";
    WorkItemSeverity["medium"] = "3 - Medium";
    WorkItemSeverity["low"] = "4 - Low";
})(WorkItemSeverity = exports.WorkItemSeverity || (exports.WorkItemSeverity = {}));
// values are written in this way to provide compatibility with azure devops api
var IssueTrackingToolConfigurationState;
(function (IssueTrackingToolConfigurationState) {
    IssueTrackingToolConfigurationState["inactive"] = "inactive";
    IssueTrackingToolConfigurationState["active"] = "active";
    IssueTrackingToolConfigurationState["removed"] = "removed";
})(IssueTrackingToolConfigurationState = exports.IssueTrackingToolConfigurationState || (exports.IssueTrackingToolConfigurationState = {}));
var IssueTrackingToolTrigger;
(function (IssueTrackingToolTrigger) {
    IssueTrackingToolTrigger["ERROR"] = "ERROR";
    IssueTrackingToolTrigger["WARNING"] = "WARNING";
    IssueTrackingToolTrigger["INFO"] = "INFO";
    IssueTrackingToolTrigger["PLATFORM_ERROR"] = "PLATFORM ERROR";
    IssueTrackingToolTrigger["PLATFORM_WARNING"] = "PLATFORM WARNING";
})(IssueTrackingToolTrigger = exports.IssueTrackingToolTrigger || (exports.IssueTrackingToolTrigger = {}));
var ExecuteEntityType;
(function (ExecuteEntityType) {
    ExecuteEntityType["SCRIPT"] = "script";
    ExecuteEntityType["JOB"] = "job";
    ExecuteEntityType["PROJECT"] = "project";
})(ExecuteEntityType = exports.ExecuteEntityType || (exports.ExecuteEntityType = {}));
var AllowedWorkItemTypes;
(function (AllowedWorkItemTypes) {
    AllowedWorkItemTypes["Bug"] = "Bug";
    AllowedWorkItemTypes["Issue"] = "Issue";
    AllowedWorkItemTypes["Task"] = "Task";
    AllowedWorkItemTypes["TestCase"] = "Test Case";
})(AllowedWorkItemTypes = exports.AllowedWorkItemTypes || (exports.AllowedWorkItemTypes = {}));
var PLATFORM;
(function (PLATFORM) {
    PLATFORM["CPOS"] = "CPOS";
    PLATFORM["D365FO"] = "D365FO";
    PLATFORM["OTHER"] = "OTHER";
})(PLATFORM = exports.PLATFORM || (exports.PLATFORM = {}));
var CONDITIONAL_TYPE;
(function (CONDITIONAL_TYPE) {
    CONDITIONAL_TYPE["SUCCESS"] = "SUCCESS";
    CONDITIONAL_TYPE["INTERRUPTION"] = "INTERRUPTION";
})(CONDITIONAL_TYPE = exports.CONDITIONAL_TYPE || (exports.CONDITIONAL_TYPE = {}));
var CONDITIONAL_ACTION_TYPE;
(function (CONDITIONAL_ACTION_TYPE) {
    CONDITIONAL_ACTION_TYPE["SIMPLE"] = "SIMPLE";
    CONDITIONAL_ACTION_TYPE["ADVANCED"] = "ADVANCED";
})(CONDITIONAL_ACTION_TYPE = exports.CONDITIONAL_ACTION_TYPE || (exports.CONDITIONAL_ACTION_TYPE = {}));
var INTERRUPTION_TYPE;
(function (INTERRUPTION_TYPE) {
    INTERRUPTION_TYPE["SYSTEM_ERROR"] = "SYSTEM_ERROR";
    INTERRUPTION_TYPE["SYSTEM_WARNING"] = "SYSTEM_WARNING";
    INTERRUPTION_TYPE["ELEMENT_NOT_FOUND"] = "ELEMENT_NOT_FOUND";
    INTERRUPTION_TYPE["ERROR"] = "ERROR";
    INTERRUPTION_TYPE["WARNING"] = "WARNING";
})(INTERRUPTION_TYPE = exports.INTERRUPTION_TYPE || (exports.INTERRUPTION_TYPE = {}));
exports.GuardsReactions = (_h = {},
    _h[INTERRUPTION_TYPE.ERROR] = [
        INTERRUPTION_TYPE.ERROR,
        INTERRUPTION_TYPE.SYSTEM_ERROR,
        INTERRUPTION_TYPE.ELEMENT_NOT_FOUND,
    ],
    _h[INTERRUPTION_TYPE.SYSTEM_ERROR] = [INTERRUPTION_TYPE.SYSTEM_ERROR],
    // TODO: R2.0 I'm not adding support for elemenot_not_found. I created task in notion to refactor
    // possible reactions / interruptions for guards
    _h[INTERRUPTION_TYPE.ELEMENT_NOT_FOUND] = [INTERRUPTION_TYPE.ELEMENT_NOT_FOUND],
    _h[INTERRUPTION_TYPE.SYSTEM_WARNING] = [INTERRUPTION_TYPE.SYSTEM_WARNING],
    _h[INTERRUPTION_TYPE.WARNING] = [INTERRUPTION_TYPE.WARNING, INTERRUPTION_TYPE.SYSTEM_WARNING],
    _h);
exports.PredefinedVariablesContainerName = "Predefined";
var PredefinedVariable;
(function (PredefinedVariable) {
    PredefinedVariable["CurrentStepValue"] = "CurrentStepValue";
})(PredefinedVariable = exports.PredefinedVariable || (exports.PredefinedVariable = {}));
var FEATURES;
(function (FEATURES) {
    FEATURES["AUDIT_TRAIL"] = "AUDIT_TRAIL";
    FEATURES["GXP_TAGS"] = "GXP_TAGS";
    FEATURES["ELECTRONIC_SIGNOFF"] = "ELECTRONIC_SIGNOFF";
    FEATURES["SCRIPT_VERSIONING"] = "SCRIPT_VERSIONING";
    FEATURES["DOCUMENTATION"] = "DOCUMENTATION";
    FEATURES["REMOVABLE_DOCUMENTATION"] = "REMOVABLE_DOCUMENTATION";
})(FEATURES = exports.FEATURES || (exports.FEATURES = {}));
var GLOBAL_SETTINGS_KEYS;
(function (GLOBAL_SETTINGS_KEYS) {
    GLOBAL_SETTINGS_KEYS["STATUS_TRANSITIONS"] = "STATUS_TRANSITIONS";
    GLOBAL_SETTINGS_KEYS["PUBLISHING_CREDENTIALS_CONFIRMATION"] = "PUBLISHING_CREDENTIALS_CONFIRMATION";
    GLOBAL_SETTINGS_KEYS["DEFAULT_APP_USER_SETTINGS"] = "DEFAULT_APP_USER_SETTINGS";
    GLOBAL_SETTINGS_KEYS["TEST_PLANS_INTEGRATION"] = "TEST_PLANS_INTEGRATION";
    GLOBAL_SETTINGS_KEYS["TIMEOUTS"] = "TIMEOUTS";
    GLOBAL_SETTINGS_KEYS["REPORT_TEMPLATE"] = "REPORT_TEMPLATE";
    GLOBAL_SETTINGS_KEYS["DOCUMENTATION_TEMPLATE"] = "DOCUMENTATION_TEMPLATE";
    GLOBAL_SETTINGS_KEYS["ADMINISTRATION_ADDRESS"] = "ADMINISTRATION_ADDRESS";
    GLOBAL_SETTINGS_KEYS["REPORTS_LINK"] = "REPORTS_LINK";
    GLOBAL_SETTINGS_KEYS["RESOLVERS_MODE"] = "RESOLVERS_MODE";
    GLOBAL_SETTINGS_KEYS["POSITION_RESOLVER_META_SCORE_THRESHOLD"] = "POSITION_RESOLVER_META_SCORE_THRESHOLD";
    GLOBAL_SETTINGS_KEYS["GENERATE_RESOLVERS_ON_PLAYBACK"] = "GENERATE_RESOLVERS_ON_PLAYBACK";
    GLOBAL_SETTINGS_KEYS["internal"] = "internal";
})(GLOBAL_SETTINGS_KEYS = exports.GLOBAL_SETTINGS_KEYS || (exports.GLOBAL_SETTINGS_KEYS = {}));
var INTERNAL_SETTINGS_KEYS;
(function (INTERNAL_SETTINGS_KEYS) {
    INTERNAL_SETTINGS_KEYS["DISABLED_FEATURES"] = "DISABLED_FEATURES";
    INTERNAL_SETTINGS_KEYS["AUTO_SCREENSHOTS_CLEANUP"] = "AUTO_SCREENSHOTS_CLEANUP";
    INTERNAL_SETTINGS_KEYS["HOTJAR"] = "HOTJAR";
})(INTERNAL_SETTINGS_KEYS = exports.INTERNAL_SETTINGS_KEYS || (exports.INTERNAL_SETTINGS_KEYS = {}));
var PUBLIC_SETTINGS_KEYS;
(function (PUBLIC_SETTINGS_KEYS) {
    PUBLIC_SETTINGS_KEYS["RECAPTCHA_PUBLIC_KEY"] = "RECAPTCHA_PUBLIC_KEY";
    PUBLIC_SETTINGS_KEYS["RECAPTCHA_ENABLED"] = "RECAPTCHA_ENABLED";
    PUBLIC_SETTINGS_KEYS["SSO_AZURE_ENABLED"] = "SSO_AZURE_ENABLED";
})(PUBLIC_SETTINGS_KEYS = exports.PUBLIC_SETTINGS_KEYS || (exports.PUBLIC_SETTINGS_KEYS = {}));
var GlobalSettingsResolversModes;
(function (GlobalSettingsResolversModes) {
    GlobalSettingsResolversModes["SMART"] = "SMART";
    GlobalSettingsResolversModes["LEGACY"] = "LEGACY";
    GlobalSettingsResolversModes["FALLBACK"] = "FALLBACK";
})(GlobalSettingsResolversModes = exports.GlobalSettingsResolversModes || (exports.GlobalSettingsResolversModes = {}));
var ApplicationStyles;
(function (ApplicationStyles) {
    ApplicationStyles["DEFAULT"] = "default";
    ApplicationStyles["EPISTA"] = "epista";
    ApplicationStyles["DOCUMENTATION"] = "documentation";
})(ApplicationStyles = exports.ApplicationStyles || (exports.ApplicationStyles = {}));
var ReportExtension;
(function (ReportExtension) {
    ReportExtension["docx"] = "docx";
    ReportExtension["pdf"] = "pdf";
})(ReportExtension = exports.ReportExtension || (exports.ReportExtension = {}));
var WebsocketMessageTypes;
(function (WebsocketMessageTypes) {
    WebsocketMessageTypes["OPEN_CONNECTION"] = "OPEN_CONNECTION";
    WebsocketMessageTypes["NOTIFICATION"] = "NOTIFICATION";
    WebsocketMessageTypes["REMOVE_CLIENT_COOKIES"] = "REMOVE_CLIENT_COOKIES";
    WebsocketMessageTypes["SCHEDULER"] = "SCHEDULER";
    WebsocketMessageTypes["SUBSCRIBE_TO_UPDATES"] = "SUBSCRIBE_UPDATES";
    WebsocketMessageTypes["UNSUBSCRIBE_TO_UPDATES"] = "UNSUBSCRIBE_UPDATES";
    WebsocketMessageTypes["PERFORM_REFRESH"] = "PERFORM_REFRESH";
    WebsocketMessageTypes["HEARTBEAT"] = "HEARTBEAT";
    WebsocketMessageTypes["REPORT_GENERATION_SUCCESS"] = "REPORT_GENERATION_SUCCESS";
    WebsocketMessageTypes["REPORT_GENERATION_FAIL"] = "REPORT_GENERATION_FAIL";
    WebsocketMessageTypes["GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE"] = "GLOBAL_ACTIONS_SCREENSHOTS_CLEANUP_UPDATE";
})(WebsocketMessageTypes = exports.WebsocketMessageTypes || (exports.WebsocketMessageTypes = {}));
var SubscribeUpdateModelNames;
(function (SubscribeUpdateModelNames) {
    SubscribeUpdateModelNames["TASK_STEP"] = "TaskStep";
    SubscribeUpdateModelNames["VARIABLE"] = "Variable";
})(SubscribeUpdateModelNames = exports.SubscribeUpdateModelNames || (exports.SubscribeUpdateModelNames = {}));
var ScheduledProjectState;
(function (ScheduledProjectState) {
    ScheduledProjectState["DISPATCHED"] = "projectDispatched";
    ScheduledProjectState["DISPATCH_FAILED"] = "projectDispatchFailed";
    ScheduledProjectState["FINISHED"] = "projectFinish";
    ScheduledProjectState["ERROR"] = "projectError";
})(ScheduledProjectState = exports.ScheduledProjectState || (exports.ScheduledProjectState = {}));
var initialConditionActions = function () { return ({
    ifAction: {
        type: undefined,
        values: [],
    },
    elseAction: {
        type: undefined,
        values: [],
    },
}); };
exports.initialConditionActions = initialConditionActions;
var initialDataSourceCondition = function () { return ({
    comparison: [
        {
            type: AssertionType.DATA_SOURCE,
            comparisonType: undefined,
            value: undefined,
        },
    ],
}); };
exports.initialDataSourceCondition = initialDataSourceCondition;
var initialCheckValueCondition = function () { return ({
    comparison: [
        {
            type: AssertionType.CHECK_VALUE,
            operator: undefined,
            leftCondition: {
                type: undefined,
                value: undefined,
            },
            rightCondition: {
                type: undefined,
                value: undefined,
            },
        },
    ],
}); };
exports.initialCheckValueCondition = initialCheckValueCondition;
exports.INITIAL_STRUCTURE_VERSION = "0.0.1";
var createInternalSteps = function (steps) {
    return steps.map(function (s, index) { return (__assign(__assign({}, s), { lineNum: index, id: index, taskScriptId: -1 })); });
};
exports.createInternalSteps = createInternalSteps;
var createInternalStep = function (step) { return step; };
exports.createInternalStep = createInternalStep;
exports.DEFAULT_LOCALE = "en";
var CHECK_REFERENCE_TYPE;
(function (CHECK_REFERENCE_TYPE) {
    CHECK_REFERENCE_TYPE["STEP_REFERENCE"] = "STEP_REFERENCE";
    CHECK_REFERENCE_TYPE["VARIABLE_REFERENCE"] = "VARIABLE_REFERENCE";
    CHECK_REFERENCE_TYPE["GLOBALVARIABLE_REFERENCE"] = "GLOBALVARIABLE_REFERENCE";
    CHECK_REFERENCE_TYPE["DATASOURCE_REFERENCE"] = "DATASOURCE_REFERENCE";
})(CHECK_REFERENCE_TYPE = exports.CHECK_REFERENCE_TYPE || (exports.CHECK_REFERENCE_TYPE = {}));
var TEMPLATE_TYPE;
(function (TEMPLATE_TYPE) {
    TEMPLATE_TYPE["REPORT_TEMPLATE"] = "reportTemplate";
    TEMPLATE_TYPE["DOCUMENTATION_TEMPLATE"] = "documentationTemplate";
})(TEMPLATE_TYPE = exports.TEMPLATE_TYPE || (exports.TEMPLATE_TYPE = {}));
var RUNNER_TYPE;
(function (RUNNER_TYPE) {
    RUNNER_TYPE["BASIC"] = "Basic";
    RUNNER_TYPE["ADVANCED"] = "Advanced";
})(RUNNER_TYPE = exports.RUNNER_TYPE || (exports.RUNNER_TYPE = {}));
var RUNNER_OPEN_MODE;
(function (RUNNER_OPEN_MODE) {
    RUNNER_OPEN_MODE["NEW_WINDOW"] = "New window";
    RUNNER_OPEN_MODE["CURRENT_WINDOW"] = "Current window";
})(RUNNER_OPEN_MODE = exports.RUNNER_OPEN_MODE || (exports.RUNNER_OPEN_MODE = {}));
var RUNNER_POSITION;
(function (RUNNER_POSITION) {
    RUNNER_POSITION["LEFT"] = "Left";
    RUNNER_POSITION["RIGHT"] = "Right";
})(RUNNER_POSITION = exports.RUNNER_POSITION || (exports.RUNNER_POSITION = {}));
var SchedulerRunTypes;
(function (SchedulerRunTypes) {
    SchedulerRunTypes["TIME"] = "TIME";
    SchedulerRunTypes["DEPENDANT"] = "DEPENDANT";
})(SchedulerRunTypes = exports.SchedulerRunTypes || (exports.SchedulerRunTypes = {}));
exports.RUNNER_FRAME_ID = "EA_RUNNER_FRAME";
var FileConverterOutputExtension;
(function (FileConverterOutputExtension) {
    FileConverterOutputExtension["PDF"] = "pdf";
    FileConverterOutputExtension["XLSX"] = "xlsx";
})(FileConverterOutputExtension = exports.FileConverterOutputExtension || (exports.FileConverterOutputExtension = {}));
var BroadcastMessages;
(function (BroadcastMessages) {
    BroadcastMessages["LOGOUT"] = "LOGOUT";
    BroadcastMessages["LOGIN"] = "LOGIN";
})(BroadcastMessages = exports.BroadcastMessages || (exports.BroadcastMessages = {}));
var ExportToCsvModels;
(function (ExportToCsvModels) {
    ExportToCsvModels["SCRIPTS"] = "TaskScript";
    ExportToCsvModels["LOGS"] = "ExecutionLog";
    ExportToCsvModels["STEP_LOGS"] = "ExecutionStepLog";
    ExportToCsvModels["EXECUTION_JOB_LOGS"] = "ExecutionJobLog";
    ExportToCsvModels["SCHEDULER_FLOW"] = "SchedulerJobMapping";
})(ExportToCsvModels = exports.ExportToCsvModels || (exports.ExportToCsvModels = {}));
var LicenseType;
(function (LicenseType) {
    LicenseType["REGULAR"] = "Regular";
    LicenseType["ENTERPRISE"] = "Enterprise";
    LicenseType["PAY_PER_USE"] = "Pay per use";
})(LicenseType = exports.LicenseType || (exports.LicenseType = {}));
var PayPerUseStatisticsPrecision;
(function (PayPerUseStatisticsPrecision) {
    PayPerUseStatisticsPrecision["Days"] = "Days";
    PayPerUseStatisticsPrecision["Months"] = "Months";
    PayPerUseStatisticsPrecision["Years"] = "Years";
})(PayPerUseStatisticsPrecision = exports.PayPerUseStatisticsPrecision || (exports.PayPerUseStatisticsPrecision = {}));
var LicenseErrors;
(function (LicenseErrors) {
    LicenseErrors["readingFile"] = "Error while reading licence file";
    LicenseErrors["decryptError"] = "Error while decrypting license file";
    LicenseErrors["invalidLicenseNumber"] = "Invalid license number";
})(LicenseErrors = exports.LicenseErrors || (exports.LicenseErrors = {}));
var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["STARTING_SESSION"] = "STARTING_SESSION";
    ErrorCodes["SESSIONS_LIMIT_REACHED"] = "SESSIONS_LIMIT_REACHED";
})(ErrorCodes = exports.ErrorCodes || (exports.ErrorCodes = {}));
var ScreenshotOptions;
(function (ScreenshotOptions) {
    ScreenshotOptions["ON_INTERRUPTION"] = "ON_INTERRUPTION";
    ScreenshotOptions["ON_STEP"] = "ON_STEP";
    ScreenshotOptions["NO_SCREENSHOT"] = "NO_SCREENSHOT";
})(ScreenshotOptions = exports.ScreenshotOptions || (exports.ScreenshotOptions = {}));
var SaveVideoCondition;
(function (SaveVideoCondition) {
    SaveVideoCondition["ON_ERROR"] = "ON_ERROR";
    SaveVideoCondition["ON_SUCCESS"] = "ON_SUCCESS";
    SaveVideoCondition["ALWAYS"] = "ALWAYS";
})(SaveVideoCondition = exports.SaveVideoCondition || (exports.SaveVideoCondition = {}));
var ScriptsCsvAdditionalFields;
(function (ScriptsCsvAdditionalFields) {
    ScriptsCsvAdditionalFields["takeScreenshots"] = "takeScreenshots";
    ScriptsCsvAdditionalFields["screenshotsOnlyOnInterruption"] = "screenshotsOnlyOnInterruption";
    ScriptsCsvAdditionalFields["clickOnDisabledButtons"] = "clickOnDisabledButtons";
    ScriptsCsvAdditionalFields["useDefaultEnvironment"] = "useDefaultEnvironment";
    ScriptsCsvAdditionalFields["topProjectName"] = "topProjectName";
    ScriptsCsvAdditionalFields["virtualUserName"] = "virtualUserName";
})(ScriptsCsvAdditionalFields = exports.ScriptsCsvAdditionalFields || (exports.ScriptsCsvAdditionalFields = {}));
var CodeParamType;
(function (CodeParamType) {
    CodeParamType["CONSTANT"] = "CONSTANT";
    CodeParamType["VARIABLE"] = "VARIABLE";
})(CodeParamType = exports.CodeParamType || (exports.CodeParamType = {}));
var CodeTemplateSaveMode;
(function (CodeTemplateSaveMode) {
    CodeTemplateSaveMode["CURRENT"] = "CURRENT";
    CodeTemplateSaveMode["AS_NEW_VERSION"] = "AS_NEW_VERSION";
    CodeTemplateSaveMode["AS_NEW_ACTIVE_VERSION"] = "AS_NEW_ACTIVE_VERSION";
})(CodeTemplateSaveMode = exports.CodeTemplateSaveMode || (exports.CodeTemplateSaveMode = {}));
var LogCsvAdditionalFields;
(function (LogCsvAdditionalFields) {
    LogCsvAdditionalFields["scriptLogUrl"] = "scriptLogUrl";
    LogCsvAdditionalFields["schedulerJobName"] = "schedulerJobName";
    LogCsvAdditionalFields["jobId"] = "jobId";
    LogCsvAdditionalFields["lastErrorMessage"] = "lastErrorMessage";
})(LogCsvAdditionalFields = exports.LogCsvAdditionalFields || (exports.LogCsvAdditionalFields = {}));
