import * as React from "react";
import CommandBar from "@ea/shared_components/CommandBar/CommandBar";
import CommandBarButton from "@ea/shared_components/CommandBar/CommandBarButton";
import { AggregatedJobLog, ROLES } from "@ea/shared_types/types";
import { getTranslationKey } from "@app/translations/translations.helpers";

import WithAuthorization from "@app/modules/common/WithAuthorization";
import CommandBarDropdown from "@ea/shared_components/CommandBar/CommandBarDropdown";
import { CloudOutlined, CheckOutlined, BarChartOutlined } from "@ant-design/icons";
import { HideWhenDocumentationWizard } from "@app/modules/common/HideWhenDocumentationWizard";
import { DataTestIds } from "@app/utils/dataTestIds";
interface AggregatedJobLogsCommandBarProps {
  onReload: () => void;
  onShowStatistics?: () => void;
  selectedItems?: AggregatedJobLog[];
  changeHideAggregatedLogsView?: () => void;
  selected?: number[];
  isLogsViewChanging?: boolean;
  changeExcludeJobLog?: () => void;
  onFilterDevopsExecutions?: () => void;
  showOnlyDevopsExecutions?: boolean;
  onExport?: () => void;
  onPerformanceCountersClick?: () => void;
  isExporting?: boolean;
}
const AuthorizedCommandBarButton = WithAuthorization([ROLES.admin], [])(CommandBarButton);
export const AggregatedJobLogsCommandBar = (props: AggregatedJobLogsCommandBarProps) => {
  const {
    selected,
    onShowStatistics,
    onReload,
    changeHideAggregatedLogsView,
    isLogsViewChanging,
    changeExcludeJobLog,
    selectedItems,
    onFilterDevopsExecutions,
    showOnlyDevopsExecutions,
    onExport,
    isExporting,
    onPerformanceCountersClick,
  } = props;

  const azureDevops = selectedItems?.[0]?.integrationMetadata?.azureDevops;
  return (
    <CommandBar data-testid={DataTestIds.COMMANDBAR_AGGREGATED_JOB_LOGS}>
      <CommandBarButton
        name="refresh"
        onClick={onReload}
        text={getTranslationKey("commandBar", "refresh")}
        icon="ReloadOutlined"
        data-testid={DataTestIds.COMMANDBAR_BUTTON_REFRESH}
      />
      {changeHideAggregatedLogsView && (
        <CommandBarButton
          name="switch"
          onClick={changeHideAggregatedLogsView}
          text={getTranslationKey("commandBar", "nonAggregated")}
          icon="TableOutlined"
          loading={isLogsViewChanging}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_SHOW_NONAGGREGATED}
        />
      )}
      {onShowStatistics && (
        <CommandBarButton
          name="statistics"
          onClick={() => onShowStatistics()}
          text={getTranslationKey("commandBar", "statistics")}
          icon="PieChartOutlined"
          disabled={!(selected && selected.length > 1)}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_STATISTIC}
        />
      )}
      <HideWhenDocumentationWizard>
        {() => (
          <CommandBarDropdown
            name="devOps"
            style={{ padding: "7px" }}
            text={getTranslationKey("commandBar", "azureDevOps")}
            icon={<CloudOutlined style={{ fontSize: 14 }} />}
            data-testid={DataTestIds.COMMANDBAR_BUTTON_DEVOPS}
          >
            <CommandBarButton
              name="openTestSuite"
              onClick={() => {
                window.open(azureDevops?.testSuiteDefineUrl, "_blank");
              }}
              text={getTranslationKey("commandBar", "openTestSuite")}
              disabled={
                !selectedItems || selectedItems.length !== 1 || !azureDevops?.testSuiteDefineUrl
              }
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN_TEST_SUIT}
            />
            <CommandBarButton
              name="openTestSuiteExecute"
              onClick={() => {
                window.open(azureDevops?.testSuiteExecuteUrl, "_blank");
              }}
              text={getTranslationKey("commandBar", "openTestSuiteResults")}
              disabled={
                !selectedItems || selectedItems.length !== 1 || !azureDevops?.testSuiteExecuteUrl
              }
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_OPEN_TEST_SUIT_EXEC}
            />
            <CommandBarButton
              name="showDevopsExecutions"
              onClick={onFilterDevopsExecutions}
              text={getTranslationKey("commandBar", "showDevopsExecutions")}
              disabled={!onFilterDevopsExecutions}
              icon={
                showOnlyDevopsExecutions ? <CheckOutlined style={{ fontSize: 14 }} /> : undefined
              }
              size="default"
              data-testid={DataTestIds.COMMANDBAR_BUTTON_SHOW_DEVOPS_EXEC}
            />
          </CommandBarDropdown>
        )}
      </HideWhenDocumentationWizard>
      {onExport && (
        <CommandBarButton
          name="export"
          onClick={onExport}
          text={getTranslationKey(
            "commandBar",
            (selectedItems || []).length > 0 ? "exportSelectedToCsv" : "exportToCsv",
          )}
          icon="FileExcelOutlined"
          loading={isExporting}
          data-testid={DataTestIds.COMMANDBAR_BUTTON_EXPORT}
        />
      )}
      <CommandBarButton
        name="kpiStats"
        onClick={onPerformanceCountersClick}
        text={getTranslationKey("commandBar", "performanceCounters")}
        disabled={(selectedItems || []).length !== 1}
        icon={<BarChartOutlined style={{ fontSize: 14 }} />}
        size="default"
        data-testid={DataTestIds.COMMANDBAR_BUTTON_KPI_STATS}
      />
      <AuthorizedCommandBarButton
        name="disableJob"
        onClick={changeExcludeJobLog}
        text={getTranslationKey("commandBar", "excludeJobs")}
        icon="CloseOutlined"
        data-testid={DataTestIds.COMMANDBAR_BUTTON_DISABLE_JOBS}
        far
      />
    </CommandBar>
  );
};

export default AggregatedJobLogsCommandBar;
