import styled from "@emotion/styled";
import * as React from "react";
import { Menu } from "antd";
import { SystemDictionaryTableContainer } from "@app/modules/systemDictionary";
import { StorageTableContainer } from "@app/modules/storage";
import UserBasicSettingsContainer from "@app/modules/users/UserBasicSettings.container";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { SequencesTableContainer } from "@app/modules/sequences";
import UserExecutionSettingsContainer from "@app/modules/users/UserExecutionSettings.container";
import VirtualUsersTableContainer from "@app/modules/virtualUsers/VirtualUsersTable.container";
import ApiKeyTableContainer from "@app/modules/apiKey/ApiKeyTable.container";
import { FormattedMessage, injectIntl, InjectedIntlProps } from "react-intl";
import { getTranslationKey } from "@app/translations/translations.helpers";
import UserGroupsLoaderContainer from "../userGroups/UserGroupsLoader.container";
import { ROLES, FEATURES } from "@ea/shared_types/types";
import { DataTestIds } from "@app/utils/dataTestIds";
import WithAuthorization from "../common/WithAuthorization";
import ProjectCategoryTableContainer from "../projectCategory/ProjectCategoriesTable.container";
import UsersTableContainer from "@app/modules/users/UsersTable.container";
import { ItsTableContainer } from "../issueTrackingTool";
import { ItsRulesTableContainer } from "../issueTrackingToolRules";
import { TagTableContainer } from "../tags";
import { AuditTrailContainer } from "../auditTrail";
import GlobalSettingsContainer from "../globalSettings/GlobalSettings.container";
import HideableFeature from "../common/HideableFeature";
import { LocalizationTableContainer } from "../localization";
import { ReportTemplatesTableContainer } from "../reportTemplate";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import {
  HideWhenDocumentationWizard,
  IS_DOCUMENTATION_WIZARD,
} from "../common/HideWhenDocumentationWizard";
import GlobalVariablesTableContainer from "../globalVariables/GlobalVariablesTable.container";
import LicenseOverview from "../license/LicenseOverview";
import { KpisTableContainer } from "../kpis";
import { CodeTemplateGroupsTableContainer } from "../codeTemplateGroups";
import ItsConfigurationsTableContainer from "../issueTrackingToolConfigurations/ItsConfigurationsTable.container";
import { GlobalActionsContainer } from "../globalActions/GlobalActions.container";
import CodeTemplatesTableContainer from "../codeTemplates/CodeTemplatesTable.container";

const SubMenu = Menu.SubMenu;

interface ISettingsContainerProps {}
interface ISettingsContainerState {}
enum SETTINGS_PAGES {
  GLOBAL_ACTIONS = "globalActions",
  GLOBAL_SETTINGS = "globalSettings",
  SYSTEM_DEFINITIONS = "systemDefinitions",
  SEQUENCES = "sequences",
  GLOBAL_VARIABLES = "globalVariables",
  USER_EXECUTION_SETTINGS = "userExecutionSettings",
  USER_BASIC_SETTINGS = "userBasicSettings",
  VIRTUAL_USERS = "virtualUsers",
  USERS = "users",
  API_KEYS = "tokens",
  STORAGES = "storages",
  USER_GROUPS = "userGroups",
  PROJECT_CATEGORY = "projectCategory",
  ISSUE_TRACING_TOOLS = "issueTrackingTools",
  ITS_RULES = "itsRules",
  ITS_CONFIGURATIONS = "itsConfigurations",
  TAGS = "tags",
  AUDIT_TRAIL = "auditTrail",
  LOCALIZATION = "localizations",
  REPORT_TEMPLATES = "reportTemplates",
  LICENSE = "license",
  CODE_TEMPLATE_GROUPS = "codeTemplateGroups",
  KPI = "kpi",
  CODE_TEMPLATES = "codeTemplates",
}

const Container = styled.div({
  marginLeft: "10px",
  width: "100%",
  display: "flex",
  height: "100%",
});

const AdminSettingsMenuItem = WithAuthorization([ROLES.admin])(Menu.Item);
class SettingsContainer extends React.Component<
  ISettingsContainerProps & RouteComponentProps<any> & InjectedIntlProps,
  ISettingsContainerState
> {
  switchSettingsSubpage = (key: SETTINGS_PAGES) => {
    switch (key) {
      case SETTINGS_PAGES.SYSTEM_DEFINITIONS:
        return <SystemDictionaryTableContainer />;
      case SETTINGS_PAGES.VIRTUAL_USERS:
        return <VirtualUsersTableContainer />;
      case SETTINGS_PAGES.USERS:
        return <UsersTableContainer />;
      case SETTINGS_PAGES.SEQUENCES:
        return <SequencesTableContainer />;
      case SETTINGS_PAGES.GLOBAL_VARIABLES:
        return <GlobalVariablesTableContainer />;
      case SETTINGS_PAGES.USER_EXECUTION_SETTINGS:
        return <UserExecutionSettingsContainer />;
      case SETTINGS_PAGES.USER_BASIC_SETTINGS:
        return <UserBasicSettingsContainer />;
      case SETTINGS_PAGES.API_KEYS:
        return (
          <HideWhenDocumentationWizard>
            {() => <ApiKeyTableContainer />}
          </HideWhenDocumentationWizard>
        );
      case SETTINGS_PAGES.STORAGES:
        return (
          <HideWhenDocumentationWizard>
            {() => <StorageTableContainer />}
          </HideWhenDocumentationWizard>
        );
      case SETTINGS_PAGES.USER_GROUPS:
        return <UserGroupsLoaderContainer />;
      case SETTINGS_PAGES.PROJECT_CATEGORY:
        return <ProjectCategoryTableContainer />;
      case SETTINGS_PAGES.ISSUE_TRACING_TOOLS:
        return (
          <HideWhenDocumentationWizard>{() => <ItsTableContainer />}</HideWhenDocumentationWizard>
        );
      case SETTINGS_PAGES.ITS_RULES:
        return (
          <HideWhenDocumentationWizard>
            {() => <ItsRulesTableContainer />}
          </HideWhenDocumentationWizard>
        );
      case SETTINGS_PAGES.ITS_CONFIGURATIONS:
        return (
          <HideWhenDocumentationWizard>
            {() => <ItsConfigurationsTableContainer />}
          </HideWhenDocumentationWizard>
        );
      case SETTINGS_PAGES.TAGS:
        return (
          <HideableFeature feature={FEATURES.GXP_TAGS}>
            {() => <TagTableContainer />}
          </HideableFeature>
        );
      case SETTINGS_PAGES.AUDIT_TRAIL:
        return (
          <HideableFeature feature={FEATURES.AUDIT_TRAIL}>
            {() => <AuditTrailContainer />}
          </HideableFeature>
        );
      // we're hiding global settings page if electronic signoff feature is disabled because
      // its the only global setting visible on UI at the moment
      case SETTINGS_PAGES.GLOBAL_SETTINGS:
        return <GlobalSettingsContainer />;
      case SETTINGS_PAGES.GLOBAL_ACTIONS:
        return <GlobalActionsContainer intl={this.props.intl} />;
      case SETTINGS_PAGES.LOCALIZATION:
        return <LocalizationTableContainer />;
      case SETTINGS_PAGES.REPORT_TEMPLATES:
        return <ReportTemplatesTableContainer />;
      case SETTINGS_PAGES.LICENSE:
        return <LicenseOverview intl={this.props.intl} />;
      case SETTINGS_PAGES.KPI:
        return <KpisTableContainer />;
      case SETTINGS_PAGES.CODE_TEMPLATE_GROUPS:
        return <CodeTemplateGroupsTableContainer />;
      case SETTINGS_PAGES.CODE_TEMPLATES:
        return <CodeTemplatesTableContainer />;
      default:
        return <Redirect to={`/settings/${SETTINGS_PAGES.SYSTEM_DEFINITIONS}`} />;
    }
  };

  onMenuClick = (e: { key: string | number }): void => {
    this.props.history.push(`/settings/${e.key}`);
  };

  render() {
    const selectedMenuKey = this.props.match.params && this.props.match.params.key;

    // can't use HOC in this case because it breaks styles
    const IssueTrakcingToolsMenuItem = IS_DOCUMENTATION_WIZARD ? null : (
      <SubMenu
        key="its"
        title={<FormattedMessage id={getTranslationKey("settings", "issueTrackingTools")} />}
        data-testid={DataTestIds.SETTINGS_ISSUE_TRACKING}
      >
        <AdminSettingsMenuItem
          key={SETTINGS_PAGES.ISSUE_TRACING_TOOLS}
          data-testid={DataTestIds.SETTINGS_ISSUE_SYSTEM_DEFINITION}
        >
          <FormattedMessage id={getTranslationKey("settings", "systemDefinitions")} />
        </AdminSettingsMenuItem>
        <Menu.Item key={SETTINGS_PAGES.ITS_RULES} data-testid={DataTestIds.SETTINGS_RULES}>
          <FormattedMessage id={getTranslationKey("settings", "itsRules")} />
        </Menu.Item>
        <Menu.Item key={SETTINGS_PAGES.ITS_CONFIGURATIONS}>
          <FormattedMessage id={getTranslationKey("settings", "itsConfigurations")} />
        </Menu.Item>
      </SubMenu>
    );

    return (
      <>
        <Menu
          onClick={this.onMenuClick}
          style={{ width: 256 }}
          defaultSelectedKeys={[selectedMenuKey || SETTINGS_PAGES.SYSTEM_DEFINITIONS]}
          defaultOpenKeys={["application", "user"]}
          mode="inline"
          data-testid={DataTestIds.SETTINGS_MENU}
        >
          <SubMenu
            key="application"
            data-testid={DataTestIds.SETTINGS_APPLICATION}
            title={
              <span>
                <HomeOutlined />
                <span>
                  <FormattedMessage id={getTranslationKey("settings", "application")} />
                </span>
              </span>
            }
          >
            <Menu.Item
              key={SETTINGS_PAGES.GLOBAL_SETTINGS}
              data-testid={DataTestIds.SETTINGS_GLOBAL_SETTINGS}
            >
              <FormattedMessage id={getTranslationKey("settings", "globalSettings")} />
            </Menu.Item>

            <AdminSettingsMenuItem key={SETTINGS_PAGES.GLOBAL_ACTIONS}>
              <FormattedMessage id={getTranslationKey("settings", "globalActions")} />
            </AdminSettingsMenuItem>
            <Menu.Item key={SETTINGS_PAGES.SEQUENCES}>
              <FormattedMessage id={getTranslationKey("settings", "sequences")} />
            </Menu.Item>
            <Menu.Item
              key={SETTINGS_PAGES.GLOBAL_VARIABLES}
              data-testid={DataTestIds.SETTINGS_GLOBAL_VARIABLES}
            >
              <FormattedMessage id={getTranslationKey("settings", "globalVariables")} />
            </Menu.Item>
            <AdminSettingsMenuItem
              key={SETTINGS_PAGES.SYSTEM_DEFINITIONS}
              data-testid={DataTestIds.SETTINGS_SYSTEM_DEFINITIONS}
            >
              <FormattedMessage id={getTranslationKey("settings", "systemDefinitions")} />
            </AdminSettingsMenuItem>
            <HideWhenDocumentationWizard key={SETTINGS_PAGES.STORAGES}>
              {(p) => (
                <AdminSettingsMenuItem {...p} data-testid={DataTestIds.SETTINGS_STORAGES}>
                  <FormattedMessage id={getTranslationKey("settings", "storages")} />
                </AdminSettingsMenuItem>
              )}
            </HideWhenDocumentationWizard>
            <Menu.Item
              key={SETTINGS_PAGES.VIRTUAL_USERS}
              data-testid={DataTestIds.SETTINGS_VIRTUAL_USERS}
            >
              <FormattedMessage id={getTranslationKey("settings", "virtualUser")} />
            </Menu.Item>
            <HideWhenDocumentationWizard key={SETTINGS_PAGES.API_KEYS}>
              {(p) => (
                <AdminSettingsMenuItem {...p} data-testid={DataTestIds.SETTINGS_API_KEY}>
                  <FormattedMessage id={getTranslationKey("settings", "apiKey")} />
                </AdminSettingsMenuItem>
              )}
            </HideWhenDocumentationWizard>
            <AdminSettingsMenuItem
              key={SETTINGS_PAGES.USER_GROUPS}
              data-testid={DataTestIds.SETTINGS_USER_GROUPS}
            >
              <FormattedMessage id={getTranslationKey("settings", "userGroups")} />
            </AdminSettingsMenuItem>
            <AdminSettingsMenuItem
              key={SETTINGS_PAGES.PROJECT_CATEGORY}
              data-testid={DataTestIds.SETTINGS_PROJECT_CATEGORIES}
            >
              <FormattedMessage id={getTranslationKey("settings", "projectCategory")} />
            </AdminSettingsMenuItem>
            <AdminSettingsMenuItem
              key={SETTINGS_PAGES.USERS}
              data-testid={DataTestIds.SETTINGS_USERS}
            >
              <FormattedMessage id={getTranslationKey("settings", "users")} />
            </AdminSettingsMenuItem>
            <AdminSettingsMenuItem
              key={SETTINGS_PAGES.LOCALIZATION}
              data-testid={DataTestIds.SETTINGS_LOCALIZATIONS}
            >
              <FormattedMessage id={getTranslationKey("settings", "localizations")} />
            </AdminSettingsMenuItem>
            <AdminSettingsMenuItem
              key={SETTINGS_PAGES.REPORT_TEMPLATES}
              data-testid={DataTestIds.SETTINGS_REPORT_TEMPLATE}
            >
              <FormattedMessage id={getTranslationKey("settings", "reportTemplates")} />
            </AdminSettingsMenuItem>
            {IssueTrakcingToolsMenuItem}
            <AdminSettingsMenuItem key={SETTINGS_PAGES.KPI} data-testid={DataTestIds.SETTINGS_KPI}>
              <FormattedMessage id={getTranslationKey("settings", "kpi")} />
            </AdminSettingsMenuItem>
            <HideableFeature key={SETTINGS_PAGES.TAGS} feature={FEATURES.GXP_TAGS}>
              {(p) => (
                <AdminSettingsMenuItem {...p} data-testid={DataTestIds.SETTINGS_TAGS}>
                  <FormattedMessage id={getTranslationKey("settings", "tags")} />
                </AdminSettingsMenuItem>
              )}
            </HideableFeature>
            <HideableFeature key={SETTINGS_PAGES.AUDIT_TRAIL} feature={FEATURES.AUDIT_TRAIL}>
              {(p) => (
                <AdminSettingsMenuItem {...p} data-testid={DataTestIds.SETTINGS_AUDIT_TRAIL}>
                  <FormattedMessage id={getTranslationKey("settings", "auditTrail")} />
                </AdminSettingsMenuItem>
              )}
            </HideableFeature>
            <AdminSettingsMenuItem key={SETTINGS_PAGES.LICENSE}>
              <FormattedMessage id={getTranslationKey("settings", "license")} />
            </AdminSettingsMenuItem>
            <SubMenu
              key="code"
              data-testid={DataTestIds.SETTINGS_CODE}
              title={<FormattedMessage id={getTranslationKey("settings", "code")} />}
            >
              <Menu.Item
                key={SETTINGS_PAGES.CODE_TEMPLATES}
                data-testid={DataTestIds.SETTINGS_CODE_TEMPLATES}
              >
                <FormattedMessage id={getTranslationKey("settings", "codeTemplates")} />
              </Menu.Item>
              <Menu.Item
                key={SETTINGS_PAGES.CODE_TEMPLATE_GROUPS}
                data-testid={DataTestIds.SETTINGS_CODE_TEMPLATE_GROUPS}
              >
                <FormattedMessage id={getTranslationKey("settings", "codeTemplateGroups")} />
              </Menu.Item>
            </SubMenu>
          </SubMenu>
          <SubMenu
            key="user"
            data-testid={DataTestIds.SETTINGS_PROFILE}
            title={
              <span>
                <UserOutlined />
                <span>
                  <FormattedMessage id={getTranslationKey("settings", "profil")} />
                </span>
              </span>
            }
          >
            <Menu.Item
              key={SETTINGS_PAGES.USER_BASIC_SETTINGS}
              data-testid={DataTestIds.SETTINGS_BASIC_SETTINGS}
            >
              <FormattedMessage id={getTranslationKey("settings", "basicSettings")} />
            </Menu.Item>
            <Menu.Item
              key={SETTINGS_PAGES.USER_EXECUTION_SETTINGS}
              data-testid={DataTestIds.SETTINGS_EXECUTION_SETTINGS}
            >
              <FormattedMessage id={getTranslationKey("settings", "executionSettings")} />
            </Menu.Item>
          </SubMenu>
        </Menu>
        <Container>{this.switchSettingsSubpage(selectedMenuKey)}</Container>
      </>
    );
  }
}

export default injectIntl(withRouter(SettingsContainer));
