import { Step } from "@ea/shared_types/types";
import { CoreCommandsIds } from "@ea/shared_types/core.commands.types";
import i18next from "../translations/backendTranslations";
import { getStepLabelParams } from "@app/modules/steps/steps.utils";

export const getStepOptions = (steps: Step[], valueKey: keyof Step) => {
  return steps.map((item) => {
    const generatedLabel = item.labelKey && i18next.t(item.labelKey, getStepLabelParams(item));
    return {
      key: item.id,
      text: `${item.lineNum}. ${
        item.manualLabel === false ? generatedLabel : item.label || generatedLabel
      }`,
      value: item[valueKey],
    };
  });
};

export const omitEndStep = (steps: Step[]) => {
  return steps.filter(({ commandId }) => commandId !== CoreCommandsIds.end);
};
