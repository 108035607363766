import { Step } from "@ea/shared_types/types";
import {
  CommandPack,
  initDefaults,
  DEFAULT_PLATFORM_ID,
  CommandDefinitionServer,
  CommandDefinitionWeb,
  CommandDefinitionBase,
  CommandDefinitionRunner,
  CommandsAPI,
  CommandsServerAPI,
  CommandsRunnerAPI,
  CommandsWebAPI,
  CommandDefinitionBaseType,
} from "./ea.internal.types";

export function setCommandsAPI(api: CommandsAPI) {
  EA_INTERNAL.commands.api = api;
}

type CommandLoader<B extends CommandsAPI, T extends CommandDefinitionBase> = (
  commandsAPI: B,
) => CommandPack<T>;
function registerCommands<B extends CommandsAPI, T extends CommandDefinitionBase>(
  platformId: string,
  version: string,
  loader: CommandLoader<B, T>,
  translations?: any,
) {
  console.log(`Loading commands fields for platform '${platformId}' in version '${version}'`);

  const commandsModule = loader(EA_INTERNAL.commands.api as any);

  if (!EA_INTERNAL.commands.packs[platformId]) {
    EA_INTERNAL.commands.packs[platformId] = {};
  }

  Object.keys(commandsModule).forEach((key) => {
    commandsModule[key].platformId = platformId;
    if (EA_INTERNAL.commands.packs[platformId][key]) {
      EA_INTERNAL.commands.packs[platformId][key] = {
        ...EA_INTERNAL.commands.packs[platformId][key],
        ...commandsModule[key],
      };
    } else {
      EA_INTERNAL.commands.packs[platformId][key] = commandsModule[key];
    }
  });

  if (translations) {
    EA_INTERNAL.commands.translations[platformId] = translations;
  }
}
function getCommandsPack<T extends CommandDefinitionBase>(platformId: string): CommandPack<T> {
  const defaultCommands = EA_INTERNAL.commands.packs[DEFAULT_PLATFORM_ID];
  const platformCommands = EA_INTERNAL.commands.packs[platformId];

  return {
    ...defaultCommands,
    ...platformCommands,
  } as any as CommandPack<T>;
}

export function getAllCommands<T extends CommandDefinitionBase>(): CommandPack<T> {
  return Object.keys(EA_INTERNAL.commands.packs).reduce((container, packId) => {
    container = {
      ...container,
      ...EA_INTERNAL.commands.packs[packId],
    };

    return container;
  }, {});
}

export function getServerCommandsPack(platformId: string) {
  return getCommandsPack<CommandDefinitionServer>(platformId);
}

export function isStepCommandPlatformValid(step: Pick<Step, "platform" | "commandId">): boolean {
  const availableCommands = {
    ...EA_INTERNAL.commands.packs[DEFAULT_PLATFORM_ID],
    ...EA_INTERNAL.commands.packs[EA_INTERNAL.currentPlatform?.id!],
  };

  return !!availableCommands[step.commandId];
}

export function getStepCommand<T extends CommandDefinitionBase>(
  step: Pick<Step, "platform" | "commandId">,
): T {
  const platformId = step.platform ? step.platform.id : DEFAULT_PLATFORM_ID;

  if (
    EA_INTERNAL.commands.packs[platformId] &&
    EA_INTERNAL.commands.packs[platformId][step.commandId]
  ) {
    return EA_INTERNAL.commands.packs[platformId][step.commandId] as T;
  }

  return EA_INTERNAL.commands.packs[DEFAULT_PLATFORM_ID][step.commandId] as T;
}

export function getWebCommandsPack(platformId: string) {
  return getCommandsPack<CommandDefinitionWeb>(platformId);
}

export function getRunnerCommandsPack(platformId: string) {
  return getCommandsPack<CommandDefinitionRunner>(platformId);
}

export function getTranslations() {
  return Object.keys(EA_INTERNAL.commands.translations).map(
    (k) => EA_INTERNAL.commands.translations[k],
  );
}

export function registerServerCommands(
  platformId: string,
  version: string,
  loader: (commandsAPI: any) => CommandPack<CommandDefinitionServer>,
) {
  return registerCommands<CommandsServerAPI, CommandDefinitionServer>(platformId, version, loader);
}

export function registerWebCommands(
  platformId: string,
  version: string,
  loader: (commandsAPI: any) => CommandPack<CommandDefinitionWeb>,
  translations?: any,
) {
  return registerCommands<CommandsWebAPI, CommandDefinitionWeb>(
    platformId,
    version,
    loader,
    translations,
  );
}

export function overrideRunnerCommands(
  platformId: string,
  version: string,
  loader: (commandsAPI: any) => CommandPack<CommandDefinitionRunner<any>>,
) {
  console.log(`Override commands fields for platform '${platformId}' in version '${version}'`);

  const commandsModule = loader(EA_INTERNAL.commands.api as any);

  Object.keys(commandsModule).forEach((key) => {
    if (!EA_INTERNAL.commands.packs[DEFAULT_PLATFORM_ID][key]) {
      return;
    }

    if (!EA_INTERNAL.commands.packs[platformId]) {
      EA_INTERNAL.commands.packs[platformId] = {};
    }

    EA_INTERNAL.commands.packs[platformId][key] = {
      ...EA_INTERNAL.commands.packs[DEFAULT_PLATFORM_ID][key],
      ...commandsModule[key],
      platformId,
    };
  });
}

export function registerRunnerCommands(
  platformId: string,
  version: string,
  loader: <K extends CommandDefinitionBaseType = CommandDefinitionBaseType>(
    commandsAPI: any,
  ) => CommandPack<CommandDefinitionRunner<K>>,
) {
  return registerCommands<CommandsRunnerAPI, CommandDefinitionRunner>(platformId, version, loader);
}

export function getCommand<T extends CommandDefinitionBase>(id: string, commandId: string) {
  const commands = getCommandsPack<T>(id);
  return commands[commandId];
}

initDefaults();
