import styled from "@emotion/styled";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "@app/modules/app.reducers";
import { LogsState } from "@app/modules/logs";
import CommandBar from "@app/modules/logs/components/AggregatedJobLogsCommandBar";
import ConnectedTable from "@ea/shared_components/Table/ConnectedTable";
import {
  AGGREGATED_JOB_LOGS_COLUMNS,
  AGGREGATED_JOB_LOGS_COLUMNS_CONFIG,
} from "./aggregatedJobLogs.table";
import { getAggregatedJobLogsTableActions } from "./aggregatedJobLogs.actions";
import { FormattedMessage } from "react-intl";
import { logsDataSelectors as aggregatedJobLogsDataSelectors } from "./aggregatedJobLogs.selectors";
import { ColumnConfig } from "@ea/shared_components/Table/common.tables";
import ChartModal from "../components/ChartModal";
import AggregatedJobExecutionAreaChart from "../statistics/AggregatedJobExecutionAreaChart.container";
import { toast } from "react-toastify";
import {
  getSelectedSelector,
  getSortedDescSelectedItemsSelector,
} from "@app/modules/logs/AggregatedJobLogs/aggregatedJobLogs.selectors";
import { API } from "@app/services/api/api";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { SelectMode } from "@ea/shared_components/redux/common.models";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import ExportingModal from "../../common/components/ExportingModal";
import { ExportToCsvModels } from "@ea/shared_types/types";
import { exportToCsv } from "../../../utils/exportCsv";
import { currentUserIdSelector } from "@ea/shared_components/auth/auth.selectors";
import KpiStatisticsModal from "@app/modules/kpis/components/KpiStatisticsModal";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  height: "100%",
});

interface IAggregatedJobLogsProps extends RouteComponentProps<any> {
  tableId: string;
  preferencesId: string;
  columns: ColumnConfig<any>[];
  persistentQuery?: any;
  className?: string;
  hideCommandBar?: boolean;
  projectId?: number;
  pageable?: boolean;
  showHeader?: boolean;
  changeHideAggregatedLogsView?: () => void;
  isLogsViewChanging?: boolean;
  onRowDoubleClick?: () => void;
}
interface IAggregatedJobLogsState extends LogsState {
  statisticsVisible: boolean;
  persistentQuery: IAggregatedJobLogsPersistentQuery;
  isExporting: boolean;
  kpiVisible: boolean;
}

interface IAggregatedJobLogsPersistentQuery {
  projectId?: number;
  mappedWithDevops?: boolean;
}
class AggregatedJobLogs extends React.Component<IConnectProps, IAggregatedJobLogsState> {
  connectedTable: any;

  constructor(props: IConnectProps) {
    super(props);

    this.state = {
      persistentQuery: this.props.persistentQuery || {
        projectId: this.props.projectId,
      },
      statisticsVisible: false,
      isExporting: false,
      kpiVisible: false,
    };
  }

  async componentDidUpdate(prevProps: IConnectProps) {
    if (prevProps.persistentQuery !== this.props.persistentQuery) {
      this.setState({
        persistentQuery: this.props.persistentQuery,
      });
    }
  }

  compareQuery = (
    currentQuery: IAggregatedJobLogsPersistentQuery,
    prevQuery: IAggregatedJobLogsPersistentQuery,
  ) =>
    currentQuery.projectId !== prevQuery.projectId ||
    currentQuery.mappedWithDevops !== prevQuery.mappedWithDevops;

  reload = async () => {
    if (this.connectedTable && this.connectedTable.reload) {
      this.connectedTable.reload();
    }
  };

  openStatistics = () => {
    this.setState({
      statisticsVisible: true,
    });
  };

  closeStatistics = () => {
    this.setState({
      statisticsVisible: false,
    });
  };

  changeExcludeJobLog = async () => {
    const { selectedItems, actions } = this.props;
    const jobIds = selectedItems.map((s) => s.jobId);
    try {
      await API.toggleJobLogsExclusion({ jobIds });
      toast.success(
        <FormattedMessage id={getTranslationKey("messages", "success", "excludeJobs")} />,
      );
    } catch (e) {
      toast.error(<FormattedMessage id={getTranslationKey("messages", "error", "excludeJobs")} />);
    }
    actions.select({ ids: [], mode: SelectMode.Replace });
    this.reload();
  };

  isRowDisabled = (record) => (record && record.isExcluded ? true : false);

  toggleFilterDevopsExecutions = () => {
    const { persistentQuery } = this.state;
    if (!persistentQuery.mappedWithDevops) {
      this.setState({
        persistentQuery: {
          ...persistentQuery,
          mappedWithDevops: true,
        },
      });
    } else {
      const { mappedWithDevops, ...rest } = persistentQuery;
      this.setState({
        persistentQuery: rest,
      });
    }
  };

  exportToExcel = async () => {
    this.setState({ isExporting: true });
    const { tableParams, selected, userId, preferencesId } = this.props;
    const params = createRequestParams(tableParams, AGGREGATED_JOB_LOGS_COLUMNS_CONFIG, {
      defaultOrder: ["startTime DESC"],
    });

    const excludeFields = [
      AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.jobId.dataIndex,
      AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.schedulerJobId.dataIndex,
      AGGREGATED_JOB_LOGS_COLUMNS_CONFIG.projectId.dataIndex,
    ];

    const fields = AGGREGATED_JOB_LOGS_COLUMNS.map((log) => log.props.dataIndex).filter(
      (field) => excludeFields.indexOf(field) === -1,
    );
    await exportToCsv({
      params,
      fields,
      modelName: ExportToCsvModels.EXECUTION_JOB_LOGS,
      onExportStart: () => this.setState({ isExporting: true }),
      onExportFinish: () => this.setState({ isExporting: false }),
      selected,
      userId,
      preferencesId,
    });
  };

  onPerformanceCountersClick = () => {
    this.setState(({ kpiVisible }) => ({
      kpiVisible: !kpiVisible,
    }));
  };

  render() {
    const {
      tableId,
      hideCommandBar,
      pageable,
      showHeader,
      preferencesId,
      selected,
      isLogsViewChanging,
      onRowDoubleClick,
      selectedItems,
      changeHideAggregatedLogsView,
      columns,
    } = this.props;
    const { persistentQuery, isExporting, kpiVisible } = this.state;

    return (
      <Container>
        {hideCommandBar || (
          <CommandBar
            onReload={this.reload}
            onShowStatistics={this.openStatistics}
            changeHideAggregatedLogsView={changeHideAggregatedLogsView}
            selected={selected}
            isLogsViewChanging={isLogsViewChanging}
            changeExcludeJobLog={this.changeExcludeJobLog}
            selectedItems={selectedItems}
            onFilterDevopsExecutions={this.toggleFilterDevopsExecutions}
            showOnlyDevopsExecutions={persistentQuery.mappedWithDevops}
            onExport={this.exportToExcel}
            isExporting={isExporting}
            onPerformanceCountersClick={() => this.onPerformanceCountersClick()}
          />
        )}
        <ChartModal
          visible={!!this.state.statisticsVisible}
          title={<FormattedMessage id={getTranslationKey("common", "label", "statistics")} />}
          onClose={this.closeStatistics}
        >
          <AggregatedJobExecutionAreaChart data={selectedItems} isInModal={true} />
        </ChartModal>
        {kpiVisible && (
          <KpiStatisticsModal
            visible={kpiVisible}
            onClose={this.onPerformanceCountersClick}
            jobId={selectedItems[0].jobId}
          />
        )}
        <ConnectedTable
          setRef={(component) => (this.connectedTable = component)}
          pageable={pageable === false ? pageable : true}
          showHeader={showHeader === false ? showHeader : true}
          columnsConfig={columns || AGGREGATED_JOB_LOGS_COLUMNS}
          persistentQuery={persistentQuery}
          comparePersistentQuery={this.compareQuery}
          tableId={tableId}
          preferencesId={preferencesId}
          stateKey="aggregatedJobLogs"
          tableActions={getAggregatedJobLogsTableActions}
          selectable={true}
          autoReload
          onRowDoubleClick={onRowDoubleClick}
          isRowDisabled={this.isRowDisabled}
        />
        <ExportingModal visible={isExporting} />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, props: IAggregatedJobLogsProps) => ({
  actions: {
    ...bindActionCreators(getAggregatedJobLogsTableActions(props.tableId), dispatch),
  },
});

const mapStateToProps = (state: ApplicationState, props: IAggregatedJobLogsProps) => ({
  ...props,
  tableId: props.tableId,
  tableParams: aggregatedJobLogsDataSelectors.getParamsSelector(state, props.tableId),
  selected: getSelectedSelector(state, props.tableId),
  selectedItems: getSortedDescSelectedItemsSelector(state, props.tableId),
  userId: currentUserIdSelector(state),
});

const connectCreator = connect(mapStateToProps, mapDispatchToProps);

type IConnectProps = ConnectedProps<typeof connectCreator>;

export default withRouter(connectCreator(AggregatedJobLogs));
