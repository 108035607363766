import * as React from "react";

import { ColumnFilters } from "@ea/shared_components/redux/common.filters";
import { PlainObject } from "@ea/shared_components/redux/common.models";
import { ColumnConfig, CustomColumn } from "@ea/shared_components/Table/common.tables";
import { VirtualUser, VIRTUAL_USER_AUTH_TYPE } from "@ea/shared_types/types";
import VirtualUserStatus from "@app/modules/virtualUsers/components/VirtualUserStatus";
import { getTranslationKey } from "@app/translations/translations.helpers";
import { FormattedMessage } from "react-intl";

export const VIRTUAL_USERS_COLUMNS_CONFIG: PlainObject<CustomColumn<VirtualUser>> = {
  name: {
    label: getTranslationKey("table", "name"),
    dataIndex: "name",
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  serverDataLogin: {
    label: getTranslationKey("table", "serverLogin"),
    dataIndex: "serverData.login" as any,
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text, record) => (record.serverData && record.serverData.login) || "",
  },
  systemDataLogin: {
    label: getTranslationKey("table", "systemLogin"),
    dataIndex: "systemData.values.login.value" as any,
    sortable: true,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
    render: (text, record) => {
      if (!record.isSystemAuthEnabled || !record.systemData) {
        return "";
      }

      const loginValue =
        (record.systemData.values as any)?.login?.value ||
        (Array.isArray(record.systemData.values) &&
          record.systemData.values.find((v) => v.isSystemLogin)?.value) ||
        "";
      return loginValue;
    },
  },
  description: {
    label: getTranslationKey("common", "label", "description"),
    dataIndex: "description",
    sortable: false,
  },
  status: {
    label: getTranslationKey("table", "status"),
    dataIndex: "status",
    sortable: true,
    render: (text) => <VirtualUserStatus status={text} />,
    filter: {
      filterType: ColumnFilters.TEXT,
    },
  },
  type: {
    label: getTranslationKey("common", "label", "type"),
    dataIndex: "serverAuthType",
    sortable: false,
    render: (text, record) => {
      const types: string[] = [];

      if (record.isSystemAuthEnabled) {
        types.push("SYSTEM");
      }

      if (record.serverAuthType && record.serverAuthType !== VIRTUAL_USER_AUTH_TYPE.NONE) {
        types.push(record.serverAuthType);
      }

      return types.join(" + ");
    },
  },
};

export const VIRTUAL_USERS_COLUMNS: ColumnConfig<VirtualUser>[] = [
  {
    props: VIRTUAL_USERS_COLUMNS_CONFIG.name,
    frameworkProps: {
      width: "16%",
    },
  },
  {
    props: VIRTUAL_USERS_COLUMNS_CONFIG.systemDataLogin,
    frameworkProps: {
      width: "16%",
    },
  },
  {
    props: VIRTUAL_USERS_COLUMNS_CONFIG.serverDataLogin,
    frameworkProps: {
      width: "16%",
    },
  },
  {
    props: VIRTUAL_USERS_COLUMNS_CONFIG.status,
    frameworkProps: {
      width: "16%",
    },
  },
  {
    props: VIRTUAL_USERS_COLUMNS_CONFIG.type,
    frameworkProps: {
      width: "16%",
    },
  },
  {
    props: VIRTUAL_USERS_COLUMNS_CONFIG.description,
    frameworkProps: {
      width: "16%",
    },
  },
];

export const VIRTUAL_USERS_TABLES_CONFIG = {
  MAIN: {
    id: () => "MAIN",
    preferencesId: "MAIN_VU",
  },
  ALL: {
    id: () => "ALL",
    preferencesId: "VU_ALL",
  },
  PROJECT_ASSIGNMENT: {
    id: (projectId) => `PROJECT_ASSIGNMENT_${projectId}`,
    preferencesId: "VU_PROJECT_ASSIGNMENT",
  },
  PROJECT_ASSIGNMENT_DISPLAY: {
    id: (projectId) => `PROJECT_ASSIGNMENT_DISPLAY_${projectId}`,
    preferencesId: "VU_PROJECT_ASSIGNMENT_DISPLAY",
  },
};
