import { CommonAPI } from "../services/api.common";

export async function downloadFileBlob(file: Blob, name: string) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(file);
  link.download = name;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

export async function downloadFile(path: string, name: string) {
  const file = await CommonAPI.downloadFile({ filePath: path });

  downloadFileBlob(file, name);
}
