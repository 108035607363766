import * as React from "react";
import { getTranslationKey } from "@app/translations/translations.helpers";
import InputField from "@ea/shared_components/Form/Fields/InputField";
import TextAreaField from "@ea/shared_components/Form/Fields/TextAreaField";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import validators, { composeValidators } from "@ea/shared_components/Form/validators";
import { FormApi, getIn } from "final-form";
import { DataTestIds } from "@app/utils/dataTestIds";
import { CodeTemplate, CodeType, CodeTemplateSaveMode } from "@ea/shared_types/types";
import { SelectField } from "@ea/shared_components";
import { OptionType } from "@ea/shared_components/Form/Form.common";
import MonacoEditorField from "@ea/shared_components/CodeField/Monaco/MonacoEditorField";
import { FieldArray } from "react-final-form-arrays";
import FormButton from "@ea/shared_components/Form/FormButton";
import { CaretRightOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { createVersionKey } from "@ea/shared_types/helpers/codeTemplates.helpers";
import { Tooltip } from "antd";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/fontawesome-free-solid";
import { Typography } from "antd";
import { RadioField, RadioType } from "@ea/shared_components/Form/Fields/RadioField";
const { Text } = Typography;

interface ICodeTemplateFormProps {
  values: CodeTemplate;
  form: FormApi;
  codeTemplateGroupOptions: OptionType[];
  versionOptions: OptionType[];
  isEdit: boolean;
}

const ParameterContainer = styled.div({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "baseline",
});

const saveModeOptions = [
  {
    key: CodeTemplateSaveMode.CURRENT,
    value: CodeTemplateSaveMode.CURRENT,
    text: getTranslationKey("codeTemplates", "label", "currentModified"),
  },
  {
    key: CodeTemplateSaveMode.AS_NEW_VERSION,
    value: CodeTemplateSaveMode.AS_NEW_VERSION,
    text: getTranslationKey("codeTemplates", "label", "asNewVersion"),
  },
  {
    key: CodeTemplateSaveMode.AS_NEW_ACTIVE_VERSION,
    value: CodeTemplateSaveMode.AS_NEW_ACTIVE_VERSION,
    text: getTranslationKey("codeTemplates", "label", "asNewActiveVersion"),
  },
];
const renderCustomFields =
  (activeVersion, validate) =>
  ({ fields }) => {
    return (
      <>
        <FormButton
          onClick={() => {
            fields.push({});
          }}
          icon={<PlusOutlined />}
          data-testid={DataTestIds.FORM_BUTTON_ADD_CUSTOM_FIELDS}
        >
          <FormattedMessage id={getTranslationKey("codeTemplates", "label", "addFunctionParam")} />
        </FormButton>
        {fields
          ? fields.map((pathObject, index) => (
              <ParameterContainer key={index}>
                <InputField
                  formItemRowStyle={{ width: "100%" }}
                  formItemControlStyle={{ width: "100%" }}
                  name={`definitions.${activeVersion}.params[${index}].name`}
                  placeholder={getTranslationKey("common", "placeholder", "name")}
                  label={getTranslationKey("common", "label", "name")}
                  validate={validate}
                  required
                />
                <InputField
                  formItemRowStyle={{ width: "100%" }}
                  formItemControlStyle={{ width: "100%" }}
                  name={`definitions.${activeVersion}.params[${index}].description`}
                  placeholder={getTranslationKey("common", "placeholder", "description")}
                  label={getTranslationKey("common", "label", "description")}
                />
                <DeleteOutlined
                  onClick={(event) => {
                    fields.remove(index);
                    event.stopPropagation();
                  }}
                  style={{ color: "red" }}
                />
              </ParameterContainer>
            ))
          : null}
      </>
    );
  };

const codeTemplateExample = `function functionName(myParam) {
    const newValue = \`Hello \${myParam}\`
return newValue
}`;

const CodeTemplateForm = ({
  codeTemplateGroupOptions,
  values,
  isEdit,
  intl,
  versionOptions,
  form,
}: ICodeTemplateFormProps & InjectedIntlProps) => {
  const { whiteSpaceAndSpecialCharValidator, mustStartWithLetter, monacoValidator } =
    validators(intl);
  const currentlyModifedVersion = createVersionKey(getIn(values, "currentlyModifedVersion"));
  const definitionFieldName = `definitions.${currentlyModifedVersion}.code`;
  const codeFieldPristine = form.getFieldState(definitionFieldName)?.pristine;
  return (
    <>
      <InputField
        name="name"
        required
        placeholder={getTranslationKey("common", "placeholder", "name")}
        label={getTranslationKey("common", "label", "name")}
        data-testid={DataTestIds.FORM_INPUT_NAME}
      />
      <TextAreaField
        name="description"
        placeholder={getTranslationKey("common", "placeholder", "description")}
        autoSize={{ minRows: 2, maxRows: 6 }}
        label={getTranslationKey("common", "label", "description")}
        data-testid={DataTestIds.FORM_INPUT_DESCRIPTION}
      />
      <SelectField
        name="codeTemplateGroupId"
        placeholder={getTranslationKey("codeTemplates", "placeholder", "group")}
        options={codeTemplateGroupOptions}
        label={getTranslationKey("codeTemplates", "label", "group")}
        data-testid={DataTestIds.FORM_SELECT_TEMPLATE_GROUP}
        readOnly={isEdit}
        required
      />
      <InputField
        name="functionName"
        required
        placeholder={getTranslationKey("codeTemplates", "placeholder", "functionName")}
        label={getTranslationKey("codeTemplates", "label", "functionName")}
        data-testid={DataTestIds.FORM_INPUT_FUNCTION_NAME}
        validate={composeValidators(mustStartWithLetter, whiteSpaceAndSpecialCharValidator)}
        readOnly={isEdit}
      />
      <SelectField
        name="activeVersion"
        options={versionOptions}
        label={getTranslationKey("codeTemplates", "label", "activeVersion")}
        data-testid={DataTestIds.FORM_SELECT_ACTIVE_VERSION}
        readOnly={!isEdit}
      />

      <Collapse
        bordered={false}
        defaultActiveKey={isEdit ? undefined : "codeSection"}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            data-testid={DataTestIds.ICON_COLLAPSE_DOC_SETTINGS}
          />
        )}
      >
        <CollapsePanel
          header={<FormattedMessage id={getTranslationKey("codeTemplates", "codeSection")} />}
          key="codeSection"
        >
          <SelectField
            name="currentlyModifedVersion"
            options={versionOptions}
            label={getTranslationKey("codeTemplates", "label", "currentlyModifedVersion")}
            data-testid={DataTestIds.FORM_SELECT_CURRENTLY_MODIFIED_VERSION}
            readOnly={!isEdit}
          />
          <FieldArray
            name={`definitions.${currentlyModifedVersion}.params`}
            render={renderCustomFields(
              currentlyModifedVersion,
              composeValidators(mustStartWithLetter, whiteSpaceAndSpecialCharValidator),
            )}
          />
          <Tooltip
            title={
              <Text code style={{ color: "white" }}>
                {codeTemplateExample}
              </Text>
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
          <MonacoEditorField
            name={definitionFieldName}
            width="100%"
            height="400"
            key={`${currentlyModifedVersion ? `${currentlyModifedVersion}_` : ""}advancedEditor`}
            required
            validate={monacoValidator}
            nestedContainerStyle={{ border: "1px solid #d9d9d9", borderRadius: "2px" }}
            mode={CodeType.CODE_TEMPLATE}
            label={getTranslationKey("codeTemplates", "label", "code")}
          />
          {isEdit && !codeFieldPristine && (
            <RadioField
              name="saveMode"
              label={getTranslationKey("codeTemplates", "label", "saveMode")}
              type={RadioType.Button}
              options={saveModeOptions}
              required
            />
          )}
        </CollapsePanel>
      </Collapse>
    </>
  );
};

export default injectIntl(CodeTemplateForm);
