import { createModuleEpics } from "@app/modules/app.reducers";
import { createRequestParams } from "@ea/shared_components/redux/createRequestParams";
import { API } from "@app/services/api/api";
import { System } from "@ea/shared_types/types";
import { systemDictionaryDataSelectors } from "./systemDictionary.selectors";
import { moduleActions } from "./systemDictionary.actions";
import { SYSTEM_DICTIONARY_COLUMNS_CONFIG } from "./systemDictionary.table";

const systemModuleEpics = createModuleEpics<System>(moduleActions, {
  normalizeItem: (userGroup) => userGroup,
  getRequestParams: (state, tableId) => {
    const tableParams = systemDictionaryDataSelectors.getParamsSelector(state, tableId!);
    const params = createRequestParams(tableParams, SYSTEM_DICTIONARY_COLUMNS_CONFIG);
    return params;
  },
  getSingleRequestParams: (query) => {
    return query;
  },
  api: {
    createItem: API.createSystem,
    deleteItem: API.deleteSystem,
    editItem: API.editSystem,
    getItems: API.getSystems,
    getItemsCount: API.getSystemCount,
  },
});

export default systemModuleEpics;
